/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EntityResultNotificationTagsRelations } from '../models/entity-result-notification-tags-relations';
import { NotificationTagsRelations } from '../models/notification-tags-relations';
import { CollectionResultNotificationTagsRelations } from '../models/collection-result-notification-tags-relations';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class NotificationTagsRelationsService extends __BaseService {
  static readonly UpdateNTRelationsPath = '/api/NotificationTagsRelations/UpdateNTRelations';
  static readonly InsertNTRelationsPath = '/api/NotificationTagsRelations/InsertNTRelations';
  static readonly FindPath = '/api/NotificationTagsRelations/Find';
  static readonly FindAllowedPath = '/api/NotificationTagsRelations/FindAllowed';
  static readonly InsertPath = '/api/NotificationTagsRelations';
  static readonly UpdatePath = '/api/NotificationTagsRelations';
  static readonly DeletePath = '/api/NotificationTagsRelations';
  static readonly EnablePath = '/api/NotificationTagsRelations/Enable';
  static readonly DisablePath = '/api/NotificationTagsRelations/Disable';
  static readonly GetAllPath = '/api/NotificationTagsRelations/GetAll';
  static readonly GetByIdPath = '/api/NotificationTagsRelations/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateNTRelationsResponse(entity?: NotificationTagsRelations): __Observable<__StrictHttpResponse<EntityResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NotificationTagsRelations/UpdateNTRelations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  UpdateNTRelations(entity?: NotificationTagsRelations): __Observable<EntityResultNotificationTagsRelations> {
    return this.UpdateNTRelationsResponse(entity).pipe(
      __map(_r => _r.body as EntityResultNotificationTagsRelations)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertNTRelationsResponse(entity?: NotificationTagsRelations): __Observable<__StrictHttpResponse<EntityResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/NotificationTagsRelations/InsertNTRelations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  InsertNTRelations(entity?: NotificationTagsRelations): __Observable<EntityResultNotificationTagsRelations> {
    return this.InsertNTRelationsResponse(entity).pipe(
      __map(_r => _r.body as EntityResultNotificationTagsRelations)
    );
  }

  /**
   * @param params The `NotificationTagsRelationsService.FindParams` containing the following parameters:
   *
   * - `Subtype`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `PartentId`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindResponse(params: NotificationTagsRelationsService.FindParams): __Observable<__StrictHttpResponse<CollectionResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Subtype != null) __params = __params.set('Subtype', params.Subtype.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.PartentId != null) __params = __params.set('PartentId', params.PartentId.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTagsRelations/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @param params The `NotificationTagsRelationsService.FindParams` containing the following parameters:
   *
   * - `Subtype`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `PartentId`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  Find(params: NotificationTagsRelationsService.FindParams): __Observable<CollectionResultNotificationTagsRelations> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultNotificationTagsRelations)
    );
  }

  /**
   * @param params The `NotificationTagsRelationsService.FindAllowedParams` containing the following parameters:
   *
   * - `Subtype`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `PartentId`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindAllowedResponse(params: NotificationTagsRelationsService.FindAllowedParams): __Observable<__StrictHttpResponse<CollectionResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Subtype != null) __params = __params.set('Subtype', params.Subtype.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.PartentId != null) __params = __params.set('PartentId', params.PartentId.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTagsRelations/FindAllowed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @param params The `NotificationTagsRelationsService.FindAllowedParams` containing the following parameters:
   *
   * - `Subtype`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `PartentId`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindAllowed(params: NotificationTagsRelationsService.FindAllowedParams): __Observable<CollectionResultNotificationTagsRelations> {
    return this.FindAllowedResponse(params).pipe(
      __map(_r => _r.body as CollectionResultNotificationTagsRelations)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: NotificationTagsRelations): __Observable<__StrictHttpResponse<EntityResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/NotificationTagsRelations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: NotificationTagsRelations): __Observable<EntityResultNotificationTagsRelations> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultNotificationTagsRelations)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: NotificationTagsRelations): __Observable<__StrictHttpResponse<EntityResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NotificationTagsRelations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: NotificationTagsRelations): __Observable<EntityResultNotificationTagsRelations> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultNotificationTagsRelations)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/NotificationTagsRelations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NotificationTagsRelations/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NotificationTagsRelations/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTagsRelations/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultNotificationTagsRelations> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultNotificationTagsRelations)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultNotificationTagsRelations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTagsRelations/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTagsRelations>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultNotificationTagsRelations> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultNotificationTagsRelations)
    );
  }
}

module NotificationTagsRelationsService {

  /**
   * Parameters for Find
   */
  export interface FindParams {
    Subtype?: string;
    SortBy?: string;
    SortAscending?: boolean;
    PartentId?: string;
    PageSize?: number;
    PageNum?: number;
    ContinuationToken?: string;
  }

  /**
   * Parameters for FindAllowed
   */
  export interface FindAllowedParams {
    Subtype?: string;
    SortBy?: string;
    SortAscending?: boolean;
    PartentId?: string;
    PageSize?: number;
    PageNum?: number;
    ContinuationToken?: string;
  }
}

export { NotificationTagsRelationsService }
