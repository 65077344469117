
// "[DAH]",  // Authority Holder
// "[DDE]",  // Delegator,
// "[DST]",  // Dal State
// "[DVE]",  // Dal Version
// "[DRD]",  // Review Date
// "[DSD]",  // Start Date
// "[DED]"   // End Date
// "[DCP]",  // Composer
// "[DCO]",  // DAL Code
// "[DDI]",  // Division(s)
// "[DFU]",  // Function(s)
// "[DAD]",  // Acceptance Date
// "[DEF]",  // Effective Date
// "[DAC]",  // Acknowledgement Date

import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DAH] => Dal Authority Holder
 */
@Injectable({
  providedIn: 'root'
})

export class DalAuthorityHolderInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[DAH]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string{

      if (config.authorityHolder) {
        return `${config.authorityHolder.firstName} ${config.authorityHolder.lastName}`;
      } else {
        return "N/A";
      }
      return this.infillCode;
    }

  }