import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialog, MatSnackBar, MatTable, MatDialogConfig } from '@angular/material';
import { ModalDialogComponent } from '@app/shared/components/modal-dialog/modal-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormControl } from '@angular/forms';
import { AddLibraryItemModalDialogComponent } from '@app/shared/components/add-library-item-modal-dialog/add-library-item-modal-dialog.component';
import { AddLibrarySectionModalDialogComponent } from '@app/shared/components/add-library-section-modal-dialog/add-library-section-modal-dialog.component';
import { AddLibrarySubSectionModalDialogComponent } from '@app/shared/components/add-library-sub-section-modal-dialog/add-library-sub-section-modal-dialog.component';
import { DalCompleteViewModelService } from '@app/api/services';
import { DalCompleteViewModel, DalLibraryItem, DalSubSection, DalSection, LibrarySubSection, LibrarySection, DalTemplateSection } from '@app/api/models';
import moment from 'moment';
import { DalContentConfiguration } from '@app/shared/shared.module';
import { RemoveSectionEventArgs, SectionSelectedEventArgs, LibraryItemSelectedEventArgs, SubSectionSelectedEventArgs, RemoveLibraryItemEventArgs, RemoveSubSectionEventArgs } from '@app/shared/library-item-processing/dal-content-configuration';


export interface UsersDemo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  employeeID: string;
  status?: string;
}


const usersData: UsersDemo[] = [
  { id: 1, firstName: 'Bruce', lastName: 'Banner', email: 'Bruce.Banner@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegatee', employeeID: 'A0012378', status: 'Active' },
  { id: 2, firstName: 'Bruce', lastName: 'Wayne', email: 'Bruce.Wayne@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegator', employeeID: 'A0000100', status: 'Disabled' },
  { id: 3, firstName: 'Carol', lastName: 'Danvers', email: 'Carol.Damvers@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Reviewer', employeeID: 'A0012312', status: 'Draft' },
  { id: 4, firstName: 'Diana', lastName: 'Prince', email: 'Diana.Prince@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Auhtority Holder', employeeID: 'A0012301', status: 'Review' },
  { id: 5, firstName: 'Natasha', lastName: 'Romanova', email: 'Natasha.Romanova@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Reviewer', employeeID: 'A0012315' },
  { id: 6, firstName: 'Peter', lastName: 'Parker', email: 'Peter.Parker@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegatee', employeeID: 'A0012319' },
  { id: 7, firstName: 'Steve', lastName: 'Rogers', email: 'Steve.Rogers@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegator', employeeID: 'A0012333' },
  { id: 8, firstName: 'Wanda', lastName: 'Maximoff', email: 'Wanda.maximoff@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Admin on Behalf', employeeID: 'A0012344' },

];


@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})


export class DemoComponent implements OnInit {
  dalContentConfiguration: DalContentConfiguration;
  libraryItemTest1: DalLibraryItem;
  displayedColumns = ['more', 'firstName', 'lastName', 'email', 'jobTitle', 'employeeID', 'status'];
  dataSource = usersData;
  newItem: boolean;
  table: MatTable<UsersDemo>;
  name: string;
  nameControl = new FormControl('', Validators.maxLength(50));
  checked = false;
  isProcessing = true;
  readonlyMode = true;
  selected = "option1";
  completeDalView: DalCompleteViewModel;
  date = new FormControl(moment);
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,

  ) { }


  ngOnInit() {
    this.newItem = false;
    // this.dalContentEditMode= false;
    this.completeDalView = {

    };

    this.dalContentConfiguration = new DalContentConfiguration();

    this.dalContentConfiguration.libraryItemSelected.subscribe((args : LibraryItemSelectedEventArgs) => {

      let libraryItem = args.libraryItem;

      // Finds the parent element
      let itemExists = this.dalContentConfiguration.libraryItems.filter(x =>x.libraryItemBase != null && x.libraryItemBase.id == libraryItem.id);
      if(itemExists.length == 0) {

        let dalLibraryItem : DalLibraryItem = {
          libraryItemBase : {
            id : libraryItem.id
          },
          description : libraryItem.description,
          // TODO: Complete with a copy of all the other properties
          name : libraryItem.name,
          libraryType : libraryItem.libraryType,
          contentType : libraryItem.contentType,
          isMandatory : libraryItem.isMandatory,
          limitsValidation : libraryItem.limitsValidation,
          startDate : libraryItem.startDate,
          endDate : libraryItem.endDate,
          order : libraryItem.order,
          itemVariables : libraryItem.itemVariables ? Array.from(libraryItem.itemVariables) : null,
          table : libraryItem.table ? Object.create(libraryItem.table) : null,
          dalTemplateLibraryItemBase : null,
          organizationDataView : libraryItem.organizationDataView ? Array.from(libraryItem.organizationDataView) : [],
          type : 'DalLibraryItem',
          status : 'Draft',
          code : libraryItem.code,
          clientId : null
      };
        this.dalContentConfiguration.libraryItems.push(dalLibraryItem);
      }
      
    });

    this.dalContentConfiguration.sectionSelected.subscribe((args : SectionSelectedEventArgs) => {

      let librarySection = args.librarySection;


      // Finds the parent element
      let sectionExist = this.dalContentConfiguration.sections.filter(x =>  x.sectionBase != null && x.sectionBase.id == librarySection.id);
      if(sectionExist.length == 0) {

        let dalSection : DalSection = {
          sectionBase : {
            id : librarySection.id,
            title : librarySection.title
          },
          title : librarySection.title,
          clientId : null
      };
        this.dalContentConfiguration.sections.push(dalSection);
      }
    });

    this.dalContentConfiguration.subSectionSelected.subscribe((args : SubSectionSelectedEventArgs) => {
      
      
      let librarySubSection = args.librarySubSection;
      let parentSection = args.librarySection;

      // TODO: Control that there is a Parent Section
      if(parentSection == null) {
        return;
      }


      // Finds the parent element
      let sectionExist = this.dalContentConfiguration.subSections.filter(x => x.subSectionBase != null && x.subSectionBase.id == librarySubSection.id);
      if(sectionExist.length == 0) {

        let dalSubSection : DalSubSection = {
          subSectionBase : {
            id : librarySubSection.id,
            title : librarySubSection.title
          },
          parentSection : {
            id : parentSection.id,
            title : parentSection.title
          },
          title : librarySubSection.title,
          clientId : null
      };
        this.dalContentConfiguration.subSections.push(dalSubSection);
      }


    });

    this.dalContentConfiguration.removeSelectedLibraryItem.subscribe((args : RemoveLibraryItemEventArgs) => {
      // TODO: Actually, the logic to remove an item is a little more complex. Involves to set the status of an item
      const index = this.dalContentConfiguration.libraryItems.indexOf(args.libraryItem, 0);
      if (index > -1) {
        this.dalContentConfiguration.libraryItems.splice(index, 1);
      }
    });

    this.dalContentConfiguration.removeSelectedSection.subscribe((args : RemoveSectionEventArgs) => {
      // TODO: Actually, the logic to remove an item is a little more complex. Involves to set the status of an item
      const index = this.dalContentConfiguration.sections.indexOf(args.librarySection, 0);
      if (index > -1) {
        this.dalContentConfiguration.sections.splice(index, 1);
      }
    });

    this.dalContentConfiguration.removeSelectedSubSection.subscribe((args : RemoveSubSectionEventArgs) => {
      // TODO: Actually, the logic to remove an item is a little more complex. Involves to set the status of an item
      const index = this.dalContentConfiguration.subSections.indexOf(args.librarySubSection, 0);
      if (index > -1) {
        this.dalContentConfiguration.subSections.splice(index, 1);
      }
    });

    this.generateData();
    

  }

  generateData(){
    var subSections:LibrarySubSection[] = [
      {
        id:"{59b7ef6a-207a-4735-afd6-a514edfb68a4}-{1}",
        title:"Sub-Section Title demo",
        parentSection:{
          id:"{a76716b8-69c6-441d-9f51-1becdecd99b5}-{1}",
          title:"Non insurance"
        },
        clientId : "",
      },
      {
        id:"SubSectionTest2",
        title:"SubSection Test 2",
        clientId : "",
        parentSection:{
          id:"SectionTest2",
          title:"Section Test 2"
        },
      },
      {
        id:"SubSectionTest3",
        title:"SubSection Test 3",
        clientId : "",
        parentSection:{
          id:"SectionTest2",
          title:"Section Test 2"
        },
      }
    ];

    var sections:LibrarySection[] =[
      {
      id : "{a76716b8-69c6-441d-9f51-1becdecd99b5}-{1}",
      title : "General Uderwriting Principles and Practice: 2",
      type:"LibrarySection",
      clientId:""
    },
      {
        id : "{59b7ef6a-207a-4735-afd6-a514edfb68a4}-{1}",
        title : "SECTION 2",
        type:"LibrarySection",
        clientId:""
      },
      {
        id : "{59b7ef6a-207a-4735-afd6-a514edfb68a5}-{1}",
        title : "SECTION 3",
        type:"LibrarySection",
        clientId:""
      },
      {
        id : "{59b7ef6a-207a-4735-afd6-a514edfb68a6}-{1}",
        title : "SECTION 4",
        type:"LibrarySection",
        clientId:""
      }
    ];

    var libraryItemWithSubSection:DalLibraryItem = {
      clientId: null,
        id:"001AB",
        code:"1234AHFC",
        name:"Sub-Section Title",
        libraryType: {
          name: "Clause"
        },
        contentType:"TEXT",
        isMandatory:false,
        section: {
          id: sections[0].id,
          title: sections[0].title
        },
        subSection: {
          id : subSections[0].id,
          title : subSections[0].title,
        },
        limitsValidation:false,
        startDate: new Date("01/01/2020").toISOString(), 
        endDate: new Date("01/01/2021").toISOString(),
        status:"Active",
        description:"The Autority Holder,<b><i> [DAU],</i></b> <br /> Accepts to manage the authority of <b>[BA:$1] </b> for the Class of Business [DS:COB1] in [GT:COU1] for <u><b>[OR:DIV1].</b></u>"+
        "<br/> some text some text some text <br /> <br/> Text test <br /><br /><br/> Extra large test for test the length into description in library items <br />",
         order: 2,
    };
    //this.dalContentConfiguration.addLibraryItem(libraryItemWithSubSection, libraryItemWithSubSection.section, libraryItemWithSubSection.subSection, null , null);

    var libraryItemWithSection:DalLibraryItem = {
      clientId: null,
        id:"001AB",
        code:"1234AHFC234234234444",
        name:"General Principle",
        libraryType: {
          name: "Clause"
        },
        contentType:"TEXT",
        isMandatory:false,
        section: {
          id : sections[0].id,
          title : sections[0].title
        },
        limitsValidation:false,
        startDate: new Date("01/01/2020").toISOString(), 
        endDate: new Date("01/01/2021").toISOString(),
        status:"Active",
        description:"The Autority Holder,<b><i> [DAU],</i></b> <br /> Accepts to manage the authority of <b>[BA:$1] </b> for the Class of Business [DS:COB1] in [GT:COU1] for <u><b>[OR:DIV1].</b></u>"+
        "<br/> some text some text some text <br /> <br/> Text test <br /><br /><br/> Extra large test for test the length into description in library items <br />",
         order: 2,
    };
   // this.dalContentConfiguration.addLibraryItem(libraryItemWithSection, libraryItemWithSection.section, libraryItemWithSection.subSection, null, null);

    var libraryItem:DalLibraryItem = {
      clientId: null,
        id:"001AB",
        code:"1234TEST",
        name:"General Principle",
        libraryType: {
          name: "Clause"
        },
        contentType:"TEXT",
        isMandatory:true,
        limitsValidation:false,
        startDate: new Date("01/01/2020").toISOString(), 
        endDate: new Date("01/01/2021").toISOString(),
        status:"Active",
        description:"The Autority Holder, [DAH],<b><i> [DAU],</i></b> <br /> Accepts to manage the authority of <b>[BA:$1] </b> for the Class of Business [DS:COB1] in [GT:COU1] for <u><b>[OR:DIV1].</b></u>"+
        "<br/> some text some text some text <br /> <br/> Text test <br /><br /><br/> text extra large for test the length into description in library items <br />",
         order: 1,
    };

    var libraryItemTable:DalLibraryItem = {
      clientId: null,
        id:"001AB_TABLE",
        code:"1234TEST",
        name:"General Principle",
        libraryType: {
          name: "Clause"
        },
        contentType:"TABLE",
        isMandatory:true,
        limitsValidation:false,
        startDate: new Date("01/01/2020").toISOString(), 
        endDate: new Date("01/01/2021").toISOString(),
        status:"Active",
        table: {
          columnsLength : 3,
          rows : [
            {
              cells : [
                {
                  text : 'Classes of Business'
                },
                {
                  text : 'Ammount'
                },
                {
                  text : `Comments (... and a long text to show the expansion of a row ... <br />
                    and a long text to show the expansion of a row... <br />
                    and a long text to show the expansion of a row <br />
                  ... and a long text to show the expansion of a row... <br />
                  and a long text to show the expansion of a row... and a long text to show the expansion of a row
                  ... and a long text to show the expansion of a row)`
                }
              ],
              order : 1,
              id : '123'
            },
            {
              cells : [
                {
                  text : '<span class="infill-change-value">Select class of business</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">Insert Text Here</span> Other Text'
                }
              ],
              order : 1,
              id : '123'
            },
            {
              cells : [
                {
                  text : '<span class="infill-change-value">Select class of business</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">Insert Text Here</span> Other Text'
                }
              ],
              order : 1,
              id : '123'
            },{
              cells : [
                {
                  text : '<span class="infill-change-value">Select class of business</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">Insert Text Here</span> Other Text'
                }
              ],
              order : 1,
              id : '123'
            },
            {
              cells : [
                {
                  text : '<span class="infill-change-value">Select class of business</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
                },
                {
                  text : '<span class="infill-change-value">Insert Text Here</span> Other Text'
                }
              ],
              order : 1,
              id : '123'
            }
          ]
        },
        order: 1,
    };


    // this.dalContentConfiguration.addLibraryItem(libraryItem, libraryItem.section, libraryItem.subSection, null, null);
    // this.dalContentConfiguration.addLibraryItem(libraryItemTable, libraryItem.section, libraryItem.subSection, null, null);
    sections.forEach(x =>
      {
        this.dalContentConfiguration.addSection(x,null,null);
      }
    );
    subSections.forEach(x =>
      {
        // this.dalContentConfiguration.addSubSection(x,{id: libraryItemWithSubSection.section.id, title: libraryItemWithSubSection.section.title}, null,null);
        this.dalContentConfiguration.addSubSection(x,{id: libraryItemWithSubSection.section.id, title: libraryItemWithSubSection.section.title}, null,null);
      }
    );
  }

  headerMenuClick() {
    // alert("Header menu clicked.");
  }

  openManagedAuthoritydemo() {
    // alert("Admin on Behalf header Clicked");
  }

  addNewItem() {
    this.newItem = true;
    const newData: UsersDemo[] = [
      { id: 0, firstName: '', lastName: '', email: '', jobTitle: '', employeeID: '' },
      { id: 1, firstName: 'Bruce', lastName: 'Banner', email: 'Bruce.Banner@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegatee', employeeID: 'A0012378' },
      { id: 2, firstName: 'Bruce', lastName: 'Wayne', email: 'Bruce.Wayne@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegator', employeeID: 'A0000100' },
      { id: 3, firstName: 'Carol', lastName: 'Danvers', email: 'Carol.Damvers@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Reviewer', employeeID: 'A0012312' },
      { id: 4, firstName: 'Diana', lastName: 'Prince', email: 'Diana.Prince@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Auhtority Holder', employeeID: 'A0012301' },
      { id: 5, firstName: 'Natasha', lastName: 'Romanova', email: 'Natasha.Romanova@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Reviewer', employeeID: 'A0012315' },
      { id: 6, firstName: 'Peter', lastName: 'Parker', email: 'Peter.Parker@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegatee', employeeID: 'A0012319' },
      { id: 7, firstName: 'Steve', lastName: 'Rogers', email: 'Steve.Rogers@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegator', employeeID: 'A0012333' },
      { id: 8, firstName: 'Wanda', lastName: 'Maximoff', email: 'Wanda.maximoff@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Admin on Behalf', employeeID: 'A0012344' },

    ];
    this.dataSource = newData;
    //this.dataSource.unshift(newRow);
    this.table.renderRows();
  }

  CloseNewItem() {
    this.newItem = false;
    const newData: UsersDemo[] = [
      { id: 1, firstName: 'Bruce', lastName: 'Banner', email: 'Bruce.Banner@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegatee', employeeID: 'A0012378' },
      { id: 2, firstName: 'Bruce', lastName: 'Wayne', email: 'Bruce.Wayne@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegator', employeeID: 'A0000100' },
      { id: 3, firstName: 'Carol', lastName: 'Danvers', email: 'Carol.Damvers@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Reviewer', employeeID: 'A0012312' },
      { id: 4, firstName: 'Diana', lastName: 'Prince', email: 'Diana.Prince@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Auhtority Holder', employeeID: 'A0012301' },
      { id: 5, firstName: 'Natasha', lastName: 'Romanova', email: 'Natasha.Romanova@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Reviewer', employeeID: 'A0012315' },
      { id: 6, firstName: 'Peter', lastName: 'Parker', email: 'Peter.Parker@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegatee', employeeID: 'A0012319' },
      { id: 7, firstName: 'Steve', lastName: 'Rogers', email: 'Steve.Rogers@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Delegator', employeeID: 'A0012333' },
      { id: 8, firstName: 'Wanda', lastName: 'Maximoff', email: 'Wanda.maximoff@CTInsuretechDAL.onmicrosoft.com', jobTitle: 'Admin on Behalf', employeeID: 'A0012344' },

    ];
    this.dataSource = newData;
    //this.dataSource.unshift(newRow);
    this.table.renderRows();

  }

  TestMessages() {

    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '550px',
      data: { title: "Warning", message: "You are about to Do <b> " + "Something" + "</b> ,so you recibe this message <br />  Do you want to continue?", okText: "Yes, do it." }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      let confirmRemove: boolean = result;
      if (confirmRemove == true) {
        this.showSnackBar('So, you press to do it.');
      }
      else {
        this.showSnackBar('So, you choose cancel');
      }
    },
      (err: HttpErrorResponse) => {
        this.showSnackBar('Something went wrong, please try again.');

      });

  }

  // snack bar to show message
  showSnackBar(msg: string): void {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  validate(): boolean {
    let valid = true;
    if (this.name == undefined || this.name == '') {
      this.nameControl.markAsTouched();
      valid = false;
    }
    return valid;
  }

  displaySearchSection() {

  }

  onCancelClick() {

  }

  onApplyClick() {

  }

  onEnableEditClick() {

  }

  onBackClick() {

  }
}
