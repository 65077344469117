import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [AED] => Authority Holder End Date
 */
@Injectable({
  providedIn: 'root',
})
export class AHEndDateInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[AED]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(!config.authorityHolderEmployeeInfo) {
        return "N/A"
      }

      let AHInfo = config.authorityHolderEmployeeInfo; 
      if (AHInfo.endDate == null) 
        return "N/A";
      else if (AHInfo.endDate)
       {
        if( AHInfo.endDate.includes("<span"))
        return AHInfo.endDate;
        else 
        return this.dateToString(AHInfo.endDate);
       } 

      return this.infillCode;
    }
  }