import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DCP] => Dal Composer
 */
@Injectable({
  providedIn: 'root',
})
export class DalComposerInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[DCP]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {
      if (config.composer) {
        return `${config.composer.firstName} ${config.composer.lastName}`;
      } else {
        return "N/A";
      }
      return this.infillCode;
    }
  }