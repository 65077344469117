import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material';
import { DalContentConfiguration } from '@app/shared/shared.module';
import { DalTemplateLibraryItem, DalLibraryItem, LibraryItem } from '@app/api/models';

@Component({
  selector: 'app-preview-text-library-item',
  templateUrl: './preview-text-library-item.component.html',
  styleUrls: ['./preview-text-library-item.component.scss']
})
export class PreviewTextLibraryItemComponent implements OnInit {

  currentLibraryItem: DalTemplateLibraryItem | DalLibraryItem | LibraryItem;
  dalContentConfiguration : DalContentConfiguration;
  editMode: boolean;
  
  constructor(
    public dialog: MatDialog,
    private dialogRef : MatDialogRef<PreviewTextLibraryItemComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) { 

      this.editMode = data.editMode;

      // Sets the current LibraryItem
      if (data.currentLibraryItem) {
        this.currentLibraryItem = data.currentLibraryItem;
      }

      if(data.dalContentConfiguration) {
        this.dalContentConfiguration = data.dalContentConfiguration;
      }
    }

  ngOnInit() {
  }

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  /**
   * Closes the preview Dialog.
   */
  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

}
