import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { GuidService } from '../services/guid/guid.service';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
    constructor(private guidService: GuidService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // merge the correlation id into the existing headers
        const correlationIdRequest = req.clone({
        headers: req.headers.set('X-Correlation-ID', this.guidService.newGuid()),
        });
        return next.handle(correlationIdRequest);
    }
}