import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { GlobalService } from '@app/shared/services/global/global.service';
import {  UsersService} from '@app/api/services';
import { UserService } from '@app/shared/services/user/user.service';
import { UserView } from '@app/api/models';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { Router} from '@angular/router';
import { ModalDialogManagingAuthorityComponent } from '@app/shared/components/modal-dialog-managing-authority/modal-dialog-managing-authority.component';
import { environment } from '@env/environment';
import { CurrentTenantService } from '@app/shared/services/tenant/tenant.service';


@Component({
  selector: 'app-managed-authorities-table',
  templateUrl: './managed-authorities-table.component.html',
  styleUrls: ['./managed-authorities-table.component.scss']
})

export class ManagedAuthoritiesTableComponent implements OnInit {
 dataSource: UserView[] =[]; 
 _loggedInUserEmail: string
 _delegatorsDataSource: Array<Object>;
 _isAdminOnBehalf :boolean ;
 page = 1;
 SearchText: string = "";
 sMUILU: string;

 showMoreResults: boolean;
 showSearchResults: boolean;
 selectionAutority: boolean = true;
 @Output() openedMenu = new EventEmitter<boolean>();

  constructor(
    private _globalService: GlobalService,
    private _apiUsersService: UsersService,
    private _userService: UserService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _router: Router,
    private _currentTenantService: CurrentTenantService
  )
  {
    this._apiUsersService.rootUrl = environment.ApiUrl;
  }

  ngOnInit() {
    this.checkUserLoggedIn();
  }

  // Fn to check if the user loggedIn
  checkUserLoggedIn() {

    this.spinnerService.show();
    this._currentTenantService
    .verifyAccessToken(true)
    .toPromise()
    .then(value => {
      if (value) {

        this._loggedInUserEmail = this._userService.msAdal.LoggedInUserEmail.toLowerCase();
        this._isAdminOnBehalf =false;
        this.doSearch("");
        this.sMUILU = sessionStorage.getItem('selectedManagedUseIsLoggedUser');        
    
        if (this.sMUILU!==null&&this.sMUILU!=='null') 
        {this._isAdminOnBehalf = true} 
        else{
          this._isAdminOnBehalf = false;
        }


      } else {
        this.spinnerService.hide();
      }
    }, (error) => {
      this.spinnerService.hide();
      this._userService.msAdal.login();
    });
  }

// set the selected manage authority.
  selected(user:any)
  {
      this._globalService.userManager.selectedManagedUseIsLoggedUser = false;
      this._globalService.userManager.selectedManagedUser ={
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        id: user.id,
      }
      sessionStorage.setItem ('selectedManagedUseIsLoggedUser', user.email + "|" + user.firstName + "|" + user.lastName +"|"+user.id );

      
      this._globalService.changeManagedUser.next(this._globalService.userManager);
      this.openedMenu.emit(false);
  };

// returns the complete name of the current selected authority;
 getCurrentMA_Name()
 {
   var managedUser = this._globalService.getSelectedManagedUser();
   return managedUser.firstName + " " +  managedUser.lastName;
 }

 // return the mail of the current selected authority.
 getCurrentMA_email()
 {
  var managedUser = this._globalService.getSelectedManagedUser();
   return managedUser.email;
 }

onSearch(filterText: string) {
  this.page = 1;
  this.SearchText = filterText;

  this.doSearch(filterText)
}

searchMoreResults(){
  this.page += 1;
  this.doSearch(this.SearchText);
}

  // API call to retrieve search results
doSearch(filterText: string){
    this.spinnerService.show();
    this._apiUsersService.GetPaginated (
      {
        limit: this.page,
        DelegateParentEmail: this._loggedInUserEmail,
        AdministratorEmail: this._loggedInUserEmail,
        SearchText: filterText.trim()
    })
    .subscribe(data => {
      this.spinnerService.hide();
        // when result found, show table. Else hide it.
        if(data)
          this.showMoreResults = data.hasMoreResults;
        if (data && data.users && data.users.length != 0) {
          this.dataSource = data.users;
          this.showSearchResults = true;
        } else {
          this.dataSource = null;
          this.showSearchResults = false;          
        }
      },
      (err: HttpErrorResponse) => {
        this.spinnerService.hide();
        console.log(err.message);
      }
    );
  }

  // set the logged user as currently managed authority
  setMyOunAuthority()
  {
        
    this._globalService.userManager.selectedManagedUseIsLoggedUser = true;
    this._globalService.userManager.selectedManagedUser ={
      firstName: this._globalService.getCurrentLoggedUser().firstName,
      lastName: this._globalService.getCurrentLoggedUser().lastName,
      email: this._globalService.getCurrentLoggedUser().email,
      id: this._globalService.getCurrentLoggedUser().id
    }
    this._globalService.changeManagedUser.next(this._globalService.userManager);
    this.openedMenu.emit(false);
    

    sessionStorage.setItem ('selectedManagedUseIsLoggedUser', null);


  }


 isMyOwnAuthority(): boolean{

  let result :boolean = false;
  result = this._globalService.userManager.selectedManagedUseIsLoggedUser;

  // if (this.sMUILU!==null)    
  // {
  //   result = false;
  // }
   return result;

  }
}
