export const environment = {
  production: true,
  ApiUrl: 'https://authorityhub.io',
  env: 'prod',
  tenant: 'common',
  clientId: '3fd3ddac-1204-468f-b262-88536d18ff6e',
  redirectUri: 'https://authorityhub.io/admin',
  navigateToLoginRequestUrl: false,
  cacheLocation: '<localStorage / sessionStorage>',
  endPoints: {
    '/':
      'https://charlestaylorplc.onmicrosoft.com/6432605a-92a9-438a-a3ac-d8411f0c0f2f',
    'https://authorityhub.io/':
      'https://charlestaylorplc.onmicrosoft.com/6432605a-92a9-438a-a3ac-d8411f0c0f2f'
  },
  logo:'assets/images/QBELogo.svg',
  useNewDALContent: true,
  productVersion: '2.1.0.14',
  showBanner:true
};
