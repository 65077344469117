import { ProcessInfillVariable } from "./process-infill-variable";
import { DalContentConfiguration } from "../shared.module";
import { ItemVariable } from "@app/api/models";

/**
 * Allow us to setup strategies to complete the information of a TEXT PREVIEW
 */
export class ProcessInformationalVariable extends ProcessInfillVariable {


  /**
   * Constructor
   */
  constructor() {
    super();
  }

  public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string{
    return `${this.infillCode} [Not yet implemented]`;
  }
}