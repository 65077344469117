import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';

@Component({
  selector: 'app-modal-acceptance-checkbox',
  templateUrl: './modal-acceptance-checkbox.html',
  styleUrls: ['./modal-acceptance-checkbox.scss']
})
export class ModalAcceptanceCheckboxComponent implements OnInit {
  checkBox: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<ModalAcceptanceCheckboxComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }
  accept()
  {
    //send logic to accept button in dal
    this.dialogRef.close({checked: this.checkBox});
  }
 
}