import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, CanActivateChild } from '@angular/router';
import { UserService } from '@app/shared/services/user/user.service';
import { MatSnackBar } from '@angular/material';
import { TenantService } from '@app/api/services/tenant.service';
import { AuthenticationGuard, MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { environment } from '@env/environment';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UsersService } from '@app/api/services';
import { GlobalService } from '@app/shared/services/global/global.service';
import { CurrentTenantService } from '@app/shared/services/tenant/tenant.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _tenantService: TenantService,
    private _msadal: MsAdalAngular6Service,
    private _userApiServices:UsersService,
    private _globalService: GlobalService,
    private _spinnerService: Ng4LoadingSpinnerService,
    private http:HttpClient,
    private currentTenantService: CurrentTenantService
    ) {
      this._tenantService.rootUrl = environment.ApiUrl;
      this._userApiServices.rootUrl = environment.ApiUrl;
    }


  msieversion() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
  
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
      {
          return true;
      }
      else  // If another browser, return 0
      {
          return false;
      }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.msieversion()) {
      return true;
    }
    // TODO: for Renew token (see frame name)
    if (window !== window.parent && !window.opener)
    {
      return true;
    }

    this._spinnerService.show();
    return this.currentTenantService.verifyAccessToken(true)
      .pipe(
        // Check User Loged In
        map((token: boolean) => {
            if (!token) {
              this._msadal.login();
              return false;
            }

            return true;
          }
        ),
        // Check User Enabled
        mergeMap((token: boolean) => {
            if (!token) {
              return of(false);
            }

            const excludeAccessDeniedRoutePath = ':client/access-denied';
            const excludeInvalidUserRoutePath = ':client/invalid-user';
            if (route.routeConfig.path == excludeAccessDeniedRoutePath || route.routeConfig.path == excludeInvalidUserRoutePath) {
              return of(true);
            }
            return this._userApiServices.UserExistAndEnableAsync(this._userService.msAdal.LoggedInUserEmail.toLowerCase())
              .pipe(
                mergeMap((data : any) => {

                  this._globalService.userDisabled = !data.result;
                  if (this._globalService.userDisabled && data.type == "invalid-user")
                  {
                    this._router.navigate(["/"+ this.currentTenantService.getTenantName() + '/' + data.type]);
                    return of(false)
                  }else if (this._globalService.userDisabled)
                  {
                    this._router.navigate(["/"+ this.currentTenantService.getTenantName() + '/access-denied']);
                    return of(false)
                  }

                  return of(true);

                }),
                catchError(
                  (err: HttpErrorResponse) => {
                    console.log(err.message);

                    // TODO: Show error indicating that the user can't be accessed

                    return of(false)
                  }));

          }
        ),

        // Check User Synced
        mergeMap((token: boolean) => {

          if (!token) {
            return of(false);
          }

          const outOfSyncRoute = '/'+ this.currentTenantService.getTenantName() + '/out-of-sync';

          const excludeOutOfSyncPath = ':client/out-of-sync';
          if (route.routeConfig.path == excludeOutOfSyncPath) {
            return of(true);
          }

          const excludeAccessDeniedRoutePath = ':client/access-denied';
          const excludeInvalidUserRoutePath = ':client/invalid-user';
          if (route.routeConfig.path == excludeAccessDeniedRoutePath || route.routeConfig.path == excludeInvalidUserRoutePath) {
            return of(true);
          }

          return this._userApiServices.UserSynchronized(this._userService.msAdal.LoggedInUserEmail.toLowerCase())
            .pipe(
              mergeMap((data : boolean) => {

                if(!data) {
                  this._router.navigate([outOfSyncRoute]);
                  return of(false);
                }
                return of(true);
              }),
              catchError(
                (err: HttpErrorResponse) => {
                  console.log(err.message);

                  // TODO: Show error indicating that the userAcceptedTermns can be accessed

                  return of(false)
                }));
        }),

        // Check User Accepted termns
        mergeMap((token: boolean) => {

          if (!token) {
            return of(false);
          }

          if (this._globalService.userAcceptedTerms) {
            return of(true);
          }

          const privacyRoute = '/'+ this.currentTenantService.getTenantName() + '/privacy';

          const excludePrivacyRoutePath = ':client/privacy';
          if (route.routeConfig.path == excludePrivacyRoutePath) {
            return of(true);
          }
          const excludeOutOfSyncPath = ':client/out-of-sync';
          if (route.routeConfig.path == excludeOutOfSyncPath) {
            return of(true);
          }
          const excludeAccessDeniedRoutePath = ':client/access-denied';
          const excludeInvalidUserRoutePath = ':client/invalid-user';
          if (route.routeConfig.path == excludeAccessDeniedRoutePath || route.routeConfig.path == excludeInvalidUserRoutePath) {
            return of(true);
          }

          return this._userApiServices.UserAcceptTerms(this._userService.msAdal.LoggedInUserEmail.toLowerCase())
            .pipe(
              mergeMap((data : boolean) => {

                this._globalService.userAcceptedTerms = data;
                if(!data) {
                  this._router.navigate([privacyRoute]);
                  return of(false);
                }
                return of(true);
              }),
              catchError(
                (err: HttpErrorResponse) => {
                  console.log(err.message);

                  // TODO: Show error indicating that the userAcceptedTermns can be accessed

                  return of(false)
                }));
        }),

        // Check user roles
        mergeMap((token: boolean) => {
          if (!token) {
            return of(false);
          }

          const excludePrivacyRoutePath = ':client/privacy';
          if (route.routeConfig.path == excludePrivacyRoutePath) {
            return of(true);
          }
          const excludeOutOfSyncPath = ':client/out-of-sync';
          if (route.routeConfig.path == excludeOutOfSyncPath) {
            return of(true);
          }
          const excludeAccessDeniedRoutePath = ':client/access-denied';
          const excludeInvalidUserRoutePath = ':client/invalid-user';
          if (route.routeConfig.path == excludeAccessDeniedRoutePath || route.routeConfig.path == excludeInvalidUserRoutePath) {
            return of(true);
          }


          const loggedInUserRole = this._userService.getUserRole();
          const roles = route.data.roles as Array<string>;

          if (roles === undefined || roles === null || roles.length === 0) {
            return of(true);
          }

          let user;
          for (let i = 0; i < loggedInUserRole.length; i++ ) {
            if (roles.indexOf(loggedInUserRole[i]) > -1) {
                return of(true);
            } else {
              user = loggedInUserRole[i];
            }
          }

          this.showSnackBar(user + ' is not authorized to access this page');
          return of(false);
          }),

        // remove spinner
        map((token: boolean) => {
          this._spinnerService.hide();
          return token;
        }),
        catchError(err => {
          this._spinnerService.hide();
          this._msadal.login();
          return of(false);
      }));
  }

  // snack bar to show message
  showSnackBar(msg: string): void {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }
}
