import { Component, OnInit, Inject } from '@angular/core';
import { DalContentConfiguration } from '@app/shared/shared.module';
import { DalLibraryItem, LibraryItem, DalTemplateLibraryItem, ItemVariable, ItemVariableValueView, OrganizationDataView, OrganizationData } from '@app/api/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-organization-variable',
  templateUrl: './modal-organization-variable.component.html',
  styleUrls: ['./modal-organization-variable.component.scss']
})
export class ModalOrganizationVariableComponent implements OnInit {
  organizationSelected: OrganizationData | OrganizationDataView;
  public organizationsValues: Array<OrganizationDataView>;
  dalContentConfiguration: DalContentConfiguration;
  currentLibraryItem: LibraryItem | DalLibraryItem | DalTemplateLibraryItem;
  variable: ItemVariable;

  constructor(
    public dialogRef: MatDialogRef<ModalOrganizationVariableComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.dalContentConfiguration = data.dalContentConfiguration;
    this.currentLibraryItem = data.currentLibraryItem;
    this.variable = data.variable;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (this.variable.variableCode === 'DVS') {
      this.organizationsValues = this.dalContentConfiguration.divisions;
      if (this.variable.currentValue != null && this.variable.currentValue.valueString !== '') {
        const foundSelected = this.dalContentConfiguration.divisions.find(x => x.orgGroupName === this.variable.currentValue.valueString);

        if (foundSelected != null) {
          this.organizationSelected = foundSelected;
        }
      }
      if (this.organizationSelected == null) {
        if (this.variable.defaultValue !== null && this.variable.defaultValue.valueString !== '') {
          const foundSelected = this.dalContentConfiguration.divisions.find(x => x.orgGroupName === this.variable.defaultValue.valueString);
          if (foundSelected !== null) {
            this.organizationSelected = foundSelected;
          } else {
            this.organizationSelected = null;
          }
        }
      }
    }
    if (this.variable.variableCode === 'FUNC') {
      this.organizationsValues = this.dalContentConfiguration.functions;
      if (this.variable.currentValue != null && this.variable.currentValue.valueString !== '') {
        const foundSelected = this.dalContentConfiguration.divisions.find(x => x.orgGroupName === this.variable.currentValue.valueString);

        if (foundSelected != null) {
          this.organizationSelected = foundSelected;
        }
      }
      if (this.organizationSelected == null) {
        if (this.variable.defaultValue != null && this.variable.defaultValue.valueString !== '') {
          const foundSelected = this.dalContentConfiguration.divisions.find(x => x.orgGroupName === this.variable.defaultValue.valueString);
          if (foundSelected != null) {
            this.organizationSelected = foundSelected;
          } else {
            this.organizationSelected = null;
          }
        }
      }
    }
  }
  onYesClick() {
    const newValue: ItemVariableValueView = {};
    this.currentLibraryItem.status ="Update";
    if (this.organizationSelected) {
      newValue.valueString = this.organizationSelected.orgGroupName;
      if (this.organizationSelected['title'] != null) {
        newValue.description = this.organizationSelected['title'];
      } else {
        newValue.description = '';
      }
      this.variable.currentValue = newValue;
    } else {
      this.variable.currentValue = null;
    }

  }
}
