import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [AEA] => Authority Holder Email Address
 */
@Injectable({
  providedIn: 'root',
})
export class AHEmailInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[AEA]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(!config.authorityHolder) {
        return "N/A"
      }

      let AHInfo = config.authorityHolder; 
      if(AHInfo.email && AHInfo.email.trim() != "") 
        return AHInfo.email;
      else if (AHInfo.email == null || AHInfo.email.trim() == "")
        return "N/A";

      return this.infillCode;
    }
  }