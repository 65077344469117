/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DalSearchFilterData } from '../models/dal-search-filter-data';
import { DalSearchResponse } from '../models/dal-search-response';
@Injectable({
  providedIn: 'root',
})
class DalSearchService extends __BaseService {
  static readonly GetPath = '/api/DalSearch';
  static readonly QuickSearchPath = '/api/DalSearch/QuickSearch';
  static readonly AdvanceSearchPath = '/api/DalSearch/AdvanceSearch';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetResponse(): __Observable<__StrictHttpResponse<DalSearchFilterData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalSearchFilterData>;
      })
    );
  }
  /**
   * @return Success
   */
  Get(): __Observable<DalSearchFilterData> {
    return this.GetResponse().pipe(
      __map(_r => _r.body as DalSearchFilterData)
    );
  }

  /**
   * @param params The `DalSearchService.QuickSearchParams` containing the following parameters:
   *
   * - `WorkdayId`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `Country`:
   *
   * - `ClassofBusiness`:
   *
   * - `AuthorityHolderLastName`:
   *
   * - `AuthorityHolderFirstName`:
   *
   * @return Success
   */
  QuickSearchResponse(params: DalSearchService.QuickSearchParams): __Observable<__StrictHttpResponse<Array<DalSearchResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WorkdayId != null) __params = __params.set('WorkdayId', params.WorkdayId.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.Country != null) __params = __params.set('Country', params.Country.toString());
    if (params.ClassofBusiness != null) __params = __params.set('ClassofBusiness', params.ClassofBusiness.toString());
    if (params.AuthorityHolderLastName != null) __params = __params.set('AuthorityHolderLastName', params.AuthorityHolderLastName.toString());
    if (params.AuthorityHolderFirstName != null) __params = __params.set('AuthorityHolderFirstName', params.AuthorityHolderFirstName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalSearch/QuickSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DalSearchResponse>>;
      })
    );
  }
  /**
   * @param params The `DalSearchService.QuickSearchParams` containing the following parameters:
   *
   * - `WorkdayId`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `Country`:
   *
   * - `ClassofBusiness`:
   *
   * - `AuthorityHolderLastName`:
   *
   * - `AuthorityHolderFirstName`:
   *
   * @return Success
   */
  QuickSearch(params: DalSearchService.QuickSearchParams): __Observable<Array<DalSearchResponse>> {
    return this.QuickSearchResponse(params).pipe(
      __map(_r => _r.body as Array<DalSearchResponse>)
    );
  }

  /**
   * @param params The `DalSearchService.AdvanceSearchParams` containing the following parameters:
   *
   * - `Location`:
   *
   * - `EffectiveDateRangeTo`:
   *
   * - `EffectiveDateRangeFrom`:
   *
   * - `Division`:
   *
   * - `Department`:
   *
   * - `DateofAcknowledegment`:
   *
   * - `DateOfLastReview`:
   *
   * - `Clause`:
   *
   * - `AuthorityType`:
   *
   * - `AuthorityStatus`:
   *
   * - `AuthorityCategory`:
   *
   * @return Success
   */
  AdvanceSearchResponse(params: DalSearchService.AdvanceSearchParams): __Observable<__StrictHttpResponse<Array<DalSearchResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Location != null) __params = __params.set('Location', params.Location.toString());
    if (params.EffectiveDateRangeTo != null) __params = __params.set('EffectiveDateRangeTo', params.EffectiveDateRangeTo.toString());
    if (params.EffectiveDateRangeFrom != null) __params = __params.set('EffectiveDateRangeFrom', params.EffectiveDateRangeFrom.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.Department != null) __params = __params.set('Department', params.Department.toString());
    if (params.DateofAcknowledegment != null) __params = __params.set('DateofAcknowledegment', params.DateofAcknowledegment.toString());
    if (params.DateOfLastReview != null) __params = __params.set('DateOfLastReview', params.DateOfLastReview.toString());
    if (params.Clause != null) __params = __params.set('Clause', params.Clause.toString());
    if (params.AuthorityType != null) __params = __params.set('AuthorityType', params.AuthorityType.toString());
    if (params.AuthorityStatus != null) __params = __params.set('AuthorityStatus', params.AuthorityStatus.toString());
    if (params.AuthorityCategory != null) __params = __params.set('AuthorityCategory', params.AuthorityCategory.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalSearch/AdvanceSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DalSearchResponse>>;
      })
    );
  }
  /**
   * @param params The `DalSearchService.AdvanceSearchParams` containing the following parameters:
   *
   * - `Location`:
   *
   * - `EffectiveDateRangeTo`:
   *
   * - `EffectiveDateRangeFrom`:
   *
   * - `Division`:
   *
   * - `Department`:
   *
   * - `DateofAcknowledegment`:
   *
   * - `DateOfLastReview`:
   *
   * - `Clause`:
   *
   * - `AuthorityType`:
   *
   * - `AuthorityStatus`:
   *
   * - `AuthorityCategory`:
   *
   * @return Success
   */
  AdvanceSearch(params: DalSearchService.AdvanceSearchParams): __Observable<Array<DalSearchResponse>> {
    return this.AdvanceSearchResponse(params).pipe(
      __map(_r => _r.body as Array<DalSearchResponse>)
    );
  }
}

module DalSearchService {

  /**
   * Parameters for QuickSearch
   */
  export interface QuickSearchParams {
    WorkdayId?: string;
    Division?: string;
    Delegator?: string;
    Country?: string;
    ClassofBusiness?: string;
    AuthorityHolderLastName?: string;
    AuthorityHolderFirstName?: string;
  }

  /**
   * Parameters for AdvanceSearch
   */
  export interface AdvanceSearchParams {
    Location?: string;
    EffectiveDateRangeTo?: string;
    EffectiveDateRangeFrom?: string;
    Division?: string;
    Department?: string;
    DateofAcknowledegment?: string;
    DateOfLastReview?: string;
    Clause?: string;
    AuthorityType?: string;
    AuthorityStatus?: string;
    AuthorityCategory?: string;
  }
}

export { DalSearchService }
