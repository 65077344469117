import { ProcessInfillVariable } from "./process-infill-variable";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";
import { DalContentConfiguration } from "../shared.module";

@Injectable({
  providedIn: 'root',
})
export class ProcessDataSetInfillVariable extends ProcessInfillVariable {
  /**
   *
   */
  constructor() {
    super();
  }
  applies(itemVariable: ItemVariable): boolean {
    if (itemVariable) {
      return itemVariable.variableType == "Data Set";
    }
    return false;
  }
  /**
* Gets a text information.
* @param variable Item Variable
* @param config 
*/
  public getTextInformation(variable: ItemVariable, config: DalContentConfiguration): string {
    if (variable) {
      if(!config.isReaderMode)
      {
      if (variable.currentValue) {
        return variable.currentValue.valueString + " - " + variable.currentValue.description;
      }
      if (variable.defaultValue) {
        return variable.defaultValue.valueString + " - " + variable.defaultValue.description;
      }
    }
      else
      {
        if (variable.currentValue) {
          return variable.currentValue.description;
        }
        if (variable.defaultValue) {
          return variable.defaultValue.description;
        }
      }

      if(!config.isReaderMode)
      {
      return 'Select '+variable.variableDescription;
      }
      else
      {
        return '';
      }
    }
  }
}