import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationStart, Router } from '@angular/router';
import { TenantService } from './api/services/tenant.service';
import { environment } from '@env/environment';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { CurrentTenantService } from './shared/services/tenant/tenant.service';
import { url } from 'inspector';
import { filter } from 'rxjs/operators';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'WebAPP';
  isUserLoggedIn: boolean;
  previousUrl :string="";
  constructor(
    private userService: UserService,
    private _router: Router,
    private _tenantService: TenantService,
    private _msadal: MsAdalAngular6Service,
    private readonly elementRef: ElementRef,
    private renderer: Renderer2,
    private _currentTenantService: CurrentTenantService) {
    
      _router.events
   .pipe(filter(event => event instanceof NavigationStart))
   .subscribe((event: NavigationStart) => {
     console.log('prev:', event.url);
     this.previousUrl = event.url;
     if(this.previousUrl.includes('view-dal?reference=') || this.previousUrl.includes('report/history?reference=') )
     {
       if(localStorage.getItem('extLanding') == null || localStorage.getItem('extLanding') != 'N0t')
        {localStorage.setItem('extLanding', event.url);}
        else{ 
          if(localStorage.getItem('extLanding') == 'N0t')
          {
             localStorage.removeItem('extLanding');
          }
         }
     }
   });
  }

  ngOnInit() {

    this.checkUserLoggedIn();
    this.loadScript('../assets/adal_addAdalFrame_fix.js');
  }

  public loadScript(url: string) {
    const script = this.renderer.createElement('script');
    script.src = url;
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  // Fn to check if the user loggedIn
   checkUserLoggedIn() {

    this.isUserLoggedIn = false;
    this._currentTenantService
    .verifyAccessToken(true)
    .toPromise()
    .then(value => {
      if (value) {
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false;
      }
    }, (error) => {
      this.isUserLoggedIn = false;
      this._msadal.login();
    });

  }
}
