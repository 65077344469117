import { Component, OnInit, ViewChild, ViewEncapsulation ,Inject, OnDestroy, AfterContentInit} from '@angular/core';
import { UserService } from '@app/shared/services/user/user.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ViewDalService, PageViewDal } from '@app/shared/services/dal/view-dal.service';
import { GlobalService } from '@app/shared/services/global/global.service';
import { DalsService, UsersService, WorkflowActionsService, TenantService } from '@app/api/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { UserView } from '@app/api/models/user-view';
import { isUndefined } from 'util';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalDialogManagingAuthorityComponent} from '@app/shared/components/modal-dialog-managing-authority/modal-dialog-managing-authority.component'
import { User } from '@app/api/models';
import { CurrentTenantService } from '@app/shared/services/tenant/tenant.service';
import { ModalDialogComponent } from '@app/shared/components/modal-dialog/modal-dialog.component';
import { shiftInitState } from '@angular/core/src/view';
import { ModalInfoComponent } from '@app/shared/components/modal-info/modal-info.component';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy  {

  profile: Object;
  _loggedInUserEmail: string
  selectedAuthority : UserView;
  selectedAuthorityHeader : string;
  tableTitle: String;
  tableDesc: String;
  isAdmin: boolean;
  reportsactive: boolean = false;
  isSuperUser = false;
  userListObj: Array<Object>;
  delegatorName = '';
  delegatorEmail = '';
  delegetedDalList: any = [];
  _delegatorsDataSource: Array<User>;
  private _submitSubscribe$: any;
  private _defaultDataubscribe$: any;
  isDataLoaded: boolean;
  private _wfServiceSubscribe$: any;
  dataSource: MatTableDataSource<any>;
  nullCheck = null;
  resultLength = -1;
  pageSize = 10;
  private _userSubscribe$: Subscription;
  _isAdminOnBehalf :boolean ;
  isManagedAuthorityExpanded: boolean;
  myownAuthority: boolean;
  private _isDialogOpened: boolean = false;
  matTooltipDisabled: boolean = true;
  matTooltipManageDisabled: boolean = true;
  className: MenuOptions;
  Options = MenuOptions;
  changeManagedUser: any;
  changeManagedUserEvent$ : Subscription;
  sMUILU: string;
  isUserLoggedIn: boolean = undefined;
  loggedInUserName: string;
  loggedInFullUserName: string;
  logoRoute: string = '';
  isIexplore: boolean = false;
  emailNotificationsEnabled : boolean;
  productVersion: string;
  showBanner: boolean

  @ViewChild(MatPaginator) paginator: MatPaginator;
  _helpLink: string;

  constructor(private _userService: UserService, private _router: Router, private _viewDalService: ViewDalService, private _globalService: GlobalService,
              private _dalsService: DalsService, private spinnerService: Ng4LoadingSpinnerService,private _snackBar: MatSnackBar, private _apiUsersService: UsersService,
              private _workFlowActionService: WorkflowActionsService, private _usersService: UsersService, public dialog: MatDialog,
              private _tenantService: TenantService,
              private _currentTenantService: CurrentTenantService) {
                this._tenantService.rootUrl = environment.ApiUrl;
               }

  ngOnInit() {
    this.sMUILU = sessionStorage.getItem('selectedManagedUseIsLoggedUser');    
    this._isDialogOpened = false;
    console.log('header');
    this.profile = this._userService.msAdal;
    this._apiUsersService.rootUrl = this._workFlowActionService.rootUrl = environment.ApiUrl;
    this._dalsService.rootUrl = environment.ApiUrl;

    this.productVersion = environment.productVersion;
    this.showBanner = environment.showBanner;
    if (this.showBanner){
      const bodyElement = document.body;
      if (bodyElement) {
        bodyElement.classList.remove("top110");
        // add next theme class
        bodyElement.classList.add("top110");
      }
    }
      this.changeManagedUser = {
        loggedUserMail : this._globalService.getCurrentLoggedUser().email.toLowerCase(),
        selectedManagedUseIsLoggedUser : (this.sMUILU!==null&&this.sMUILU!=='null') ? false : true
      };
      this.tableTitle = 'Delegators';
      this.tableDesc = '';
      this.isAdmin = true;
      this.myownAuthority =true;
      this._isAdminOnBehalf= false;
      this.isManagedAuthorityExpanded= false;
       this.spinnerService.show();
      this.getLoggedInUserDetails();
      this.getUser();

      if(this._globalService.canChange == null || this._globalService.canChange == undefined)
      {
        this._globalService.canChange = { currentProcess: "", canChangeAA:true};
      }
      //this.setManagingUser();
      if (this.sMUILU!==null&&this.sMUILU!=='null'){
        this._isAdminOnBehalf = true;
      }
      this.helpLink();
      //this.chkisAuthenticated();
      this.getUrlLogo()
  }

  openModalInfo(){
    this.dialog.open(ModalInfoComponent,
      
      {width: '40%', data: { version: this.productVersion} });
  }


  chkisAuthenticated() {
    this.isUserLoggedIn = false;
    this._currentTenantService
    .verifyAccessToken(true)
    .toPromise()
    .then(value => {
      if (value) {
        
        // Check user name only once
        this.loggedInUserName = this.getNameUser(this._globalService.userManager.currentLoggedUser.firstName+" "+this._globalService.userManager.currentLoggedUser.lastName);
        this.loggedInFullUserName = this.getNameUserComplete(this._globalService.userManager.currentLoggedUser.firstName+" "+this._globalService.userManager.currentLoggedUser.lastName);
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false;
      }
    }, (error) => {
      this.isUserLoggedIn = false;
      this._userService.msAdal.login();
    });

  // if (this._userService.msAdal.isAuthenticated) {
  //   if(sessionStorage.getItem('client') === undefined || sessionStorage.getItem('client') === null) {
  //     this.isUserLoggedIn = false;        
  //   } else {
  //     this.isUserLoggedIn = true;
  //   }
  // } else {
  //   this.isUserLoggedIn = false;
  // }
  }

  /**
   * Navigates to a route or reloads the page
   * @param route 
   */
  navigateToRoute(route : string) {
    if (this._router.url == route) {
      this._router.navigateByUrl('/refresh?refresh=1', { skipLocationChange : true }).then(() => {
        this._router.navigate([route]);
      });
   }
   else {
     // Direct navigation
     this._router.navigate([route]);
   }
  }

    /**
   * Navigates to a tenant route or reloads the page
   * @param route 
   */
  navigateToTenantRoute(route : string) {
    const tenantName = this._currentTenantService.getTenantName();
    route = "/" + tenantName + route;
    this._globalService.canChange = { currentProcess: "", canChangeAA:true};
    if (this._router.url == route) {
      this._router.navigateByUrl('/refresh?refresh=1', { skipLocationChange : true }).then(() => {
        this._router.navigate([route]);
      });
   }
   else {
     // Direct navigation
     this._router.navigate([route]);
   }
  }

  findPermission(id: string) {
    if(id && this._globalService.selecteBaseDataUserPermissions){
      var findPermission = this._globalService.selecteBaseDataUserPermissions.findIndex(x=>x.id==id);
      if(findPermission!= -1){
        return true;
      } 
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.changeManagedUserEvent$) {
      this.changeManagedUserEvent$.unsubscribe();
    }
    if (this._userSubscribe$){
      this._userSubscribe$.unsubscribe();
    }
    localStorage.setItem ('selectedManagedUseIsLoggedUser', this.changeManagedUser);
  }

  helpLink(){
    this._tenantService.GetTenantHelpLink()
    .subscribe(response => {
      //this._baseAcceptanceCode = response;
      //this._spinnerService.hide();
      if(response){
        this._helpLink = response;
      }
    },
    (err: HttpErrorResponse) => {
      console.log(err.message);
      //this._spinnerService.hide();
      return null
    }
  );
  }

  getUser(){
    let isOnInitComponent = true;
    this.changeManagedUserEvent$ = this._globalService.changeManagedUser.subscribe(value => {
      if(value && value.currentLoggedUser && value.currentLoggedUser.receiveEmails){
        this.emailNotificationsEnabled = value.currentLoggedUser.receiveEmails;
      }

      if(isOnInitComponent)
        return;
      this.changeManagedUser = {
        loggedUserMail : value.currentLoggedUser.email,
        loggedUserName : value.currentLoggedUser.firstName + " " + value.currentLoggedUser.lastName,
        managedUserMail : value.selectedManagedUser.email,
        managedUserName: value.selectedManagedUser.firstName + " " + value.selectedManagedUser.lastName,
        selectedManagedUseIsLoggedUser: (this.sMUILU!==null&&this.sMUILU!=='null') ? false : value.selectedManagedUseIsLoggedUser,
      }
    });

    this._apiUsersService.Get({ DelegateParentEmail:  this.changeManagedUser.loggedUserMail, 
      AdministratorEmail: this.changeManagedUser.loggedUserMail }).subscribe(
      data => {
        this.spinnerService.hide();

        this._delegatorsDataSource = data;
        this.storeDelegatorsListData(data);
        this.userListObj = this._delegatorsDataSource;
        if (data !== null && data.length > 0) {          
           this._isAdminOnBehalf = this._globalService.isAdminonBehalf= true;      
         }         
        else
        {
          if (this.sMUILU!==null&&this.sMUILU!=='null'){
            this._isAdminOnBehalf = true;
          }
          else {
            this._globalService.isAdminonBehalf=false;
          }

          
        }

         this.isDataLoaded = true;
        var userRoles = JSON.parse(localStorage.getItem("userRole"));
        // Fix: to avoid to use role before login
        if(userRoles == null || isUndefined(userRoles))
        {
          this.isSuperUser = false;
        }
        else
        {
           this.isSuperUser = userRoles.includes('SUPERUSER');
        }
      },
      (err: HttpErrorResponse) => {
        this.spinnerService.hide();
        console.log(err.message);
      }
    );

    

    //this.getTaskInboxData();
    isOnInitComponent = false;
  }

  // This fn stores delegators list in global service to refer globally
  storeDelegatorsListData(data) {
    this._globalService.delegatorsList = data;
    console.log("Header - Delegator List: " + this._globalService.delegatorsList)
  }

  getLoggedInUserDetails() {
    this.spinnerService.show();
    this._userService.loggedInUserRole = [];
    this._userSubscribe$ = this._usersService.GetByEmail(this.changeManagedUser.loggedUserMail).subscribe(response => {
      if (response && response.userRoleView) {
        this.spinnerService.hide();

        var currentLoggedUser = response;

        this._globalService.userManager.currentLoggedUser = response;
        this.emailNotificationsEnabled = response.receiveEmails;
        
        this._globalService.userManager.selectedManagedUseIsLoggedUser = (this.sMUILU!==null&&this.sMUILU!='null') ? false : true;
        if (this._globalService.userManager.selectedManagedUseIsLoggedUser) {
          this._globalService.userManager.selectedManagedUser = {
            firstName: currentLoggedUser.firstName,
            lastName: currentLoggedUser.lastName,
            email: currentLoggedUser.email
          };
        } else {
        }
        
        this.changeManagedUser = {
          loggedUserMail : this._globalService.userManager.currentLoggedUser.email,
          loggedUserName : this._globalService.userManager.currentLoggedUser.firstName + " " + this._globalService.userManager.currentLoggedUser.lastName,
          managedUserMail : this._globalService.userManager.selectedManagedUser.email,
          managedUserName: this._globalService.userManager.selectedManagedUser.firstName + " " + this._globalService.userManager.selectedManagedUser.lastName,
          selectedManagedUseIsLoggedUser: (this.sMUILU!==null&&this.sMUILU!=='null') ? false : this._globalService.userManager.selectedManagedUseIsLoggedUser,
        }
       /** 
        * TODO :IVI
        *  To change GetPermissionbyUser method to GetPermissionWithRoles,
        *  this new method has all the roles with the permission involved. 
        *  The previous method only load permisions and roles as separated items.  
        *  Because of the scope of the bug (where the change is required) and 
        * the implications that the previous method has is not changed by now.
        * 
       */
        let userView = response;
        this._usersService.GetPermissionsWithRolesByUser(userView).subscribe( rolesWpermission =>{
          if(rolesWpermission){
          this._globalService.selectedBaseDataUserRolesAndPermissions = rolesWpermission;}
        },
        (err:HttpErrorResponse)=>
        {console.log(err.message);
        });

        // Assign userRole to userService property
        for (let i = 0; i < response.userRoleView.length; i++) {
          this._userService.loggedInUserRole.push((response.userRoleView[i].title).toUpperCase().trim());
        }

                //findPermissions For user logged in and stored
                this._usersService.GetPermissionsByUser(response)
                .subscribe(response => {
                  if(response){
                     this._globalService.selecteBaseDataUserPermissions = response;
                  }
                  
                }, (err: HttpErrorResponse) => {
                  //this._spinnerService.hide();
                  console.log(err.message);
                });
        
                //this._usersService.GetPermi
        

        // store user role in local storage
        const uRole = this._userService.loggedInUserRole;
        localStorage.setItem ('userRole', JSON.stringify(uRole));
        this.isSuperUser = this._userService.loggedInUserRole.includes('SUPERUSER');

        this.chkisAuthenticated();
      } else {
        localStorage.setItem ('userRole', JSON.stringify([]));
      }
    },
    (err: HttpErrorResponse) => {
      this.spinnerService.hide();
      console.log(err.message);
    });
  }

  logout() {
    if (this.changeManagedUserEvent$) {
      this.changeManagedUserEvent$.unsubscribe();
    }
    if (this._userSubscribe$){
      this._userSubscribe$.unsubscribe();
    }

    localStorage.clear();
    sessionStorage.clear();
    this._userService.logOut();    
  }

  navigateToAdmin() {
    this.reportsactive = false;
    this._globalService.changeDelegatorParams = null;
    this.navigateToTenantRoute('/admin')
  }

  navigateToBaseDataPage() {
    this._globalService.canChange = { currentProcess: "", canChangeAA:true};
    this.reportsactive = false;
    this._globalService.changeDelegatorParams = null;

    this.navigateToTenantRoute('/base-data');
  }

  navigateToViewDalPage() {
    //clean global object delegate dal
    this.reportsactive = false;
    this._globalService.delegationDalParams = null;
    this._globalService.changeDelegatorParams = null;
    this._globalService.canChange = { currentProcess: "", canChangeAA:true};
    var viewDalParams: ViewDalService.ViewDalParams = {
      viewerEmail: this._loggedInUserEmail,
      viewerRole: ViewDalService.ViewDalRole.Delegatee
    };
    this._viewDalService.navigateToViewDalPage(viewDalParams, PageViewDal.ViewDal);
  }

  navigateToSearch(element) {
    this.reportsactive = false;
    element = this.trimObj(element);
    if (element['Delegators']) {
      this._globalService.delegatorName = element['Delegators'];
      this.getDelegatorData();
    }
    this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/search']);
  }

  navigateToMySearch() {
    this._globalService.canChange = { currentProcess: "", canChangeAA:true};
    this.reportsactive = false;
    this.reportsactive = false;
    this.getLoggedInObject();
    if (this._userService.msAdal.LoggedInUserName) {
      this._globalService.delegatorName = this._userService.msAdal.LoggedInUserName;
      this.delegatorEmail = this.changeManagedUser.loggedUserMail;
    }
    this._submitSubscribe$ = this._dalsService.Get({ CheckDelegate: true, AuthorityHolderEmail: this.changeManagedUser.loggedUserMail }).subscribe(
      response => {
        if (response) {
          this.delegetedDalList = response;
          if (this.delegetedDalList.filter(data => data.delegate === true).length > 0) {
            this.spinnerService.hide();
              this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/search']);
          } else {
            this.showValidationMessage('No delegatable DAL(s) exists for this user');
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.spinnerService.hide();
        this.showValidationMessage(err.error.message);
      }
    );

  }

  navigateTodelegatioTreePage()
  {
    this.reportsactive = false;
    //clean global object delegate dal
    this._globalService.delegationDalParams = null;
    //clean blobal object change delegator
    this._globalService.changeDelegatorParams = null;

    this.navigateToTenantRoute('/view-dal/delegation-tree');
  }

  navigateTodelegation()
  {
    this._globalService.canChange = { currentProcess: "", canChangeAA:true};
    this.reportsactive = false;
    this._globalService.delegationDalParams = {
      division: undefined,
      function: undefined,
      to: undefined,
      from:undefined,
      dal:undefined};

      this._globalService.changeDelegatorParams = null;

      this.navigateToTenantRoute('/view-dal/delegation');
  }

  linkToMyDelegate() {
    //clean global object delegate dal
    this._globalService.delegationDalParams = null;
    this._globalService.changeDelegatorParams =null;
    this.getLoggedInObject();
    if (this._userService.msAdal.LoggedInUserName) {
      this._globalService.delegatorObj.email = this.changeManagedUser.loggedUserMail;
      this._globalService.selectedDelegate = this._userService.msAdal.LoggedInUserName;
      this._globalService.delegatorName = this._userService.msAdal.LoggedInUserName;
      this.delegatorEmail = this.changeManagedUser.loggedUserMail;
    }

    this.navigateToTenantRoute('/admin/people');
  }

  linkToSearchAuthority(): void {
    this.navigateToTenantRoute('/admin/search-authority');
  }

  getLoggedInObject() {
    if (this.changeManagedUser.loggedUserMail) {
      this.delegatorEmail = this.changeManagedUser.loggedUserMaill;
      var LoggedInObject = {
        firstname: this.delegatorName.split(' ')[0],
        lastname: this.delegatorName.split(' ')[1],
        email: this.changeManagedUser.loggedUserMail
    };
    }
    this._globalService.delegatorObj = LoggedInObject;
  }

  getDelegatorData() {
    this.delegatorName = this._globalService.delegatorName;
    if (this.delegatorName) {
      const first_name = this.delegatorName.split(' ')[0];
      const last_name = this.delegatorName.split(' ')[1];
      if (this._globalService.delegatorsList !== undefined && this._globalService.delegatorsList !== null) {

        if (this._globalService.delegatorsList.length > 0) {
          for (const obj of this._globalService.delegatorsList) {
            if (obj.firstName === first_name && obj.lastName === last_name) {
              this.storeDelegatorObj(obj);
            }
          }
          if (this._globalService.delegatorObj === undefined) {

            this.delegatorEmail = this.changeManagedUser.loggedUserMail;
          } else {
            this.delegatorEmail = this._globalService.delegatorObj.email;
          }
        } else {
          this.delegatorEmail = this.changeManagedUser.loggedUserMail;
        }
      } else {
        this.delegatorEmail = this.changeManagedUser.loggedUserMail;
      }
    }
  }

  storeDelegatorObj(obj) {
    this._globalService.delegatorObj = obj;
  }

  activatePaginator(): void {
    setTimeout(() => {
      if (this.dataSource['filteredData']) {
        this.resultLength = this.dataSource['filteredData'].length;
        if (this.resultLength > this.pageSize) {
          this.dataSource.paginator = this.paginator;
          this.paginator._intl.previousPageLabel = '';
          this.paginator._intl.nextPageLabel = '';
        }
      }
    }, 100);
  }

  //this fn is to remove the leading and trailing  spaces in an obj
  trimObj(obj) {
    if (!Array.isArray(obj) && typeof obj != 'object') return obj;
    return Object.keys(obj).reduce(function (acc, key) {
      acc[key.trim()] = typeof obj[key] == 'string' ? obj[key].trim() : (obj[key]);
      return acc;
    }, Array.isArray(obj) ? [] : {});
  }

  showValidationMessage(msg: string) {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

// set the header text.
  getAuthorityHeader() : string  {
    let authorityHeader = '';        

    this.sMUILU = sessionStorage.getItem('selectedManagedUseIsLoggedUser');
    if (this.sMUILU==null || this.sMUILU==undefined || this.sMUILU=='null')
    {
      authorityHeader = "my own Authority" ;
    }
    else
    {      
        let usr: string = "";
        if (this.sMUILU!=null){
          usr = (this.sMUILU.split("|")[2] + ', '+ this.sMUILU.split("|")[1]);

        }
        authorityHeader = this.getNameUserManage(usr);        
    }
    return authorityHeader;
  }

  openManagedAuthority()
  {
     let cantChangeAA = this._globalService.canChange;
     
   
   
    if (this._isDialogOpened) {
      try{
        this.dialog.closeAll();
      } catch(e) {

      } finally {
        this._isDialogOpened = false;
      }

      return;
    } 
    
    if(cantChangeAA.canChangeAA)
    { // To avoid multiple instances
          // Check if is not in the middle of a process
          this._isDialogOpened = true;
          const dialogRef = this.dialog.open(ModalDialogManagingAuthorityComponent, {
            width: '500px',
            position: { top:'70px', right:'16px;'},
            panelClass: 'panel-position'
          });

          dialogRef.afterClosed().subscribe(result => {
            this._isDialogOpened = false;      

          });
    }
    else
    {
      // Waring that a process is "running" and the user can't change the A
      this.showValidationMessage("You cannot change the Managed Authority in the middle of a process.");
    }

   
  }
 
  getNameUser(userName: string): string {
    if(userName){
    if(userName.length <= 30)
    {
      return userName;
    }else{
      this.matTooltipDisabled = false;
      return userName.substring(0, 29) + "...";
    }
  }
  }

  getNameUserManage(userName: string): string {
    if(userName){
    if(userName.length <= 30)
    {
      return userName;
    }else{
      this.matTooltipManageDisabled = false;
      return userName.substring(0, 29) + "...";
    }
  }
  }

  getNameUserComplete(userName: string): string {
    return userName;
  }

  getNameUserManageComplete(userName: string): string {
    if (!this.changeManagedUser.selectedManagedUseIsLoggedUser)
    {
      return 'Managing ' + this.changeManagedUser.managedUserName;
    }
    return "";
  }

  getUrlViewDal(){
    return "/"+sessionStorage.getItem('client')+'/view-dal';
  }

  getUrlReports(){
    return "/"+sessionStorage.getItem('client')+'/reports';
  }

  getUrlTree(){
    return "/"+sessionStorage.getItem('client') + '/view-dal/delegation-tree';
  }

  getUrlDelegation(){
   return "/"+sessionStorage.getItem('client') + '/view-dal/delegation';
  }

  getUrlMyDelegatees(){
    return "/"+sessionStorage.getItem('client') + '/admin/people';
  }

  getUrlAuthorities(){
    return "/"+sessionStorage.getItem('client') + '/admin/search-authority';
  }
  
  getUrlAdmin(){
   return "/"+sessionStorage.getItem('client')+'/admin';
  }

  getUrlBaseData(){
    return "/"+sessionStorage.getItem('client')+'/base-data';
  }

  // Set the logo image according the enviroment.logo propierty.
  getUrlLogo()
  {
    this.spinnerService.show();
    this._tenantService.GetTenantLogoImgUrl()
    .subscribe(response => {
      this.spinnerService.hide();
      if(response){
        this.logoRoute = response;
      }else{
        this.logoRoute = 'assets/images/CTILogo.svg';
      }
      return this.logoRoute;

    },
    (err: HttpErrorResponse) => {
      console.log(err.message);
      this.spinnerService.hide();
      return 'assets/images/CTILogo.svg';
    }
  );
  }

  navigateToReportPage() {
    this._globalService.canChange = { currentProcess: "", canChangeAA:true};
    this.reportsactive = true;
    this._globalService.changeDelegatorParams = null;
    this.navigateToTenantRoute('/report');
  }

  setEmailNotifications() {
    var currentLoggedUser = this._globalService.getCurrentLoggedUser();
    var name = currentLoggedUser.firstName + " " + currentLoggedUser.lastName;
    if(this.emailNotificationsEnabled)
    {
      let message = "You are about to disable all email notifications for " + name + ", do you want to proceed?";
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '450px',
        data: {title: "Warning", message: message, okText: "Yes, disable", cancelText: "Cancel" }
      });
      dialogRef.afterClosed().subscribe(result => {
        let confirmEnable: boolean = result;
        if (confirmEnable == true)
        {
         this._usersService.DisableEmailNotifications(this._globalService.userManager.currentLoggedUser.id).subscribe(
            response => {
              if (response) {
                this._globalService.selectedBaseDataUserObj = response[0];
                this.emailNotificationsEnabled = false;
                this.showValidationMessage('The Email Notifications were successfully disabled');
              }
            },
            (err: HttpErrorResponse) => {
              this.spinnerService.hide();
              this.showValidationMessage(err.error.message);
            }
          );
        }
      });
    }
    else if(!this.emailNotificationsEnabled){
      let message = "You are about to enable all email notifications for " + name + ", do you want to proceed?";
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '450px',
        data: {title: "Warning", message: message, okText: "Yes, enable", cancelText: "Cancel" }
      });
      dialogRef.afterClosed().subscribe(result => {
        let confirmEnable: boolean = result;
        if (confirmEnable == true)
        {
          this._usersService.EnableEmailNotifications(this._globalService.userManager.currentLoggedUser.id).subscribe(
            response => {
              if (response) {
                this._globalService.selectedBaseDataUserObj = response[0];
                this.emailNotificationsEnabled = true;
                this.showValidationMessage('The Email Notifications were successfully enabled');
              }
            },
            (err: HttpErrorResponse) => {
              this.spinnerService.hide();
              this.showValidationMessage(err.error.message);
            }
          );
        }
      });
    }
  }
}

export enum MenuOptions  {
MyDals = "MyDals",
People = "People",
Reports = "Reports",
Tree = "Tree",
DelegateAuthority = "DelegateAuthority",
Authorities = "Authorities",
Admin = "Admin",
}
