import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@app/core/page-not-found/page-not-found.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { RoleGuardService } from '@app/shared/services/guards/role/role-guard.service';
import { AuthGuardService } from '@app/guards/auth-guard.service';
import { LoginPageValidateComponent } from './LoginPageValidate/login-page-validate/login-page-validate.component';
import { DemoComponent } from '@app/demo/demo.component';
import { DemoDalLibraryItemsComponent } from '@app/demo/demo-dal-library-items/demo-dal-library-items.component';
import { DemoLibraryItemComponent } from '@app/demo/demo-library-item/demo-library-item.component';
import { DemoAuditModalComponent } from '@app/demo/demo-audit-modal/demo-audit-modal.component';
import  { AccessDeniedComponent } from '@app/errors/access-denied/access-denied.component';
import  { InvalidUserComponent } from '@app/errors/invalid-user/invalid-user.component';
import { ReportComponent } from '@app/report/report.component';
import { PrivacyPolicyComponent } from '@app/demo/privacy-policy/privacy-policy.component';
import { RefreshComponent } from './refresh/refresh.component';
// import { UnsuportedComponent } from '@app/demo/unsuported/unsuported.component';
import {OutOfSyncComponent} from '@app/errors/out-of-sync/out-of-sync.component'




const routes: Routes = [
  // This pages allow us to check that the Tenant is allowed to enter
  {
    path: '',
    canActivate: [AuthGuardService],
    component: LoginPageValidateComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    component: LoginPageValidateComponent
  },
  {
    path: 'refresh',
    component: RefreshComponent
  },

  // This pages, are related just to clients
  {
    path: ':client/admin',
    canActivate: [AuthGuardService],
    loadChildren: '@app/admin/admin.module#AdminModule'
  },
  {
    path: ':client/base-data',
    canActivate: [AuthGuardService],
    loadChildren: '@app/base-data/base-data.module#BaseDataModule'
  },
  {
    path: ':client/view-dal',
    canActivate: [AuthGuardService],
    loadChildren: '@app/view-dal/view-dal.module#ViewDalModule'
  },
  {
    path: ':client/admin/people/view-dal',
    canActivate: [AuthGuardService],
    loadChildren: '@app/view-dal/view-dal.module#ViewDalModule'
  },
  {
    path: ':client/admin/search-authority/view-dal',
    canActivate: [AuthGuardService],
    loadChildren: '@app/view-dal/view-dal.module#ViewDalModule'
  },
  {
    path: ':client/admin/view-dal',
    canActivate: [AuthGuardService],
    loadChildren: '@app/view-dal/view-dal.module#ViewDalModule'
  },
  {
    path:':client/privacy',
    canActivate: [AuthGuardService],
    component: PrivacyPolicyComponent
  },
  // {
  //   path:':client/unsuported',
  //   canActivate: [AuthGuardService],
  //   component: UnsuportedComponent
  // },
  {
    path:':client/demo',
    canActivate: [AuthGuardService],
    component: DemoComponent
  },
  {
    path:':client/demo-dal-library-items',
    canActivate: [AuthGuardService],
    component: DemoDalLibraryItemsComponent
  },{
    path:':client/demo-dal-library-item',
    canActivate: [AuthGuardService],
    component: DemoLibraryItemComponent
  }, {
    path:':client/demo-audit',
    component: DemoAuditModalComponent
  },
  {
    path:':client/access-denied',
    canActivate: [AuthenticationGuard],
    component: AccessDeniedComponent
  }, {
    path:':client/invalid-user',
    canActivate: [AuthenticationGuard],
    component: InvalidUserComponent
  },{
    path:':client/report',
    canActivate: [AuthGuardService],
    loadChildren: '@app/report/report.module#ReportModule'
  },
  {
    path:':client/out-of-sync',
    canActivate: [AuthenticationGuard],
    component: OutOfSyncComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
  ,{
    path: 'notfound',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
