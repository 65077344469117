import { Component, OnInit, Input } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportFilter } from '@app/api/models/report-filter';
import { ReportInstance } from '@app/api/models/report-instance';
import { ReportInstanceService } from '@app/api/services/report-instance.service';
import { element } from '@angular/core/src/render3/instructions';
import { Identifiers } from '@angular/compiler';
import { Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { MatSnackBar } from '@angular/material';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '@app/shared/services/global/global.service';
import { isUndefined } from 'util';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  changeManagedUserEvent$ : Subscription;
  displayedColumns: string[] = ['more', 'createdDate', 'name', 'user', 'schedule', 'status'];
  datasource:ReportFilter[] = [];
  @Input() reportId:string;
  @Input() reloadIntance: Subject<boolean> = new Subject<boolean>();
  private _reference:string ="";
  changeManagedUser: any;
  isSuperUser:boolean=false;

  constructor(
    private _spinnerService: Ng4LoadingSpinnerService,
    private reportInstanceService:ReportInstanceService,
    private _snackBar: MatSnackBar,
    private _route : ActivatedRoute,
    private _globalService: GlobalService,
  ) { 
    this.reportInstanceService.rootUrl = environment.ApiUrl;
  }

  ngOnInit() {
    this.checkSuperUser();
    this.loadViewParam();
    this.loadHistory();
    let isOnInitComponent = true;
    this.reloadIntance.subscribe(response => {
        if(isOnInitComponent)
        return;
        if(response){
          this.loadHistory();
      }
    });
    this.changeManagedUserEvent$ = this._globalService.changeManagedUser.subscribe(value => {
      if(isOnInitComponent)
        return;
      this.changeManagedUser = {
        managedUserMail : value.selectedManagedUser.email,
        managedUserName: value.selectedManagedUser.firstName + " " + value.selectedManagedUser.lastName,
        selectedManagedUseIsLoggedUser: value.selectedManagedUseIsLoggedUser,
      }
      this.loadHistory();
    });

    isOnInitComponent = false;
  }

  loadViewParam()
  {
    let reference = this._route.snapshot.queryParamMap.get('reference');
    if( reference != null && reference != "" )
    {
      this._reference = atob(reference);
    }
  }

  loadHistory() {
    if(this.reportId)
    {
      this._spinnerService.show();
      this.reportInstanceService.GetReportIntanceByReportDefinition(this.reportId)
      .subscribe(response => {
        this.datasource = response != null && response.results ? response.results: null;
        if (response && response.errors && response.errors.length > 0)
          response.errors.forEach(x => console.log(x.message));
        this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }else if (this._reference != ""){
      this._spinnerService.show();
      this.reportInstanceService.GetById(this._reference)
      .subscribe(response => {
        if (response && response.result)
          this.datasource = [response.result];
        else if (response && response.errors && response.errors.length > 0)
          response.errors.forEach(x => console.log(x.message));
          this._spinnerService.hide();
      },(err: HttpErrorResponse) =>{
        this._spinnerService.hide();
        console.log(err.message);
      });
    }else{
      this._spinnerService.show();
      this.reportInstanceService.GetAllReportIntance()
      .subscribe(response => {
        if(response && response.results && response.results.length > 0)
          this.datasource = response.results;
        else if (response && response.errors && response.errors.length > 0)
          response.errors.forEach(x => console.log(x.message));
        this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }
}

  getBGColorCode(reasonStatus): string {
    if (reasonStatus) {
      let colorCode;
      switch ((reasonStatus).toLowerCase().trim()) {
        case 'queued':
          colorCode = '#DCDCDF';
          break;
        case 'processing':
          colorCode = '#ECCCFF';
          break;
        case 'done':
          colorCode = '#CCF7CC';
          break;
        case 'new':
          colorCode = '#DCDCDF';
          break;
        case 'error':
          colorCode = '#FF8A80';
          break;
        case 'no data':
            colorCode = '#DCDCDF';
            break;
        case 'time out':
             colorCode = '#FF8A80';
             break;   
      }
      return colorCode;
    }
  }

  getReportHasValue() {
    if(this.reportId)
    {
      return true
    }
    return false;
  }

  getReportName(element:ReportInstance) {
    if(element.reportDefinitionView)
    {
      return element.reportDefinitionView.name;      

    }
    return "";
  }

  Downloadfile(event, element, type){
    this.downloadAttachment(element.id, type, element.createdDate, element.reportDefinitionView.name);
   }
  

  getNameUser(element:ReportInstance) {
    if(element.createdByUser)
    {
      return element.user.email;
    }
    return "";
  }

downloadAttachment(id, type, createdDate, reportname ) {
    let fileName = reportname + '_'+ formatDate(createdDate, 'ddMMyyyy', 'en-US') + '_' + formatDate(createdDate, 'HHmm', 'en-US') + "." + type;  
    var fileType;
    this.showSnackBar("Downloading report " + fileName);

    if (type == "csv") {fileType = "application/vnd.ms-excel";}
    if (type == "txt") {fileType = "text/plain";}

    let DownloadCSVParams = {id: id, doctype:  type};

    this.reportInstanceService.DownloadCSV(DownloadCSVParams).subscribe(
      data => {
        if(data){
          var blob = new Blob(["\ufeff",data], { type: fileType });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else { // for the rest of browsers
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName;
            link.click();
          }
        }else{
          this.showSnackBar("There is not a file associated.");
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

  showSnackBar(msg: string): void {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }
  canBeDownloaded(element)
  {
    let response: boolean = false;
    let getCurrentLoggedUserid =  this._globalService.getCurrentLoggedUser().id ;
    let aAdmin = this._globalService.delegatorsList.filter(x=> x.id == element.user.id );
    if( this.isSuperUser ||  // Is Super user, so can delelte
       element.user.id == getCurrentLoggedUserid || // is de creator , so can delete
       element.user.id == this._globalService.getSelectedManagedUser().id && aAdmin.length>0 ) // Managing Authority of his MA
       {
        response = true;
       }  
    return response;
  }

  checkSuperUser()
  {
      var userRoles = JSON.parse(localStorage.getItem("userRole"));
      // Fix: to avoid to use role before login
      if(userRoles == null || isUndefined(userRoles))
      {
        this.isSuperUser = false;
      }
      else
      {
        this.isSuperUser = userRoles.includes('SUPERUSER');
      }
  }
}
