import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DDE] => Dal Delegator
 */
@Injectable({
  providedIn: 'root',
})
export class DalDelegatorInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[DDE]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {
      if (config.delegator) {
        return `${config.delegator.firstName} ${config.delegator.lastName}`;
      } else {
        return "N/A";
      }
      return this.infillCode;
    }
  }