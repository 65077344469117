import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [APR] => Authority Holder Preferred Name
 */
@Injectable({
  providedIn: 'root',
})
export class AHPreferredNameInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[APR]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(!config.authorityHolderEmployeeInfo) {
        return "N/A"
      }
      
      let AHInfo = config.authorityHolderEmployeeInfo; 
      if(AHInfo.preferredName && AHInfo.preferredName.trim() != "") 
        return AHInfo.preferredName;
      else if (AHInfo.preferredName == null || AHInfo.preferredName.trim() == "")
        return "N/A";
      
      return this.infillCode;
    }
  }