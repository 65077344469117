import { OnInit, ElementRef, OnDestroy, EventEmitter, Directive, HostListener, Output, Input } from '@angular/core';

/**
 * Allow us to click on an infill
 */
@Directive({selector: '[infillClicker]'})
export class InfillClicker implements OnInit, OnDestroy{

  @Input('infillClicker')
  enableEditing : boolean = false;

  @Output()
  infillSelected : EventEmitter<InfillClickedEventArgs> = new EventEmitter<InfillClickedEventArgs>();

  constructor(el: ElementRef) {
    // TODO: Add this line just to know what elements are applied.
    //el.nativeElement.style.backgroundColor = 'yellow';
 }

  @HostListener('click', ['$event']) onClick($event) {
    if (this.enableEditing === false) {
      return;
    }
    if (!$event.target) {
      return;
    }

    if (!$event.target.attributes) {
      return;
    }

    if (!$event.target.attributes.value) {
      return;
    }

    if (!$event.target.attributes.value.value) {
      return;
    }

    // Stop the event to go to Parent element, document and window
    $event.stopImmediatePropagation();

    let value = $event.target.attributes.value.value;

    const infillClicked : InfillClickedEventArgs = {
      element : $event.target,
      value : value
    };

    this.infillSelected.emit(infillClicked);
  } 

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.infillSelected)
      this.infillSelected.unsubscribe();
  }
}

export interface InfillClickedEventArgs {
  element : any;
  value : string
}