import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { LibraryItem, DalLibraryItem, DalTemplateLibraryItem, ItemVariable, ItemVariableValueView, Currency, Country, BaseData } from '@app/api/models';
import { ProcessBasicInfillVariable } from '@app/shared/infill-processing/process-basic-infill-variable';
import { BaseDatasService, OrganizationDatasService, CurrencyService, TenantService, ExchangeRateService, ReasonService, CountryService } from '@app/api/services';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ReinstateReasonComponent } from '@app/base-data/reinstate-reason/reinstate-reason.component';

@Component({
  selector: 'app-modal-general-tables-variable',
  templateUrl: './modal-general-tables-variable.component.html',
  styleUrls: ['./modal-general-tables-variable.component.scss']
})
export class ModalGeneralTablesComponent implements OnInit {
  textControl = new FormControl({ value: '' });
  countryControl = new FormControl({ value: '' });
  currencyControl = new FormControl({ value: '' });
  reinstateReasonControl = new FormControl({ value: '' });
  revokeReasonControl = new FormControl({ value: '' });
  suspendReasonControl = new FormControl({ value: '' });

  countrySelected : Country;
  currencySelected: Currency;
  reinstateReasonSelected: BaseData;
  revokeReasonSelected: BaseData;
  suspendReasonSelected: BaseData;
  exchangeRate: string;
  title: string;
  message: string;
  okText: string;
  cancelText: string;
  confirmRevoke: boolean = false;
  dalContentConfiguration: DalContentConfiguration;
  currentLibraryItem: LibraryItem | DalLibraryItem | DalTemplateLibraryItem;
  variable: ItemVariable;
  textValue: string;
  numberValue: number;
  amountValue: number;
  currencies: Currency[];

  countries: Country[];
  reinstaReasons: BaseData[];
  revokeReasons: BaseData[];
  suspendReasons: BaseData[];

  
  _baseCurrencyCode: string;


  constructor(
    private _exchangeRateService: ExchangeRateService,
    private _baseDatasService: CurrencyService,
    private _reasonsService: ReasonService,
    private _countrieService: CountryService,
    private _spinnerService :Ng4LoadingSpinnerService,
    private _tenantService: TenantService,
    public dialogRef: MatDialogRef<ModalGeneralTablesComponent>,
    public dbv: ProcessBasicInfillVariable,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.dalContentConfiguration = data.dalContentConfiguration;
    this.currentLibraryItem = data.currentLibraryItem;
    this.variable = data.variable;
    this._baseDatasService.rootUrl = environment.ApiUrl;
    this._tenantService.rootUrl = environment.ApiUrl;
    this._reasonsService.rootUrl = environment.ApiUrl;
    this._countrieService.rootUrl = environment.ApiUrl;
    this._exchangeRateService.rootUrl = environment.ApiUrl;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log('currenciesModal')
    this._spinnerService.show();

    if(this.variable.variableCode=="CUR"){
      this._baseDatasService.GetActiveCurrencies()
      .subscribe(response => {
        let currencies: any = response;
        this.currencies = currencies;

        if (this.variable.variableType) {
            if (this.variable.currentValue) {
              this.currencySelected = this.currencies.find(x => x.code == this.variable.currentValue.valueString);
              //this.textValue = this.variable.currentValue.valueString;
            } else if (this.variable.defaultValue) {
              this.currencySelected = this.currencies.find(x => x.code == this.variable.defaultValue.valueString);
              //this.textValue = this.variable.currentValue.valueString;
            }
           
        }

        this._tenantService.GetTenantCurrency()
          .subscribe(response => {
            this._baseCurrencyCode = response;
            this.onCurrencySelected();
            this._spinnerService.hide();
          },
            (err: HttpErrorResponse) => {
              console.log(err.message);

            }
          );
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

    if(this.variable.variableCode=="COU"){
      this._countrieService.GetCountries().subscribe(response => {
        let countries: any = response;
        this.countries = countries;

        if (this.variable.variableType) {
          if (this.variable.currentValue) {
            this.countrySelected = this.countries.find(x => x.code == this.variable.currentValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          } else if (this.variable.defaultValue) {
            this.countrySelected = this.countries.find(x => x.code == this.variable.defaultValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          }
      }
      this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

    if(this.variable.variableCode=="RER"){
      this._reasonsService.GetReinstateReasons().subscribe(response => {
        this.reinstaReasons = response;

        if (this.variable.variableType) {
          if (this.variable.currentValue) {
            this.reinstateReasonSelected = this.reinstaReasons.find(x => x.title == this.variable.currentValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          } else if (this.variable.defaultValue) {
            this.reinstateReasonSelected = this.reinstaReasons.find(x => x.title == this.variable.defaultValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          }}
          this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

    if(this.variable.variableCode=="RVR"){
      this._reasonsService.GetRevokeReasons().subscribe(response => {
        this.revokeReasons = response;

        if (this.variable.variableType) {
          if (this.variable.currentValue) {
            this.revokeReasonSelected = this.revokeReasons.find(x => x.title == this.variable.currentValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          } else if (this.variable.defaultValue) {
            this.revokeReasonSelected = this.revokeReasons.find(x => x.title == this.variable.defaultValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          }}
          this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

    if(this.variable.variableCode=="SUR"){
      this._reasonsService.GetSuspendReasons().subscribe(response => {
        this.suspendReasons = response;

        if (this.variable.variableType) {
          if (this.variable.currentValue) {
            this.suspendReasonSelected = this.suspendReasons.find(x => x.title == this.variable.currentValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          } else if (this.variable.defaultValue) {
            this.suspendReasonSelected = this.suspendReasons.find(x => x.title == this.variable.defaultValue.valueString);
            //this.textValue = this.variable.currentValue.valueString;
          }}
          this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

  }

  onCurrencySelected(){
    if(this.currencySelected){
      if(this.currencySelected.code == this._baseCurrencyCode){
        this.exchangeRate = "1";
      } else{
        this._exchangeRateService.GetCurrentExchangeRate(this.currencySelected.id).subscribe(response => {
          if(response){
            if(response.value){
              this.exchangeRate = response.value.toString();
            } else{
              this.exchangeRate = "";
            }
          }else{
            this.exchangeRate = "";
          }
        }),
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      }
    }
  }

    onYesClick() {
      let newValue: ItemVariableValueView = {};
      this.currentLibraryItem.status ="Update";
      if (this.variable && this.variable.variableCode == 'CUR'){
      if (this.currencySelected) {
        newValue.valueString = this.currencySelected.code;
        newValue.description = this.currencySelected.title;
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      }
      else if (this.variable.defaultValue) {
        Object.assign(newValue, this.variable.defaultValue);
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      }
      else
      {
        this.variable.currentValue = null;
        this.dialogRef.close();
      }
    }

      if (this.variable && this.variable.variableCode == 'COU'){
      if (this.countrySelected) {
          newValue.valueString = this.countrySelected.code;
          newValue.description = this.countrySelected.title;
          this.variable.currentValue = newValue;
          this.dialogRef.close();
      }
      else if (this.variable.defaultValue) {
        Object.assign(newValue, this.variable.defaultValue);
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      }
      else
      {
        this.variable.currentValue = null;
        this.dialogRef.close();
      }
    }

    if (this.variable && this.variable.variableCode == 'RVR'){
      if (this.revokeReasonSelected) {
          newValue.valueString = this.revokeReasonSelected.title;
          newValue.description = this.revokeReasonSelected.title;
          this.variable.currentValue = newValue;
          this.dialogRef.close();
      }
      else if (this.variable.defaultValue) {
        Object.assign(newValue, this.variable.defaultValue);
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      }
      else
      {
        this.variable.currentValue = null;
        this.dialogRef.close();
      }}

      if (this.variable && this.variable.variableCode == 'RER'){
        if (this.reinstateReasonSelected) {
            newValue.valueString = this.reinstateReasonSelected.title;
            newValue.description = this.reinstateReasonSelected.title;
            this.variable.currentValue = newValue;
            this.dialogRef.close();
        }
        else if (this.variable.defaultValue) {
          Object.assign(newValue, this.variable.defaultValue);
          this.variable.currentValue = newValue;
          this.dialogRef.close();
        }
        else
        {
          this.variable.currentValue = null;
          this.dialogRef.close();
        }}

        if (this.variable && this.variable.variableCode == 'SUR'){
          if (this.suspendReasonSelected) {
              newValue.valueString = this.suspendReasonSelected.title;
              newValue.description = this.suspendReasonSelected.title;
              this.variable.currentValue = newValue;
              this.dialogRef.close();
          }
          else if (this.variable.defaultValue) {
            Object.assign(newValue, this.variable.defaultValue);
            this.variable.currentValue = newValue;
            this.dialogRef.close();
          }
          else
          {
            this.variable.currentValue = null;
            this.dialogRef.close();
          }}
   
    }
  

  }