import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-audit-log-modal',
  templateUrl: './audit-log-modal.component.html',
  styleUrls: ['./audit-log-modal.component.scss']
})
export class AuditLogModalComponent implements OnInit {
  id: string;
  type: string;
  subType: string;
  childIdObject: string;
  description:string;
  typeDescription:string;

  constructor(
    public dialogRef: MatDialogRef<AuditLogModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.type) {
        this.type = data.type;
      }
      if (data.subType) {
        this.subType = data.subType;
      }
      if (data.childIdObject) {
        this.childIdObject = data.childIdObject;
      }
      if (data.description) {
        this.description = data.description;
      }
      if (data.typeDescription) {
        this.typeDescription = data.typeDescription;
      }
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  getOpenedMenu( isOpen :boolean){
    console.log('getOpenedMenu: '+isOpen)
    this.close()
  }

  closeModal(parameter:boolean): void {
    this.dialogRef.close();
  }
}