import { Component, OnInit } from '@angular/core';
import {  MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '@app/shared/services/user/user.service';

@Component({
  selector: 'app-out-of-sync',
  templateUrl: './out-of-sync.component.html',
  styleUrls: ['./out-of-sync.component.scss']
})
export class OutOfSyncComponent implements OnInit {

  constructor(
    private _msAdal: MsAdalAngular6Service,
    private _spinnerService : Ng4LoadingSpinnerService,
    private _userService: UserService
  ) { }

  ngOnInit() {
    this._spinnerService.hide();
  }

  Logout(){
    localStorage.clear();
    sessionStorage.clear();
    this._userService.logOut();
  }

}
