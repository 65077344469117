import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { OrganizationDataView, Dal, LibrarySection, LibraryItem, LibrarySubSection, Region } from '@app/api/models';
import { AddLibrarySubSectionModalDialogComponent } from '../add-library-sub-section-modal-dialog/add-library-sub-section-modal-dialog.component';
import { AddLibraryItemModalDialogComponent } from '../add-library-item-modal-dialog/add-library-item-modal-dialog.component';
import { MatDialog } from '@angular/material';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { AuditLogModalComponent } from '../audit-log-modal/audit-log-modal.component';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  @Input() dalContentConfiguration: DalContentConfiguration;
  @Input() editMode:boolean = false;
  @Input() masterId:boolean = false;
  @Input() masterType:string;

  sectionExpanded :boolean = false;
  elementSubSection:any = {};
  @Input() section:any;
  @Input() FirstItem:boolean;
  @Input() LastItem:boolean;
  @Input() expandAll:boolean;
  warningFlag :boolean = false;
  editFlag:boolean =false;
  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
    this.flagsEnables();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.expandAll){
      this.expandAll = changes.expandAll.currentValue;
      this.sectionExpanded = changes.expandAll.currentValue;
    }
  }

  sectionExpand()
  {
    this.sectionExpanded = !this.sectionExpanded;
  }
 
  getSubSections()
  {
    var subSections = [];

    if(this.dalContentConfiguration && this.dalContentConfiguration.subSections!= null)
    {
      subSections = this.dalContentConfiguration.subSections.filter(x => 
        (x.parentSection.id == this.section.id || x.parentSection.id == this.section.sectionBase.id))
        .sort((x,y) => 
        {
          if(x.order > y.order) {
            return 1;
          } else if(x.order < y.order) {
            return -1;
          } else {
            return 0;
          }
        });
        
    }
    return subSections;
  }

  getQueryComments()
  {
    if(this.dalContentConfiguration && this.dalContentConfiguration.hasQueryComments())
    {
      let queryList = this.dalContentConfiguration.queryComments.filter(x => 
        (x.itemType != undefined && x.itemType != null)
        && (x.itemType == "SECTION")
        && (x.itemId == this.section.id));
        return queryList;
    }
    return [];
  }

  getLibraryItems()
  {
    if(this.dalContentConfiguration && this.dalContentConfiguration.libraryItems && this.dalContentConfiguration.isEmployeeDetailLoaded)
    {
      let libraryItems = this.dalContentConfiguration.libraryItems.filter(x => 
        (x.section != undefined && x.section != null)
        && (x.section.id == this.section.id || x.section.id == this.section.sectionBase.id)
        && (x.subSection == undefined || x.subSection == null))
        .sort((x,y) => 
        {
          if(x.order > y.order) {
            return 1;
          } else if(x.order < y.order) {
            return -1;
          } else {
            return 0;
          }
        });
        return libraryItems;
    }
    return;
  }
  
  showAddSubSection(){
    const dialogRef = this.dialog.open(AddLibrarySubSectionModalDialogComponent, {
      width: '80%',
      data: {
        dalContentConfiguration: this.dalContentConfiguration,
        section: this.section.sectionBase,
        subSections: this.dalContentConfiguration.subSections
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        var results = result as LibrarySubSection[];
        results.forEach(x =>{
            let order = this.getSubSections().length;
            this.dalContentConfiguration.addSubSection(x, this.section.sectionBase,null,order);
          }
        );
      }
    });
  }

  showAddLibraryItem(){
    const dialogRef = this.dialog.open(AddLibraryItemModalDialogComponent, {
      width: '80%',
      data: {
        dalContentConfiguration:this.dalContentConfiguration,
        section: this.section.sectionBase,
        libraryItems: this.dalContentConfiguration.libraryItems
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        var results = result as LibraryItem[];
        results.forEach(x =>{
            let order = this.getLibraryItems().length;
            this.dalContentConfiguration.addLibraryItem(x, this.section.sectionBase, null, null ,order);
          }
        );
      }
    });
  }

  removeSection(){
    if(this.getLibraryItems().length > 0 || this.getSubSections().length > 0)
    {
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '450px',
        data: {title: "Warning", message: "You are about to remove a Section from the DAL. This will also remove its Sub-Sections and Library Items. Do you wish to continue?"
        , okText: "Yes, delete.", cancelText: "No, cancel." }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      let confirm = result;
      if ( confirm == true)
      {
        if (this.dalContentConfiguration) {
          this.dalContentConfiguration.removeSection(this.section);
        }
      }});
    }else{
      this.dalContentConfiguration.removeSection(this.section);
    }
  }

  moveUp () {
    if (this.dalContentConfiguration) {
      this.dalContentConfiguration.moveUp(this.section);
    }
  }

  moveDown () {
    if (this.dalContentConfiguration) {
      this.dalContentConfiguration.moveDown(this.section);
    }
  }

  undoRemove () {
    if (this.dalContentConfiguration) {
      this.dalContentConfiguration.undoRemove(this.section);
    }
  }

  gotoAudit(){
    if(this.section && this.section.id){
      const dialogRef = this.dialog.open(AuditLogModalComponent, {
        width: '80%',
        data: {
          id: this.masterId,
          type: this.masterType,
          subType: this.section.type,
          childIdObject: this.section.id,
          typeDescription:'DAL SECTION'
        }
      });
    }
  }

  getHasSectionsOrLibraryItems()
  {
    var subSections = [];
    let libraryItems = [];

    if(this.dalContentConfiguration && this.dalContentConfiguration.subSections!= null)
    {
      subSections = this.dalContentConfiguration.subSections.filter(x => 
        (x.parentSection.id == this.section.id || x.parentSection.id == this.section.sectionBase.id));
    }
    
    if(this.dalContentConfiguration && this.dalContentConfiguration.libraryItems && this.dalContentConfiguration.isEmployeeDetailLoaded)
    {
      libraryItems = this.dalContentConfiguration.libraryItems.filter(x => 
        (x.section != undefined && x.section != null)
        && (x.section.id == this.section.id || x.section.id == this.section.sectionBase.id)
        && (x.subSection == undefined || x.subSection == null));
    }

    return (subSections && subSections.length > 0) || (libraryItems && libraryItems.length > 0);
  }

  flagsEnables()
  {//"<span class=\"material-icons\">create</span>";
  //private readonly string warnigFlag = "<span class=\"material-icons\">warning</span>";
     var hasDiferences = this.section.title.indexOf("<span hidden class=\"material-icons\">create</span>");
     var hasWarning = this.section.title.indexOf("<span hidden class=\"material-icons\">warning</span>");
     if (hasDiferences >0)
     {
     //  this.section.title= this.section.title.replace("<span class=\"material-icons\">create</span>","");
        this.editFlag = true;
     }

     if(hasWarning >0)
    {
     // this.section.title = this.section.title.replace("<span class=\"material-icons\">warning</span>","");
      this.warningFlag = true;
    }
    
  }
}

