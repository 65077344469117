/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Function } from '../models/function';
@Injectable({
  providedIn: 'root',
})
class FunctionService extends __BaseService {
  static readonly InsertFunctionPath = '/api/Function/InsertFunction';
  static readonly UpdateFunctionPath = '/api/Function/UpdateFunction';
  static readonly GetFunctionsPath = '/api/Function/GetFunctions';
  static readonly GetActiveFunctionsPath = '/api/Function/GetActiveFunctions';
  static readonly DisablePath = '/api/Function/Disable';
  static readonly EnablePath = '/api/Function/Enable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param functionObject undefined
   * @return Success
   */
  InsertFunctionResponse(functionObject?: Function): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = functionObject;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Function/InsertFunction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param functionObject undefined
   * @return Success
   */
  InsertFunction(functionObject?: Function): __Observable<boolean> {
    return this.InsertFunctionResponse(functionObject).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param functionObject undefined
   * @return Success
   */
  UpdateFunctionResponse(functionObject?: Function): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = functionObject;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Function/UpdateFunction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param functionObject undefined
   * @return Success
   */
  UpdateFunction(functionObject?: Function): __Observable<boolean> {
    return this.UpdateFunctionResponse(functionObject).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetFunctionsResponse(): __Observable<__StrictHttpResponse<Array<Function>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Function/GetFunctions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Function>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetFunctions(): __Observable<Array<Function>> {
    return this.GetFunctionsResponse().pipe(
      __map(_r => _r.body as Array<Function>)
    );
  }

  /**
   * @return Success
   */
  GetActiveFunctionsResponse(): __Observable<__StrictHttpResponse<Array<Function>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Function/GetActiveFunctions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Function>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetActiveFunctions(): __Observable<Array<Function>> {
    return this.GetActiveFunctionsResponse().pipe(
      __map(_r => _r.body as Array<Function>)
    );
  }

  /**
   * @param acceptanceId undefined
   * @return Success
   */
  DisableResponse(acceptanceId?: string): __Observable<__StrictHttpResponse<Function>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (acceptanceId != null) __params = __params.set('acceptanceId', acceptanceId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Function/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Function>;
      })
    );
  }
  /**
   * @param acceptanceId undefined
   * @return Success
   */
  Disable(acceptanceId?: string): __Observable<Function> {
    return this.DisableResponse(acceptanceId).pipe(
      __map(_r => _r.body as Function)
    );
  }

  /**
   * @param acceptanceId undefined
   * @return Success
   */
  EnableResponse(acceptanceId?: string): __Observable<__StrictHttpResponse<Function>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (acceptanceId != null) __params = __params.set('acceptanceId', acceptanceId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Function/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Function>;
      })
    );
  }
  /**
   * @param acceptanceId undefined
   * @return Success
   */
  Enable(acceptanceId?: string): __Observable<Function> {
    return this.EnableResponse(acceptanceId).pipe(
      __map(_r => _r.body as Function)
    );
  }
}

module FunctionService {
}

export { FunctionService }
