import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ReportDefinitionService } from '@app/api/services/report-definition.service';
import { environment } from '@env/environment';
import { ReportDefinition } from '@app/api/models/report-definition';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from '@app/shared/services/global/global.service';

@Component({
  selector: 'app-general-execution',
  templateUrl: './general-execution.component.html',
  styleUrls: ['./general-execution.component.scss']
})
export class GeneralExecutionComponent implements OnInit {
  changeManagedUserEvent$ : Subscription;
  changeManagedUser: any;
  reportSelected:ReportDefinition;
  reports:ReportDefinition[] = [];
  private _reportDefinitionSubscribe$: Subscription;

  constructor(
    private _spinnerService: Ng4LoadingSpinnerService,
    private reportDefinitionService:ReportDefinitionService,
    private _router: Router,
    private _globalService: GlobalService
    ) {
      this.reportDefinitionService.rootUrl = environment.ApiUrl;
    }

  ngOnInit() {
    let isOnInitComponent = true;
    this.changeManagedUser = {
      managedUserMail : this._globalService.getSelectedManagedUser().email,
      selectedManagedUseIsLoggedUser: this._globalService.userManager.selectedManagedUseIsLoggedUser,
    }

    

    this.changeManagedUserEvent$ = this._globalService.changeManagedUser.subscribe(value => {
      if(isOnInitComponent)
        return;
      this.changeManagedUser = {
        managedUserMail : value.selectedManagedUser.email,
        managedUserName: value.selectedManagedUser.firstName + " " + value.selectedManagedUser.lastName,
        selectedManagedUseIsLoggedUser: value.selectedManagedUseIsLoggedUser,
      }
      this.loadReportDefinition();
    });

    this.loadReportDefinition();
    isOnInitComponent = false;
  }
  
  loadReportDefinition () {
      this._spinnerService.show();
      this._reportDefinitionSubscribe$ = this.reportDefinitionService.GetReportDefinitionByUser()
      .subscribe(response => {
        if(response.errors.length == 0)
        this.reports = response.results;
        else
          response.errors.forEach(x => console.log(x.message));
        this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
  }

  changeReportSelected(){
    if(this.reportSelected)
      this._router.navigate(["/"+sessionStorage.getItem('client')+'/report/execution/executionReport'], { queryParams: 
        {
          reportId: this.reportSelected.id,
          reportName: this.reportSelected.name
        }
      });
  }

  ngOnDestroy() {
    if ( this._reportDefinitionSubscribe$ ) {
      this._reportDefinitionSubscribe$.unsubscribe();
    }
  }
}
