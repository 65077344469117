import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';

@Component({
  selector: 'app-modal-dialog-acknowledge',
  templateUrl: './acknowledge-modal-dialog.component.html',
  styleUrls: ['./acknowledge-modal-dialog.component.scss']
})
export class ModalDialogAcknowledgeComponent implements OnInit {
  title:string;
  message:string;
  //hasErrors:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalDialogAcknowledgeComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.message = this.data.message;
  }

  onYesClick()
  {
    this.dialogRef.close({hasErrors: this.data.hasErrors});
  }

}
