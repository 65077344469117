import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ReportSchedule } from '@app/api/models';
import { ReportScheduleService} from '@app/api/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';



@Component({
  selector: 'modal-dialog-no-filters',
  templateUrl: './modal-dialog-no-filters.component.html',
  styleUrls: ['./modal-dialog-no-filters.component.scss'],
  providers: [   
    ]
  })

export class NofiltersModalDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<NofiltersModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _snackBar: MatSnackBar,
    private spinnerService: Ng4LoadingSpinnerService,
    private reportScheduleService: ReportScheduleService ,
    private _adapter: DateAdapter<any>
    ) { 
  
    }

  ngOnInit() {
    

  }

  onClickApply(){
    this.dialogRef.close(true);
  }

  onClickCancel(){
    this.dialogRef.close(false);
  }

 

}
