import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [ALS] => Employment Leave Start Date
 */
@Injectable({
  providedIn: 'root',
})
export class AHLeaveStartDateInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[ALS]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(!config.authorityHolderEmployeeInfo) {
        return "N/A"
      }

      let AHInfo = config.authorityHolderEmployeeInfo; 
      if (AHInfo.leaveStartDate == null) 
        return "N/A";
      else if (AHInfo.leaveStartDate)
        { if(AHInfo.leaveStartDate.includes("<span"))
            return AHInfo.leaveStartDate;
          else
           return this.dateToString(AHInfo.leaveStartDate);
        }
       

      return this.infillCode;
    }
  }