import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Inject, Pipe, PipeTransform, Directive, ElementRef, NgZone, ChangeDetectorRef, OnDestroy, Input, ViewChild, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ok } from 'assert';
import { OverlayModule } from '@angular/cdk/overlay';
import { DalCompleteViewModelService } from '@app/api/services/dal-complete-view-model.service';
import angular from 'angular';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { DalsService } from '@app/api/services';
import { environment } from '@env/environment';

@Component({
  selector: 'app-modal-comments',
  templateUrl: './modal-comments.component.html',
  styleUrls: ['./modal-comments.component.scss']
})


export class ModalCommentsComponent implements OnInit {
  title: string;
  // message:string;
  comment: string = '';
  okText: string;
  cancelText: string;
  position: any;
  center: any;
  @Input() dalId: any;

  @Output() CloseComments = new EventEmitter();
  constructor(
    // public dialogRef: MatDialogRef<ModalCommentsComponent>,
    private dalService: DalsService,
    // @Inject(MAT_DIALOG_DATA) private data: any
  ) {


    this.dalService.rootUrl = environment.ApiUrl;
  }
  // @Inject(MAT_DIALOG_DATA) private data: any,
  // @Inject(MAT_DIALOG_DATA) public options: { positionRelativeToElement: ElementRef }



  ngOnInit() {

    var doc = document.documentElement;
    var docu = document ;
    // var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    var rest = doc.scrollTop > 0 ? doc.scrollTop + 450 : 420;
    this.position = -(doc.scrollHeight - rest);

    if (docu.location.href.includes('delegation-tree')) {
      this.center = docu.body.clientHeight;
    } else {

      this.center = 517;
    }
    // this.position = (window).scrollTop();
    // this.okText = this.data.okText;

  }

  close(event): void {

    this.CloseComments.emit({ close: event });
  }


  save() {
    this.dalService.PostComment(
      {
        DalId: this.dalId,

        Comment: this.comment,
        // continueWithWarning: continueWithWarning
      }).subscribe(response => {
        // var hola = response;
        this.close(false);

      })
  }





}

