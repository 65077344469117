/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedCollectionResponseBaseData } from '../models/paged-collection-response-base-data';
import { BaseData } from '../models/base-data';
import { BaseDataSearchCriteria } from '../models/base-data-search-criteria';
import { Dal } from '../models/dal';
import { UploadResponse } from '../models/upload-response';
@Injectable({
  providedIn: 'root',
})
class BaseDatasService extends __BaseService {
  static readonly GetPath = '/api/BaseDatas';
  static readonly PostPath = '/api/BaseDatas';
  static readonly GetVersioningReasonsPath = '/api/BaseDatas/GetVersioningReasons';
  static readonly GetByIdPath = '/api/BaseDatas/{id}';
  static readonly PutPath = '/api/BaseDatas/{id}';
  static readonly DeletePath = '/api/BaseDatas/{id}';
  static readonly UpsertPath = '/api/BaseDatas/Upsert';
  static readonly DownloadPath = '/api/BaseDatas/Download';
  static readonly UploadPath = '/api/BaseDatas/Upload';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BaseDatasService.GetParams` containing the following parameters:
   *
   * - `WordingSearch`:
   *
   * - `Type`:
   *
   * - `Title`:
   *
   * - `TableType`:
   *
   * - `SubType`:
   *
   * - `PageSize`:
   *
   * - `MethodOfAcceptance`:
   *
   * - `ListType`:
   *
   * - `FunctionsList`:
   *
   * - `Functions`:
   *
   * - `Entity`:
   *
   * - `DivisionsList`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * - `CurrentPage`:
   *
   * - `ClassOfBusiness`:
   *
   * - `Active`:
   *
   * @return Success
   */
  GetResponse(params: BaseDatasService.GetParams): __Observable<__StrictHttpResponse<PagedCollectionResponseBaseData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WordingSearch != null) __params = __params.set('WordingSearch', params.WordingSearch.toString());
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Title != null) __params = __params.set('Title', params.Title.toString());
    if (params.TableType != null) __params = __params.set('TableType', params.TableType.toString());
    if (params.SubType != null) __params = __params.set('SubType', params.SubType.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.MethodOfAcceptance != null) __params = __params.set('MethodOfAcceptance', params.MethodOfAcceptance.toString());
    if (params.ListType != null) __params = __params.set('ListType', params.ListType.toString());
    (params.FunctionsList || []).forEach(val => {if (val != null) __params = __params.append('FunctionsList', val.toString())});
    if (params.Functions != null) __params = __params.set('Functions', params.Functions.toString());
    if (params.Entity != null) __params = __params.set('Entity', params.Entity.toString());
    (params.DivisionsList || []).forEach(val => {if (val != null) __params = __params.append('DivisionsList', val.toString())});
    if (params.Divisions != null) __params = __params.set('Divisions', params.Divisions.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    if (params.CurrentPage != null) __params = __params.set('CurrentPage', params.CurrentPage.toString());
    if (params.ClassOfBusiness != null) __params = __params.set('ClassOfBusiness', params.ClassOfBusiness.toString());
    if (params.Active != null) __params = __params.set('Active', params.Active.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BaseDatas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseBaseData>;
      })
    );
  }
  /**
   * @param params The `BaseDatasService.GetParams` containing the following parameters:
   *
   * - `WordingSearch`:
   *
   * - `Type`:
   *
   * - `Title`:
   *
   * - `TableType`:
   *
   * - `SubType`:
   *
   * - `PageSize`:
   *
   * - `MethodOfAcceptance`:
   *
   * - `ListType`:
   *
   * - `FunctionsList`:
   *
   * - `Functions`:
   *
   * - `Entity`:
   *
   * - `DivisionsList`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * - `CurrentPage`:
   *
   * - `ClassOfBusiness`:
   *
   * - `Active`:
   *
   * @return Success
   */
  Get(params: BaseDatasService.GetParams): __Observable<PagedCollectionResponseBaseData> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as PagedCollectionResponseBaseData)
    );
  }

  /**
   * @param baseData undefined
   * @return Success
   */
  PostResponse(baseData?: BaseData): __Observable<__StrictHttpResponse<BaseData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = baseData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BaseDatas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseData>;
      })
    );
  }
  /**
   * @param baseData undefined
   * @return Success
   */
  Post(baseData?: BaseData): __Observable<BaseData> {
    return this.PostResponse(baseData).pipe(
      __map(_r => _r.body as BaseData)
    );
  }

  /**
   * @param baseDataSearchCriteria undefined
   * @return Success
   */
  GetVersioningReasonsResponse(baseDataSearchCriteria?: BaseDataSearchCriteria): __Observable<__StrictHttpResponse<PagedCollectionResponseBaseData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = baseDataSearchCriteria;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BaseDatas/GetVersioningReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseBaseData>;
      })
    );
  }
  /**
   * @param baseDataSearchCriteria undefined
   * @return Success
   */
  GetVersioningReasons(baseDataSearchCriteria?: BaseDataSearchCriteria): __Observable<PagedCollectionResponseBaseData> {
    return this.GetVersioningReasonsResponse(baseDataSearchCriteria).pipe(
      __map(_r => _r.body as PagedCollectionResponseBaseData)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BaseDatas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<Dal> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as Dal)
    );
  }

  /**
   * @param params The `BaseDatasService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `baseData`:
   *
   * @return Success
   */
  PutResponse(params: BaseDatasService.PutParams): __Observable<__StrictHttpResponse<BaseData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.baseData;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/BaseDatas/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseData>;
      })
    );
  }
  /**
   * @param params The `BaseDatasService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `baseData`:
   *
   * @return Success
   */
  Put(params: BaseDatasService.PutParams): __Observable<BaseData> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as BaseData)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<BaseData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/BaseDatas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseData>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<BaseData> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as BaseData)
    );
  }

  /**
   * @param baseDatas undefined
   * @return Success
   */
  UpsertResponse(baseDatas?: Array<BaseData>): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = baseDatas;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BaseDatas/Upsert`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param baseDatas undefined
   * @return Success
   */
  Upsert(baseDatas?: Array<BaseData>): __Observable<boolean> {
    return this.UpsertResponse(baseDatas).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BaseDatasService.DownloadParams` containing the following parameters:
   *
   * - `WordingSearch`:
   *
   * - `Type`:
   *
   * - `Title`:
   *
   * - `TableType`:
   *
   * - `SubType`:
   *
   * - `PageSize`:
   *
   * - `MethodOfAcceptance`:
   *
   * - `ListType`:
   *
   * - `FunctionsList`:
   *
   * - `Functions`:
   *
   * - `Entity`:
   *
   * - `DivisionsList`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * - `CurrentPage`:
   *
   * - `ClassOfBusiness`:
   *
   * - `Active`:
   *
   * @return Success
   */
  DownloadResponse(params: BaseDatasService.DownloadParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WordingSearch != null) __params = __params.set('WordingSearch', params.WordingSearch.toString());
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Title != null) __params = __params.set('Title', params.Title.toString());
    if (params.TableType != null) __params = __params.set('TableType', params.TableType.toString());
    if (params.SubType != null) __params = __params.set('SubType', params.SubType.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.MethodOfAcceptance != null) __params = __params.set('MethodOfAcceptance', params.MethodOfAcceptance.toString());
    if (params.ListType != null) __params = __params.set('ListType', params.ListType.toString());
    (params.FunctionsList || []).forEach(val => {if (val != null) __params = __params.append('FunctionsList', val.toString())});
    if (params.Functions != null) __params = __params.set('Functions', params.Functions.toString());
    if (params.Entity != null) __params = __params.set('Entity', params.Entity.toString());
    (params.DivisionsList || []).forEach(val => {if (val != null) __params = __params.append('DivisionsList', val.toString())});
    if (params.Divisions != null) __params = __params.set('Divisions', params.Divisions.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    if (params.CurrentPage != null) __params = __params.set('CurrentPage', params.CurrentPage.toString());
    if (params.ClassOfBusiness != null) __params = __params.set('ClassOfBusiness', params.ClassOfBusiness.toString());
    if (params.Active != null) __params = __params.set('Active', params.Active.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BaseDatas/Download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `BaseDatasService.DownloadParams` containing the following parameters:
   *
   * - `WordingSearch`:
   *
   * - `Type`:
   *
   * - `Title`:
   *
   * - `TableType`:
   *
   * - `SubType`:
   *
   * - `PageSize`:
   *
   * - `MethodOfAcceptance`:
   *
   * - `ListType`:
   *
   * - `FunctionsList`:
   *
   * - `Functions`:
   *
   * - `Entity`:
   *
   * - `DivisionsList`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * - `CurrentPage`:
   *
   * - `ClassOfBusiness`:
   *
   * - `Active`:
   *
   * @return Success
   */
  Download(params: BaseDatasService.DownloadParams): __Observable<string> {
    return this.DownloadResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `BaseDatasService.UploadParams` containing the following parameters:
   *
   * - `WordingSearch`:
   *
   * - `Type`:
   *
   * - `Title`:
   *
   * - `TableType`:
   *
   * - `SubType`:
   *
   * - `PageSize`:
   *
   * - `MethodOfAcceptance`:
   *
   * - `ListType`:
   *
   * - `FunctionsList`:
   *
   * - `Functions`:
   *
   * - `Entity`:
   *
   * - `DivisionsList`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * - `CurrentPage`:
   *
   * - `ClassOfBusiness`:
   *
   * - `Active`:
   *
   * @return Success
   */
  UploadResponse(params: BaseDatasService.UploadParams): __Observable<__StrictHttpResponse<UploadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WordingSearch != null) __params = __params.set('WordingSearch', params.WordingSearch.toString());
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Title != null) __params = __params.set('Title', params.Title.toString());
    if (params.TableType != null) __params = __params.set('TableType', params.TableType.toString());
    if (params.SubType != null) __params = __params.set('SubType', params.SubType.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.MethodOfAcceptance != null) __params = __params.set('MethodOfAcceptance', params.MethodOfAcceptance.toString());
    if (params.ListType != null) __params = __params.set('ListType', params.ListType.toString());
    (params.FunctionsList || []).forEach(val => {if (val != null) __params = __params.append('FunctionsList', val.toString())});
    if (params.Functions != null) __params = __params.set('Functions', params.Functions.toString());
    if (params.Entity != null) __params = __params.set('Entity', params.Entity.toString());
    (params.DivisionsList || []).forEach(val => {if (val != null) __params = __params.append('DivisionsList', val.toString())});
    if (params.Divisions != null) __params = __params.set('Divisions', params.Divisions.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    if (params.CurrentPage != null) __params = __params.set('CurrentPage', params.CurrentPage.toString());
    if (params.ClassOfBusiness != null) __params = __params.set('ClassOfBusiness', params.ClassOfBusiness.toString());
    if (params.Active != null) __params = __params.set('Active', params.Active.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BaseDatas/Upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadResponse>;
      })
    );
  }
  /**
   * @param params The `BaseDatasService.UploadParams` containing the following parameters:
   *
   * - `WordingSearch`:
   *
   * - `Type`:
   *
   * - `Title`:
   *
   * - `TableType`:
   *
   * - `SubType`:
   *
   * - `PageSize`:
   *
   * - `MethodOfAcceptance`:
   *
   * - `ListType`:
   *
   * - `FunctionsList`:
   *
   * - `Functions`:
   *
   * - `Entity`:
   *
   * - `DivisionsList`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * - `CurrentPage`:
   *
   * - `ClassOfBusiness`:
   *
   * - `Active`:
   *
   * @return Success
   */
  Upload(params: BaseDatasService.UploadParams): __Observable<UploadResponse> {
    return this.UploadResponse(params).pipe(
      __map(_r => _r.body as UploadResponse)
    );
  }
}

module BaseDatasService {

  /**
   * Parameters for Get
   */
  export interface GetParams {
    WordingSearch?: boolean;
    Type?: string;
    Title?: string;
    TableType?: string;
    SubType?: string;
    PageSize?: number;
    MethodOfAcceptance?: string;
    ListType?: string;
    FunctionsList?: Array<string>;
    Functions?: string;
    Entity?: string;
    DivisionsList?: Array<string>;
    Divisions?: string;
    Description?: string;
    CurrentPage?: number;
    ClassOfBusiness?: string;
    Active?: boolean;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    baseData?: BaseData;
  }

  /**
   * Parameters for Download
   */
  export interface DownloadParams {
    WordingSearch?: boolean;
    Type?: string;
    Title?: string;
    TableType?: string;
    SubType?: string;
    PageSize?: number;
    MethodOfAcceptance?: string;
    ListType?: string;
    FunctionsList?: Array<string>;
    Functions?: string;
    Entity?: string;
    DivisionsList?: Array<string>;
    Divisions?: string;
    Description?: string;
    CurrentPage?: number;
    ClassOfBusiness?: string;
    Active?: boolean;
  }

  /**
   * Parameters for Upload
   */
  export interface UploadParams {
    WordingSearch?: boolean;
    Type?: string;
    Title?: string;
    TableType?: string;
    SubType?: string;
    PageSize?: number;
    MethodOfAcceptance?: string;
    ListType?: string;
    FunctionsList?: Array<string>;
    Functions?: string;
    Entity?: string;
    DivisionsList?: Array<string>;
    Divisions?: string;
    Description?: string;
    CurrentPage?: number;
    ClassOfBusiness?: string;
    Active?: boolean;
  }
}

export { BaseDatasService }
