import { Component, OnInit, ElementRef } from '@angular/core';
import { DalLibraryItem } from '@app/api/models';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { MatDialog } from '@angular/material';
import { PreviewTextLibraryItemComponent } from '@app/shared/components/preview-text-library-item/preview-text-library-item.component';
//import { DalContentConfiguration } from '@app/shared';


@Component({
  selector: 'app-demo-dal-library-items',
  templateUrl: './demo-dal-library-items.component.html',
  styleUrls: ['./demo-dal-library-items.component.scss']
})
export class DemoDalLibraryItemsComponent implements OnInit {

  dalContentConfiguration : DalContentConfiguration;

  libraryItemTest1 : DalLibraryItem;

  private hostElement: HTMLElement;

  constructor(public dialog: MatDialog) { 

  }

  ngOnInit() {

    this.libraryItemTest1 = {
      clientId: null,
        id:"001AB",
        code:"1234AHFC",
        name:"General Principle",
        libraryType: {
          name: "Clause"
        },
        contentType:"LEVELTABLE",
        isMandatory:true,
        section: {
          id : "01",
          title : "Section"
        },
        limitsValidation:false,
        startDate: new Date("01/01/2020").toISOString(), 
        endDate: new Date("01/01/2021").toISOString(),
        status:"Active",
        description:"<ng-container *ngIf='false'><h4>Permissions</h4></ng-container> The Autority Holder,<b><i> [DAH],</i></b> <br /> Accepts to manage the authority of <b>[BA:$1] </b> for the Class of Business [DS:COB1] in [GT:COU1] for <u><b>[OR:DIV1].</b></u>"+
        "<br/> some text some text some text <br /> <br/> some text some text some text <br /><br/> some text some text some text <br /><br/> some text some text some text <br />",
        // itemVariables: [


        // ],
        //table:"",
        order: 2,
    };

    this.dalContentConfiguration = new DalContentConfiguration();
    this.dalContentConfiguration.libraryItems.push(this.libraryItemTest1);

  }

  showPreviewDialog() {
    const dialogRef = this.dialog.open(PreviewTextLibraryItemComponent, {
      width: '80%',
      height: '600px',
      data: {
        currentLibraryItem: this.libraryItemTest1,
        dalContentConfiguration: this.dalContentConfiguration
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null) {
        



      }
    });
  }

}
