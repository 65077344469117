/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseDTO } from '../models/response-dto';
import { Permission } from '../models/permission';
@Injectable({
  providedIn: 'root',
})
class PermissionService extends __BaseService {
  static readonly InsertPermissionPath = '/api/Permission/InsertPermission';
  static readonly UpdatePermissionPath = '/api/Permission/UpdatePermission';
  static readonly GetPermissionsPath = '/api/Permission/GetPermissions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param permission undefined
   * @return Success
   */
  InsertPermissionResponse(permission?: Permission): __Observable<__StrictHttpResponse<ResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = permission;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Permission/InsertPermission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseDTO>;
      })
    );
  }
  /**
   * @param permission undefined
   * @return Success
   */
  InsertPermission(permission?: Permission): __Observable<ResponseDTO> {
    return this.InsertPermissionResponse(permission).pipe(
      __map(_r => _r.body as ResponseDTO)
    );
  }

  /**
   * @param permission undefined
   * @return Success
   */
  UpdatePermissionResponse(permission?: Permission): __Observable<__StrictHttpResponse<ResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = permission;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Permission/UpdatePermission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseDTO>;
      })
    );
  }
  /**
   * @param permission undefined
   * @return Success
   */
  UpdatePermission(permission?: Permission): __Observable<ResponseDTO> {
    return this.UpdatePermissionResponse(permission).pipe(
      __map(_r => _r.body as ResponseDTO)
    );
  }

  /**
   * @return Success
   */
  GetPermissionsResponse(): __Observable<__StrictHttpResponse<Array<Permission>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Permission/GetPermissions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Permission>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPermissions(): __Observable<Array<Permission>> {
    return this.GetPermissionsResponse().pipe(
      __map(_r => _r.body as Array<Permission>)
    );
  }
}

module PermissionService {
}

export { PermissionService }
