import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [ASD] => Authority Holder Start Date
 */
@Injectable({
  providedIn: 'root',
})
export class AHStartDateInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[ASD]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(!config.authorityHolderEmployeeInfo) {
        return "N/A"
      }

      let AHInfo = config.authorityHolderEmployeeInfo; 
      if (AHInfo.startDate == null) 
        return "N/A";
      else if (AHInfo.startDate)
          {
            if(AHInfo.startDate.includes("<span"))
            return AHInfo.startDate;
            else
            return this.dateToString(AHInfo.startDate);
          } 
        

      return this.infillCode;
    }
  }