/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DalAttachment } from '../models/dal-attachment';
@Injectable({
  providedIn: 'root',
})
class DalAttachmentsService extends __BaseService {
  static readonly GetPath = '/api/DalAttachments';
  static readonly PostPath = '/api/DalAttachments';
  static readonly DownloadPath = '/api/DalAttachments/Download';
  static readonly DownloadStreamPath = '/api/DalAttachments/DownloadStream';
  static readonly DeletePath = '/api/DalAttachments/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param DalId undefined
   * @return Success
   */
  GetResponse(DalId?: string): __Observable<__StrictHttpResponse<Array<DalAttachment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (DalId != null) __params = __params.set('DalId', DalId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalAttachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DalAttachment>>;
      })
    );
  }
  /**
   * @param DalId undefined
   * @return Success
   */
  Get(DalId?: string): __Observable<Array<DalAttachment>> {
    return this.GetResponse(DalId).pipe(
      __map(_r => _r.body as Array<DalAttachment>)
    );
  }

  /**
   * @param DalId undefined
   * @return Success
   */
  PostResponse(DalId?: string): __Observable<__StrictHttpResponse<DalAttachment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (DalId != null) __params = __params.set('DalId', DalId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalAttachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalAttachment>;
      })
    );
  }
  /**
   * @param DalId undefined
   * @return Success
   */
  Post(DalId?: string): __Observable<DalAttachment> {
    return this.PostResponse(DalId).pipe(
      __map(_r => _r.body as DalAttachment)
    );
  }

  /**
   * @param params The `DalAttachmentsService.DownloadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  DownloadResponse(params: DalAttachmentsService.DownloadParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalAttachments/Download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `DalAttachmentsService.DownloadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  Download(params: DalAttachmentsService.DownloadParams): __Observable<string> {
    return this.DownloadResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `DalAttachmentsService.DownloadStreamParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  DownloadStreamResponse(params: DalAttachmentsService.DownloadStreamParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalAttachments/DownloadStream`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `DalAttachmentsService.DownloadStreamParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  DownloadStream(params: DalAttachmentsService.DownloadStreamParams): __Observable<string> {
    return this.DownloadStreamResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `DalAttachmentsService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  DeleteResponse(params: DalAttachmentsService.DeleteParams): __Observable<__StrictHttpResponse<DalAttachment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/DalAttachments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalAttachment>;
      })
    );
  }
  /**
   * @param params The `DalAttachmentsService.DeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  Delete(params: DalAttachmentsService.DeleteParams): __Observable<DalAttachment> {
    return this.DeleteResponse(params).pipe(
      __map(_r => _r.body as DalAttachment)
    );
  }
}

module DalAttachmentsService {

  /**
   * Parameters for Download
   */
  export interface DownloadParams {
    id?: string;
    dalId?: string;
  }

  /**
   * Parameters for DownloadStream
   */
  export interface DownloadStreamParams {
    id?: string;
    dalId?: string;
  }

  /**
   * Parameters for Delete
   */
  export interface DeleteParams {
    id: string;
    dalId?: string;
  }
}

export { DalAttachmentsService }
