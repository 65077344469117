import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LibraryItem } from '@app/api/models/library-item';;
import { HttpErrorResponse } from '@angular/common/http';
import { LibraryItemService } from '@app/api/services/library-item.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { OrganizationDatasService, FunctionService, DivisionService, TenantService } from '@app/api/services';
import { environment } from '@env/environment';
import { LibraryTypeService } from '@app/api/services/library-type.service';
import { EntityResultLibraryItem, HcmEmployeeDetails, LibrarySection, LibrarySubSection, OrganizationDataView } from '@app/api/models';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { PreviewTextLibraryItemComponent } from '../preview-text-library-item/preview-text-library-item.component';

@Component({
  selector: 'app-add-library-item-modal-dialog',
  templateUrl: './add-library-item-modal-dialog.component.html',
  styleUrls: ['./add-library-item-modal-dialog.component.scss']
})
export class AddLibraryItemModalDialogComponent implements OnInit {

  filter:LibraryItemFilter = new LibraryItemFilter();
  types:any = [];
  dataSource:LibraryItem[]=[];
  displayedColumns: string[] = ['select','preview','name', 'type', 'code'];
  selectedLibraryItems:LibraryItem[] = [];
  section:any;
  subSection:any;
  libraryItems:any[];
  dalContentConfiguration : DalContentConfiguration;
  showButtons:boolean = false;
  searchClicked:boolean = false;
  divisionsForSearch: Array<OrganizationDataView>;
  functionsForSearch: Array<OrganizationDataView>;
  liResult: LibraryItem;
  public baseCurrencyCode: string = null;

  constructor(
    public dialogRef: MatDialogRef<AddLibraryItemModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _spinnerService: Ng4LoadingSpinnerService,
    private _organizationDataService : OrganizationDatasService,
    private libraryItemService:LibraryItemService,
    private libraryTypeService:LibraryTypeService,
    private divisionService: DivisionService,
    private functionService: FunctionService,
    private _tenantService: TenantService,
    private _dialog: MatDialog,
    ) {
      this._organizationDataService.rootUrl = environment.ApiUrl;
      this.libraryItemService.rootUrl = environment.ApiUrl;
      this.libraryTypeService.rootUrl = environment.ApiUrl;
      this._tenantService.rootUrl = environment.ApiUrl;

      if(data) {
        if(data.section)
          this.section = data.section;
  
        if(data.subSection)
          this.subSection = data.subSection;
  
        if(data.libraryItems)
          this.libraryItems = data.libraryItems;

        if(data.dalContentConfiguration) {
          this.dalContentConfiguration = data.dalContentConfiguration;

          if(!this.section && this.subSection){
            var findSubSec = this.dalContentConfiguration.subSections.find(x=> x.subSectionBase.id == this.subSection.id);
            if(findSubSec){
              this.section = this.dalContentConfiguration.sections.find(x => x.id == findSubSec.parentSection.id);
              if(this.section){
                this.section = this.section.sectionBase.id;
              } else {
                this.section = findSubSec.parentSection;
              }
            }
          }
          
        }

        
      }
     
    }

  close(): void {
    this.dialogRef.close();
  }

  getOpenedMenu( isOpen :boolean){
    console.log('getOpenedMenu: '+isOpen)
    this.close()
  }

  ngOnInit() {
    this.loadLibraryTypes();
    this.loadDivisionAndFunctions();
  }

  openPreview(id: string) {
    if (id) {
      //todo call openPreview
      this.getLibraryByID(id);
    }
  }

  getLibraryByID(id: string) {
    this._spinnerService.show();
    this.libraryItemService.GetById(id)
      .subscribe((data: EntityResultLibraryItem) => {
        this._spinnerService.hide();
        if (data) {
          this.liResult = data.result;
          if (data.result) {
            //call preview
            //console.log(this.liResult);
            this.showPreview();
          }
        }

      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          //this.showSnackBar(err.message);
        }
      );
  }

  showPreview() {
    let dalContentConfiguration: DalContentConfiguration = new DalContentConfiguration();
    dalContentConfiguration.state = this.liResult != null ? this.liResult.status : 'Draft';
    dalContentConfiguration.version = '1';
    dalContentConfiguration.startDate = this.liResult != null ? this.liResult.startDate : this.toDate(new Date());
    dalContentConfiguration.endDate = this.liResult != null ? this.liResult.endDate : this.toDate(new Date());
    dalContentConfiguration.reviewDate = this.toDate(new Date());
    dalContentConfiguration.acknowledgementDate = this.toDate(new Date());
    dalContentConfiguration.endDate = this.toDate(new Date());
    dalContentConfiguration.baseCurrencyCode = this.baseCurrencyCode;
    dalContentConfiguration.code = '000215';

    dalContentConfiguration.divisions = [];
    // if (this.divisions) {
    //   this.divisions.forEach(x => {
    //     const divisiondata: OrganizationData = {
    //       clientId: null, orgGroupName: x.orgGroupName, orgGroupType: x.orgGroupType
    //     };
    //     dalContentConfiguration.divisions.push(divisiondata);
    //   });
    // }

    dalContentConfiguration.functions = [];
    // if (this.functions) {
    //   this.functions.forEach(x => {
    //     const functiondata: OrganizationData = {
    //       clientId: null, orgGroupName: x.orgGroupName, orgGroupType: x.orgGroupType
    //     };
    //     dalContentConfiguration.functions.push(functiondata);
    //   });
    // }
    dalContentConfiguration.authorityHolder = {
      email: "test@preview.com",
      firstName: "test name",
      lastName: "Authority Holder",
      id: null
    };

    dalContentConfiguration.composer = {
      email: "test@preview.com",
      firstName: "test name",
      lastName: "Composer",
      id: null
    };

    dalContentConfiguration.delegator = {
      email: "test@preview.com",
      firstName: "test name",
      lastName: "Delegator",
      id: null
    };

    dalContentConfiguration.authorityHolderEmployeeInfo = this.buildHcmEmployeeMock();

    //const libraryItemToPreview = this.getBindedCurrentLibraryItem();
    const libraryItemToPreview = this.liResult;

    var CopiedLibraryItem: any = {};
    //Object.assign(CopiedLibraryItem, libraryItemToPreview);
    CopiedLibraryItem = this.deepCopy(libraryItemToPreview);


    dalContentConfiguration.libraryItems = [CopiedLibraryItem];

    if (this.liResult.contentType == 'TEXT' ||
      this.liResult.contentType == 'TABLE' ||
      this.liResult.contentType == 'LEVELTABLE') {
      const dialogRef = this._dialog.open(PreviewTextLibraryItemComponent, {
        width: '80%',
        data: {
          currentLibraryItem: CopiedLibraryItem,
          dalContentConfiguration: dalContentConfiguration,
          editMode: true,
          mode: "Preview"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined && result != null) {
        }
      });
    }
  }


  getBaseCurrencyCode() {
    this._tenantService.GetTenantCurrency()
      .subscribe(response => {
        this.baseCurrencyCode = response;
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
  }

  toDate(date: Date): string {
    return date.toISOString();
  }

  buildHcmEmployeeMock() {
    const hcmEmployee: HcmEmployeeDetails =
    {
      jobTitle: 'Test jobTitle',
      company: 'Test company',
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      preferredName: 'Test preferredName',
      previousFirstName: 'Test previousFirstName',
      previousLastName: 'Test previousLastName',
      location: 'Test location',
      officeAddress: 'Test officeAddress',
      country: 'Test country',
      phoneNumber: 'Test phoneNumber',
      manager: 'Test manager',
      team: 'Test team',
      division: 'Test division',
      department: 'Test department',
      costCentreID: 'Test costCentreID',
      costCentreName: 'Test costCentreName',
      broadbandLevel: 'Test broadbandLevel',
      embMember: 'Test embMember',
      userView: {
        email: "test@preview.com",
        firstName: "test name",
        lastName: "Employee",
        id: null
      }
    }

    return hcmEmployee;

  }

  deepCopy(obj: any) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  loadLibraryTypes () {

    if (this.dalContentConfiguration != null &&
      this.dalContentConfiguration.libraryTypes != null &&
      this.dalContentConfiguration.libraryTypes.length > 0
    ) {
        this.types = this.dalContentConfiguration.libraryTypes;
    } else {

      this._spinnerService.show();
      this.libraryTypeService.Find({
        IsAvailable: true
      })
      .subscribe(response => {
        if(response.errors.length == 0)
        this.types = response.results;
        else
          response.errors.forEach(x => console.log(x.message));
        this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }
  }

  loadDivisionAndFunctions() {

    // First searchs in the dalContentConfiguration

    if (this.dalContentConfiguration != null &&
      this.dalContentConfiguration.functions != null &&
      this.dalContentConfiguration.functions.length > 0
    ) {
      this.functionsForSearch = this.dalContentConfiguration.functions;
      if(this.functionsForSearch.length == 1)
        this.filter.functionLI = this.functionsForSearch[0];
    } else {
      this._spinnerService.show();
      this.functionService.GetActiveFunctions()
      .subscribe(response => {
        this.functionsForSearch = response;
        this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

    if (this.dalContentConfiguration != null &&
      this.dalContentConfiguration.divisions != null &&
      this.dalContentConfiguration.divisions.length > 0
    ) {
      this.divisionsForSearch = this.dalContentConfiguration.divisions;
      if(this.divisionsForSearch.length == 1)
        this.filter.division = this.divisionsForSearch[0];
    } else {
      this._spinnerService.show();
      this.divisionService.GetActiveDivisions()
      .subscribe(response => {
        this.divisionsForSearch = response;
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
    }
  }

  search() {
    this._spinnerService.show();
    let sectionId = this.section ? this.section.id : null;
    let subSectionId = this.subSection ? this.subSection.id : null;
    let libraryItemType = this.filter.type ? this.filter.type.name : "";

    let onlyInThisDivisions : string[] = null;
    let onlyInThisFunctions : string[] = null;

    // Only needs to search in this division and functions
    if (this.dalContentConfiguration != null) {
      if (this.dalContentConfiguration.divisions != null) {
        onlyInThisDivisions = this.dalContentConfiguration.divisions.map(x => x.orgGroupName);
      }

      if (this.dalContentConfiguration.functions != null) {
        onlyInThisFunctions = this.dalContentConfiguration.functions.map(x => x.orgGroupName);
      }
    }

    this.libraryItemService.Find({
      DivisionName: this.filter.division != null ? this.filter.division.orgGroupName : "",
      FunctionName: this.filter.functionLI  != null ? this.filter.functionLI.orgGroupName : "",
      Code: this.filter.code,
      Name: this.filter.name.replace(/  +/g, ' '), // replace to remove double space
      LibraryTypeName: libraryItemType,
      SectionId: sectionId,
      HasSection: sectionId ? true : (subSectionId ? true : false),
      SubSectionId: subSectionId,
      HasSubSection: subSectionId ? true : false,
      OnlyInTheseDivisions: onlyInThisDivisions,
      OnlyInTheseFunctions: onlyInThisFunctions,
      IsAvailable: true
    })
    .subscribe(response =>
      {
        this._spinnerService.hide();
        this.searchClicked = true;
        if(response.errors.length == 0)
        {
          if(this.libraryItems){
            var results = response.results.filter(x => 
              !this.libraryItems.find(y => y.libraryItemBase.id == x.id));
            this.dataSource = results;
          } else {
            this.dataSource = response.results;
          }

          if(this.dataSource.length == 0)
            this.showButtons = false;
          else
            this.showButtons = true;
        }
        else
          response.errors.forEach(x => console.log(x.message));
      },
      (err: HttpErrorResponse) => {
        this.searchClicked = false;
        this._spinnerService.hide();
        console.log(err.message);
      }
    );
  }

  checkSelected(value:any, libraryItem:any) {
    if(value.checked){
      this.selectedLibraryItems.push(libraryItem);
    }else{
      this.selectedLibraryItems = this.selectedLibraryItems.filter( x => x.id != libraryItem.id);
    }
  }

  add(): void {
    this.dialogRef.close(this.selectedLibraryItems);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

export class LibraryItemFilter{
  name:string="";
  type:any="";
  code:string="";
  division:any;
  functionLI:any;
}