/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExchangeRate } from '../models/exchange-rate';
import { PagedCollectionResponseExchangeRate } from '../models/paged-collection-response-exchange-rate';
@Injectable({
  providedIn: 'root',
})
class ExchangeRateService extends __BaseService {
  static readonly InsertExchangeRatePath = '/api/ExchangeRate/InsertExchangeRate';
  static readonly UpdateExchangeRatePath = '/api/ExchangeRate/UpdateExchangeRate';
  static readonly GetExchangeRatesPath = '/api/ExchangeRate/GetExchangeRates';
  static readonly GetAllExchangeRatesPath = '/api/ExchangeRate/GetAllExchangeRates';
  static readonly GetCurrentExchangeRatePath = '/api/ExchangeRate/GetCurrentExchangeRate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param exchangeRate undefined
   * @return Success
   */
  InsertExchangeRateResponse(exchangeRate?: ExchangeRate): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = exchangeRate;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ExchangeRate/InsertExchangeRate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param exchangeRate undefined
   * @return Success
   */
  InsertExchangeRate(exchangeRate?: ExchangeRate): __Observable<string> {
    return this.InsertExchangeRateResponse(exchangeRate).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param exchangeRate undefined
   * @return Success
   */
  UpdateExchangeRateResponse(exchangeRate?: ExchangeRate): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = exchangeRate;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ExchangeRate/UpdateExchangeRate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param exchangeRate undefined
   * @return Success
   */
  UpdateExchangeRate(exchangeRate?: ExchangeRate): __Observable<string> {
    return this.UpdateExchangeRateResponse(exchangeRate).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param currencyId undefined
   * @return Success
   */
  GetExchangeRatesResponse(currencyId?: string): __Observable<__StrictHttpResponse<PagedCollectionResponseExchangeRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (currencyId != null) __params = __params.set('currencyId', currencyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ExchangeRate/GetExchangeRates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseExchangeRate>;
      })
    );
  }
  /**
   * @param currencyId undefined
   * @return Success
   */
  GetExchangeRates(currencyId?: string): __Observable<PagedCollectionResponseExchangeRate> {
    return this.GetExchangeRatesResponse(currencyId).pipe(
      __map(_r => _r.body as PagedCollectionResponseExchangeRate)
    );
  }

  /**
   * @return Success
   */
  GetAllExchangeRatesResponse(): __Observable<__StrictHttpResponse<Array<ExchangeRate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ExchangeRate/GetAllExchangeRates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ExchangeRate>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllExchangeRates(): __Observable<Array<ExchangeRate>> {
    return this.GetAllExchangeRatesResponse().pipe(
      __map(_r => _r.body as Array<ExchangeRate>)
    );
  }

  /**
   * @param currencyId undefined
   * @return Success
   */
  GetCurrentExchangeRateResponse(currencyId?: string): __Observable<__StrictHttpResponse<ExchangeRate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (currencyId != null) __params = __params.set('currencyId', currencyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ExchangeRate/GetCurrentExchangeRate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExchangeRate>;
      })
    );
  }
  /**
   * @param currencyId undefined
   * @return Success
   */
  GetCurrentExchangeRate(currencyId?: string): __Observable<ExchangeRate> {
    return this.GetCurrentExchangeRateResponse(currencyId).pipe(
      __map(_r => _r.body as ExchangeRate)
    );
  }
}

module ExchangeRateService {
}

export { ExchangeRateService }
