import { Component, OnInit, Inject, AfterContentChecked ,ChangeDetectorRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatRadioChange } from '@angular/material';
import { ok } from 'assert';
import { DalActionsService } from '@app/api/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';

@Component({
  selector: 'app-modal-dialog-change-delegator',
  templateUrl: './change-delegator-modal-dialog.component.html',
  styleUrls: ['./change-delegator-modal-dialog.component.scss']
})
export class ModalDialogChangeDelegatorComponent implements OnInit, AfterContentChecked{
  title:string;
  message:string;
  displayedColumns = ['select','description','code', 'version', 'status', 'division','function'];
  dataSource:any[]=[];
  dalId:string;
  canSelect:boolean;
  selectCount:number=0;
  showLog:boolean=false;
  newParentId:string;
  constructor(
    public dialogRef: MatDialogRef<ModalDialogChangeDelegatorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _dalActionsService: DalActionsService,
    private changeDetector: ChangeDetectorRef,
    private _spinnerService: Ng4LoadingSpinnerService) {
      this._dalActionsService.rootUrl = environment.ApiUrl;
      if(data) {
        if(data.dalId)
          this.dalId = data.dalId;
      }
    }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.message = this.data.message; 
    this.canSelect = this.data && this.data.canSelect ? true : false;
    this.newParentId = this.data.newParentId;
    this.loadDals();
  }

  loadDals(){
    this._spinnerService.show();
    this._dalActionsService.GetDalChildrenById(this.dalId)
    .subscribe(response =>
      {
        if(response.length != 0)
        {
          var dataList = [];
          response.forEach(child => {
            var data = {
              dal: child,
              selected: (this.newParentId != child.id?true:false)
            };
            dataList.push(data);
          });
          this.selectCount = dataList.length;
          this.dataSource = dataList;
        }

        this._spinnerService.hide();
      },
      (err: HttpErrorResponse) => {
        this._spinnerService.hide();
        console.log(err.message);
      }
    );
  }

  enableYes(){
    var seleteds = this.dataSource.filter(x => x.selected);
    this.selectCount=this.dataSource.filter(x => x.selected).length;
    if(seleteds.length > 0){
      return true;
    }else{
      return false;
    }
  }

  onYesClick()
  {
    var selectedChilds = this.dataSource.filter(x => x.selected);
    var childIds:string[] = [];
    selectedChilds.forEach(x => 
      {
        childIds.push(x.dal.id);
      }
    );
    this.dialogRef.close({confirm: true, data:childIds});
  }

  getTitle(data: any):string
  {
    if(data != undefined && data.delegator != undefined)
    {
      if(data.delegator.firstName === '' || data.delegator.firstName === null)
      {
       return "Master DAL of " + data.authorityHolder.firstName + " " + data.authorityHolder.lastName;
      }
      if(data.delegator.firstName !=='' && data.delegator.firstName !== null)
      {
        return "DAL from " + data.delegator.firstName + " " + data.delegator.lastName + " to " + data.authorityHolder.firstName + " " + data.authorityHolder.lastName;
      }
    }
    return "";
  }

  getFunctionDescriptions(data: any){
    var organizationDatas : any;
    if(data != undefined && data.organizationDataView != undefined && data.organizationDataView !== ""){
      organizationDatas = data.organizationDataView;
    }
    else
    {      
    return "";
    }

    if(!organizationDatas){
      return null;
    } 
    if(organizationDatas.length == 0){
      return null;
    }
    let functionDescription = "";
    var _functionNames = organizationDatas.filter(data => data.orgGroupType === "FUNCTION")
      .map(x => x.orgGroupName);

    if(_functionNames != null && _functionNames.length > 0) {
      return _functionNames.join(", ");
    }

    return "";
  }

  getDivisionDescriptions(data: any) {
    var organizationDatas : any;
    if(data != undefined && data.organizationDataView != undefined && data.organizationDataView !== ""){
      organizationDatas = data.organizationDataView;
    }
    else
    {      
    return "";
    }

    if(!organizationDatas){
      return null;
    } 
    if(organizationDatas.length == 0){
      return null;
    }
    let functionDescription = "";
    var _functionNames = organizationDatas.filter(data => data.orgGroupType === "DIVISIONS")
      .map(x => x.orgGroupName);

    if(_functionNames != null && _functionNames.length > 0) {
      return _functionNames.join(", ");
    }

    return "";
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }  
  
  getTooltip(element:any):string
  {
    let response="";
    if(element.dal.id == this.newParentId)
    {
      response ="The new parent DAL can not be selected";
    }
    return response;
  }

}