import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DFU] => Dal Function(s)
 */
@Injectable({
  providedIn: 'root',
})
export class DalFunctionsInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[DFU]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {
      
      if (config.functions){
        return this.getFunctionnDescriptions(config.functions)
      }

      return this.infillCode;
    }

    
    getFunctionnDescriptions(organizationDatas: any) {
  
      if(!organizationDatas){
        return this.infillCode;
      } 

      var _functionNames = organizationDatas.filter(data => data.orgGroupType === "FUNCTION")
        .map(x => x.orgGroupName);
  
      if(_functionNames != null && _functionNames.length > 0) {
        return _functionNames.join(", ");
      }
  
      return "N/A";
    }

  }