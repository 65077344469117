/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReviewerGroup } from '../models/reviewer-group';
import { OrganizationDataView } from '../models/organization-data-view';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class ReviewerGroupService extends __BaseService {
  static readonly InsertReviewerGroupPath = '/api/ReviewerGroup/InsertReviewerGroup';
  static readonly updateReviewerGroupPath = '/api/ReviewerGroup/updateReviewerGroup';
  static readonly GetReviewerGroupsPath = '/api/ReviewerGroup/GetReviewerGroups';
  static readonly GetAllowedOrganizationDataPath = '/api/ReviewerGroup/GetAllowedOrganizationData';
  static readonly GetReviewerGroupsByFilterAsyncPath = '/api/ReviewerGroup/GetReviewerGroupsByFilterAsync';
  static readonly GetByIdReviewerGroupsPath = '/api/ReviewerGroup/GetByIdReviewerGroups/{id}';
  static readonly EnablePath = '/api/ReviewerGroup/Enable';
  static readonly DisablePath = '/api/ReviewerGroup/Disable';
  static readonly GetReviewerGroupsByDalReviewerGroupAsyncPath = '/api/ReviewerGroup/GetReviewerGroupsByDalReviewerGroupAsync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param reviewerGroup undefined
   * @return Success
   */
  InsertReviewerGroupResponse(reviewerGroup?: ReviewerGroup): __Observable<__StrictHttpResponse<ReviewerGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reviewerGroup;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReviewerGroup/InsertReviewerGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReviewerGroup>;
      })
    );
  }
  /**
   * @param reviewerGroup undefined
   * @return Success
   */
  InsertReviewerGroup(reviewerGroup?: ReviewerGroup): __Observable<ReviewerGroup> {
    return this.InsertReviewerGroupResponse(reviewerGroup).pipe(
      __map(_r => _r.body as ReviewerGroup)
    );
  }

  /**
   * @param reviewerGroup undefined
   * @return Success
   */
  updateReviewerGroupResponse(reviewerGroup?: ReviewerGroup): __Observable<__StrictHttpResponse<ReviewerGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reviewerGroup;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReviewerGroup/updateReviewerGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReviewerGroup>;
      })
    );
  }
  /**
   * @param reviewerGroup undefined
   * @return Success
   */
  updateReviewerGroup(reviewerGroup?: ReviewerGroup): __Observable<ReviewerGroup> {
    return this.updateReviewerGroupResponse(reviewerGroup).pipe(
      __map(_r => _r.body as ReviewerGroup)
    );
  }

  /**
   * @return Success
   */
  GetReviewerGroupsResponse(): __Observable<__StrictHttpResponse<Array<ReviewerGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReviewerGroup/GetReviewerGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReviewerGroup>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetReviewerGroups(): __Observable<Array<ReviewerGroup>> {
    return this.GetReviewerGroupsResponse().pipe(
      __map(_r => _r.body as Array<ReviewerGroup>)
    );
  }

  /**
   * @return Success
   */
  GetAllowedOrganizationDataResponse(): __Observable<__StrictHttpResponse<Array<OrganizationDataView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReviewerGroup/GetAllowedOrganizationData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationDataView>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllowedOrganizationData(): __Observable<Array<OrganizationDataView>> {
    return this.GetAllowedOrganizationDataResponse().pipe(
      __map(_r => _r.body as Array<OrganizationDataView>)
    );
  }

  /**
   * @param params The `ReviewerGroupService.GetReviewerGroupsByFilterAsyncParams` containing the following parameters:
   *
   * - `matchDivisionAndFunctionExactly`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * @return Success
   */
  GetReviewerGroupsByFilterAsyncResponse(params: ReviewerGroupService.GetReviewerGroupsByFilterAsyncParams): __Observable<__StrictHttpResponse<Array<ReviewerGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.matchDivisionAndFunctionExactly != null) __params = __params.set('matchDivisionAndFunctionExactly', params.matchDivisionAndFunctionExactly.toString());
    (params.OnlyInTheseFunctions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseFunctions', val.toString())});
    (params.OnlyInTheseDivisions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseDivisions', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReviewerGroup/GetReviewerGroupsByFilterAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReviewerGroup>>;
      })
    );
  }
  /**
   * @param params The `ReviewerGroupService.GetReviewerGroupsByFilterAsyncParams` containing the following parameters:
   *
   * - `matchDivisionAndFunctionExactly`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * @return Success
   */
  GetReviewerGroupsByFilterAsync(params: ReviewerGroupService.GetReviewerGroupsByFilterAsyncParams): __Observable<Array<ReviewerGroup>> {
    return this.GetReviewerGroupsByFilterAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<ReviewerGroup>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdReviewerGroupsResponse(id: string): __Observable<__StrictHttpResponse<ReviewerGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReviewerGroup/GetByIdReviewerGroups/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReviewerGroup>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetByIdReviewerGroups(id: string): __Observable<ReviewerGroup> {
    return this.GetByIdReviewerGroupsResponse(id).pipe(
      __map(_r => _r.body as ReviewerGroup)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReviewerGroup/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReviewerGroup/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param params The `ReviewerGroupService.GetReviewerGroupsByDalReviewerGroupAsyncParams` containing the following parameters:
   *
   * - `dalReviewerGroupsViewId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  GetReviewerGroupsByDalReviewerGroupAsyncResponse(params: ReviewerGroupService.GetReviewerGroupsByDalReviewerGroupAsyncParams): __Observable<__StrictHttpResponse<Array<ReviewerGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dalReviewerGroupsViewId != null) __params = __params.set('dalReviewerGroupsViewId', params.dalReviewerGroupsViewId.toString());
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReviewerGroup/GetReviewerGroupsByDalReviewerGroupAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReviewerGroup>>;
      })
    );
  }
  /**
   * @param params The `ReviewerGroupService.GetReviewerGroupsByDalReviewerGroupAsyncParams` containing the following parameters:
   *
   * - `dalReviewerGroupsViewId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  GetReviewerGroupsByDalReviewerGroupAsync(params: ReviewerGroupService.GetReviewerGroupsByDalReviewerGroupAsyncParams): __Observable<Array<ReviewerGroup>> {
    return this.GetReviewerGroupsByDalReviewerGroupAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<ReviewerGroup>)
    );
  }
}

module ReviewerGroupService {

  /**
   * Parameters for GetReviewerGroupsByFilterAsync
   */
  export interface GetReviewerGroupsByFilterAsyncParams {
    matchDivisionAndFunctionExactly?: boolean;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
  }

  /**
   * Parameters for GetReviewerGroupsByDalReviewerGroupAsync
   */
  export interface GetReviewerGroupsByDalReviewerGroupAsyncParams {
    dalReviewerGroupsViewId?: string;
    dalId?: string;
  }
}

export { ReviewerGroupService }
