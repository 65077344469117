/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CollectionResultReportInstance } from '../models/collection-result-report-instance';
import { ReportInstance } from '../models/report-instance';
import { EntityResultReportInstance } from '../models/entity-result-report-instance';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class ReportInstanceService extends __BaseService {
  static readonly GetAllReportIntancePath = '/api/ReportInstance/GetAllReportIntance';
  static readonly GetReportIntanceByReportDefinitionPath = '/api/ReportInstance/GetReportIntanceByReportDefinition';
  static readonly RunReportPath = '/api/ReportInstance/RunReport';
  static readonly DownloadCSVPath = '/api/ReportInstance/DownloadCSV';
  static readonly InsertPath = '/api/ReportInstance';
  static readonly UpdatePath = '/api/ReportInstance';
  static readonly DeletePath = '/api/ReportInstance';
  static readonly EnablePath = '/api/ReportInstance/Enable';
  static readonly DisablePath = '/api/ReportInstance/Disable';
  static readonly GetAllPath = '/api/ReportInstance/GetAll';
  static readonly GetByIdPath = '/api/ReportInstance/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetAllReportIntanceResponse(): __Observable<__StrictHttpResponse<CollectionResultReportInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportInstance/GetAllReportIntance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReportInstance>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllReportIntance(): __Observable<CollectionResultReportInstance> {
    return this.GetAllReportIntanceResponse().pipe(
      __map(_r => _r.body as CollectionResultReportInstance)
    );
  }

  /**
   * @param reportDefinitionId undefined
   * @return Success
   */
  GetReportIntanceByReportDefinitionResponse(reportDefinitionId?: string): __Observable<__StrictHttpResponse<CollectionResultReportInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (reportDefinitionId != null) __params = __params.set('reportDefinitionId', reportDefinitionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportInstance/GetReportIntanceByReportDefinition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReportInstance>;
      })
    );
  }
  /**
   * @param reportDefinitionId undefined
   * @return Success
   */
  GetReportIntanceByReportDefinition(reportDefinitionId?: string): __Observable<CollectionResultReportInstance> {
    return this.GetReportIntanceByReportDefinitionResponse(reportDefinitionId).pipe(
      __map(_r => _r.body as CollectionResultReportInstance)
    );
  }

  /**
   * @param reportInstance undefined
   * @return Success
   */
  RunReportResponse(reportInstance?: ReportInstance): __Observable<__StrictHttpResponse<ReportInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reportInstance;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ReportInstance/RunReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportInstance>;
      })
    );
  }
  /**
   * @param reportInstance undefined
   * @return Success
   */
  RunReport(reportInstance?: ReportInstance): __Observable<ReportInstance> {
    return this.RunReportResponse(reportInstance).pipe(
      __map(_r => _r.body as ReportInstance)
    );
  }

  /**
   * @param params The `ReportInstanceService.DownloadCSVParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `doctype`:
   *
   * @return Success
   */
  DownloadCSVResponse(params: ReportInstanceService.DownloadCSVParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.doctype != null) __params = __params.set('doctype', params.doctype.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportInstance/DownloadCSV`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ReportInstanceService.DownloadCSVParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `doctype`:
   *
   * @return Success
   */
  DownloadCSV(params: ReportInstanceService.DownloadCSVParams): __Observable<string> {
    return this.DownloadCSVResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: ReportInstance): __Observable<__StrictHttpResponse<EntityResultReportInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ReportInstance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReportInstance>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: ReportInstance): __Observable<EntityResultReportInstance> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultReportInstance)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: ReportInstance): __Observable<__StrictHttpResponse<EntityResultReportInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReportInstance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReportInstance>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: ReportInstance): __Observable<EntityResultReportInstance> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultReportInstance)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ReportInstance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReportInstance/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReportInstance/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultReportInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportInstance/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReportInstance>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultReportInstance> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultReportInstance)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultReportInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportInstance/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReportInstance>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultReportInstance> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultReportInstance)
    );
  }
}

module ReportInstanceService {

  /**
   * Parameters for DownloadCSV
   */
  export interface DownloadCSVParams {
    id?: string;
    doctype?: string;
  }
}

export { ReportInstanceService }
