/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WorkflowAction } from '../models/workflow-action';
@Injectable({
  providedIn: 'root',
})
class WorkflowActionsService extends __BaseService {
  static readonly GetPath = '/api/WorkflowActions';
  static readonly PostPath = '/api/WorkflowActions';
  static readonly GetTaskInboxPath = '/api/WorkflowActions/WorkActionbyTask';
  static readonly GetByIdPath = '/api/WorkflowActions/{id}';
  static readonly PutPath = '/api/WorkflowActions/{id}';
  static readonly DeletePath = '/api/WorkflowActions/{id}';
  static readonly assignTaskPath = '/api/WorkflowActions/assignTask';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WorkflowActionsService.GetParams` containing the following parameters:
   *
   * - `UserEmail`:
   *
   * - `Status`:
   *
   * @return Success
   */
  GetResponse(params: WorkflowActionsService.GetParams): __Observable<__StrictHttpResponse<Array<WorkflowAction>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UserEmail != null) __params = __params.set('UserEmail', params.UserEmail.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/WorkflowActions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkflowAction>>;
      })
    );
  }
  /**
   * @param params The `WorkflowActionsService.GetParams` containing the following parameters:
   *
   * - `UserEmail`:
   *
   * - `Status`:
   *
   * @return Success
   */
  Get(params: WorkflowActionsService.GetParams): __Observable<Array<WorkflowAction>> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as Array<WorkflowAction>)
    );
  }

  /**
   * @param workFlowAction undefined
   * @return Success
   */
  PostResponse(workFlowAction?: WorkflowAction): __Observable<__StrictHttpResponse<WorkflowAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = workFlowAction;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowActions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowAction>;
      })
    );
  }
  /**
   * @param workFlowAction undefined
   * @return Success
   */
  Post(workFlowAction?: WorkflowAction): __Observable<WorkflowAction> {
    return this.PostResponse(workFlowAction).pipe(
      __map(_r => _r.body as WorkflowAction)
    );
  }

  /**
   * @param params The `WorkflowActionsService.GetTaskInboxParams` containing the following parameters:
   *
   * - `UserEmail`:
   *
   * - `Status`:
   *
   * @return Success
   */
  GetTaskInboxResponse(params: WorkflowActionsService.GetTaskInboxParams): __Observable<__StrictHttpResponse<Array<WorkflowAction>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UserEmail != null) __params = __params.set('UserEmail', params.UserEmail.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/WorkflowActions/WorkActionbyTask`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkflowAction>>;
      })
    );
  }
  /**
   * @param params The `WorkflowActionsService.GetTaskInboxParams` containing the following parameters:
   *
   * - `UserEmail`:
   *
   * - `Status`:
   *
   * @return Success
   */
  GetTaskInbox(params: WorkflowActionsService.GetTaskInboxParams): __Observable<Array<WorkflowAction>> {
    return this.GetTaskInboxResponse(params).pipe(
      __map(_r => _r.body as Array<WorkflowAction>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<WorkflowAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/WorkflowActions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowAction>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<WorkflowAction> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as WorkflowAction)
    );
  }

  /**
   * @param params The `WorkflowActionsService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `workFlowAction`:
   *
   * @return Success
   */
  PutResponse(params: WorkflowActionsService.PutParams): __Observable<__StrictHttpResponse<WorkflowAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.workFlowAction;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/WorkflowActions/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowAction>;
      })
    );
  }
  /**
   * @param params The `WorkflowActionsService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `workFlowAction`:
   *
   * @return Success
   */
  Put(params: WorkflowActionsService.PutParams): __Observable<WorkflowAction> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as WorkflowAction)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<WorkflowAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/WorkflowActions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowAction>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<WorkflowAction> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as WorkflowAction)
    );
  }

  /**
   * @param params The `WorkflowActionsService.AssignTaskParams` containing the following parameters:
   *
   * - `workFlowAction`:
   *
   * - `LastName`:
   *
   * - `Id`:
   *
   * - `FirstName`:
   *
   * - `Email`:
   *
   * - `CanReview`:
   *
   * - `CanApprove`:
   *
   * - `CanAccept`:
   *
   * @return Success
   */
  assignTaskResponse(params: WorkflowActionsService.AssignTaskParams): __Observable<__StrictHttpResponse<WorkflowAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.workFlowAction;
    if (params.LastName != null) __params = __params.set('LastName', params.LastName.toString());
    if (params.Id != null) __params = __params.set('Id', params.Id.toString());
    if (params.FirstName != null) __params = __params.set('FirstName', params.FirstName.toString());
    if (params.Email != null) __params = __params.set('Email', params.Email.toString());
    if (params.CanReview != null) __params = __params.set('CanReview', params.CanReview.toString());
    if (params.CanApprove != null) __params = __params.set('CanApprove', params.CanApprove.toString());
    if (params.CanAccept != null) __params = __params.set('CanAccept', params.CanAccept.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowActions/assignTask`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowAction>;
      })
    );
  }
  /**
   * @param params The `WorkflowActionsService.AssignTaskParams` containing the following parameters:
   *
   * - `workFlowAction`:
   *
   * - `LastName`:
   *
   * - `Id`:
   *
   * - `FirstName`:
   *
   * - `Email`:
   *
   * - `CanReview`:
   *
   * - `CanApprove`:
   *
   * - `CanAccept`:
   *
   * @return Success
   */
  assignTask(params: WorkflowActionsService.AssignTaskParams): __Observable<WorkflowAction> {
    return this.assignTaskResponse(params).pipe(
      __map(_r => _r.body as WorkflowAction)
    );
  }

  /**
   * @param task task to remove
   * @return Success
   */
   RemoveTaskResponse(task: WorkflowAction): __Observable<__StrictHttpResponse<WorkflowAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = task;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowActions/RemoveTask`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowAction>;
      })
    );
  }
  /**
   * @param task task to remove
   * @return Success
   */
  RemoveTask(task: WorkflowAction): __Observable<WorkflowAction> {
    return this.RemoveTaskResponse(task).pipe(
      __map(_r => _r.body as WorkflowAction)
    );
  }

  /**
   * @param task task to restore
   * @return Success
   */
   RestoreTaskResponse(task: WorkflowAction): __Observable<__StrictHttpResponse<WorkflowAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = task;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowActions/RestoreTask`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowAction>;
      })
    );
  }
  /**
   * @param task task to restore
   * @return Success
   */
  RestoreTask(task: WorkflowAction): __Observable<WorkflowAction> {
    return this.RestoreTaskResponse(task).pipe(
      __map(_r => _r.body as WorkflowAction)
    );
  }


}

module WorkflowActionsService {

  /**
   * Parameters for Get
   */
  export interface GetParams {
    UserEmail?: string;
    Status?: string;
  }

  /**
   * Parameters for GetTaskInbox
   */
  export interface GetTaskInboxParams {
    UserEmail?: string;
    Status?: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    workFlowAction?: WorkflowAction;
  }

  /**
   * Parameters for assignTask
   */
  export interface AssignTaskParams {
    workFlowAction?: WorkflowAction;
    LastName?: string;
    Id?: string;
    FirstName?: string;
    Email?: string;
    CanReview?: boolean;
    CanApprove?: boolean;
    CanAccept?: boolean;
  }
}

export { WorkflowActionsService }
