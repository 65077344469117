import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [ABD] => Authority Holder Broadband Level
 */
@Injectable({
  providedIn: 'root',
})
export class AHBroadbandLevelInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[ABD]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {
 
      if(!config.authorityHolderEmployeeInfo) {
        return "N/A"
      }

      let AHInfo = config.authorityHolderEmployeeInfo; 
      if(AHInfo.broadbandLevel && AHInfo.broadbandLevel.trim() != "") 
        return AHInfo.broadbandLevel;
      else if (AHInfo.broadbandLevel == null || AHInfo.broadbandLevel.trim() == "")
        return "N/A";
      
      return this.infillCode;
    }
  }