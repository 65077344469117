import { Component, OnInit, Inject, Input } from '@angular/core';
import { LibrarySubSection } from '@app/api/models/library-sub-section';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LibrarySubSectionService } from '@app/api/services/library-sub-section.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationDataView } from '@app/api/models';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { OrganizationDatasService, DivisionService, FunctionService } from '@app/api/services';
import { environment } from '@env/environment';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';

@Component({
  selector: 'app-add-library-sub-section-modal-dialog',
  templateUrl: './add-library-sub-section-modal-dialog.component.html',
  styleUrls: ['./add-library-sub-section-modal-dialog.component.scss']
})
export class AddLibrarySubSectionModalDialogComponent implements OnInit {
  @Input() section:any;
  filter:LibrarySubSectionFilter = new LibrarySubSectionFilter();
  types:any = [];
  dataSource:LibrarySubSection[]=[];
  displayedColumns: string[] = ['select','name', 'division', 'function'];
  selectedLibrarySubSections:LibrarySubSection[] = [];
  divisionsForSearch: Array<OrganizationDataView>;
  functionsForSearch: Array<OrganizationDataView>;
  subSections:any[];
  dalContentConfiguration: DalContentConfiguration;
  showButtons:boolean = false;
  searchClicked:boolean = false;
  matTooltipDisabled: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<AddLibrarySubSectionModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private librarySubSectionService:LibrarySubSectionService,
    private _spinnerService: Ng4LoadingSpinnerService,
    private _organizationDataService : OrganizationDatasService,
    private divisionService: DivisionService,
    private functionService: FunctionService) {
      this._organizationDataService.rootUrl = environment.ApiUrl;
      this.librarySubSectionService.rootUrl = environment.ApiUrl;
      this.divisionService.rootUrl = environment.ApiUrl;
      this.functionService.rootUrl = environment.ApiUrl;
      if(data){
        if(data.section)
          this.section = data.section;
        if(data.subSections)
          this.subSections = data.subSections;
          

        if (data.dalContentConfiguration) {
          this.dalContentConfiguration = data.dalContentConfiguration;
        }
      }
      
    }

  close(): void {
    this.dialogRef.close();
  }

  getOpenedMenu( isOpen :boolean){
    console.log('getOpenedMenu: '+isOpen)
    this.close()
  }

  getTitle(title: string): string {
    const newStr : string = title;

    if(newStr){
    if(newStr.length <= 60)
      {
        return newStr;
      }else{
        this.matTooltipDisabled = false;
        return newStr.substring(0, 59) + "...";
      }
    }
    //console.log(this.fullName);
  }


  ngOnInit() {
    this.matTooltipDisabled = true;
    //this.fullName = this.section.title;
    this.loadDivisionAndFunctions();
  }

  loadDivisionAndFunctions() {

    // First searchs in the dalContentConfiguration

    if (this.dalContentConfiguration != null &&
      this.dalContentConfiguration.functions != null &&
      this.dalContentConfiguration.functions.length > 0
    ) {
      this.functionsForSearch = this.dalContentConfiguration.functions;
      if(this.functionsForSearch.length == 1)
        this.filter.functionSubSection = this.functionsForSearch[0];
    } else {
      this._spinnerService.show();
      this.functionService.GetActiveFunctions()
      .subscribe(response => {
        this.functionsForSearch = response;
        this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

    if (this.dalContentConfiguration != null &&
      this.dalContentConfiguration.divisions != null &&
      this.dalContentConfiguration.divisions.length > 0
    ) {
        this.divisionsForSearch = this.dalContentConfiguration.divisions;
        if(this.divisionsForSearch.length == 1)
          this.filter.division = this.divisionsForSearch[0];
    } else {
      this._spinnerService.show();
      this.divisionService.GetActiveDivisions()
      .subscribe(response => {
        this.divisionsForSearch = response;
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
    }
  }

  search() {
    this._spinnerService.show();

    let onlyInThisDivisions : string[] = null;
    let onlyInThisFunctions : string[] = null;

    // Only needs to search in this division and functions
    if (this.dalContentConfiguration != null) {
      if (this.dalContentConfiguration.divisions != null) {
        onlyInThisDivisions = this.dalContentConfiguration.divisions.map(x => x.orgGroupName);
      }

      if (this.dalContentConfiguration.functions != null) {
        onlyInThisFunctions = this.dalContentConfiguration.functions.map(x => x.orgGroupName);
      }
    }

    this.librarySubSectionService.Find({
      DivisionName: this.filter.division != null ? this.filter.division.orgGroupName : "",
      FunctionName: this.filter.functionSubSection  != null ? this.filter.functionSubSection.orgGroupName : "",
        Title: this.filter.name.replace(/  +/g, ' '), // replace to remove double space,
        ParentSectionId: this.section.id,
        OnlyInTheseDivisions: onlyInThisDivisions,
        OnlyInTheseFunctions: onlyInThisFunctions,
        IsAvailable: true
    })
    .subscribe(response =>
      {
        this._spinnerService.hide();
        this.searchClicked = true;
        if(response.errors.length == 0)
        {
          if(this.subSections){
            var results = response.results.filter(x => 
              !this.subSections.find(y => y.subSectionBase.id == x.id));
            this.dataSource = results;
          }else
            this.dataSource = response.results;

          if(this.dataSource.length == 0)
            this.showButtons = false;
          else
            this.showButtons = true;
        }
        else
          response.errors.forEach(x => console.log(x.message));
      },
      (err: HttpErrorResponse) => {
        this.searchClicked = false;
        this._spinnerService.hide();
        console.log(err.message);
      }
    );
  }

  checkSelected(value:any, subSection:any) {
    if(value.checked){
      this.selectedLibrarySubSections.push(subSection);
    }else{
      this.selectedLibrarySubSections = this.selectedLibrarySubSections.filter( x => x.id != subSection.id);
    }
  }
  getDivisionDescription(organizationDatas: Array<OrganizationDataView>) {
    if(!organizationDatas) {
      return null;
    } 
    if(organizationDatas.length == 0){
      return null;
    }
    var division = organizationDatas.find(data => data.orgGroupType === "DIVISIONS");

    if(!division){
      return null;
    }

    return division.orgGroupName;
  }

  getFunctionDescription(organizationDatas: Array<OrganizationDataView>){
    if(!organizationDatas){
      return null;
    } 
    if(organizationDatas.length == 0){
      return null;
    }
    var _function = organizationDatas.find(data => data.orgGroupType === "FUNCTION");

    if(!_function){
      return null;
    }

    return _function.orgGroupName;
  }

  add(): void {
    this.dialogRef.close(this.selectedLibrarySubSections);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

export class LibrarySubSectionFilter{
  name:string="";
  section:string="";
  division:any;
  functionSubSection:any;
}