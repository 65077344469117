import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterData, OrganizationData, OrganizationDataView, User } from '@app/api/models';
import { DivisionService, FunctionService, OrganizationDatasService, UsersService } from '@app/api/services';
import { environment } from '@env/environment';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { isUndefined } from 'util';
import {ReportsService} from '@app/api/services'
import { GlobalService } from '@app/shared/services/global/global.service';
import { findReadVarNames } from '@angular/compiler/src/output/output_ast';
import { HttpErrorResponse } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MatOption, MatSelect, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ReportHierarchyFilters } from '@app/api/models/report-hierarchy-filters';


@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnInit {

  filter_divisions = [];
  filter_functions = [];
  division_selected = null;
  function_selected = null;
  isSuperUserorAuditor = false;
  selectedDelegatorMail:string;
  selectedDelegatorName:string;
  private _divisionSubscribe$: any;
  private _functionSubscribe$: any;
  private searchCriteria : HierarchySeachCriteria={ function: [], division:[],userSelectedEmail:"", userLoggedEmail:""};
  filter_delegator ="";
  private _initialTableDataSubscribe$: Subscription;
  searchResult_DS: MatTableDataSource<any>;
  displayedColumns: Array<string>;
  resultLength:number = 0;
  FilterMyHerierachy = true;
  invalidDelegator=false;
  selectedUserData: User;
  onlyMyHierachy :boolean= true;
  showPeopleSearch:boolean = false;
  subModule:string;
  changeManagedUser$ : Subscription;
  allDivisionSelected:boolean = false;
  allFunctionsSelected:boolean = false;
  @ViewChild('selectDivisions') selectDivisions: MatSelect;
  @ViewChild('selectFunctions') selectFunctions: MatSelect;
 

  constructor(
    private _organizationDataService: OrganizationDatasService,
    private _hierachyService: ReportsService,
    private _globalService: GlobalService,
    private _usersService: UsersService,
    private _spinnerService: Ng4LoadingSpinnerService,
    private divisionService: DivisionService,
    private functionService: FunctionService,
    private _snackBar: MatSnackBar,
    private _router: Router,
  ) {  this._organizationDataService.rootUrl = environment.ApiUrl;
       this._hierachyService.rootUrl = environment.ApiUrl;
       this.divisionService.rootUrl = environment.ApiUrl;
       this.functionService.rootUrl = environment.ApiUrl;
  }
 
  ngOnInit() {
      this.getUserManaged();
      this.getMasterData();
  }

  onClickRun(selectedDelegator:string) {
   this._spinnerService.show();
   let orgData :OrganizationDataView[] =[];
   if(this.function_selected && this.allFunctionsSelected == false)
    {
       orgData = orgData.concat(this.function_selected);
    }
 
    if(this.division_selected && this.allDivisionSelected == false)
    { 
      orgData = orgData.concat(this.division_selected);
    }
 

    if( selectedDelegator != null)
      this.searchCriteria.userSelectedEmail =selectedDelegator;
      else
      this.searchCriteria.userSelectedEmail ="";

    this.searchCriteria.userLoggedEmail = this._globalService.getCurrentLoggedUser().email;
   
    let today = new Date();
    let dd = String(today.getDate()).padStart(2,'0');
    let MM = String(today.getMonth()+1).padStart(2,'0');
    let yy= today.getFullYear().toString();
    let HH = today.getHours() + today.getMinutes().toString();
    var fileName = "Hierarchy Report_"+dd+MM+yy+"_"+HH+".pdf";
    let filter : ReportHierarchyFilters  ={ userSelectedMail: this.searchCriteria.userSelectedEmail, 
    organizationDataView : orgData, onlyMyHierarchy:this.onlyMyHierachy};

   this._hierachyService.DalHierachyReport(filter).subscribe(
        file =>{
          if(file != null)
          {
            var blob = new Blob([file],{type:'application/pdf'});
        
            if (window.navigator && window.navigator.msSaveOrOpenBlob) // for IE
            {
              window.navigator.msSaveOrOpenBlob(blob,fileName);
            } else {
              var downloadURL = window.URL.createObjectURL(blob);
              var link = document.createElement('a');
              link.href = downloadURL;
              link.download = fileName;
              link.click();
            };
            }
            else
            {
              // No hierarchy found
              this.showValidationMessage("No hierarchy found to this criteria.")
            }
          
          this._spinnerService.hide();
        },
        (err: HttpErrorResponse)=> {
          this._spinnerService.hide;
          console.log(err.message);
        }
      )
  }
  

  getUserManaged()
  {
    let delegateParent = this._globalService.getSelectedManagedUser();
    this.selectedDelegatorMail = delegateParent.email;
    this.selectedDelegatorName = delegateParent.firstName +" "+ delegateParent.lastName;

    this.changeManagedUser$ = this._globalService.changeManagedUser.subscribe(value => {
          
    let selectedUser:any = this._globalService.getSelectedManagedUser();
    if( this.selectedDelegatorMail != selectedUser.email)
      {
        this.selectedDelegatorMail = selectedUser.email;
        this.selectedDelegatorName = selectedUser.firstName + " "+ selectedUser.lastName;
      }
    });
  }


  getMasterData() {
    this._divisionSubscribe$ = this.divisionService.GetActiveDivisions()
      .subscribe(response => {
        this.filter_divisions = response;
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );

      this._functionSubscribe$ = this.functionService.GetActiveFunctions()
      .subscribe(response => {
        this.filter_functions = response;
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );    
         

  }

  backToHierachy() {
    if (this.subModule != undefined) {
      this.subModule = undefined;
      this.showPeopleSearch = false;
    }
    else {
      this.showPeopleSearch = false;
      this._router.navigate(["/" + sessionStorage.getItem('client') + '/report/hierarchy']);
    }

  }

  showValidationMessage(msg: string) {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  navigateToPeopleSearch(PreviosAA: any) {
    this.showPeopleSearch = !this.showPeopleSearch;
    let previousAA: string[] = [];
    //Breadcrumb header
    if (this.showPeopleSearch) {
      this.subModule = "Select delegator";
    }
    else {
      this.subModule = undefined;
    }
    // the editing user

    if (PreviosAA != undefined && PreviosAA != null) {
      PreviosAA.forEach(element => {
        previousAA.push(element.email);
      });
    }
    previousAA.push(this.selectedDelegatorMail);

  }

  selectedDelegatorUser(user: any) {
    //Hide people seache component.
    this.showPeopleSearch = false;
    //Hide the breadcrumb title
    this.subModule = undefined;
    // load delegator selected
    this.selectedDelegatorMail = user.email;
    this.selectedDelegatorName = user.firstName +" "+ user.lastName;
  }

  OnlyMyDalsSelected(onlyMyUser:boolean)
  {
    this.onlyMyHierachy =onlyMyUser;
  }

  toggleAllSelectionDivisions() {
    this.allDivisionSelected = !this.allDivisionSelected;  // to control select-unselect
    
    if (this.allDivisionSelected) {
      this.selectDivisions.options.forEach( (item : MatOption) => item.select());
    } else {
      this.selectDivisions.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  toggleSelectionDivisions(event) {
    if(event.source && !event.source.selected){
     this.selectDivisions.options.first.deselect();
     this.allDivisionSelected = false;
    }
   }

  toggleAllSelectionFunctions() {
    this.allFunctionsSelected = !this.allFunctionsSelected;  // to control select-unselect
    
    if (this.allFunctionsSelected) {
      this.selectFunctions.options.forEach( (item : MatOption) => item.select());
    } else {
      this.selectFunctions.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  toggleSelectionFunctions(event) {
    if(event.source && !event.source.selected){
     this.selectFunctions.options.first.deselect();
     this.allFunctionsSelected = false;
    }
   }
}

export interface HierarchySeachCriteria{
  userSelectedEmail:string;
  userLoggedEmail :string;
  function:OrganizationDataView[];
  division: OrganizationDataView[];
}

