import { ProcessInfillVariable } from "./process-infill-variable";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";
import { DalContentConfiguration } from "../shared.module";

@Injectable({
    providedIn: 'root',
  })
export class ProcessGeneralInfillVariable extends ProcessInfillVariable{
    /**
     *
     */
    constructor() {
        super();
      }
    applies(itemVariable: ItemVariable): boolean {
        if (itemVariable) {
          return itemVariable.variableType == "General Table";
        }
        return false;
    }

/**
* Gets a text information.
* @param variable Item Variable
* @param config 
*/
  public getTextInformation(variable: ItemVariable, config: DalContentConfiguration): string {
    if (variable) {
      if (variable.currentValue) {
        switch (variable.variableCode) {
          case "COU":
            if(!config.isReaderMode)
            {
            return variable.currentValue.valueString +" - "+ variable.currentValue.description;
            }
            else
            {
              return variable.currentValue.description;
            }
            break;
          case "CUR":
            if(!config.isReaderMode)
            {
            return variable.currentValue.valueString +" - "+ variable.currentValue.description;
            }
            else
            {
              return variable.currentValue.description;
            }
            break;
          case "RER":
            return variable.currentValue.valueString;
            break;
          case "RVR":
            return variable.currentValue.valueString;
            break;
          case "SUR":
            return variable.currentValue.valueString;
            break;
          default:
            return ""
            break;
        }
      }
      if (variable.defaultValue) {
        switch (variable.variableCode) {
          case "COU":
            if(!config.isReaderMode)
            {
            return variable.defaultValue.valueString +" - "+ variable.defaultValue.description;
            }
            else
            {
              return variable.defaultValue.description;
            }
            break;
          case "CUR":
            if(!config.isReaderMode)
            {
            return variable.defaultValue.valueString +" - "+ variable.defaultValue.description;
            }
            else
            {
              return variable.defaultValue.description;
            }
            break;
          case "RER":
            return variable.defaultValue.valueString;
            break;
          case "RVR":
            return variable.defaultValue.valueString;
            break;
          case "SUR":
            return variable.defaultValue.valueString;
            break;
          default:
            return ""
            break;
        }
      }

      if(!config.isReaderMode)
      {
      switch (variable.variableCode) {
        case "COU":
          return "Select Country";
          break;
        case "CUR":
          return "Select Currency";
          break;
        case "RER":
          return "Select Reinstate Reason";
          break;
        case "RVR":
          return "Select Revoke Reason";
          break;
        case "SUR":
          return "Select Suspend Reason";
          break;
        default:
          return ""
          break;
      }
    }
    else
    {
      return "";
    }
    }
  }

}