import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { BaseDatasService } from '@app/api/services';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '@app/shared/services/global/global.service';
import { RequestClauseComponent } from '../request-clause/request-clause.component';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
@Component({
  selector: 'app-search-clause',
  templateUrl: './search-clause.component.html',
  styleUrls: ['./search-clause.component.scss']
})
export class SearchClauseComponent implements OnInit, OnDestroy {
  newClause: string;
  searchKeyword = '';
  searchKeywordDescription = '';
  searchInputLimit = 50;
  resultLength = -1;
  selectedType = '';
  searchTerm$ = new Subject<string>();
  searchResult_DS: MatTableDataSource<any>;
  showSearchResults = false;
  displayedColumns: string[] = ['select', 'description'];
  pageSize = 5;
  selectedDescription_arr = [];
  selectedCOB = '';
  selectedMOA = '';
  private tableDD_DS;
  selectedENTITY = '';
  isValuesValidated: boolean;
  cob_DS = [];
  moa_DS = [];
  entity_DS = [];
  private _searchClause$: any;
  // private _paginator: MatPaginator;
  // @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //   this._paginator = mp;
  //   this.activatePaginator();
  // }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('tableContainer') tableContainerElement: ElementRef;
  @ViewChild('searchInput') searchInputElement: ElementRef;
  @ViewChild('searchInputDescription') searchInputDescriptionElement: ElementRef;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() NewClauseLevel: EventEmitter<any> = new EventEmitter();

  constructor(
    private _baseDataService: BaseDatasService,
    private _globalService: GlobalService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this._baseDataService.rootUrl = environment.ApiUrl;

    //this.searchTerm$.pipe(debounceTime(1000),
    //  distinctUntilChanged(),
    //  switchMap(term => this.doSearch()))
    //  .subscribe(data => {
    //    // when result found, show table. Else hide it.
    //    if (data && data.items && data.items.length > 0) {
    //      this.searchResult_DS = new MatTableDataSource(data.items);
    //      this.showSearchResults = true;
    //      this.selectedDescription_arr = [];
    //      this.activatePaginator();
    //    } else {
    //      this.searchResult_DS = null;
    //      this.showSearchResults = false;
    //      this.resultLength = 0;
    //    }
    //    this.selectedDescription_arr = [];
    //  },
    //    (err: HttpErrorResponse) => {
    //      console.log(err.message);
    //    }
    //  );
  }

  ngOnInit() {
    this.getDataSource();
  }

  // unsubscribe observables on component destroy
  ngOnDestroy(): void {
    if (this.searchTerm$) {
      this.searchTerm$.unsubscribe();
    }

    if (this._searchClause$) {
      this._searchClause$.unsubscribe();
    }
  }

  getDataSource(): void {
    this.tableDD_DS = JSON.parse(sessionStorage.getItem('dataset'));
    if (this.tableDD_DS.items !== null) {
      this.cob_DS = this.tableDD_DS.items.filter(data => data.listType === 'Class Of Business');
      this.moa_DS = this.tableDD_DS.items.filter(data => data.listType === 'Method Of Acceptance');
      this.entity_DS = this.tableDD_DS.items.filter(data => data.listType === 'Entity');
    }
  }

  getList() {
    this.getDataSource();
  }

  selectedOption(event, data, selectedOption) {
    if (event.currentTarget.id === 'cob') {
      this.selectedCOB = selectedOption;
    } else if (event.currentTarget.id === 'moa') {
      this.selectedMOA = selectedOption;
    } else {
      this.selectedENTITY = selectedOption;
    }

  }

  validateForSubmitBtn() {
    if (this.searchKeyword !== '' || this.searchKeywordDescription !== '' || (this.selectedCOB !== '' && this.selectedMOA !== '' && this.selectedENTITY !== '')) {
      this.isValuesValidated = true;
    }
    this.isValuesValidated = false;
  }

  submitSearch() {
    if (this.selectedMOA.length > 0 || this.selectedCOB.length > 0 || this.selectedENTITY.length > 0) {
      if (this.selectedMOA.length > 0 && this.selectedCOB.length > 0 && this.selectedENTITY.length > 0) {
        this.callSearch();
      } else {
        this.showValidationMessage('Please select all the Drop Down values for search.')
      }

    } else {
      this.callSearch();
    }
  }

  // snack bar to show message
  showValidationMessage(msg: string) {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }


  callSearch() {
    this._searchClause$ = this._baseDataService.Get({
      SubType: this.selectedType,
      Description: this.searchKeywordDescription,
      Functions: this._globalService.organizationData_functions_arr.toString(),
      Divisions: this._globalService.organizationData_divisions,
      MethodOfAcceptance: this.selectedMOA,
      ClassOfBusiness: this.selectedCOB,
      Entity: this.selectedENTITY,
      WordingSearch: true,
      Title: this.searchKeyword
    }).subscribe(data => {
      // when result found, show table. Else hide it.
      if (data && data.items && data.items.length > 0) {
        this.searchResult_DS = new MatTableDataSource(data.items);
        this.showSearchResults = true;
        this.selectedDescription_arr = [];
        this.activatePaginator();
      } else {
        this.showValidationMessage('No results to return')
        this.selectedCOB = '';
        this.selectedMOA = '';
        this.selectedENTITY = '';
        this.searchResult_DS = null;
        this.showSearchResults = false;
        this.resultLength = 0;
      }
      this.selectedDescription_arr = [];
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  //// API call to retrieve search results
  //doSearch(): Observable<any> {
  //  return this._baseDataService.Get({
  //    SubType: 'Clause,Block,Restriction',
  //    Description: this.searchKeyword,
  //    Functions: this._globalService.organizationData_functions_arr.toString(),
  //    Divisions: this._globalService.organizationData_divisions
  //  });
  //}

  // Activate table paginator
  activatePaginator(): void {
    setTimeout(() => {
      if (this.searchResult_DS) {
        this.resultLength = this.searchResult_DS.data.length;
        if (this.resultLength > this.pageSize) {
          this.searchResult_DS.paginator = this.paginator;
          this.paginator._intl.previousPageLabel = '';
          this.paginator._intl.nextPageLabel = '';
        }
      }
    }, 100);
  }

  // handler when a row selected/deselected
  selectRow($event, row): void {
    if ($event.checked) {
      // reset the following properties for the selected row object as API need
      row['type'] = 'DalContent';
      row['partitionKey'] = '';
      // added placeHolderValues to the selected clause so that it will be added in the Edit DAL Flow. 
      row['placeHolderValues'] = null;
      delete row['_etag'];
      this.selectedDescription_arr.push(row);

    } else {
      if (this.selectedDescription_arr.length > 1) {
        for (let i = 0; i < this.selectedDescription_arr.length; i++) {
          if (this.selectedDescription_arr[i].id === row.id) {
            this.selectedDescription_arr.splice(i, 1);
          }
        }
      } else {
        this.selectedDescription_arr.splice(0);
      }
    }
  }

  // fn to confirm search selection. This emits 'selectionChange' event and that handled in editcomponent
  confirmClauseSelection(): void {

    if (this.selectedDescription_arr.length > 0) {
      this.selectionChange.emit(this.selectedDescription_arr);
      // clear search to avoid duplication
      this.clearSearch();
    }
  }

  // fn to clear search field
  clearSearch(): void {
    this.searchKeyword = '';
    this.searchResult_DS = null;
    this.showSearchResults = false;
    this.resultLength = -1;
    this.selectedDescription_arr = [];
  }

  // fn to clear description search field
  clearDescriptionSearch(): void {
    this.searchKeywordDescription = '';
    this.searchResult_DS = null;
    this.showSearchResults = false;
    this.resultLength = -1;
    this.selectedDescription_arr = [];
  }

  // fn to close overlay - this emits 'close' event and that will be handled in editComponent
  onClose(): void {
    this.selectedDescription_arr = [];
    this.close.emit(true);
  }

  /* fn to enable/disable check box by checking if content's id matches with existing
      dal content objects. This prevents user from making selection to add.
  */
  checkForContentExist(rowElement): boolean {
    if (this._globalService.editDalViewObj) {
      for (let i = 0; i < this._globalService.editDalViewObj['contents'].length; i++) {
        if (this._globalService.editDalViewObj['contents'][i]['baseDataid'] === rowElement['id']) {
          return true;
        }
      }
    }
    return false;
  }

  onTypeSelection(event) {
    this.selectedType = event.value;
  }
  requestClause(): void {
    const dialogRef = this.dialog.open(RequestClauseComponent, { disableClose: true });
    const subscribeDialog = dialogRef.componentInstance.submitNewClause.subscribe(data => {
      this.selectedDescription_arr.push(data);
      this.selectionChange.emit(this.selectedDescription_arr);
    });

    const subscribeDialog1 = dialogRef.componentInstance.NewClauseLevel.subscribe(data => {
      this.NewClauseLevel.emit(data);
    });

    dialogRef.afterClosed().subscribe(result => {
      this.newClause = result;
      subscribeDialog.unsubscribe();
      subscribeDialog1.unsubscribe();
    });
  }
}
