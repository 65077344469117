import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-page-validate',
  templateUrl: './login-page-validate.component.html',
  styleUrls: ['./login-page-validate.component.scss']
})
export class LoginPageValidateComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    // this.router.config.forEach(x => {
    //   if(x.path.replace(":client", sessionStorage.getItem('client')) == sessionStorage.getItem('client') +"/"+ this.route.snapshot.url[0].path)
    //   {
    //     this.router.navigate([x.path.replace(":client", sessionStorage.getItem('client'))]);
    //   }
    // });
    const tenantName = sessionStorage.getItem('client');

    this.router.navigate([`/${tenantName}/admin`]);
  }
}
