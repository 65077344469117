import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DST] => Dal State
 */
@Injectable({
  providedIn: 'root',
})
export class DalStateInformationalVariable extends ProcessInformationalVariable {
  /**
   *
   */
  constructor() {
    super();
    this.infillCode = "[DST]";
  }

  public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string{

    if(config.state && config.state.trim() != "") 
      return config.state;
    else if (config.state == null || config.state.trim() == "")
      return "N/A";

    return this.infillCode;
  }

}