import { ProcessInfillVariable } from "./process-infill-variable";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";
import { DalContentConfiguration } from "../shared.module";
import { formatNumber,formatPercent } from "@angular/common";
import { BigNumber } from "bignumber.js";

@Injectable({
  providedIn: 'root',
})
export class ProcessBasicInfillVariable extends ProcessInfillVariable {
  constructor() {
    super();
  }
  applies(itemVariable: ItemVariable): boolean {
    if (itemVariable) {
      return itemVariable.variableType == "Basic";
    }
    return false;
  }
  /**
  * Gets a text information.
  * @param variable Item Variable
  * @param config 
  */
  public getTextInformation(variable: ItemVariable, config: DalContentConfiguration): string {

    // let baseCurrencyCode = "(Base Currency)";
    // if(config && config.baseCurrencyCode) {
    //   baseCurrencyCode = config.baseCurrencyCode;
    // }

    if (variable) {
      if (variable.currentValue) {
        switch (variable.variableCode) {
          case "T":
            return variable.currentValue.valueString;
            break;
          case "#":
            let _amountString = '';
            if (variable.currentValue && variable.currentValue.valueNumber && 
                variable.currentValue.valueNumber != 0 )
                _amountString = formatNumber(variable.currentValue.valueNumber,this.getCurrentLocale("en-US"));
            else{
              var regExp = new RegExp(this.getGroupSeparator(), "g");
              let valueNum = new BigNumber(variable.currentValue.valueString.replace(regExp, ''));
              if (valueNum.decimalPlaces() > 0) {
                _amountString = valueNum.toFormat(2);
              }
              else {
                _amountString = valueNum.toFormat();
              }
            }
          return _amountString;
            break;
          case "A":
              let amountString = '';
              if (variable.currentValue && !isNaN(variable.currentValue.valueNumber) 
              && variable.currentValue.valueNumber != null)
                amountString = formatNumber(variable.currentValue.valueNumber,this.getCurrentLocale("en-US"));
              else{
                var regExp = new RegExp(this.getGroupSeparator(), "g");
                let valueNum = new BigNumber(variable.currentValue.valueString.replace(regExp, ''));
                if (valueNum.decimalPlaces() > 0) {
                  amountString = valueNum.toFormat(2);
                }
                else {
                  amountString = valueNum.toFormat();
                }
              }
            return (variable.currentValue.currencyCode ? variable.currentValue.currencyCode + " ": "") + amountString;
            break;
          case "%":
           return formatNumber(variable.currentValue.valueNumber ? variable.currentValue.valueNumber : 0, this.getCurrentLocale("en-US") )+ "%";
            break;
          default:
            return ""
            break;
        }
      }

      if (variable.defaultValue) {
        switch (variable.variableCode) {
          case "T":
            return variable.defaultValue.valueString;
            break;
          case "#":
            return (variable.defaultValue.valueNumber ? formatNumber(variable.defaultValue.valueNumber,this.getCurrentLocale("en-US")) : 0).toString();
            break;
          case "A":
              let amountString = '';
              if (variable.defaultValue && !isNaN(variable.defaultValue.valueNumber)
               && variable.defaultValue.valueNumber != null )
                amountString = formatNumber(variable.defaultValue.valueNumber,this.getCurrentLocale("en-US"));
              else {
                var regExp = new RegExp(this.getGroupSeparator(), "g");
                let valueNum = new BigNumber(variable.defaultValue.valueString.replace(regExp, ''));
                if (valueNum.decimalPlaces() > 0) {
                  amountString = valueNum.toFormat(2);
                }
                else {
                  amountString = valueNum.toFormat();
                }
              }               

            return (variable.defaultValue.currencyCode ? variable.defaultValue.currencyCode + " ": "") + amountString;
            break;
          case "%":
             return formatNumber(variable.defaultValue.valueNumber ? variable.defaultValue.valueNumber : 0,this.getCurrentLocale("en-US")) + "%";
             break;
          default:
            return ""
            break;
        }
      }

      if(!config.isReaderMode)
      {
      switch (variable.variableCode) {
        case "T":
          return "Insert text here"
          break;
        case "#":
          return "Insert number here (0)"
          break;
        case "A":
          return "Insert amount here";
          break;
        case "%":
          return "Insert number here (0.00%)";
          break;         
        default:
          return ""
          break;
      }
    }
    else
    {
      return "";
    }
    }
  }

  getGroupSeparator(){
    let currentLocale = this.getCurrentLocale('en-US');
    return this.getSeparator(currentLocale, 'group', ',');
  }

  getSeparator(locale, separatorType, defaultSep) {
    const numberWithGroupAndDecimalSeparator = 1000.1;
    let numIntl = new Intl.NumberFormat(locale);
    if (numIntl['formatToParts'] && numIntl['formatToParts'][separatorType]) {
      let info = numIntl['formatToParts'](numberWithGroupAndDecimalSeparator);
      return info.find(part => part.type === separatorType).value;
      }
    else
      return defaultSep;
  }

  getCurrentLocale(defaultLocale) {
    var isIE = (navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document['documentMode'] == true );
   // Be careful with the internationalization params, eg. es-US is not supported but users can configure the browser.
    if (isIE)
    {
       return navigator['browserLanguage'];
    }
     
    if (navigator.language)
    {
       return navigator.language;
    }
     
    return defaultLocale;
  }
}