/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReportFilterData } from '../models/report-filter-data';
import { ReportResponse } from '../models/report-response';
import { ReportHierarchyFilters } from '../models/report-hierarchy-filters';
@Injectable({
  providedIn: 'root',
})
class ReportsService extends __BaseService {
  static readonly GetFilterDataPath = '/api/Reports/GetFilterData';
  static readonly DownloadPath = '/api/Reports/Download';
  static readonly GetReportDataPath = '/api/Reports/GetReportData';
  static readonly DalHierachyReportPath = '/api/Reports/DalHierachyReport';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetFilterDataResponse(): __Observable<__StrictHttpResponse<ReportFilterData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Reports/GetFilterData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportFilterData>;
      })
    );
  }
  /**
   * @return Success
   */
  GetFilterData(): __Observable<ReportFilterData> {
    return this.GetFilterDataResponse().pipe(
      __map(_r => _r.body as ReportFilterData)
    );
  }

  /**
   * @param params The `ReportsService.DownloadParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `ReportType`:
   *
   * - `PurchaseFacRI`:
   *
   * - `NegotiateFacRI`:
   *
   * - `Location`:
   *
   * - `Function`:
   *
   * - `EffectiveDateTo`:
   *
   * - `EffectiveDateFrom`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `BinderTypePriorSubmit`:
   *
   * - `BinderTypeMatrix`:
   *
   * - `BinderTypeLineSlip`:
   *
   * - `BinderTypeLimited`:
   *
   * - `BinderTypeFull`:
   *
   * - `AuthorityId`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  DownloadResponse(params: ReportsService.DownloadParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.ReportType != null) __params = __params.set('ReportType', params.ReportType.toString());
    if (params.PurchaseFacRI != null) __params = __params.set('PurchaseFacRI', params.PurchaseFacRI.toString());
    if (params.NegotiateFacRI != null) __params = __params.set('NegotiateFacRI', params.NegotiateFacRI.toString());
    if (params.Location != null) __params = __params.set('Location', params.Location.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
    if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.BinderTypePriorSubmit != null) __params = __params.set('BinderTypePriorSubmit', params.BinderTypePriorSubmit.toString());
    if (params.BinderTypeMatrix != null) __params = __params.set('BinderTypeMatrix', params.BinderTypeMatrix.toString());
    if (params.BinderTypeLineSlip != null) __params = __params.set('BinderTypeLineSlip', params.BinderTypeLineSlip.toString());
    if (params.BinderTypeLimited != null) __params = __params.set('BinderTypeLimited', params.BinderTypeLimited.toString());
    if (params.BinderTypeFull != null) __params = __params.set('BinderTypeFull', params.BinderTypeFull.toString());
    if (params.AuthorityId != null) __params = __params.set('AuthorityId', params.AuthorityId.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Reports/Download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ReportsService.DownloadParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `ReportType`:
   *
   * - `PurchaseFacRI`:
   *
   * - `NegotiateFacRI`:
   *
   * - `Location`:
   *
   * - `Function`:
   *
   * - `EffectiveDateTo`:
   *
   * - `EffectiveDateFrom`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `BinderTypePriorSubmit`:
   *
   * - `BinderTypeMatrix`:
   *
   * - `BinderTypeLineSlip`:
   *
   * - `BinderTypeLimited`:
   *
   * - `BinderTypeFull`:
   *
   * - `AuthorityId`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  Download(params: ReportsService.DownloadParams): __Observable<string> {
    return this.DownloadResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ReportsService.GetReportDataParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `ReportType`:
   *
   * - `PurchaseFacRI`:
   *
   * - `NegotiateFacRI`:
   *
   * - `Location`:
   *
   * - `Function`:
   *
   * - `EffectiveDateTo`:
   *
   * - `EffectiveDateFrom`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `BinderTypePriorSubmit`:
   *
   * - `BinderTypeMatrix`:
   *
   * - `BinderTypeLineSlip`:
   *
   * - `BinderTypeLimited`:
   *
   * - `BinderTypeFull`:
   *
   * - `AuthorityId`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetReportDataResponse(params: ReportsService.GetReportDataParams): __Observable<__StrictHttpResponse<ReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.ReportType != null) __params = __params.set('ReportType', params.ReportType.toString());
    if (params.PurchaseFacRI != null) __params = __params.set('PurchaseFacRI', params.PurchaseFacRI.toString());
    if (params.NegotiateFacRI != null) __params = __params.set('NegotiateFacRI', params.NegotiateFacRI.toString());
    if (params.Location != null) __params = __params.set('Location', params.Location.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
    if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.BinderTypePriorSubmit != null) __params = __params.set('BinderTypePriorSubmit', params.BinderTypePriorSubmit.toString());
    if (params.BinderTypeMatrix != null) __params = __params.set('BinderTypeMatrix', params.BinderTypeMatrix.toString());
    if (params.BinderTypeLineSlip != null) __params = __params.set('BinderTypeLineSlip', params.BinderTypeLineSlip.toString());
    if (params.BinderTypeLimited != null) __params = __params.set('BinderTypeLimited', params.BinderTypeLimited.toString());
    if (params.BinderTypeFull != null) __params = __params.set('BinderTypeFull', params.BinderTypeFull.toString());
    if (params.AuthorityId != null) __params = __params.set('AuthorityId', params.AuthorityId.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Reports/GetReportData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportResponse>;
      })
    );
  }
  /**
   * @param params The `ReportsService.GetReportDataParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `ReportType`:
   *
   * - `PurchaseFacRI`:
   *
   * - `NegotiateFacRI`:
   *
   * - `Location`:
   *
   * - `Function`:
   *
   * - `EffectiveDateTo`:
   *
   * - `EffectiveDateFrom`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `BinderTypePriorSubmit`:
   *
   * - `BinderTypeMatrix`:
   *
   * - `BinderTypeLineSlip`:
   *
   * - `BinderTypeLimited`:
   *
   * - `BinderTypeFull`:
   *
   * - `AuthorityId`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetReportData(params: ReportsService.GetReportDataParams): __Observable<ReportResponse> {
    return this.GetReportDataResponse(params).pipe(
      __map(_r => _r.body as ReportResponse)
    );
  }

  /**
   * @param filter undefined
   */
  DalHierachyReportResponse(filter?: ReportHierarchyFilters): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Reports/DalHierachyReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param filter undefined
   */
  DalHierachyReport(filter?: ReportHierarchyFilters): __Observable<null> {
    return this.DalHierachyReportResponse(filter).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ReportsService {

  /**
   * Parameters for Download
   */
  export interface DownloadParams {
    Status?: string;
    ReportType?: string;
    PurchaseFacRI?: boolean;
    NegotiateFacRI?: boolean;
    Location?: string;
    Function?: string;
    EffectiveDateTo?: string;
    EffectiveDateFrom?: string;
    Division?: string;
    Delegator?: string;
    BinderTypePriorSubmit?: boolean;
    BinderTypeMatrix?: boolean;
    BinderTypeLineSlip?: boolean;
    BinderTypeLimited?: boolean;
    BinderTypeFull?: boolean;
    AuthorityId?: string;
    AuthorityHolder?: string;
  }

  /**
   * Parameters for GetReportData
   */
  export interface GetReportDataParams {
    Status?: string;
    ReportType?: string;
    PurchaseFacRI?: boolean;
    NegotiateFacRI?: boolean;
    Location?: string;
    Function?: string;
    EffectiveDateTo?: string;
    EffectiveDateFrom?: string;
    Division?: string;
    Delegator?: string;
    BinderTypePriorSubmit?: boolean;
    BinderTypeMatrix?: boolean;
    BinderTypeLineSlip?: boolean;
    BinderTypeLimited?: boolean;
    BinderTypeFull?: boolean;
    AuthorityId?: string;
    AuthorityHolder?: string;
  }
}

export { ReportsService }
