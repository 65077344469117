/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Acceptance } from '../models/acceptance';
@Injectable({
  providedIn: 'root',
})
class AcceptanceService extends __BaseService {
  static readonly InsertAcceptancePath = '/api/Acceptance/InsertAcceptance';
  static readonly UpdateAcceptancePath = '/api/Acceptance/UpdateAcceptance';
  static readonly GetAcceptancesPath = '/api/Acceptance/GetAcceptances';
  static readonly DisablePath = '/api/Acceptance/Disable';
  static readonly EnablePath = '/api/Acceptance/Enable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param acceptance undefined
   * @return Success
   */
  InsertAcceptanceResponse(acceptance?: Acceptance): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = acceptance;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Acceptance/InsertAcceptance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param acceptance undefined
   * @return Success
   */
  InsertAcceptance(acceptance?: Acceptance): __Observable<boolean> {
    return this.InsertAcceptanceResponse(acceptance).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param acceptance undefined
   * @return Success
   */
  UpdateAcceptanceResponse(acceptance?: Acceptance): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = acceptance;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Acceptance/UpdateAcceptance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param acceptance undefined
   * @return Success
   */
  UpdateAcceptance(acceptance?: Acceptance): __Observable<boolean> {
    return this.UpdateAcceptanceResponse(acceptance).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetAcceptancesResponse(): __Observable<__StrictHttpResponse<Array<Acceptance>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Acceptance/GetAcceptances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Acceptance>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAcceptances(): __Observable<Array<Acceptance>> {
    return this.GetAcceptancesResponse().pipe(
      __map(_r => _r.body as Array<Acceptance>)
    );
  }

  /**
   * @param acceptanceId undefined
   * @return Success
   */
  DisableResponse(acceptanceId?: string): __Observable<__StrictHttpResponse<Acceptance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (acceptanceId != null) __params = __params.set('acceptanceId', acceptanceId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Acceptance/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Acceptance>;
      })
    );
  }
  /**
   * @param acceptanceId undefined
   * @return Success
   */
  Disable(acceptanceId?: string): __Observable<Acceptance> {
    return this.DisableResponse(acceptanceId).pipe(
      __map(_r => _r.body as Acceptance)
    );
  }

  /**
   * @param acceptanceId undefined
   * @return Success
   */
  EnableResponse(acceptanceId?: string): __Observable<__StrictHttpResponse<Acceptance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (acceptanceId != null) __params = __params.set('acceptanceId', acceptanceId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Acceptance/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Acceptance>;
      })
    );
  }
  /**
   * @param acceptanceId undefined
   * @return Success
   */
  Enable(acceptanceId?: string): __Observable<Acceptance> {
    return this.EnableResponse(acceptanceId).pipe(
      __map(_r => _r.body as Acceptance)
    );
  }
}

module AcceptanceService {
}

export { AcceptanceService }
