import { Component, OnInit, Input, AfterViewInit, OnDestroy, EventEmitter, Output  } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatTableDataSource,MatSnackBar, MatTooltipModule } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { Router } from '@angular/router';
import { GlobalService } from '@app/shared/services/global/global.service';
import { UsersService, AdUsersService } from '@app/api/services';
import { UserService } from '@app/shared/services/user/user.service';
import { User, DalHierarchyView, Dal, UserView } from '@app/api/models';
import { HttpErrorResponse } from '@angular/common/http';
import { DalsService } from '@app/api/services/dals.service';
import { environment } from '@env/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ViewDalService, PageViewDal } from '@app/shared/services/dal/view-dal.service';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('collapsed =>  expanded', animate('1500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TableComponent implements OnInit, OnDestroy {
  @Input() SectionId: any;
  @Input() dataSource: Array<any>;
  @Input() tableTitle: String;
  @Input() tableDesc: String;
  @Input() isAdmin: boolean;
  @Input() isCopy: boolean;
  @Input() displayedColumnsLimit = 0;
  @Input() displayDals: boolean;
  
  name: any;
  tooltTipPostion = ['right'];
  displayedColumns: String[];
  datasourceKeys: String[];
  postSubscription: any;
  delegatorName = '';
  delegatorEmail = '';
  isDataAvailable = true;
  userSelectedDelegate = '';
  delegetedDalList: any = [];
  dalList: any = [];
  dalSelected: any;
  private _submitSubscribe$: any;
  private _defaultDataubscribe$: any;

  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    private _adUsersService: AdUsersService,
    private _userService: UserService,
    private _dalsService: DalsService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _snackBar: MatSnackBar,
    private _viewDalService: ViewDalService,
    private _usersService: UsersService,
  ) {
    this._dalsService.rootUrl = environment.ApiUrl;
  }
  ngOnInit() {
    this.refreshStatus();
    if (this.dataSource) {
        if (this.dataSource instanceof MatTableDataSource) {
         if (this.dataSource.filteredData.length > 0) {
          this.displayedColumns = Object.keys(this.dataSource.filteredData[0]);
        }
      } else {
        if (this.dataSource.length > 0) {
          if (!this.isAdmin) {
          //  this.dataSource = this.dataSource.filter(data => data.isDeleted === false);
          }
          this.dataSource = this.dataSource.map(obj => this.replaceKeys(obj, '', ' '));
          if (this.dataSource.length > 0) {
            this.displayedColumns = Object.keys(this.dataSource[0]);
          }
         }
      }
      // for serach need to show only first 4 properties as columns
      if (this.displayedColumnsLimit > 0) {
        this.displayedColumns = this.displayedColumns.slice(0, this.displayedColumnsLimit);
      }
      // Remove 'id' and 'isDeleted' column headers
      if (this.displayedColumns && this.displayedColumns.length > 0) {
        for (let j = 0; j < this.displayedColumns.length; j++) {
          if (this.displayedColumns[j] === 'id' ||
              this.displayedColumns[j] === 'isDeleted' ||
              this.displayedColumns[j] === ' id' ||
            this.displayedColumns[j] === ' isDeleted' ||
            this.displayedColumns[j] === 'isRowEditable' ||
              this.displayedColumns[j] === ' isRowEditable'
              ) {
            this.displayedColumns.splice(j, 1);
            j--;
          }
        }
      }
      if (this.isCopy || this.isAdmin) {
        if (this.displayedColumns && this.displayedColumns.length > 0) {
          this.displayedColumns.push('actions');
        } else {
          this.isDataAvailable = false;
        }
      }
    } else {
      
    }
  }

  replaceKeys(obj: any, search: string, replace: string): any {
      return Object.keys(obj).reduce(
        (acc, key) => Object.assign(acc, { [key.replace(search, replace)]: obj[key] }), {});
  }

  /* call post method with user object.
     navigate page to copy dal on APL call response
  */
  copyDelegator(element) {
    this.delegatorName = this._globalService.delegatorName;
    const userObj: User = {
      adminUserView: null,
      firstName: element.firstName,
      email: element.email,
     // division: '',
      status: '',
      startDate: '',
      endDate: '',
      organizationDataView: null,
      userRoleView: null,
      lastName: element.lastName,
      id: '',
      _etag: '',
      partitionKey: '',
      type: '',
      clientId: '',
      createdBy: this._userService.msAdal.LoggedInUserName,
      createdDate: '',
      modifiedBy: '',
      modifiedDate: ''
    };
    this.storeSelectedAuthorityHolder(userObj);

    // call method to get delegator object
    this.getSelectedDelegate();

    if (this._globalService.delegatorObj.email !== undefined && element.email !== undefined) {
      if (this._globalService.delegatorObj.email !== element.email) {
        // on respose, navigate page to copy dal
        this.postSubscription = this._adUsersService.Post(userObj).subscribe(data => {
          if (data) {
            this.storeAuthorityHolder(data);
            this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/copy-dal']);
          }
        },
          (err: HttpErrorResponse) => {
            console.log(err.message);
          });
      } else {
        this.showValidationMessage('Authority holder and Delegator cannot be the same');
      }
    }
  }

  copyDefault(element) {
    const userObj: User = {
      adminUserView: null,
      firstName: element.firstName,
      email: element.email,
     // division: '',
      status: '',
      startDate: '',
      endDate: '',
      organizationDataView: null,
      userRoleView: null,
      lastName: element.lastName,
      id: '',
      _etag: '',
      partitionKey: '',
      type: '',
      clientId: '',
      createdBy: this._userService.msAdal.LoggedInUserName,
      createdDate: '',
      modifiedBy: '',
      modifiedDate: ''
    };

    if (this._globalService.delegatorObj.email !== undefined && element.email !== undefined) {
      if (this._globalService.delegatorObj.email !== element.email) {
        // on respose, navigate page to copy dal
        this.postSubscription = this._adUsersService.Post(userObj).subscribe(data => {
          if (data) {
            this.storeAuthorityHolder(data);
            }
        },
          (err: HttpErrorResponse) => {
            console.log(err.message);
          });
      } else {
        this.showValidationMessage('Authority holder and Delegator cannot be the same');
      }
    }

    this.spinnerService.show();

    this._submitSubscribe$ = this._dalsService.CopyDefaultAsync(
      { 
        sourceDalId: this.dalSelected.id, 
        loggedInUserName: this._userService.msAdal.LoggedInUserEmail.toLowerCase(),
        delegator: this._globalService.delegatorObj,
        authorityHolder: element,
      }).subscribe(
      response => {
        if (response.status_code == '200') {
          this.spinnerService.hide();
          this.navigateToViewDal(response.result);
        }
        else
        {
          this.spinnerService.hide();
          this.showValidationMessage(response.message);
        }
      },
      (err: HttpErrorResponse) => {
        this.spinnerService.hide();
        if(err.error.message != undefined && err.error.message != null)
        this.showValidationMessage(err.error.message);
        else if(err.error != undefined && err.error != null)
          this.showValidationMessage(err.error);
      }
    )

   }

  navigateToViewDal(dal: Dal) {
     var viewDalParams: ViewDalService.ViewDalParams = {
      viewerEmail: dal.authorityHolder.email,
      viewerRole: ViewDalService.ViewDalRole.Delegator
    };
    this._viewDalService.navigateToViewDalPage(viewDalParams, PageViewDal.DalDelegatee);
  }

  // This fn stores authority holder object to global service to access globally
  storeAuthorityHolder(data) {
    this._globalService.authorityHolderObj = data;
  }

  // This fn stores selected authority holder object to global service to access globally
  storeSelectedAuthorityHolder(data) {
    this._globalService.selectedAuthorityHolderObj = data;
  }

  navigateToSearch(element) {
    element = this.trimObj(element);
       if (element['Delegators']) {
         this._globalService.delegatorName = element['Delegators'];
    }
    this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/search']);
  }


  navigateToMySearch() {
    this.getLoggedInObject();
    if (this._userService.msAdal.LoggedInUserName) {
      this._globalService.delegatorName = this._userService.msAdal.LoggedInUserName;
      this.delegatorEmail = this._userService.msAdal.LoggedInUserEmail.toLowerCase();
    }
    this._submitSubscribe$ = this._dalsService.Get({ CheckDelegate: true, AuthorityHolderEmail: this._userService.msAdal.LoggedInUserEmail.toLowerCase() }).subscribe(
      response => {
        if (response) {
          this.delegetedDalList = response;
          if (this.delegetedDalList.filter(data => data.delegate === true).length > 0) {
            this.spinnerService.hide();
            this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/search']);
          } else {
            this.showValidationMessage('No delegatable DAL(s) exists for this user');
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.spinnerService.hide();
        this.showValidationMessage(err.error.message);
      }
    );

  }

  linkToMyDelegate() {
    this.getLoggedInObject();
    if (this._userService.msAdal.LoggedInUserName) {
      this._globalService.delegatorObj.email = this._userService.msAdal.LoggedInUserEmail.toLowerCase();
      this._globalService.selectedDelegate = this._userService.msAdal.LoggedInUserName;
      this._globalService.delegatorName = this._userService.msAdal.LoggedInUserName;
      this.delegatorEmail = this._userService.msAdal.LoggedInUserEmail.toLowerCase();
    }
    this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/people']);
  }

  linkToSearchAuthority(): void {
    this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/search-authority']);
  }

  getLoggedInObject() {
    if (this._userService.msAdal.LoggedInUserName) {
      this.delegatorEmail = this._userService.msAdal.LoggedInUserEmail;
      var LoggedInObject = {
        firstname: this.delegatorName.split(' ')[0],
        lastname: this.delegatorName.split(' ')[1],
        email: this._userService.msAdal.LoggedInUserEmail.toLowerCase()
    };
    }
    this._globalService.delegatorObj = LoggedInObject;
  }

  storeDelegatorObj(obj) {
    this._globalService.delegatorObj = obj;
  }


/*  link to selected delgate - starts here  */

linkToDelegate(element) {
  /* console.log('btn clik', this._globalService.selectedDelegate) ; */
  // this._router.navigate(['/admin/search-delegator']);

  element = this.trimObj(element);
  if (element['Delegators']) {
      this._globalService.selectedDelegate = element['Delegators'];
      this._globalService.delegatorName = element['Delegators'];
      this.delegatorName = this._globalService.delegatorName;
  }
  this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin/people']);
}

 onChange(row: {name: string}) {
  row = this.trimObj(row);
  if (row['Delegators']) {
   this._globalService.selectedDelegate = row['Delegators'];
   this.getSelectedDelegate();
  }
  return row;
 }

 getSelectedDelegate() {
  this.userSelectedDelegate = this._globalService.selectedDelegate;
  if (this.userSelectedDelegate) {
    const first_name = this.userSelectedDelegate.split(' ')[0];
    if (this._globalService.delegatorsList !== null || this._globalService.delegatorsList !== undefined ) {
      if (this._globalService.delegatorsList.length > 0) {
        for (const obj of this._globalService.delegatorsList) {
          if (obj.firstName === first_name) {
            this.storeDelegatorObj(obj);
          }
        }
        this.delegatorEmail = this._globalService.delegatorObj.email;
      }
    }
  }
}

/* Link to selected delgate - ends here */


  //this fn is to remove the leading and trailing  spaces in an obj
  trimObj(obj) {
  if (!Array.isArray(obj) && typeof obj != 'object') return obj;
  return Object.keys(obj).reduce(function (acc, key) {
    acc[key.trim()] = typeof obj[key] == 'string' ? obj[key].trim() : (obj[key]);
    return acc;
  }, Array.isArray(obj) ? [] : {});
  }

  // Unsubscribe on destroy
  ngOnDestroy() {
    if (this.postSubscription) {
      this.postSubscription.unsubscribe();
    }
  }

  showValidationMessage(msg: string) {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  deleteRow(ele) {
    let removedItem;
    for (let i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i][' id'] && ele[' id']) {
        if (this.dataSource[i][' id'] === ele[' id']) {
          if (ele[' isDeleted'] !== null && ele[' isDeleted'] !== undefined) {
            ele[' isDeleted'] = true;

            this.dataSource = this.dataSource.concat(this.dataSource.splice(i, 1));
            break;
          }
        }
      }
    }
   // console.log(this.dataSource);
  }

  undoRow(ele) {
    for (let i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i][' id'] && ele[' id']) {
        if (this.dataSource[i][' id'] === ele[' id']) {
          if (ele[' isDeleted'] !== null && ele[' isDeleted'] !== undefined) {
            ele[' isDeleted'] = false;
            break;
          }
        }
      }
    }
    
  }

  // This fn to decide the background color based on status
  getBGColorCode(element): string {
    let compareStatus = element[' CompareStatus'];
    if (compareStatus) {
      
      let colorCode;
      switch (compareStatus.toLowerCase().trim()) {
        case 'new':
          colorCode = 'blue';
          break;

        case 'modified':
          colorCode = 'green';
          break;

        case 'active':
          colorCode = 'green';
          break;

        case 'deleted':
          colorCode = 'red';
          break;

        case 'unchanged':
          colorCode = 'black';
          break;

        default:
          colorCode = 'black';
      }
      return colorCode;
    }
  }

  defaultDal(element){
    let dalSearchCriteria: DalsService.GetParams = {};
    dalSearchCriteria.OnlyMyDals = true;
    dalSearchCriteria.AuthorityHolderEmail = this._globalService.delegatorObj.email;
    dalSearchCriteria.DalStatus = 'ACCEPTED';
    this._dalsService.Get(dalSearchCriteria).subscribe(
      response => {
        if (response) {
          this.dalList = response;
          let delegatorName = this._globalService.delegatorName;
          if(this.dalList == undefined ||  this.dalList == null || this.dalList.length == 0)
            this.showValidationMessage(delegatorName + " doesn't have an Accepted DAL to delegate."
            );
          else if(this.dalList.length > 1)
            this.displayDals = true;
          else if(this.dalList.length == 1){
            this.dalSelected = this.dalList[0];
            this.copyDefault(element);
          }
        }
      },
      (err: HttpErrorResponse) => {
        this.showValidationMessage(err.error);
      }
    );
  }

  refreshStatus()
  {
    this.displayDals = false;
    this.dalSelected = null;
  }

  radioChange(event)
  {
    this.dalSelected = event.value;
  }

  selectDal(element)
  {
    if(this.dalSelected != null)
    {
      this.copyDefault(element);
    }
  }

    // This fn to decide the background color based on status
    getBGColorCodeDalStatus(dalstatus): string {
      if (dalstatus) {
        let colorCode;
        switch (dalstatus.toLowerCase().trim()) {
          case 'new':
            colorCode = 'blue';
            break;
  
          case 'accepted':
            colorCode = 'green';
            break;
  
          case 'active':
            colorCode = 'green';
            break;
  
          case 'queried':
            colorCode = 'red';
            break;
  
          case 'remainder':
            colorCode = 'grey';
            break;
  
          case 'review':
            colorCode = 'blue';
            break;
  
          case 'revoked':
            colorCode = 'red';
            break;
  
          case 'suspended':
            colorCode = 'red';
            break;
  
          case 'approval':
            colorCode = 'green';
            break;
  
          case 'draft':
            colorCode = 'grey';
            break;

          case 'approved':
            colorCode = 'blue';
            break;
  
          // case 'pending approval':
          case 'in review':
            colorCode = 'red';
            break;
  
          case 'deleted':
            colorCode = 'red';
            break;
  
          case 'superceded':
            colorCode = 'red';
            break;
  
          default:
            colorCode = 'black';
        }
        return colorCode;
      }
    }
  hasData(): boolean {
    if(this.dataSource == undefined || this.dataSource == null)
      return false;
    if (this.dataSource instanceof MatTableDataSource) {
        if(this.dataSource.filteredData == undefined || this.dataSource.filteredData == null)
          return false;
        if (this.dataSource.filteredData.length > 0) {
          return true;
      }
    }
    else if( this.dataSource.length > 0){
      return true;
    }
    return false;
  }
 }
