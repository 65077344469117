import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user/user.service';
import { GlobalService } from '@app/shared/services/global/global.service';
import { TenantService } from '@app/api/services/tenant.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AHAccessToken, CurrentTenantService } from '../services/tenant/tenant.service';
import * as moment from 'moment';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    constructor(private adal: MsAdalAngular6Service,
                private _userService: UserService,
                private _globalService: GlobalService,
                private _tenantService: TenantService,
                private _currentTenantService: CurrentTenantService,
                private _router: Router) {
                  this._tenantService.rootUrl = environment.ApiUrl;
                 }

    handleError(error: HttpErrorResponse){
        console.log(error.status);
        /*
        if(error.status == 500){
            this._userService.msAdal.logout();
        }
        */
        return throwError(error);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const getTenantRequest = req.url.endsWith('/api/Tenant/GetTenantName');
      // resource to secure
      const resource = this.adal.GetResourceForEndpoint(req.url);

      // Call to another api
      if (!resource) {
        return this.nonInterceptableRequest(req, next);
      }

      let acquireTokenObservable : Observable<AHAccessToken>;

      if (getTenantRequest) {
        // Avoids circular references
        acquireTokenObservable = this._currentTenantService.getAccessTokenForTenantService(resource);
      }
      else {
        acquireTokenObservable = this._currentTenantService.getAccessToken(resource);
      }

      return acquireTokenObservable.pipe(
          mergeMap((accessToken: AHAccessToken) => {

            const token = accessToken.accessToken;
            const tenantName = accessToken.tenantName;
            let header = this.getDefaultHttpHeader(req.headers, token);

            if (!getTenantRequest) {

              const managedUserEmail = this._globalService.getSelectedManagedUser().email;

              let utcOffset:number = moment.default().utcOffset();
              if(!utcOffset && utcOffset==null)
              {
                utcOffset=0;
              }

              header = header
                .set('TENANT_HEADER', `${tenantName}`)
                .set('MANAGED_USER_HEADER', `${managedUserEmail}`)
                .set('UTC_OFFSET', utcOffset.toString());
            }

            const authorizedRequest = req.clone({
              headers: header,
            });

            return next.handle(authorizedRequest)
              .pipe(
                catchError((error: HttpErrorResponse) => {
                    const isGetTenantRequest = error.url.endsWith('/api/Tenant/GetTenantName');
                    if (isGetTenantRequest) {
                      if (error.status == 401) {
                        this._userService.msAdal.logout();
                      }
                    }
                    if (error.error instanceof Error) {
                      // A client-side or network error occurred. Handle it accordingly.
                      console.error('An error occurred:', error.error.message);
                    } else {
                      // The backend returned an unsuccessful response code.
                      // The response body may contain clues as to what went wrong,
                      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                    }

                    // If you want to return the error on the upper level:
                    return throwError(error);

                  })
              );
          }
        )
      );



    }

    /**
     * Requests to other apis (ex. microsoft.login)
     * @param req
     * @param next
     */
    nonInterceptableRequest(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.error instanceof Error) {
              // A client-side or network error occurred. Handle it accordingly.
              console.error('An error occurred:', error.error.message);
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
            }

            // check error.url to see if it is a microsoft login error
            if (error.status == 500) {
                this._userService.msAdal.logout();
            }

            // If you want to return the error on the upper level:
            return throwError(error);
          })
       );
    }

    getDefaultHttpHeader(headers : HttpHeaders, token : string) : HttpHeaders {
      let header = headers
      .set('Authorization', `Bearer ${token}`)  // Authentication
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .set('If-Modified-Since', '0');
      return header;
    }
}
