/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrganizationDataView } from '../models/organization-data-view';
import { CollectionResultEmailNotification } from '../models/collection-result-email-notification';
import { EntityResultEmailNotification } from '../models/entity-result-email-notification';
import { EmailNotification } from '../models/email-notification';
import { VoidResult } from '../models/void-result';
import { EmailNotificationSeachCriteria } from '../models/email-notification-seach-criteria';
@Injectable({
  providedIn: 'root',
})
class EmailNotificationService extends __BaseService {
  static readonly GetAllowedOrganizationDataPath = '/api/EmailNotification/GetAllowedOrganizationData';
  static readonly FindPath = '/api/EmailNotification/Find';
  static readonly FindAllowedPath = '/api/EmailNotification/FindAllowed';
  static readonly InsertPath = '/api/EmailNotification';
  static readonly UpdatePath = '/api/EmailNotification';
  static readonly DeletePath = '/api/EmailNotification';
  static readonly EnablePath = '/api/EmailNotification/Enable';
  static readonly DisablePath = '/api/EmailNotification/Disable';
  static readonly GetAllPath = '/api/EmailNotification/GetAll';
  static readonly GetByIdPath = '/api/EmailNotification/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetAllowedOrganizationDataResponse(): __Observable<__StrictHttpResponse<Array<OrganizationDataView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmailNotification/GetAllowedOrganizationData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationDataView>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllowedOrganizationData(): __Observable<Array<OrganizationDataView>> {
    return this.GetAllowedOrganizationDataResponse().pipe(
      __map(_r => _r.body as Array<OrganizationDataView>)
    );
  }

  /**
   * @param params The `EmailNotificationService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindResponse(params: EmailNotificationService.FindParams): __Observable<__StrictHttpResponse<CollectionResultEmailNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    (params.OnlyInTheseFunctions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseFunctions', val.toString())});
    (params.OnlyInTheseDivisions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseDivisions', val.toString())});
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.HasFunctions != null) __params = __params.set('HasFunctions', params.HasFunctions.toString());
    if (params.HasDivisions != null) __params = __params.set('HasDivisions', params.HasDivisions.toString());
    if (params.FunctionName != null) __params = __params.set('FunctionName', params.FunctionName.toString());
    if (params.DivisionName != null) __params = __params.set('DivisionName', params.DivisionName.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmailNotification/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultEmailNotification>;
      })
    );
  }
  /**
   * @param params The `EmailNotificationService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  Find(params: EmailNotificationService.FindParams): __Observable<CollectionResultEmailNotification> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultEmailNotification)
    );
  }

  /**
   * @param searchCriteria undefined
   * @return Success
   */
  FindAllowedResponse(searchCriteria?: EmailNotificationSeachCriteria): __Observable<__StrictHttpResponse<CollectionResultEmailNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchCriteria;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmailNotification/FindAllowed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultEmailNotification>;
      })
    );
  }
  /**
   * @param searchCriteria undefined
   * @return Success
   */
  FindAllowed(searchCriteria?: EmailNotificationSeachCriteria): __Observable<CollectionResultEmailNotification> {
    return this.FindAllowedResponse(searchCriteria).pipe(
      __map(_r => _r.body as CollectionResultEmailNotification)
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: EmailNotification): __Observable<__StrictHttpResponse<EntityResultEmailNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmailNotification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultEmailNotification>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: EmailNotification): __Observable<EntityResultEmailNotification> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultEmailNotification)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: EmailNotification): __Observable<__StrictHttpResponse<EntityResultEmailNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmailNotification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultEmailNotification>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: EmailNotification): __Observable<EntityResultEmailNotification> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultEmailNotification)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmailNotification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmailNotification/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmailNotification/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultEmailNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmailNotification/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultEmailNotification>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultEmailNotification> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultEmailNotification)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultEmailNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmailNotification/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultEmailNotification>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultEmailNotification> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultEmailNotification)
    );
  }
}

module EmailNotificationService {

  /**
   * Parameters for Find
   */
  export interface FindParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
    Name?: string;
    IsAvailable?: boolean;
    HasFunctions?: boolean;
    HasDivisions?: boolean;
    FunctionName?: string;
    DivisionName?: string;
    Description?: string;
    ContinuationToken?: string;
  }

  /**
   * Parameters for FindAllowed
   */
  export interface FindAllowedParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
    Name?: string;
    IsAvailable?: boolean;
    HasFunctions?: boolean;
    HasDivisions?: boolean;
    FunctionName?: string;
    DivisionName?: string;
    Description?: string;
    ContinuationToken?: string;
  }
}

export { EmailNotificationService }
