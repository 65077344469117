/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseDTO } from '../models/response-dto';
import { ReferenceTableMeta } from '../models/reference-table-meta';
@Injectable({
  providedIn: 'root',
})
class ReferenceTableMetaService extends __BaseService {
  static readonly InsertReferenceTableMetaPath = '/api/ReferenceTableMeta/InsertReferenceTableMeta';
  static readonly GetReferenceTablesMetaPath = '/api/ReferenceTableMeta/GetReferenceTablesMeta';
  static readonly GetReferenceTableMetaByIdPath = '/api/ReferenceTableMeta/GetReferenceTableMetaById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param referenceTableMeta undefined
   * @return Success
   */
  InsertReferenceTableMetaResponse(referenceTableMeta?: ReferenceTableMeta): __Observable<__StrictHttpResponse<ResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = referenceTableMeta;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReferenceTableMeta/InsertReferenceTableMeta`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseDTO>;
      })
    );
  }
  /**
   * @param referenceTableMeta undefined
   * @return Success
   */
  InsertReferenceTableMeta(referenceTableMeta?: ReferenceTableMeta): __Observable<ResponseDTO> {
    return this.InsertReferenceTableMetaResponse(referenceTableMeta).pipe(
      __map(_r => _r.body as ResponseDTO)
    );
  }

  /**
   * @return Success
   */
  GetReferenceTablesMetaResponse(): __Observable<__StrictHttpResponse<Array<ReferenceTableMeta>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTableMeta/GetReferenceTablesMeta`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferenceTableMeta>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetReferenceTablesMeta(): __Observable<Array<ReferenceTableMeta>> {
    return this.GetReferenceTablesMetaResponse().pipe(
      __map(_r => _r.body as Array<ReferenceTableMeta>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetReferenceTableMetaByIdResponse(id?: string): __Observable<__StrictHttpResponse<ReferenceTableMeta>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTableMeta/GetReferenceTableMetaById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferenceTableMeta>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetReferenceTableMetaById(id?: string): __Observable<ReferenceTableMeta> {
    return this.GetReferenceTableMetaByIdResponse(id).pipe(
      __map(_r => _r.body as ReferenceTableMeta)
    );
  }
}

module ReferenceTableMetaService {
}

export { ReferenceTableMetaService }
