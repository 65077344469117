import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SortPipe } from '@app/shared/pipes/sort.pipe';
import { UserService } from '@app/shared/services/user/user.service';
import { TableComponent } from '@app/shared/components/table/table.component';
import { MaterialModule } from '@app/material/material.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SearchClauseComponent } from '@app/shared/components/search-clause/search-clause.component';
import { ImpactAnalysisComponent } from '@app/shared/components/impact-analysis/impact-analysis.component';
import { RequestClauseComponent } from '@app/shared/components/request-clause/request-clause.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill';
import { AuditTableComponent } from '@app/shared/components/audit-table/audit-table.component';
import { GuidService } from '@app/shared/services/guid/guid.service';
import { MatFormFieldModule, MatButtonModule, MatInputModule } from '@angular/material';
import { ModalDialogComponent } from '@app/shared/components/modal-dialog/modal-dialog.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { ModalDialogAcknowledgeComponent } from '@app/shared/components/acknowledge-modal-dialog/acknowledge-modal-dialog.component';
import { ModalDialogChangeDelegatorComponent } from '@app/shared/components/change-delegator-modal-dialog/change-delegator-modal-dialog.component';
import { ManagedAuthoritiesTableComponent } from '@app/shared/components/managed-authorities-table/managed-authorities-table.component';
import { ModalDialogManagingAuthorityComponent } from  '@app/shared/components/modal-dialog-managing-authority/modal-dialog-managing-authority.component';
import { PeopleSearchComponent } from './people-search/people-search.component';
import { TextLibraryItemComponent } from './components/text-library-item/text-library-item.component';
import { SubsectionComponent } from './components/subsection/subsection.component';
import { SectionComponent } from './components/section/section.component';
import { QueryCommentComponent  } from './components/query-comment/query-comment.component';
import { DalContentComponent } from './components/dal-content/dal-content.component';
import { AddLibraryItemModalDialogComponent } from './components/add-library-item-modal-dialog/add-library-item-modal-dialog.component';
import { AddLibrarySectionModalDialogComponent } from './components/add-library-section-modal-dialog/add-library-section-modal-dialog.component';
import { AddLibrarySubSectionModalDialogComponent } from './components/add-library-sub-section-modal-dialog/add-library-sub-section-modal-dialog.component';
import { LibraryItemComponent } from './components/library-item/library-item.component';
import { InfillClicker } from './components/text-library-item/text-preview-html/Infill-clicker';
import { PreviewTextLibraryItemComponent } from './components/preview-text-library-item/preview-text-library-item.component';
import { PreviewTableLibraryItemComponent } from './components/preview-table-library-item/preview-table-library-item.component';
import { SafeHtmlStylePipe } from './pipes/safe-html-style.pipe';
import { DalTemplateContentComponent } from './components/dal-template-content/dal-template-content.component';
import { InfillBasicEditorComponent } from './components/infill-basic-editor/infill-basic-editor.component';
import { ModalBasicVariableComponent } from './components/modal-basic-variable/modal-basic-variable.component';
import { ModalGeneralTablesComponent } from './components/modal-general-tables-variable/modal-general-tables-variable.component';
import { ModalOrganizationVariableComponent } from './components/modal-organization-variable/modal-organization-variable.component';
import { ModalDatasetComponent } from './components/modal-dataset-variable/modal-dataset-variable.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { AuditLogModalComponent } from './components/audit-log-modal/audit-log-modal.component';
import { ModalReviewersGroupComponent } from './components/modal-reviewers-group/modal-reviewers-group.component';
import { PrivacyPolicyComponent } from '@app/demo/privacy-policy/privacy-policy.component';
import { ModalAcceptanceCheckboxComponent } from './components/modal-acceptance-checkbox/modal-acceptance-checkbox';
import { ModalBulkUploadComponent } from './components/modal-bulk-upload/modal-bulk-upload';
import { ModalLIComponent } from './components/modal-li-variable/modal-li-variable.component';
import { ChangeDelegatorModalComponent } from './components/change-delegator-modal/change-delegator-modal.component';
import { ModalValidationComponent } from './components/modal-validation/modal-validation.component';
import {NgPipesModule} from 'ngx-pipes';
import { ModalCommentsComponent } from './components/modal-comments/modal-comments.component';
import { ModalTagsAddComponent } from './components/modal-tags-add/modal-tags-add.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { ChangeDelegatorLogsModalComponent } from './components/change-delegator-logs-modal/change-delegator-logs-modal.component';
export { ProcessInfillVariable } from './infill-processing/process-infill-variable';
export { ProcessInformationalVariable } from './infill-processing/process-informational-variable';
export { DalAuthorityHolderInformationalVariable } from './infill-processing/dal-authority-holder-informational-variable';
export { DalDelegatorInformationalVariable } from './infill-processing/dal-delegator-informational-variable';
export { DalComposerInformationalVariable } from './infill-processing/dal-composer-informational-variable';
export { DalAcknowledgementDateInformationalVariable } from './infill-processing/dal-acknowledgement-informational-variable';
export { DalCodeInformationalVariable } from './infill-processing/dal-code-informational-variable';
export { DalDivisionsInformationalVariable } from './infill-processing/dal-divisions-informational-variable';
export { DalFunctionsInformationalVariable } from './infill-processing/dal-functions-informational-variable';
export { DalStateInformationalVariable } from './infill-processing/dal-state-informational-variable';
export { DalVersionInformationalVariable } from './infill-processing/dal-version-informational-variable';
export { DalReviewDateInformationalVariable } from './infill-processing/dal-review-date-informational-variable';
export { DalStartDateInformationalVariable } from './infill-processing/dal-start-date-informational-variable';
export { DalContentConfiguration} from './library-item-processing/dal-content-configuration';
export { ProcessBasicInfillVariable }  from './infill-processing/process-basic-infill-variable';
export { ProcessDataSetInfillVariable }  from './infill-processing/process-dataset-infill-variable';
export { ProcessGeneralInfillVariable }  from './infill-processing/process-general-infill-variable';
export { ProcessOrganizationInfillVariable }  from './infill-processing/process-organization-infill-variable';
export { AHBroadbandLevelInformationalVariable} from './infill-processing/ah-broadband-level-informational-variable';
export { AHCompanyInformationalVariable} from './infill-processing/ah-company-informational-variable';
export { AHCostCentreIdInformationalVariable} from './infill-processing/ah-cost-centre-id-informational-variable';
export { AHCostCentreNameInformationalVariable} from './infill-processing/ah-cost-centre-name-informational-variable';
export { AHBCountryInformationalVariable} from './infill-processing/ah-country-informational-variable';
export { AHDepartamentInformationalVariable} from './infill-processing/ah-departament-informational-variable';
export { AHDivisionInformationalVariable} from './infill-processing/ah-division-informational-variable';
export { AHEmailInformationalVariable} from './infill-processing/ah-email-informational-variable';
export { AHEmbMemberInformationalVariable} from './infill-processing/ah-emb-member-informational-variable';
export { AHEndDateInformationalVariable} from './infill-processing/ah-end-date-informational-variable';
export { AHJobTitleInformationalVariable} from './infill-processing/ah-job-title-informational-variable';
export { AHLocationInformationalVariable} from './infill-processing/ah-location-informational-variable';
export { AHManagerInformationalVariable} from './infill-processing/ah-manager-informational-variable';
export { AHOfficeAddressInformationalVariable} from './infill-processing/ah-office-address-informational-variable';
export { AHPhoneNumberInformationalVariable} from './infill-processing/ah-phone-number-informational-variable';
export { AHPreferredNameInformationalVariable} from './infill-processing/ah-preferred-name-informational-variable';
export { AHPreviousFirstNameInformationalVariable} from './infill-processing/ah-previous-firstname-informational-variable';
export { AHPreviousLastNameInformationalVariable} from './infill-processing/ah-previous-lastname-informational-variable';
export { AHStartDateInformationalVariable} from './infill-processing/ah-start-date-informational-variable';
export { AHTeamInformationalVariable} from './infill-processing/ah-team-informational-variable';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    QuillModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    AngularDraggableModule,
    NgPipesModule
  ],
  exports: [
    ModalInfoComponent,
    SortPipe,
    TableComponent,
    SearchClauseComponent,
    ImpactAnalysisComponent,
    RequestClauseComponent,
    MatDialogModule,
    ModalDialogComponent,
    ModalValidationComponent,
    ModalCommentsComponent,
    ModalDialogAcknowledgeComponent,
    ModalDialogChangeDelegatorComponent,
    ManagedAuthoritiesTableComponent,
    ModalDialogManagingAuthorityComponent,
    PeopleSearchComponent,
    TextLibraryItemComponent,
    SubsectionComponent,
    SectionComponent,
    QueryCommentComponent,
    DalContentComponent,
    AddLibraryItemModalDialogComponent,
    AddLibrarySectionModalDialogComponent,
    AddLibrarySubSectionModalDialogComponent,
    LibraryItemComponent,
    InfillClicker,
    SafeHtmlStylePipe,
    DalTemplateContentComponent,
    AuditLogModalComponent,
    ModalAcceptanceCheckboxComponent,
    ModalBulkUploadComponent,
    ModalLIComponent,
    ModalTagsAddComponent,
    ChangeDelegatorLogsModalComponent,
  ],
  declarations: [
    ModalInfoComponent,
    SortPipe,
    TableComponent,
    SearchClauseComponent,
    ImpactAnalysisComponent,
    RequestClauseComponent,
    AuditTableComponent,
    ModalDialogComponent,
    ModalValidationComponent,
    ModalCommentsComponent,
    ModalDialogAcknowledgeComponent,
    ModalDialogChangeDelegatorComponent,
    ModalDialogManagingAuthorityComponent,
    ManagedAuthoritiesTableComponent,
    PeopleSearchComponent,
    TextLibraryItemComponent,
    SubsectionComponent,
    SectionComponent,
    QueryCommentComponent,
    DalContentComponent,
    AddLibraryItemModalDialogComponent,
    AddLibrarySectionModalDialogComponent,
    AddLibrarySubSectionModalDialogComponent,
    LibraryItemComponent,
    InfillClicker,
    PreviewTextLibraryItemComponent,
    PreviewTableLibraryItemComponent,
    SafeHtmlStylePipe,
    DalTemplateContentComponent,
    InfillBasicEditorComponent,
    ModalBasicVariableComponent,
    ModalOrganizationVariableComponent,
    ModalGeneralTablesComponent,
    AuditLogComponent,
    AuditLogModalComponent,
    ModalDatasetComponent,
    ModalReviewersGroupComponent,
    ModalAcceptanceCheckboxComponent,
    ModalBulkUploadComponent,
    ModalLIComponent,
    ChangeDelegatorModalComponent,
    ChangeDelegatorLogsModalComponent,
    ModalTagsAddComponent
  ],
  entryComponents: [
    ModalInfoComponent,
    SearchClauseComponent,
    RequestClauseComponent,
    ModalDialogComponent,
    ModalValidationComponent,
    ModalCommentsComponent,
    ModalDialogAcknowledgeComponent,
    ModalDialogChangeDelegatorComponent,
    ModalDialogManagingAuthorityComponent,
    TextLibraryItemComponent,
    DalContentComponent,
    AddLibraryItemModalDialogComponent,
    AddLibrarySectionModalDialogComponent,
    AddLibrarySubSectionModalDialogComponent,
    PreviewTextLibraryItemComponent,
    PreviewTableLibraryItemComponent,
    DalTemplateContentComponent,
    InfillBasicEditorComponent,
    ModalBasicVariableComponent,
    ModalOrganizationVariableComponent,
    ModalGeneralTablesComponent,
    ModalDatasetComponent,
    AuditLogModalComponent,
    ModalDatasetComponent,
    ModalReviewersGroupComponent,
    ModalAcceptanceCheckboxComponent,
    ModalBulkUploadComponent,
    ModalLIComponent,
    ChangeDelegatorModalComponent,
    ModalTagsAddComponent,
    ChangeDelegatorLogsModalComponent,
    
  ]
})
export class SharedModule {
  // To access UserService as singleton pattern, provide forRoot method and return ModuleWithProviders
  // Place all services in providers that need to access as singleton
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: UserService },
        { provide: GuidService },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        { provide: DatePipe}
      ]
    };
  }

}
