import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog, MatSnackBar, MatTable, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ReportSchedule } from '@app/api/models';
import { ReportScheduleService } from '@app/api/services';
import { AuditLogModalComponent } from '@app/shared/components/audit-log-modal/audit-log-modal.component';
import { ModalDialogComponent } from '@app/shared/components/modal-dialog/modal-dialog.component';
import { GlobalService } from '@app/shared/services/global/global.service';
import { environment } from '@env/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { isUndefined } from 'util';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-scheduled-report',
  templateUrl: './scheduled-report.component.html',
  styleUrls: ['./scheduled-report.component.scss']
})
export class ScheduledReportComponent implements OnInit {

  changeManagedUserEvent$ : Subscription;
  @Input() reloadIntance: Subject<boolean> = new Subject<boolean>();
  changeManagedUser: any;

  constructor(
    private _router: Router,
    private _reportScheduleService: ReportScheduleService,
    private _spinnerService: Ng4LoadingSpinnerService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _globalService: GlobalService
  ) { 
    this._reportScheduleService.rootUrl = environment.ApiUrl;
  }

  displayedColumns = ['more', 'reportName', 'description', 'scheduleStart', 'recurrence', 'periodicity', 'scheduleEnd', 'user'];
  session = sessionStorage.getItem('client');
  reportSchedule_DS: MatTableDataSource<ReportSchedule>;
  isSuperUser : boolean = false;

  @ViewChild('reportschedule') table: MatTable<ReportSchedule>;

  ngOnInit() {
    this.checkSuperUser();
    this.LoadReportSchedules();
    let isOnInitComponent = true;

    this.reloadIntance.subscribe(response => {
      if(isOnInitComponent)
      return;
      if(response){
        this.LoadReportSchedules();
    }
  });

  this.changeManagedUserEvent$ = this._globalService.changeManagedUser.subscribe(value => {
    if(isOnInitComponent)
      return;
    this.changeManagedUser = {
      managedUserMail : value.selectedManagedUser.email,
      managedUserName: value.selectedManagedUser.firstName + " " + value.selectedManagedUser.lastName,
      selectedManagedUseIsLoggedUser: value.selectedManagedUseIsLoggedUser,
    }
    this.LoadReportSchedules();
  });

  isOnInitComponent = false;

  }

  LoadReportSchedules(){
    this._spinnerService.show();

    this._reportScheduleService.GetAllReportScheduleByUser()
    .subscribe((data) => {
      this._spinnerService.hide();
      if (data && data.results && data.results.length != 0) {
        this.reportSchedule_DS = new MatTableDataSource(data.results);
        console.log(this.reportSchedule_DS);
      }
      else{
        this.reportSchedule_DS = new MatTableDataSource();
        console.log("No Results");
      }
      this._spinnerService.hide();
   }, (err: HttpErrorResponse) => {
    console.log(err.message);
  });

  }

  checkSuperUser()
  {
      var userRoles = JSON.parse(localStorage.getItem("userRole"));
      // Fix: to avoid to use role before login
      if(userRoles == null || isUndefined(userRoles))
      {
        this.isSuperUser = false;
      }
      else
      {
        this.isSuperUser = userRoles.includes('SUPERUSER');
      }
  }

  deleteEntity(element) {
    
    let message = "You are about to Delete the Report Schedule. This cannot be undone, do you wish to continue?";
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '450px',
      data: {title: "Warning", message: message, okText: "Yes, delete.", cancelText: "No, cancel." }
    });

    dialogRef.afterClosed().subscribe(result => {
      let confirmEnable: boolean = result;
      if (confirmEnable == true)
      {
        this._spinnerService.show();
        this._reportScheduleService.DeleteReportSchedule(element.id)
          .subscribe(response => {
            if(response.errors && response.errors.length > 0){
              response.errors.forEach(element => {
                this.showMessage(element.message);
              });
            }
            else if (response.status_code == '200') {
              if(this.reportSchedule_DS != null && this.reportSchedule_DS/*.data*/ != null) {
                let deleteIndex = this.reportSchedule_DS.data.findIndex((el) => el.id == element.id );
                if(deleteIndex >= 0) {
                  this.reportSchedule_DS.data.splice(deleteIndex, 1);
                  this.reportSchedule_DS = new MatTableDataSource(this.reportSchedule_DS.data);
                }
            }
            this.showMessage('Report Schedule is deleted successfully');
          } else {
            this.showMessage('Report Schedule Delete failure');
          }
          this._spinnerService.hide();
        },
          (err: HttpErrorResponse) => {
            this._spinnerService.hide();
            this.showMessage('Report Schedule Delete failure');
          });
      }
      else {
      }
    });
  }

  // snack bar to show save success
  showMessage(msg: string) {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

    // Show Audit PopUp
    gotoAudit(element,event){
      const dialogRef = this.dialog.open(AuditLogModalComponent, {
        width: '80%',
        data: {
          id:element.id,
          type:element.type,
          typeDescription: "Scheduled"
        }
      });
    }

    findPermission(id: string) {
      if (id && this._globalService.selecteBaseDataUserPermissions) {
        const findPermission = this._globalService.selecteBaseDataUserPermissions.findIndex(x => x.id == id);
        if (findPermission !== -1) {
          return true;
        }
      }
      return false;
    }
  
  editReportSchedule(element){
    let canUpdate = this.canBeDeleted(element);
    this._router.navigate(
      ["/"+sessionStorage.getItem('client')+'/report/execution/executionReport'], 
      { queryParams: { reportId:element.reportDefinitionView.id,reportName:element.reportDefinitionView.name,reportScheduleId:btoa(element.id),canUpdate} });
    }
  
    canBeDeleted(element)
    {
      let response: boolean = false;
      let getCurrentLoggedUserid =  this._globalService.getCurrentLoggedUser().id ;
      let aAdmin = this._globalService.delegatorsList.filter(x=> x.id == element.user.id );
      if( this.isSuperUser ||  // Is Super user, so can delelte
         element.user.id == getCurrentLoggedUserid || // is de creator , so can delete
         element.user.id == this._globalService.getSelectedManagedUser().id && aAdmin.length>0 ) // Managing Authority of his MA
         {
          response = true;
         }  
      return response;
  }
    
}
