import { Component, OnInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-root',
  template: '',
})
export class MsalComponent implements OnInit {

  constructor(private _msadal: MsAdalAngular6Service) { }

  ngOnInit() {
  }

}
