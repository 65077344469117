/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Dal } from '../models/dal';
import { DalContent } from '../models/dal-content';
import { DalImpactView } from '../models/dal-impact-view';
@Injectable({
  providedIn: 'root',
})
class DalTemplatesService extends __BaseService {
  static readonly GetPath = '/api/DalTemplates';
  static readonly Get_1Path = '/api/DalTemplates/{id}';
  static readonly AnalyseImpactPath = '/api/DalTemplates/AnalyseImpact';
  static readonly UpsertPath = '/api/DalTemplates/Upsert';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DalTemplatesService.GetParams` containing the following parameters:
   *
   * - `UpdateMode`:
   *
   * - `MasterTemplateId`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `BaseDataId`:
   *
   * - `Action`:
   *
   * @return Success
   */
  GetResponse(params: DalTemplatesService.GetParams): __Observable<__StrictHttpResponse<Array<Dal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UpdateMode != null) __params = __params.set('UpdateMode', params.UpdateMode.toString());
    if (params.MasterTemplateId != null) __params = __params.set('MasterTemplateId', params.MasterTemplateId.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.DelegatorEmail != null) __params = __params.set('DelegatorEmail', params.DelegatorEmail.toString());
    if (params.BaseDataId != null) __params = __params.set('BaseDataId', params.BaseDataId.toString());
    if (params.Action != null) __params = __params.set('Action', params.Action.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Dal>>;
      })
    );
  }
  /**
   * @param params The `DalTemplatesService.GetParams` containing the following parameters:
   *
   * - `UpdateMode`:
   *
   * - `MasterTemplateId`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `BaseDataId`:
   *
   * - `Action`:
   *
   * @return Success
   */
  Get(params: DalTemplatesService.GetParams): __Observable<Array<Dal>> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as Array<Dal>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  Get_1Response(id: string): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplates/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get_1(id: string): __Observable<Dal> {
    return this.Get_1Response(id).pipe(
      __map(_r => _r.body as Dal)
    );
  }

  /**
   * @param params The `DalTemplatesService.AnalyseImpactParams` containing the following parameters:
   *
   * - `updateMode`:
   *
   * - `dalId`:
   *
   * - `dalContents`:
   *
   * @return Success
   */
  AnalyseImpactResponse(params: DalTemplatesService.AnalyseImpactParams): __Observable<__StrictHttpResponse<Array<Dal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.updateMode != null) __params = __params.set('updateMode', params.updateMode.toString());
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    __body = params.dalContents;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplates/AnalyseImpact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Dal>>;
      })
    );
  }
  /**
   * @param params The `DalTemplatesService.AnalyseImpactParams` containing the following parameters:
   *
   * - `updateMode`:
   *
   * - `dalId`:
   *
   * - `dalContents`:
   *
   * @return Success
   */
  AnalyseImpact(params: DalTemplatesService.AnalyseImpactParams): __Observable<Array<Dal>> {
    return this.AnalyseImpactResponse(params).pipe(
      __map(_r => _r.body as Array<Dal>)
    );
  }

  /**
   * @param params The `DalTemplatesService.UpsertParams` containing the following parameters:
   *
   * - `updateMode`:
   *
   * - `dalImpactView`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  UpsertResponse(params: DalTemplatesService.UpsertParams): __Observable<__StrictHttpResponse<DalImpactView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.updateMode != null) __params = __params.set('updateMode', params.updateMode.toString());
    __body = params.dalImpactView;
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplates/Upsert`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalImpactView>;
      })
    );
  }
  /**
   * @param params The `DalTemplatesService.UpsertParams` containing the following parameters:
   *
   * - `updateMode`:
   *
   * - `dalImpactView`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  Upsert(params: DalTemplatesService.UpsertParams): __Observable<DalImpactView> {
    return this.UpsertResponse(params).pipe(
      __map(_r => _r.body as DalImpactView)
    );
  }
}

module DalTemplatesService {

  /**
   * Parameters for Get
   */
  export interface GetParams {
    UpdateMode?: string;
    MasterTemplateId?: string;
    Function?: string;
    Division?: string;
    DelegatorEmail?: string;
    BaseDataId?: string;
    Action?: string;
  }

  /**
   * Parameters for AnalyseImpact
   */
  export interface AnalyseImpactParams {
    updateMode?: string;
    dalId?: string;
    dalContents?: Array<DalContent>;
  }

  /**
   * Parameters for Upsert
   */
  export interface UpsertParams {
    updateMode?: string;
    dalImpactView?: DalImpactView;
    dalId?: string;
  }
}

export { DalTemplatesService }
