import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportDefinition } from '@app/api/models/report-definition';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSetLevel, EntityResultLibraryItem, HcmEmployeeDetails, LibraryItem, OrganizationData, OrganizationDataView, ReportSchedule } from '@app/api/models';
import { CurrencyService, LibraryItemService, OrganizationDatasService, ReferenceTableMetaService, ReferenceTableService, UsersService } from '@app/api/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '@env/environment';
import { ReportInstanceService } from '@app/api/services/report-instance.service';
import { MatSnackBar, MatAutocomplete, MatOption, MatSelect, MatDialog} from '@angular/material';
import { ReportInstance } from '@app/api/models/report-instance';

import { ReportDefinitionView } from '@app/api/models/report-definition-view';
import { ReportFilterView } from '@app/api/models/report-filter-view';
import { ReportDefinitionService } from '@app/api/services/report-definition.service';
import { ViewDalService } from '@app/shared/services/dal/view-dal.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map, subscribeOn } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserDataView } from '@app/api/models/user-data-view';
import * as moment from 'moment';
import { ScheduleReportModalDialogComponent } from '../schedule-report-modal-dialog/schedule-report-modal-dialog.component';
import { ReportScheduleService} from '@app/api/services';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { GlobalService } from '@app/shared/services/global/global.service';
import { AddLibraryItemModalDialogComponent } from '@app/shared/components/add-library-item-modal-dialog/add-library-item-modal-dialog.component';
import { DalContentConfiguration } from '@app/shared/shared.module';
import { MatTableModule} from '@angular/material/table'
import { PreviewTextLibraryItemComponent } from '@app/shared/components/preview-text-library-item/preview-text-library-item.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Region } from '@app/api/models';
import { RegionService } from '@app/api/services';
import { NofiltersModalDialogComponent } from '../modal-dialog-no-filters/modal-dialog-no-filters.component';

export const MY_FORMATS = {
  parse: {
    dateInput: ['DD MMM YYYY','DD/MM/YYYY'],
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-execution-report',
  templateUrl: './execution-report.component.html',
  styleUrls: ['./execution-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class ExecutionReportComponent implements OnInit {
  isSU_RA_Auditor : boolean = false;
  controlDelegator = new FormControl();
  controlAuthorityHolder = new FormControl();
  users: UserDataView[] = [];
  filteredDelegator: Observable<UserDataView[]>;
  filteredAuthorityHolder: Observable<UserDataView[]>;
  delegatorSelected: string;
  authorityHolderSelected: string;
  reportDefinitionId:string;
  reportName:string;
  reportDefinition:ReportDefinition;
  divisions: any[];
  functions: any[];
  divisionsForm = new FormControl('');
  functionsForm = new FormControl('');
  DALStatus: string[] = [
    ViewDalService.DalStatus.Accepted,
    ViewDalService.DalStatus.Approved,
    ViewDalService.DalStatus.Draft,
    ViewDalService.DalStatus.InReview,
    ViewDalService.DalStatus.Queried,
    ViewDalService.DalStatus.Revoked,
    ViewDalService.DalStatus.SentToDelegator,
    ViewDalService.DalStatus.Superceded,
    ViewDalService.DalStatus.Suspended,
  ];
  reportFilter:FilterHeader = new FilterHeader();
  allStatusSelected:boolean = false;
  allDivisionSelected:boolean = false;
  alllibraryItemSelected:boolean = false;
  allFunctionSelected:boolean = false;
  additionalFilters:ReportFilterView[] = [];
  showRefresh:boolean = false;
  datasets: DataToSelect[] = [];
  libraryItems: LibraryItem[] = [];
  currencies: any;
  datasetLevels: DataSetLevel[] = null;
  dataSource:LibraryItem[]=[];
  listLibreryItemCode = [];
  reportScheduleId: string;
  scheduledFilters: {};
  isReportSchedule:boolean = false;
  canUpdateSchedule: boolean = true;
  existmandatoryFilters: boolean = false;
  dalContentConfiguration: DalContentConfiguration;
  @ViewChild("autoDelegator") autoDelegator: MatAutocomplete;
  @ViewChild("autoAH") autoAH: MatAutocomplete;
  @ViewChild('anyStatus') statusSel: MatSelect;
  @ViewChild ('anyDivision') divSel: MatSelect;
  @ViewChild ('anyFunction') funSel: MatSelect;
  @ViewChild('selectlibraryItem') selectlibraryItem: MatSelect;
  reloadIntance: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = ['select', 'preview', 'name', 'type', 'code'];
  liResult: LibraryItem;
  public baseCurrencyCode: string = null;
  regions: Region[];
  constructor(
    private _activatedroute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _organizationDataService: OrganizationDatasService,
    private spinnerService: Ng4LoadingSpinnerService,
    private reportInstanceService:ReportInstanceService,
    private reportDefinitionService:ReportDefinitionService,
    private _usersService: UsersService,
    private _referenceTableService: ReferenceTableService,
    private _libraryItemsService: LibraryItemService,
    private _currencyService: CurrencyService,
    private _globalService: GlobalService,
    private dialog: MatDialog,
    private reportScheduleService: ReportScheduleService,
    private _regionService: RegionService
  ) {
    this._organizationDataService.rootUrl = environment.ApiUrl;
    this.reportInstanceService.rootUrl = environment.ApiUrl;
    this.reportDefinitionService.rootUrl = environment.ApiUrl;
    this._usersService.rootUrl = environment.ApiUrl;
    this._referenceTableService.rootUrl = environment.ApiUrl;
    this._libraryItemsService.rootUrl = environment.ApiUrl;
    this._currencyService.rootUrl = environment.ApiUrl;
    this.reportScheduleService.rootUrl = environment.ApiUrl;
    this._regionService.rootUrl = environment.ApiUrl;
  }

  ngOnInit() {
    this.validateSU_RA_Auditor();

    this.reportDefinitionId = this._activatedroute.snapshot.queryParamMap.get('reportId');
    this.reportName = this._activatedroute.snapshot.queryParamMap.get('reportName');
    let updateRerportSchedule = this._activatedroute.snapshot.queryParamMap.get('canUpdate');
    if(updateRerportSchedule && updateRerportSchedule == "false")
    {
      this.canUpdateSchedule  = false;
    }
    this.loadReportScheduleViewParam();

    this.filteredDelegator = this.controlDelegator.valueChanges
    .pipe(
      map(value => this._filter(value))
    );

    this.filteredAuthorityHolder = this.controlAuthorityHolder.valueChanges
    .pipe(
      map(value => this._filterAH(value))
    );

    this.getRegions();
    this.loadScheduledData();
    this.getOrganizationData();
    this.getReportDefinition();
    this.getUsers();
    this.loadHeaderFilters();
  }

  findPermission(id: string) {
    if(id && this._globalService.selecteBaseDataUserPermissions){
      var findPermission = this._globalService.selecteBaseDataUserPermissions.findIndex(x=>x.id==id);
      if(findPermission!= -1){
        return true;
      } 
    }
    return false;
  }

  loadStatusSelection(){
    this.reportFilter.dalStatus = [];

    var arr = this.scheduledFilters['dalStatus'];
    if (arr != undefined && arr != null){
      if (arr.length == 0)
      {
        this.reportFilter.dalStatus.push("0");
        // for(var i=0;i<this.DALStatus.length;i++){
        //   this.reportFilter.dalStatus.push(this.DALStatus[i]);
        // }
        this.allStatusSelected = true;
      }
      else
      {
        for(var i=0; i<arr.length;i++){
          this.reportFilter.dalStatus.push(arr[i]);
        }
        this.allStatusSelected = false;
      }
    }

  }

  loadDivisionsSelection(){
    if (this.divisions == undefined)
      return;

    this.reportFilter.divisionSelected = [];
    var arr = this.scheduledFilters['division'];
    if (arr != undefined && arr != null){
      if (arr.length == 0)
      {
        this.reportFilter.divisionSelected.push("0");

        this.allDivisionSelected = true;
        this.divisionsForm.setValue(0);
      }
      else
      {
        for(var i=0; i<arr.length;i++){
          var j=0;
          var founded=false;
          while(j<this.divisions.length && !founded){
            if ((arr[i] != null) && (this.divisions[j].id === arr[i].id))
            {
              founded = true;
            }
            else j++;
          }
          if (founded)
          {
          this.reportFilter.divisionSelected.push(this.divisions[j]);
          }
        }
        this.divisionsForm.setValue(this.reportFilter.divisionSelected);
        this.allDivisionSelected = false;
      }
    }
  }

  loadFunctionsSelection(){
    if (this.functions == undefined)
      return;

    this.reportFilter.functionSelected = [];
    var arr = this.scheduledFilters['function'];
    if (arr != undefined && arr != null){
      if (arr.length == 0)
      {

        this.reportFilter.functionSelected.push("0");
        // for(var i=0;i<this.functions.length;i++){
        //   this.reportFilter.functionSelected.push(this.functions[i]);
        // }
        this.functionsForm.setValue(0);
        this.allFunctionSelected = true;
      }
      else
      {
        for(var i=0; i<arr.length;i++){
          var j=0;
          var founded=false;
          while(j<this.functions.length && !founded){
            if ((arr[i] != null) && (this.functions[j].id === arr[i].id))
            {
              founded = true;
            }
            else j++;
          }
          if (founded)
          {
          this.reportFilter.functionSelected.push(this.functions[j]);
          }
        }
        this.functionsForm.setValue(this.reportFilter.functionSelected);
        this.allFunctionSelected = false;
      }

    }
  }

  resetChildForm(){
    this.reloadIntance.next(true);
 }

  navigateToGeneral(){
    this._router.navigate(["/"+sessionStorage.getItem('client')+'/report/execution']);
  }

  navigateToSchedule(){
    this._router.navigate(["/"+sessionStorage.getItem('client')+'/report/schedule']);
  }

  getUsers(){
    this.spinnerService.show();
    this._usersService.GetAllUsersData()
      .subscribe(response => {
        this.users = response;
        if (!this.isReportSchedule){
        this.controlDelegator.setValue('');
        this.controlAuthorityHolder.setValue('');
        }
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
          this.spinnerService.hide();
        }
      );
  }

  getOrganizationData() {

    this._organizationDataService.Get()
      .subscribe((data) => {
        if (data != null) {

          this.divisions = data.filter(this.divisionFilter)
            .map(this.toOrganizationDataView);

          this.functions = data.filter(this.functionFilter)
            .map(this.toOrganizationDataView);

            this.loadDivisionsSelection();
            this.loadFunctionsSelection();
        } else {
          this.divisions = [];
          this.functions = [];
        }

      }, (error) => {
        this.spinnerService.hide();
      });
  }

  divisionFilter(organizationData: OrganizationData): boolean {
    return organizationData != null
      && organizationData.status == 'Active'
      && organizationData.orgGroupType == 'DIVISIONS';
  }

  functionFilter(organizationData: OrganizationData): boolean {
    return organizationData != null
      && organizationData.status == 'Active'
      && organizationData.orgGroupType == 'FUNCTION';
  }

  toOrganizationDataView(organizationData: OrganizationData): OrganizationDataView {
    if (organizationData == null) {
      return null;
    }
    let orgData: OrganizationDataView = {
      orgGroupName: organizationData.orgGroupName,
      orgGroupType: organizationData.orgGroupType,
      id: organizationData.id,
      orgLevel: organizationData.orgLevel,
      orgParentGroupId: organizationData.orgParentGroupId
    };
    return orgData;
  }

  getReportDefinition() {
    this.reportDefinitionService.GetById(this.reportDefinitionId)
      .subscribe((data) => {
        if (data != null) {
          this.reportDefinition = data.result;
        } else {
          this.reportDefinition = null;
        }
        this.loadFilters();
      }, (err: HttpErrorResponse) => {
        console.log(err.message);
        this.spinnerService.hide();
      });
  }

  loadFilters(){
    if(this.reportDefinition && this.reportDefinition.reportFilters){
      this.additionalFilters = [];
      if(this.reportDefinition.reportFilters.length > 0){
        let exitsMandatory = this.reportDefinition.reportFilters.filter(x=> x.mandatory == true)
        if(exitsMandatory.length >0)
        {
          this.existmandatoryFilters = true;
        }
        else
        {
          this.existmandatoryFilters = false;
        }
        this.reportDefinition.reportFilters.forEach(element => {
          if(element.type.toLowerCase() == 'DataSet'.toLowerCase()){
            this.spinnerService.show();
            this._referenceTableService.GetActiveReferenceTables(element.id)
            .subscribe(data => {
              if(data && data.length > 0){
                data = data.sort((a,b)=> (a.description).localeCompare(b.description));
                this.datasets.push({
                  dataSetID: element.id,
                  valuesDataSet: data
                });
              }

              this.spinnerService.hide();
            }, (err: HttpErrorResponse) => {
              console.log(err.message);
              this.spinnerService.hide();
            });
          }
          if(element.type.toLowerCase() == 'LibraryItem'.toLowerCase()){
            this.spinnerService.show();
            this._libraryItemsService.Find({
              ShowRemoved: false,
            })
            .subscribe(data => {
              if (data && data.results && data.results.length != 0)
              {
                this.libraryItems = data.results.sort((a,b)=>(a.name).localeCompare(b.name));
              }

              this.spinnerService.hide();
            }, (err: HttpErrorResponse) => {
              console.log(err.message);
              this.spinnerService.hide();
            });
          }
          if(element.type.toLowerCase() == 'Currency'.toLowerCase()){
            this.spinnerService.show();
            this._currencyService.GetActiveCurrencies()
            .subscribe(response => {
              this.currencies = response.items.sort((a,b)=> (a.description).localeCompare(b.description));
              this.spinnerService.hide();
            },
              (err: HttpErrorResponse) => {
                this.spinnerService.hide();
              }
            );
          }

          this.additionalFilters.push({
            id: element.id,
            type: element.type,
            visibleName: element.visibleName,
            value: this.scheduledFilters[element.id],
            parentFilters: element.parentFilters,
            mandatory: element.mandatory
          });

        });
      }
    }else{
      this.additionalFilters = [];
    }
  }

  onClickRun() {
    if(this.isSU_RA_Auditor && this.validateNoFilters()){
      const dialogRef = this.dialog.open(NofiltersModalDialogComponent, {
        width: '45%',
        data: {
          message: "You are trying to run the report with no filters defined. This can result in a processing time out due to a very high volume of data. Do you wish to continue?"
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.runInstance();
          } else {
            return;
          }
        });
    } else {
      this.runInstance();
    }
  }

  runInstance() {
    if(this.validate()){
      let reportInstance = this.generateReportInstance();
      if(reportInstance)
      {
        this.spinnerService.show();
        this.reportInstanceService.RunReport(reportInstance)
        .subscribe((data) => {
          if(data){
            this.showSnackBar("The report was executed.");
            this.resetChildForm();
            this.showRefresh = true;
          }
          this.spinnerService.hide();
        }, (err: HttpErrorResponse) => {
          console.log(err.message);
          this.spinnerService.hide();
        });
      }
    }
  }

  validateNoFilters():boolean{
    let notFiltersSelected = false;
    let additionalFilters = this.additionalFilters.every(x => x.value==null || x.value == undefined)

    if(!this.reportFilter.delegator 
      && !this.reportFilter.authorityHolder
      && !this.reportFilter.code
      && (!this.reportFilter.dalStatus || this.reportFilter.dalStatus.length == 0)
      && !this.reportFilter.reviewDateFrom
      && !this.reportFilter.reviewDateTo
      && !this.reportFilter.startDateFrom
      && !this.reportFilter.startDateTo
      && !this.reportFilter.endDateFrom
      && !this.reportFilter.endDateTo
      && !this.reportFilter.location
      && !this.reportFilter.costCentreName
      && (!this.reportFilter.divisionSelected || this.reportFilter.divisionSelected.length == 0)
      && (!this.reportFilter.functionSelected || this.reportFilter.functionSelected.length == 0)
      && !this.reportFilter.regionSelected
      && additionalFilters)
    {
      notFiltersSelected = true;
    }


    return notFiltersSelected;
  }

  validateSU_RA_Auditor(){
    if(this.findPermission("6000")){
      this.isSU_RA_Auditor = true;
    }

    if(this.findPermission("7000")){
      this.isSU_RA_Auditor = true;
    }

    let userRoles = JSON.parse(localStorage.getItem("userRole"));
    if(userRoles && userRoles.includes('SUPERUSER')){
      this.isSU_RA_Auditor = true;
    }

  }

  showAddLibraryItem() {
    this.dalContentConfiguration = new DalContentConfiguration();
    const dialogRef = this.dialog.open(AddLibraryItemModalDialogComponent, {
      width: '80%',
      data: {
        dalContentConfiguration: this.dalContentConfiguration,
        // libraryItems: this.libraryItems
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result != null) {
        this.dataSource = result as LibraryItem[];
        this.additionalFilters.find(x => x.type == "LibraryItem").value = [];
        //  if(this.dataSource) {

        //   var newLI =  predataSource.filter((f) => !this.dataSource.includes(f))
        //   newLI.forEach(x => {
        //     this.dataSource.push(x);
        //   }
        //   );
        //  } else {

        //   this.dataSource = predataSource;
        //  }
        //

        this.addLibreryItems();
      }
    });
  }

  addLibreryItems() {
    this.listLibreryItemCode = [];
    this.dataSource.forEach(x => {
      this.listLibreryItemCode.push(x.code);
    }
    );
    this.additionalFilters.find(x => x.type == "LibraryItem").value = this.listLibreryItemCode;
  }

  openPreview(id: string) {
    if (id) {
      //todo call openPreview
      this.getLibraryByID(id);
    }
  }

  removeLibreryItem(item) {
    // var listLibreryItemCode = [];
    this.additionalFilters.find(x => x.type == "LibraryItem").value = null;
    var filterSub = this.dataSource.filter(function (d) {
      return d.code !== item.code;
    });
    this.dataSource = filterSub;
    this.addLibreryItems();
  }

  getLibraryByID(id: string) {
    this.spinnerService.show();
    this._libraryItemsService.GetById(id)
      .subscribe((data: EntityResultLibraryItem) => {
        this.spinnerService.hide();
        if (data) {
          this.liResult = data.result;
          if (data.result) {
            //call preview
            //console.log(this.liResult);
            this.showPreview();
          }
        }

      },
        (err: HttpErrorResponse) => {
          this.spinnerService.hide();
          //this.showSnackBar(err.message);
        }
      );
  }

  showPreview() {
    let dalContentConfiguration: DalContentConfiguration = new DalContentConfiguration();
    dalContentConfiguration.state = this.liResult != null ? this.liResult.status : 'Draft';
    dalContentConfiguration.version = '1';
    dalContentConfiguration.startDate = this.liResult != null ? this.liResult.startDate : this.toDate(new Date());
    dalContentConfiguration.endDate = this.liResult != null ? this.liResult.endDate : this.toDate(new Date());
    dalContentConfiguration.reviewDate = this.toDate(new Date());
    dalContentConfiguration.acknowledgementDate = this.toDate(new Date());
    dalContentConfiguration.endDate = this.toDate(new Date());
    dalContentConfiguration.baseCurrencyCode = this.baseCurrencyCode;
    dalContentConfiguration.code = '000215';

    dalContentConfiguration.divisions = [];

    dalContentConfiguration.functions = [];

    dalContentConfiguration.authorityHolder = {
      email: "test@preview.com",
      firstName: "test name",
      lastName: "Authority Holder",
      id: null
    };

    dalContentConfiguration.composer = {
      email: "test@preview.com",
      firstName: "test name",
      lastName: "Composer",
      id: null
    };

    dalContentConfiguration.delegator = {
      email: "test@preview.com",
      firstName: "test name",
      lastName: "Delegator",
      id: null
    };

    dalContentConfiguration.authorityHolderEmployeeInfo = this.buildHcmEmployeeMock();

    //const libraryItemToPreview = this.getBindedCurrentLibraryItem();
    const libraryItemToPreview = this.liResult;

    var CopiedLibraryItem: any = {};
    //Object.assign(CopiedLibraryItem, libraryItemToPreview);
    CopiedLibraryItem = this.deepCopy(libraryItemToPreview);


    dalContentConfiguration.libraryItems = [CopiedLibraryItem];

    if (this.liResult.contentType == 'TEXT' ||
      this.liResult.contentType == 'TABLE' ||
      this.liResult.contentType == 'LEVELTABLE') {
      const dialogRef = this.dialog.open(PreviewTextLibraryItemComponent, {
        width: '80%',
        data: {
          currentLibraryItem: CopiedLibraryItem,
          dalContentConfiguration: dalContentConfiguration,
          editMode: true,
          mode: "Preview"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined && result != null) {
        }
      });
    }
  }

  toDate(date: Date): string {
    return date.toISOString();
  }

  deepCopy(obj: any) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  buildHcmEmployeeMock() {
    const hcmEmployee: HcmEmployeeDetails =
    {
      jobTitle: 'Test jobTitle',
      company: 'Test company',
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      preferredName: 'Test preferredName',
      previousFirstName: 'Test previousFirstName',
      previousLastName: 'Test previousLastName',
      location: 'Test location',
      officeAddress: 'Test officeAddress',
      country: 'Test country',
      phoneNumber: 'Test phoneNumber',
      manager: 'Test manager',
      team: 'Test team',
      division: 'Test division',
      department: 'Test department',
      costCentreID: 'Test costCentreID',
      costCentreName: 'Test costCentreName',
      broadbandLevel: 'Test broadbandLevel',
      embMember: 'Test embMember',
      userView: {
        email: "test@preview.com",
        firstName: "test name",
        lastName: "Employee",
        id: null
      }
    }

    return hcmEmployee;

  }

  onClickSchedule() {
    if (this.validate()){
      let reportInstance = this.generateReportInstance();
      if(reportInstance)
      {
         const dialogRef = this.dialog.open(ScheduleReportModalDialogComponent, {
        width: '45%',
        data: {
          id: this.reportScheduleId,
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined && result != null) {
            var results = result as ReportSchedule;
            results.reportDefinitionView = reportInstance.reportDefinitionView;
            results.reportFilterViews = reportInstance.reportFilterViews;
            results.timeZone = reportInstance.timeZone;
            results.nextSchedule = results.scheduleStart;
            this.spinnerService.show();
            this.reportScheduleService.ScheduleReport(results)
            .subscribe((data) => {
              if(data){
                this.showSnackBar("The report was scheduled.");
              }
          this.spinnerService.hide();
        }, (err: HttpErrorResponse) => {
          console.log(err.message);
          this.spinnerService.hide();
        });
      }
        });
      }

     

    

  }
  }

  completeWithZero(code: string) :string{
    var calculateZeros = 6 - code.length;

    if(calculateZeros > 0){
      for (let index = 0; index < calculateZeros; index++) {
        code = '0' + code;
      }
    }
    return code;
  }


  loadFilterHeaderReportInstance(reportInstance:ReportInstance){
    if(!reportInstance.reportFilterViews)
      reportInstance.reportFilterViews = [];

    if(this.reportFilter.authorityHolder){
      reportInstance.reportFilterViews.push({
        id : "authorityHolder",
        type : "string",
        value : this.reportFilter.authorityHolder,
        visibleName : "Authority Holder"
      });
    }

    if(this.reportFilter.code){

      this.reportFilter.code = this.completeWithZero(this.reportFilter.code);

      reportInstance.reportFilterViews.push({
        id : "dalCode",
        type : "string",
        value : this.reportFilter.code,
        visibleName : "DAL Code"
      });
    }

    if(this.reportFilter.dalStatus){
      var statusSelection = [];
      if (!this.allStatusSelected)
        statusSelection = this.reportFilter.dalStatus;

      reportInstance.reportFilterViews.push({
        id : "dalStatus",
        type : "string",
        //value : this.reportFilter.dalStatus,
        value : statusSelection,
        visibleName : "DAL Status"
      });
    }

    if(this.reportFilter.delegator){
      reportInstance.reportFilterViews.push({
        id : "delegator",
        type : "string",
        value : this.reportFilter.delegator,
        visibleName : "Delegator"
      });
    }

    if(this.reportFilter.endDateFrom){
      reportInstance.reportFilterViews.push({
        id : "endDateFrom",
        type : "datetime",
        value : moment.default(this.reportFilter.endDateFrom).format('LL'),
        visibleName : "End Date (From)"
      });
    }

    if(this.reportFilter.endDateTo){
      reportInstance.reportFilterViews.push({
        id : "endDateTo",
        type : "string",
        value : moment.default(this.reportFilter.endDateTo).format('LL'),
        visibleName : "End Date (To)"
      });
    }

    if(this.reportFilter.location){
      reportInstance.reportFilterViews.push({
        id : "location",
        type : "string",
        value : this.reportFilter.location,
        visibleName : "Location"
      });
    }

    if(this.reportFilter.reviewDateFrom){
      reportInstance.reportFilterViews.push({
        id : "reviewDateFrom",
        type : "datetime",
        value : moment.default(this.reportFilter.reviewDateFrom).format('LL'),
        visibleName : "Review Date (From)"
      });
    }

    if(this.reportFilter.reviewDateTo){
      reportInstance.reportFilterViews.push({
        id : "reviewDateTo",
        type : "datetime",
        value : moment.default(this.reportFilter.reviewDateTo).format('LL'),
        visibleName : "Review Date (To)"
      });
    }

    if(this.reportFilter.startDateFrom){
      reportInstance.reportFilterViews.push({
        id : "startDateFrom",
        type : "datetime",
        value : moment.default(this.reportFilter.startDateFrom).format('LL'),
        visibleName : "Start Date (From)"
      });
    }

    if(this.reportFilter.startDateTo){
      reportInstance.reportFilterViews.push({
        id : "startDateTo",
        type : "datetime",
        value : moment.default(this.reportFilter.startDateTo).format('LL'),
        visibleName : "Start Date (To)"
      });
    }

    if(this.reportFilter.costCentreName){
      reportInstance.reportFilterViews.push({
        id : "costCentreName",
        type : "string",
        value : this.reportFilter.costCentreName,
        visibleName : "Cost Centre Name"
      });
    }

    if(this.reportFilter.divisionSelected || (this.reportFilter.divisionSelected == 0)){

      var divisionSelection = [];
      if (!this.allDivisionSelected){
        divisionSelection = this.reportFilter.divisionSelected;
      }
      reportInstance.reportFilterViews.push({
        id : "division",
        type : "string",
        //value : this.reportFilter.divisionSelected,
        value : divisionSelection,
        visibleName : "Division"
      });
    }

    if(this.reportFilter.functionSelected || (this.reportFilter.functionSelected == 0)){

      var functionSelection = [];
      if (!this.allFunctionSelected){
        functionSelection = this.reportFilter.functionSelected;
      }
      reportInstance.reportFilterViews.push({
        id : "function",
        type : "string",
        //value : this.reportFilter.functionSelected,
        value : functionSelection,
        visibleName : "Function"
      });
    }

    if(this.reportFilter.regionSelected){
      reportInstance.reportFilterViews.push({
        id : "region",
        type : "string",
        value : this.reportFilter.regionSelected,
        visibleName : "Region"
      });
    }
  }

  //** this function select or deselected all status */
  toggleAllStatusSelection() {
    this.allStatusSelected = !this.allStatusSelected;

    if (this.allStatusSelected) {
      this.statusSel.options.forEach( (item : MatOption) => {
        if(item.value!=0){
          item.deselect()
        }

      });
  }

  }

//** this function unselect the Any when all status was selecte and the user deselect a option. */
  singleStatusSelection(){
    if (this.allStatusSelected) {
      this.statusSel.options.forEach(
         (item : MatOption) =>
         {
          if(item.value == 0)
          {item.deselect();}
        }
         );
         this.allStatusSelected = false;
    }
  }

   //** this function select or deselected all Divisions */
   toggleAllDivisionSelection() {
    this.allDivisionSelected = !this.allDivisionSelected;

    if (this.allDivisionSelected) {
      this.divSel.options.forEach( (item : MatOption) => {
        if(item.value!=0){
          item.deselect()
        }

      });
  }
}

//** this function unselect the Any when all divisions was selecte and the user deselect a option. */
  singleDivisionSelection(){
    if (this.allDivisionSelected) {
      this.divSel.options.forEach(
         (item : MatOption) =>
         {
          if(item.value == 0)
          {item.deselect();}
        }
         );
         this.allDivisionSelected = false;
    }
  }

  //** this function select or deselect all Functions */
  toggleAllFunctionSelection(){
    this.allFunctionSelected = !this.allFunctionSelected;

    if (this.allFunctionSelected) {
      this.funSel.options.forEach( (item : MatOption) => {
        if(item.value!=0){
          item.deselect()
        }

      });
  }
  }

//** this function unselect the Any when all function was selected and the user deselect a option. */
singleFunctionSelection(){
  if (this.allFunctionSelected) {
    this.funSel.options.forEach(
       (item : MatOption) =>
       {
        if(item.value == 0)
        {item.deselect();}
      }
       );
       this.allFunctionSelected = false;
  }
}

  generateReportInstance():ReportInstance{
    let reportDefinition:ReportDefinitionView = {
      id: this.reportDefinition.id,
      name: this.reportDefinition.name
    };

    let reportInstance = {
      clientId: "",
      reportDefinitionView: reportDefinition,
      reportFilterViews: [],
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if(!reportInstance.timeZone){
      let momenttz = require('moment-timezone');
      reportInstance.timeZone = momenttz.tz.guess();
    }
    let madatoryFilter ="";
    let countErrors = 0;

    this.additionalFilters.forEach(element => {
      if(element.mandatory)
      {
        if(element.value=== undefined || element.value == null)
        {
           madatoryFilter += element.visibleName +", ";
           countErrors++;
        }
      }
      (element.value === undefined || element.value === null) ? delete element.value : element;
      reportInstance.reportFilterViews.push(element);
    });

    if(madatoryFilter){
      if(countErrors>1){
        this.showSnackBar("The filters " + madatoryFilter + " are mandatory.")
      } else {
        this.showSnackBar("The filter " + madatoryFilter + " is mandatory.")
      }
      
      return;
    }

    this.loadFilterHeaderReportInstance(reportInstance);
    return reportInstance;
  }

  getOptionText(user:UserDataView){
    if(user){
     return user.firstName + ' ' + user.lastName;
    }else{
      return '';
    }
  }

  private _filter(value: string | UserDataView): UserDataView[] {
    let user = value as UserDataView;

    if(this.users){
      let filterValue = value.toString().toLowerCase();
      let usersFiltered = this.users.filter(user =>
         (user.firstName != null && user.firstName != '' &&
        user.firstName.toLowerCase().includes(filterValue))
        ||
        ( (user.lastName != null && user.lastName != '' && user.firstName != null && user.firstName != '') &&
        (user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()).includes(filterValue))
        ||
        ( user.lastName != null && user.lastName != '' &&
          user.lastName.toLowerCase().includes(filterValue)));
      return usersFiltered;
    }
  }

  private _filterAH(value: string | UserDataView): UserDataView[] {
    let user = value as UserDataView;

      if(this.users){
      let filterValue = value.toString().toLowerCase();
      let usersFiltered = this.users.filter(user =>
        (user.firstName != null && user.firstName != '' &&
        user.firstName.toLowerCase().includes(filterValue))
        ||
        ( (user.lastName != null && user.lastName != '' && user.firstName != null && user.firstName != '') &&
        (user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()).includes(filterValue))
        ||
        ( user.lastName != null && user.lastName != '' &&
        user.lastName.toLowerCase().includes(filterValue)));
      return usersFiltered;
    }
  }

  chooseFirstDelegator(){
    if(this.autoDelegator.options && this.autoDelegator.options.length == 1)
      this.autoDelegator.options.first.select();
  }

  chooseFirstAH(){
    if(this.autoAH.options && this.autoAH.options.length == 1)
    this.autoAH.options.first.select();
  }

  showSnackBar(msg: string): void {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  getDatasetValues(dataSetID:string){
    if(dataSetID && dataSetID != '' && this.datasets){
      let values = this.datasets.filter(x => x.dataSetID == dataSetID);
      if(values && values.length > 0)
      return values[0].valuesDataSet;
    }
  }

  validate(){
    let message:string = '';
    if(this.reportFilter.startDateFrom && this.reportFilter.startDateTo){
      let startDateFrom = new Date(this.reportFilter.startDateFrom);
      let startDateTo = new Date(this.reportFilter.startDateTo);
      if(startDateFrom > startDateTo){
        message += 'The "Start Date: From" must be earlier than "Start Date: To" \n';
      }
    }

    if(this.reportFilter.endDateFrom && this.reportFilter.endDateTo){
      let endDateFrom = new Date(this.reportFilter.endDateFrom);
      let endDateTo = new Date(this.reportFilter.endDateTo);
      if(endDateFrom > endDateTo){
        message += 'The "End Date: From" must be earlier than "End Date: To" \n';
      }
    }

    if(this.reportFilter.reviewDateFrom && this.reportFilter.reviewDateTo){
      let reviewDateFrom = new Date(this.reportFilter.reviewDateFrom);
      let reviewDateTo = new Date(this.reportFilter.reviewDateTo);
      if(reviewDateFrom > reviewDateTo){
        message += 'The "Review Date: From" must be earlier than "Review Date: To" \n';
      }
    }

    if(this.reportFilter.delegator){
      if(!this.reportFilter.delegator.id){
        message += "The delegator selected is invalid. \n";
      }
    }

    if(this.reportFilter.authorityHolder){
      if(!this.reportFilter.authorityHolder.id){
        message += "The authority holder selected is invalid. \n";
      }
    }

    if(message != ''){
      this.showSnackBar(message);
      return false;
    }
    return true;
  }

  onClickDataSet(dataset){
    var subFilters = this.additionalFilters.filter(x => x.parentFilters);
    if(subFilters && subFilters.length > 0){
      subFilters = subFilters.filter(x =>
           x.parentFilters.filter(y => y == dataset.subType).length > 0
           );
    }

    if(subFilters && subFilters.length > 0){
      subFilters.forEach(element => {
        if(element.id == "LevelDataSet"){
          this.filterLevels();
        }
      });
    }
  }

  onClickCurrency(currency){
    var subFilters = this.additionalFilters.filter(x => x.parentFilters);

    if(subFilters && subFilters.length > 0){
      subFilters = subFilters.filter(x =>
           x.parentFilters.filter(y => y.toUpperCase() == 'CURRENCY').length > 0
           );
    }

    if(subFilters && subFilters.length > 0){
      subFilters.forEach(element => {
        if(element.id == "LevelDataSet"){
          this.filterLevels();
        }
      });
    }
  }

  filterLevels(){
   var levelFilter = this.additionalFilters.find(x => x.id == 'LevelDataSet');
    if(levelFilter){
      // levelFilter.parentFilters.filter(x => x == )
      var parents = this.additionalFilters.filter(x => levelFilter.parentFilters.find(y => y == x.id));
      if(parents){
        var currency = parents.find( x => x.id.toUpperCase() == 'CURRENCY');
        var dataset = parents.find( x => x.type.toUpperCase() == 'DATASET');
        this.loadLevels(dataset.value, currency.value);
      }
    }
  }

  loadLevels(datasetID, currency){
    if(datasetID){
      this.spinnerService.show();
      this._referenceTableService.GetLevelsByDatasetAndCurrency({id:datasetID, currency: currency})
      .subscribe(data => {
        if(data && data.results && data.results.length > 0){
          this.datasetLevels = data.results;
        }else{
          this.datasetLevels = null;
        }
        this.spinnerService.hide();
      }, (err: HttpErrorResponse) => {
        this.showSnackBar(err.message);
      });
    }
  }

  loadScheduledData(){
    if (this.reportScheduleId != undefined && this.reportScheduleId != null && this.reportScheduleId != ""){
      this.spinnerService.show();
      this.reportScheduleService.GetById(this.reportScheduleId)
      .subscribe(response => {
        if (response && response.result){
          this.scheduledFilters = {}
          response.result.reportFilterViews.forEach(x => this.scheduledFilters[x.id] = x.value);
          this.loadHeaderFilters();
        }
        else if (response && response.errors && response.errors.length > 0)
          response.errors.forEach(x => console.log(x.message));
          this.spinnerService.hide();
      },(err: HttpErrorResponse) =>{
        this.spinnerService.hide();
        console.log(err.message);
      });
    }
  }

  loadReportScheduleViewParam(){
    this.scheduledFilters = {};
    let reference = this._activatedroute.snapshot.queryParamMap.get('reportScheduleId');
    if( reference != null && reference != "" ){
      this.reportScheduleId = atob(reference);
      this.isReportSchedule = true;
    }
  }

  loadHeaderFilters(){
    this.reportFilter.authorityHolder = this.scheduledFilters["authorityHolder"];
    this.reportFilter.code = this.scheduledFilters["dalCode"];
    //this.reportFilter.dalStatus = this.scheduledFilters["dalStatus"];
    this.loadStatusSelection();
    this.reportFilter.delegator = this.scheduledFilters["delegator"];
    this.reportFilter.location = this.scheduledFilters["location"];
    this.reportFilter.costCentreName = this.scheduledFilters["costCentreName"];
    this.reportFilter.endDateFrom = this.parseDateFilterValue(this.scheduledFilters["endDateFrom"]);
    this.reportFilter.endDateTo = this.parseDateFilterValue(this.scheduledFilters["endDateTo"]);
    this.reportFilter.reviewDateFrom = this.parseDateFilterValue(this.scheduledFilters["reviewDateFrom"]);
    this.reportFilter.reviewDateTo = this.parseDateFilterValue(this.scheduledFilters["reviewDateTo"]);
    this.reportFilter.startDateFrom = this.parseDateFilterValue(this.scheduledFilters["startDateFrom"]);
    this.reportFilter.startDateTo = this.parseDateFilterValue(this.scheduledFilters["startDateTo"]);
    this.reportFilter.regionSelected = this.scheduledFilters["region"];

    this.additionalFilters.forEach(item =>
      item.value = this.scheduledFilters[item.id]);

    this.loadDivisionsSelection();
    this.loadFunctionsSelection();
  }

  parseDateFilterValue(dateFilterValue){
    if (dateFilterValue != undefined && dateFilterValue != null && dateFilterValue != "")
      return new Date(dateFilterValue).toISOString();
    else
      return dateFilterValue;
  }

  getRegions(){
    this.spinnerService.show();
    this._regionService.GetActiveRegions()
    .subscribe(response => {
      if (response) {
        this.regions = [];
        //let noneRegion: Region = { code: 'None', title: 'None', clientId: '' };
        //this.regions.push(noneRegion);
        let regions: any = response;
        for (var i = 0; i < regions.length; i++) {
          let region: Region = regions[i];
          this.regions.push(region);
        }
        this.spinnerService.hide();
      }
    },
      (err: HttpErrorResponse) => {
        console.log(err.message);
        this.spinnerService.hide();
      }
    );

  }

}

export class FilterHeader{
  delegator?: any;
  authorityHolder?: any;
  divisionSelected?: any;
  functionSelected?: any;
  code?: string;
  location?: string;
  startDateFrom?: string;
  startDateTo?: string;
  reviewDateFrom?: string;
  reviewDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  dalStatus?: any;
  type?: string;
  costCentreName: string;
  regionSelected?: any;
}

export interface DataToSelect{
  dataSetID: string;
  valuesDataSet: any[];
}


