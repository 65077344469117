import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TenantService } from '@app/api/services';
import { environment } from '@env/environment';
import { SpawnSyncOptionsWithStringEncoding } from 'child_process';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { of, Observable, Subject } from 'rxjs';
import { catchError, concatAll, map, mergeMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

class CurrentTenantService {

    private _adalIdToken : string = 'adal.idtoken';
    private _verifyAccessTokenSubscriber : Subject<boolean> = null;

    /**
     * Current user Name to show in the header
     */
    loggedInUserName : string;

    constructor(
        private _msadal: MsAdalAngular6Service,
        private _tenantService: TenantService,
        private _router: Router) {
        this._tenantService.rootUrl = environment.ApiUrl;
    }

    /**
     * Verifies and renew token if neccessary
     * @param renewToken true if 
     */
    verifyAccessToken (renewToken : boolean) : Observable<boolean> {
        // if (this._verifyAccessTokenSubscriber != null) {
        //     return this._verifyAccessTokenSubscriber;
        // }
        // const sub = new Subject<boolean>();
        const verifyAccessTokenSubscriber = this.verifyAccessTokenInternal(renewToken)
            .pipe(
                map((value : boolean) => {

                    // removes the subscriber
                    // if (sub == this._verifyAccessTokenSubscriber) {
                    //     this._verifyAccessTokenSubscriber = null;
                    // }

                    return value;
                })
            );
        
        // verifyAccessTokenSubscriber.subscribe(sub);
        // this._verifyAccessTokenSubscriber = sub;
        
        // return sub;
        return verifyAccessTokenSubscriber;
    }

    /**
     * Verifies and renew token if neccessary
     * @param renewToken true if 
     */
    private verifyAccessTokenInternal (renewToken : boolean) : Observable<boolean> {

        const currentAccessToken = this._msadal.accessToken;
        const currentTenantName = this.getTenantName();
        const refreshTenantName = this.stringIsEmpty( currentTenantName);

        // User Not logged in
        // if (this.stringIsEmpty(currentAccessToken)) {
        //     return of (false);
        // }

        if (!this._msadal.isAuthenticated && !renewToken) {
            return of (false);
        }

        return this._msadal.acquireToken(this._adalIdToken)
            .pipe(

                map(
                    (res : string) => {

                        // No changes. Proceed
                        if (res == currentAccessToken && !refreshTenantName) {
                            return of(true);
                        }

                        this.clearTenantName();

                        // Needs to reload the tenantName
                        // return this.loadTenantName().pipe(
                        //     map((value: boolean))
                        // );
                        return this.loadTenantName()
                            .pipe(
                                map(
                                    (loadTenantRes : boolean) => {
                                        var newTenantName = this.getTenantName();
                                        if (refreshTenantName || newTenantName !== currentTenantName) {

                                            // Don't redirect in iframe
                                            if (!(window !== window.parent && !window.opener)) {
                                                this._router.navigate(['/' + newTenantName + '/admin']);   
                                            }
                                        }
                                        return true;
                                    }, 
                                    loadTenantError => {
                                        this._msadal.login();
                                        return false;
                                    }));
                    },
                    error => {
                        this._msadal.login();
                        return false;
                    }
                ),
                concatAll(),
            );
    }

    /**
     * Verifies and renew token if neccessary for TenantService
     * @param renewToken true if 
     */
    verifyAccessTokenForTenantService (renewToken : boolean) : Observable<boolean> {

        if (this._msadal.isAuthenticated) {
            return of(true);
        }

        if (!this._msadal.isAuthenticated && !renewToken) {
            return of (false);
        }

        return this._msadal.acquireToken(this._adalIdToken)
            .pipe(

                map(
                    (res : string) => {
                        return true;
                    },
                    error => {
                        this._msadal.login();
                        return false;
                    }
                ),
                concatAll(),
            );
    }

    getAccessTokenForTenantService (resource : string) : Observable<AHAccessToken> {

        // 1) Verify login
        return this.verifyAccessTokenForTenantService(true)
            .pipe(
                mergeMap((response : boolean) => {
                    let ahAccessToken = new AHAccessToken();
                    if (response) {

                        //workaround to avoid taking the old key on access backend token
                        sessionStorage.setItem("adal.access.token.key"+resource, '');
                        // 2) Acquire token for resource
                        return this._msadal.acquireToken(resource)
                            .pipe(
                                map((responseToken : string) => {

                                    
                                    ahAccessToken.accessToken = responseToken;
                                    return ahAccessToken;
                                })
                            );
                    }
                    return of(ahAccessToken);
                })
            );
        
    }


    getAccessToken (resource : string) : Observable<AHAccessToken> {

        // 1) Verify login
        return this.verifyAccessToken(true)
            .pipe(
                mergeMap((response : boolean) => {
                    let ahAccessToken = new AHAccessToken();
                    if (response) {

                        ahAccessToken.tenantName = this.getTenantName();

                        // 2) Acquire token for resource
                        return this._msadal.acquireToken(resource)
                            .pipe(
                                map((responseToken : string) => {
                                    ahAccessToken.accessToken = responseToken;
                                    return ahAccessToken;
                                })
                            );
                    }
                    return of(ahAccessToken);
                })
            );
        
    }

    loadTenantName(): Observable<boolean> {
        try {
          return this._tenantService.GetTenantName().pipe(
              map((response : string) => {
                const result = response;
                this.setTenantName(result);
                return true;
              },
              error => false)
          );
        } catch(error) {
          console.log(error);
          return of(false);
        }
      }

    /**
     * Removes the tenant name
     */
    clearTenantName() {
        sessionStorage.removeItem('client');
    }

    /**
     * Sets the current tenant name
     * @param tenantName 
     */
    setTenantName(tenantName : string) {
        sessionStorage.setItem('client', tenantName);
    }

    /**
     * Gets the current tenant name
     */
    getTenantName() : string {
        const tenantName = sessionStorage.getItem('client');
        return tenantName;
    }

    stringIsEmpty (value : string) : boolean {
        return (value == undefined || value == null || value == '');
    }

}

export class AHAccessToken {
    loginAccessToken : string;
    accessToken : string;
    tenantName : string;
}

module CurrentTenantService {
}

export { CurrentTenantService }