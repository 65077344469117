import { Component, OnInit, Input } from '@angular/core';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { QueryComment } from '@app/api/models/query-comment';

@Component({
  selector: 'app-query-comment',
  templateUrl: './query-comment.component.html',
  styleUrls: ['./query-comment.component.scss']
})
export class QueryCommentComponent implements OnInit {

  @Input() dalContentConfiguration: DalContentConfiguration;
  @Input() label:string = "Comment";
  @Input() queryElement: QueryComment;

  constructor() { }

  ngOnInit() {}

  showQueryComment(){
    //return this.dalContentConfiguration && (this.dalContentConfiguration.queryMode == "QUERY" || this.dalContentConfiguration.queryMode == "REPLY");
    if(this.dalContentConfiguration && this.dalContentConfiguration.queryMode)
    {
      let queryMode = this.dalContentConfiguration.queryMode;
      return queryMode == "QUERY" || queryMode == "REPLY" || queryMode == "REJECTED";
    }
    return false;
  }

  readonly(){
    if(this.dalContentConfiguration)
      return this.dalContentConfiguration.queryMode != "QUERY";
    return false;   
  }

  getLabelTitle(){
    return this.label;
  }

}
