/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Dal } from '../models/dal';
@Injectable({
  providedIn: 'root',
})
class DalVersionsService extends __BaseService {
  static readonly GetPath = '/api/dals/{dalId}/versions';
  static readonly PostPath = '/api/dals/{dalId}/versions';
  static readonly GetByIdPath = '/api/dals/{dalId}/versions/{versionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param dalId undefined
   * @return Success
   */
  GetResponse(dalId: string): __Observable<__StrictHttpResponse<Array<Dal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/dals/${dalId}/versions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Dal>>;
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  Get(dalId: string): __Observable<Array<Dal>> {
    return this.GetResponse(dalId).pipe(
      __map(_r => _r.body as Array<Dal>)
    );
  }

  /**
   * @param dalId undefined
   * @return Success
   */
  PostResponse(dalId: string): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${dalId}/versions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  Post(dalId: string): __Observable<Dal> {
    return this.PostResponse(dalId).pipe(
      __map(_r => _r.body as Dal)
    );
  }

  /**
   * @param params The `DalVersionsService.GetByIdParams` containing the following parameters:
   *
   * - `versionId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  GetByIdResponse(params: DalVersionsService.GetByIdParams): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/dals/${params.dalId}/versions/${params.versionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param params The `DalVersionsService.GetByIdParams` containing the following parameters:
   *
   * - `versionId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  GetById(params: DalVersionsService.GetByIdParams): __Observable<Dal> {
    return this.GetByIdResponse(params).pipe(
      __map(_r => _r.body as Dal)
    );
  }
}

module DalVersionsService {

  /**
   * Parameters for GetById
   */
  export interface GetByIdParams {
    versionId: string;
    dalId: string;
  }
}

export { DalVersionsService }
