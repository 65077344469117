/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DefinitionSourceV1Adapter } from '../models/definition-source-v1adapter';
@Injectable({
  providedIn: 'root',
})
class DefinitionSourceV1AdaptersService extends __BaseService {
  static readonly GetPath = '/api/DefinitionSourceV1Adapters';
  static readonly PostPath = '/api/DefinitionSourceV1Adapters';
  static readonly GetByIdPath = '/api/DefinitionSourceV1Adapters/{id}';
  static readonly PutPath = '/api/DefinitionSourceV1Adapters/{id}';
  static readonly DeletePath = '/api/DefinitionSourceV1Adapters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetResponse(): __Observable<__StrictHttpResponse<Array<DefinitionSourceV1Adapter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DefinitionSourceV1Adapters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DefinitionSourceV1Adapter>>;
      })
    );
  }
  /**
   * @return Success
   */
  Get(): __Observable<Array<DefinitionSourceV1Adapter>> {
    return this.GetResponse().pipe(
      __map(_r => _r.body as Array<DefinitionSourceV1Adapter>)
    );
  }

  /**
   * @param definitionSourceV1 undefined
   * @return Success
   */
  PostResponse(definitionSourceV1?: DefinitionSourceV1Adapter): __Observable<__StrictHttpResponse<DefinitionSourceV1Adapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = definitionSourceV1;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DefinitionSourceV1Adapters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DefinitionSourceV1Adapter>;
      })
    );
  }
  /**
   * @param definitionSourceV1 undefined
   * @return Success
   */
  Post(definitionSourceV1?: DefinitionSourceV1Adapter): __Observable<DefinitionSourceV1Adapter> {
    return this.PostResponse(definitionSourceV1).pipe(
      __map(_r => _r.body as DefinitionSourceV1Adapter)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<DefinitionSourceV1Adapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DefinitionSourceV1Adapters/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DefinitionSourceV1Adapter>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<DefinitionSourceV1Adapter> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as DefinitionSourceV1Adapter)
    );
  }

  /**
   * @param params The `DefinitionSourceV1AdaptersService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `definitionSourceV1`:
   *
   * @return Success
   */
  PutResponse(params: DefinitionSourceV1AdaptersService.PutParams): __Observable<__StrictHttpResponse<DefinitionSourceV1Adapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.definitionSourceV1;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DefinitionSourceV1Adapters/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DefinitionSourceV1Adapter>;
      })
    );
  }
  /**
   * @param params The `DefinitionSourceV1AdaptersService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `definitionSourceV1`:
   *
   * @return Success
   */
  Put(params: DefinitionSourceV1AdaptersService.PutParams): __Observable<DefinitionSourceV1Adapter> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as DefinitionSourceV1Adapter)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<DefinitionSourceV1Adapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/DefinitionSourceV1Adapters/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DefinitionSourceV1Adapter>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<DefinitionSourceV1Adapter> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as DefinitionSourceV1Adapter)
    );
  }
}

module DefinitionSourceV1AdaptersService {

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    definitionSourceV1?: DefinitionSourceV1Adapter;
  }
}

export { DefinitionSourceV1AdaptersService }
