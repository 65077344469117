/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
@Injectable({
  providedIn: 'root',
})
class AdUsersService extends __BaseService {
  static readonly SearchPath = '/api/AdUsers';
  static readonly PostPath = '/api/AdUsers';
  static readonly SearchUsersPaginatedPath = '/api/AdUsers/SearchUserAD';
  static readonly SearchUserAndMailADPath = '/api/AdUsers/SearchUserAndMailAD';
  static readonly GetByIdPath = '/api/AdUsers/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param search undefined
   * @return Success
   */
  SearchResponse(search?: string): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (search != null) __params = __params.set('search', search.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AdUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param search undefined
   * @return Success
   */
  Search(search?: string): __Observable<Array<User>> {
    return this.SearchResponse(search).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  PostResponse(user?: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AdUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  Post(user?: User): __Observable<User> {
    return this.PostResponse(user).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `AdUsersService.SearchUsersPaginatedParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `limit`:
   *
   * @return Success
   */
  SearchUsersPaginatedResponse(params: AdUsersService.SearchUsersPaginatedParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AdUsers/SearchUserAD`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `AdUsersService.SearchUsersPaginatedParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `limit`:
   *
   * @return Success
   */
  SearchUsersPaginated(params: AdUsersService.SearchUsersPaginatedParams): __Observable<Array<User>> {
    return this.SearchUsersPaginatedResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param params The `AdUsersService.SearchUserAndMailADParams` containing the following parameters:
   *
   * - `searchUser`:
   *
   * - `searchMail`:
   *
   * @return Success
   */
  SearchUserAndMailADResponse(params: AdUsersService.SearchUserAndMailADParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchUser != null) __params = __params.set('searchUser', params.searchUser.toString());
    if (params.searchMail != null) __params = __params.set('searchMail', params.searchMail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AdUsers/SearchUserAndMailAD`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `AdUsersService.SearchUserAndMailADParams` containing the following parameters:
   *
   * - `searchUser`:
   *
   * - `searchMail`:
   *
   * @return Success
   */
  SearchUserAndMailAD(params: AdUsersService.SearchUserAndMailADParams): __Observable<Array<User>> {
    return this.SearchUserAndMailADResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AdUsers/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<User> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as User)
    );
  }
}

module AdUsersService {

  /**
   * Parameters for SearchUsersPaginated
   */
  export interface SearchUsersPaginatedParams {
    search?: string;
    limit?: number;
  }

    /**
   * Parameters for SearchUserAndMailAD
   */
  export interface SearchUserAndMailADParams {
    searchUser?: string;
    searchMail?: string;
  }
}

export { AdUsersService }
