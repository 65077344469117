import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  DalLibraryItem, LibrarySubSection, LibraryItem, LibrarySection, DalSection, DalSubSection,
  ItemVariable
} from '@app/api/models';
import { DalContentConfiguration, ProcessBasicInfillVariable, ProcessGeneralInfillVariable, ProcessDataSetInfillVariable, ProcessOrganizationInfillVariable } from '@app/shared/shared.module';
import { LibraryItemSelectedEventArgs, RemoveLibraryItemEventArgs, RemoveSectionEventArgs, RemoveSubSectionEventArgs, SectionSelectedEventArgs, SubSectionSelectedEventArgs } from '@app/shared/library-item-processing/dal-content-configuration';
import { AddLibraryItemModalDialogComponent } from '@app/shared/components/add-library-item-modal-dialog/add-library-item-modal-dialog.component';
import { MatDialog } from '@angular/material';
import { type } from 'os';
import { AddLibrarySubSectionModalDialogComponent } from '@app/shared/components/add-library-sub-section-modal-dialog/add-library-sub-section-modal-dialog.component';
import { AddLibrarySectionModalDialogComponent } from '@app/shared/components/add-library-section-modal-dialog/add-library-section-modal-dialog.component';
import { ModalReviewersGroupComponent } from '@app/shared/components/modal-reviewers-group/modal-reviewers-group.component';
import { ReviewerGroupService } from '@app/api/services';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ModalAcceptanceCheckboxComponent } from '@app/shared/components/modal-acceptance-checkbox/modal-acceptance-checkbox';

@Component({
  selector: 'app-demo-library-item',
  templateUrl: './demo-library-item.component.html',
  styleUrls: ['./demo-library-item.component.scss']
})
export class DemoLibraryItemComponent implements OnInit, OnDestroy {
  basicText = "";
  basicTextNull = "";
  basicNumber = "";
  basicNumberNull = "";
  basicPercentage = "";
  basicPercentageNull = "";
  basicAmount = "";
  basicAmountNull = "";
  datasetFIA = "";
  datasetFIANull = "";
  generalTableCOU = "";
  generalTableCOUNull = "";
  organizationDVS = "";
  organizationDVSNull = "";

  dalContentConfiguration: DalContentConfiguration;
  constructor(private _dialog: MatDialog,
    private spinnerService: Ng4LoadingSpinnerService,
    private _reviewerGroupService: ReviewerGroupService,
    private dbv: ProcessBasicInfillVariable,
    private dgv: ProcessGeneralInfillVariable,
    private ddv: ProcessDataSetInfillVariable,
    private dov: ProcessOrganizationInfillVariable) {
    this._reviewerGroupService.rootUrl = environment.ApiUrl;
  }

  ngOnInit() {


    this.dalContentConfiguration = new DalContentConfiguration();

    this.dalContentConfiguration.libraryItemSelected.subscribe((args: LibraryItemSelectedEventArgs) => {

      let libraryItem = args.libraryItem;

      // Finds the parent element
      let itemExists = this.dalContentConfiguration.libraryItems.filter(x => x.libraryItemBase != null && x.libraryItemBase.id == libraryItem.id);
      if (itemExists.length == 0) {

        let dalLibraryItem: DalLibraryItem = {
          libraryItemBase: {
            id: libraryItem.id
          },
          description: libraryItem.description,
          // TODO: Complete with a copy of all the other properties
          name: libraryItem.name,
          libraryType: libraryItem.libraryType,
          contentType: libraryItem.contentType,
          isMandatory: libraryItem.isMandatory,
          limitsValidation: libraryItem.limitsValidation,
          startDate: libraryItem.startDate,
          endDate: libraryItem.endDate,
          order: libraryItem.order,
          itemVariables: libraryItem.itemVariables ? Array.from(libraryItem.itemVariables) : null,
          table: libraryItem.table ? Object.create(libraryItem.table) : null,
          dalTemplateLibraryItemBase: null,
          organizationDataView: libraryItem.organizationDataView ? Array.from(libraryItem.organizationDataView) : [],
          type: 'DalLibraryItem',
          status: 'Draft',
          code: libraryItem.code,
          clientId: null
        };
        this.dalContentConfiguration.libraryItems.push(dalLibraryItem);
      }

    });

    this.dalContentConfiguration.sectionSelected.subscribe((args: SectionSelectedEventArgs) => {

      let librarySection = args.librarySection;


      // Finds the parent element
      let sectionExist = this.dalContentConfiguration.sections.filter(x => x.sectionBase != null && x.sectionBase.id == librarySection.id);
      if (sectionExist.length == 0) {

        let dalSection: DalSection = {
          sectionBase: {
            id: librarySection.id,
            title: librarySection.title
          },
          title: librarySection.title,
          clientId: null
        };
        this.dalContentConfiguration.sections.push(dalSection);
      }
    });

    this.dalContentConfiguration.subSectionSelected.subscribe((args: SubSectionSelectedEventArgs) => {


      let librarySubSection = args.librarySubSection;
      let parentSection = args.librarySection;

      // TODO: Control that there is a Parent Section
      if (parentSection == null) {
        return;
      }


      // Finds the parent element
      let sectionExist = this.dalContentConfiguration.subSections.filter(x => x.subSectionBase != null && x.subSectionBase.id == librarySubSection.id);
      if (sectionExist.length == 0) {

        let dalSubSection: DalSubSection = {
          subSectionBase: {
            id: librarySubSection.id,
            title: librarySubSection.title
          },
          parentSection: {
            id: parentSection.id,
            title: parentSection.title
          },
          title: librarySubSection.title,
          clientId: null
        };
        this.dalContentConfiguration.subSections.push(dalSubSection);
      }


    });

    this.dalContentConfiguration.removeSelectedLibraryItem.subscribe((args: RemoveLibraryItemEventArgs) => {
      // TODO: Actually, the logic to remove an item is a little more complex. Involves to set the status of an item
      const index = this.dalContentConfiguration.libraryItems.indexOf(args.libraryItem, 0);
      if (index > -1) {
        this.dalContentConfiguration.libraryItems.splice(index, 1);
      }
    });

    this.dalContentConfiguration.removeSelectedSection.subscribe((args: RemoveSectionEventArgs) => {
      // TODO: Actually, the logic to remove an item is a little more complex. Involves to set the status of an item
      const index = this.dalContentConfiguration.sections.indexOf(args.librarySection, 0);
      if (index > -1) {
        this.dalContentConfiguration.sections.splice(index, 1);
      }
    });

    this.dalContentConfiguration.removeSelectedSubSection.subscribe((args: RemoveSubSectionEventArgs) => {
      // TODO: Actually, the logic to remove an item is a little more complex. Involves to set the status of an item
      const index = this.dalContentConfiguration.subSections.indexOf(args.librarySubSection, 0);
      if (index > -1) {
        this.dalContentConfiguration.subSections.splice(index, 1);
      }
    });



    this.generateData();

    this.testVariableInfills();
  }

  openReviewerGroup() {
    this.spinnerService.show();
    this._reviewerGroupService.GetReviewerGroups()
      .subscribe(data => {
        this.spinnerService.hide();
        if (data && data.length != 0) {
          const demoReviewerGroup = this._dialog.open(ModalReviewersGroupComponent, {
            data: {
              reviewersGroup: data
            }
          });
        }
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );


  }

  openAcceptanceChk() {

    const demoAcceptanceChk = this._dialog.open(ModalAcceptanceCheckboxComponent);

    demoAcceptanceChk.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result != undefined && result != null) {
        //this.isProcessing = true;    

        //let list: ReviewerGroup[] = result.selectedReviewedGroups;

        //this.submitSendForReview(data, list, continueWithWarning);
        alert(result.checked);
      }
    });
  }



  testVariableInfills() {
    let basicTextItem: ItemVariable = {
      variableCode: "T",
      variableType: "Basic",
      currentValue: {
        valueString: "Texto de prueba"
      },
      infillCode: "[BA:T1]",
      description: "Texto 1"
    };

    let basicTextNullItem: ItemVariable = {
      variableCode: "T",
      variableType: "Basic",
      currentValue: null,
      infillCode: "[BA:T1]",
      description: "Texto 1"
    };

    let basicNumberItem: ItemVariable = {
      variableCode: "#",
      variableType: "Basic",
      currentValue: {
        valueNumber: 100
      },
      infillCode: "[BA:#1]",
      description: "Numero 1"
    };

    let basicNumberNullItem: ItemVariable = {
      variableCode: "#",
      variableType: "Basic",
      currentValue: null,
      infillCode: "[BA:#1]",
      description: "Numero 1"
    };

    let basicPercentageItem: ItemVariable = {
      variableCode: "%",
      variableType: "Basic",
      currentValue: {
        valueNumber: 100
      },
      infillCode: "[BA:%1]",
      description: "Percentage 1"
    };

    let basicPercentageItemNull: ItemVariable = {
      variableCode: "%",
      variableType: "Basic",
      currentValue: null,
      infillCode: "[BA:%1]",
      description: "Percentage 1"
    };

    let basicAmountItem: ItemVariable = {
      variableCode: "FIA",
      variableType: "Basic",
      currentValue: {
        valueNumber: 100
      },
      infillCode: "[DS:FIA1]",
      description: "FIA 1"
    };

    let basicAmountItemNull: ItemVariable = {
      variableCode: "A",
      variableType: "Basic",
      currentValue: null,
      infillCode: "[BA:A1]",
      description: "Amount 1"
    };

    let datasetFIAItem: ItemVariable = {
      variableCode: "FIA",
      variableType: "Data set",
      currentValue: {
        valueNumber: 100,
        valueString: "value FIA",
        description: "desc FIA"
      },
      infillCode: "[DS:FIA1]",
      description: "FIA 1"
    };

    let datasetFIANull: ItemVariable = {
      variableCode: "FIA",
      variableType: "Data set",
      currentValue: null,
      infillCode: "[DS:FIA1]",
      description: "FIA 1"
    };

    let generalCOUItem: ItemVariable = {
      variableCode: "COU",
      variableType: "General Table",
      currentValue: {
        valueNumber: 100,
        valueString: "value COU",
        description: "desc COU"
      },
      infillCode: "[GT:COU1]",
      description: "COU 1"
    };

    let generalCOUNull: ItemVariable = {
      variableCode: "COU",
      variableType: "General Table",
      currentValue: null,
      infillCode: "[GT:COU1]",
      description: "COU 1"
    };

    let organizationDVSItem: ItemVariable = {
      variableCode: "DVS",
      variableType: "Organization",
      currentValue: {
        valueNumber: 100,
        valueString: "value DVS",
        description: "desc DVS"
      },
      infillCode: "[OR:DVS1]",
      description: "DVS 1"
    };

    let organizationDVSNull: ItemVariable = {
      variableCode: "DVS",
      variableType: "Organization",
      currentValue: null,
      infillCode: "[OR:DVS1]",
      description: "DVS 1"
    };


    this.basicText = this.dbv.getPreviewVariable(basicTextItem, this.dalContentConfiguration);
    this.basicTextNull = this.dbv.getPreviewVariable(basicTextNullItem, this.dalContentConfiguration);
    this.basicNumber = this.dbv.getPreviewVariable(basicNumberItem, this.dalContentConfiguration);
    this.basicNumberNull = this.dbv.getPreviewVariable(basicNumberNullItem, this.dalContentConfiguration);
    this.basicPercentage = this.dbv.getPreviewVariable(basicPercentageItem, this.dalContentConfiguration);
    this.basicPercentageNull = this.dbv.getPreviewVariable(basicPercentageItemNull, this.dalContentConfiguration);
    this.basicAmount = this.dbv.getPreviewVariable(basicAmountItem, this.dalContentConfiguration);
    this.basicAmountNull = this.dbv.getPreviewVariable(basicAmountItemNull, this.dalContentConfiguration);

    this.datasetFIA = this.ddv.getPreviewVariable(datasetFIAItem, this.dalContentConfiguration);
    this.datasetFIANull = this.ddv.getPreviewVariable(datasetFIANull, this.dalContentConfiguration);

    this.generalTableCOU = this.dgv.getPreviewVariable(generalCOUItem, this.dalContentConfiguration);
    this.generalTableCOUNull = this.dgv.getPreviewVariable(generalCOUNull, this.dalContentConfiguration);

    this.organizationDVS = this.dov.getPreviewVariable(organizationDVSItem, this.dalContentConfiguration);
    this.organizationDVSNull = this.dov.getPreviewVariable(organizationDVSNull, this.dalContentConfiguration);
  }

  ngOnDestroy() {
    // Add unsuscribes
  }

  generateData() {
    var subSections: LibrarySubSection[] = [
      {
        id: "{59b7ef6a-207a-4735-afd6-a514edfb68a4}-{1}",
        title: "SubSection APAC",
        parentSection: {
          id: "{a76716b8-69c6-441d-9f51-1becdecd99b5}-{1}",
          title: "Non insurance"
        },
        clientId: "",
      },
      {
        id: "SubSectionTest2",
        title: "SubSection Test 2",
        clientId: "",
        parentSection: {
          id: "SectionTest2",
          title: "Section Test 2"
        },
      },
      {
        id: "SubSectionTest3",
        title: "SubSection Test 3",
        clientId: "",
        parentSection: {
          id: "SectionTest2",
          title: "Section Test 2"
        },
      }
    ];

    var sections: LibrarySection[] = [{
      id: "{a76716b8-69c6-441d-9f51-1becdecd99b5}-{1}",
      title: "Non insurance",
      type: "LibrarySection",
      clientId: ""
    },
    {
      id: "{59b7ef6a-207a-4735-afd6-a514edfb68a4}-{1}",
      title: "SubSection APAC",
      type: "LibrarySection",
      clientId: ""
    }
    ];

    var libraryItemWithSubSection: DalLibraryItem = {
      clientId: null,
      id: "001AB",
      code: "1234AHFC",
      name: "General Principle",
      libraryType: {
        name: "Clause"
      },
      contentType: "TEXT",
      isMandatory: false,
      section: {
        id: sections[0].id,
        title: sections[0].title
      },
      subSection: {
        id: subSections[0].id,
        title: subSections[0].title,
      },
      limitsValidation: false,
      startDate: new Date("01/01/2020").toISOString(),
      endDate: new Date("01/01/2021").toISOString(),
      status: "Active",
      description: "The Autority Holder,<b><i> [DAU],</i></b> <br /> Accepts to manage the authority of <b>[BA:$1] </b> for the Class of Business [DS:COB1] in [GT:COU1] for <u><b>[OR:DIV1].</b></u>" +
        "<br/> some text some text some text <br /> <br/> Text test <br /><br /><br/> Extra large test for test the length into description in library items <br />",
      order: 2,
    };
    this.dalContentConfiguration.addLibraryItem(libraryItemWithSubSection, libraryItemWithSubSection.section, libraryItemWithSubSection.subSection, null, null);

    var libraryItemWithSection: DalLibraryItem = {
      clientId: null,
      id: "001AB",
      code: "1234AHFC",
      name: "General Principle",
      libraryType: {
        name: "Clause"
      },
      contentType: "TEXT",
      isMandatory: false,
      section: {
        id: sections[0].id,
        title: sections[0].title
      },
      limitsValidation: false,
      startDate: new Date("01/01/2020").toISOString(),
      endDate: new Date("01/01/2021").toISOString(),
      status: "Active",
      description: "The Autority Holder,<b><i> [DAU],</i></b> <br /> Accepts to manage the authority of <b>[BA:$1] </b> for the Class of Business [DS:COB1] in [GT:COU1] for <u><b>[OR:DIV1].</b></u>" +
        "<br/> some text some text some text <br /> <br/> Text test <br /><br /><br/> Extra large test for test the length into description in library items <br />",
      order: 2,
    };
    this.dalContentConfiguration.addLibraryItem(libraryItemWithSection, libraryItemWithSection.section, libraryItemWithSection.subSection, null, null);

    var libraryItem: DalLibraryItem = {
      clientId: null,
      id: "001AB",
      code: "1234TEST",
      name: "General Principle",
      libraryType: {
        name: "Clause"
      },
      contentType: "TEXT",
      isMandatory: true,
      limitsValidation: false,
      startDate: new Date("01/01/2020").toISOString(),
      endDate: new Date("01/01/2021").toISOString(),
      status: "Active",
      description: "The Autority Holder, [DAH],<b><i> [DAU],</i></b> <br /> Accepts to manage the authority of <b>[BA:$1] </b> for the Class of Business [DS:COB1] in [GT:COU1] for <u><b>[OR:DIV1].</b></u>" +
        "<br/> some text some text some text <br /> <br/> Text test <br /><br /><br/> text extra large for test the length into description in library items <br />",
      order: 1,
    };

    var libraryItemTable: DalLibraryItem = {
      clientId: null,
      id: "001AB_TABLE",
      code: "1234TEST",
      name: "General Principle",
      libraryType: {
        name: "Clause"
      },
      contentType: "TABLE",
      isMandatory: true,
      limitsValidation: false,
      startDate: new Date("01/01/2020").toISOString(),
      endDate: new Date("01/01/2021").toISOString(),
      status: "Active",
      table: {
        columnsLength: 3,
        rows: [
          {
            cells: [
              {
                text: 'Classes of Business'
              },
              {
                text: 'Ammount'
              },
              {
                text: `Comments (... and a long text to show the expansion of a row ... <br />
                    and a long text to show the expansion of a row... <br />
                    and a long text to show the expansion of a row <br />
                  ... and a long text to show the expansion of a row... <br />
                  and a long text to show the expansion of a row... and a long text to show the expansion of a row
                  ... and a long text to show the expansion of a row)`
              }
            ],
            order: 1,
            id: '123'
          },
          {
            cells: [
              {
                text: '<span class="infill-change-value">Select class of business</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">Insert Text Here</span> Other Text'
              }
            ],
            order: 1,
            id: '123'
          },
          {
            cells: [
              {
                text: '<span class="infill-change-value">Select class of business</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">Insert Text Here</span> Other Text'
              }
            ],
            order: 1,
            id: '123'
          }, {
            cells: [
              {
                text: '<span class="infill-change-value">Select class of business</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">Insert Text Here</span> Other Text'
              }
            ],
            order: 1,
            id: '123'
          },
          {
            cells: [
              {
                text: '<span class="infill-change-value">Select class of business</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">00.00 (Currency)</span> Other Text'
              },
              {
                text: '<span class="infill-change-value">Insert Text Here</span> Other Text'
              }
            ],
            order: 1,
            id: '123'
          }
        ]
      },
      order: 1,
    };


    this.dalContentConfiguration.addLibraryItem(libraryItem, libraryItem.section, libraryItem.subSection, null, null);
    this.dalContentConfiguration.addLibraryItem(libraryItemTable, libraryItem.section, libraryItem.subSection, null, null);
    sections.forEach(x => {
      this.dalContentConfiguration.addSection(x, null, null);
    }
    );
    subSections.forEach(x => {
      this.dalContentConfiguration.addSubSection(x, { id: libraryItemWithSubSection.section.id, title: libraryItemWithSubSection.section.title }, null, null);
    }
    );
  }

  showAddLibraryItem() {
    const dialogRef = this._dialog.open(AddLibraryItemModalDialogComponent, {
      width: '80%',
      data: {
        dalContentConfiguration: this.dalContentConfiguration,
        section: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result != null) {
        var results = result as LibraryItem[];
        results.forEach(x => {
          this.dalContentConfiguration.addLibraryItem(x, x.section, x.subSection, null, null);
        }
        );
      }
    });
  }

  showAddLibrarySection() {
    const dialogRef = this._dialog.open(AddLibrarySectionModalDialogComponent, {
      width: '80%',
      data: {
        dalContentConfiguration: this.dalContentConfiguration,
        section: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result != null) {
        var results = result as LibraryItem[];
        results.forEach(x => {
          this.dalContentConfiguration.addSection(x, null, null);
        }
        );
      }
    });
  }

}
