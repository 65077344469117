/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Currency } from '../models/currency';
import { PagedCollectionResponseCurrency } from '../models/paged-collection-response-currency';
@Injectable({
  providedIn: 'root',
})
class CurrencyService extends __BaseService {
  static readonly InsertCurrecyPath = '/api/Currency/InsertCurrecy';
  static readonly UpdateCurrencyPath = '/api/Currency/UpdateCurrency';
  static readonly DisablePath = '/api/Currency/Disable';
  static readonly EnablePath = '/api/Currency/Enable';
  static readonly GetCurrenciesPath = '/api/Currency/GetCurrencies';
  static readonly GetCurrenciesExchangesPath = '/api/Currency/GetCurrenciesExchanges';
  static readonly GetActiveCurrenciesPath = '/api/Currency/GetActiveCurrencies';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param currency undefined
   * @return Success
   */
  InsertCurrecyResponse(currency?: Currency): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = currency;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Currency/InsertCurrecy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param currency undefined
   * @return Success
   */
  InsertCurrecy(currency?: Currency): __Observable<boolean> {
    return this.InsertCurrecyResponse(currency).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param currency undefined
   * @return Success
   */
  UpdateCurrencyResponse(currency?: Currency): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = currency;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Currency/UpdateCurrency`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param currency undefined
   * @return Success
   */
  UpdateCurrency(currency?: Currency): __Observable<boolean> {
    return this.UpdateCurrencyResponse(currency).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param currencyId undefined
   * @return Success
   */
  DisableResponse(currencyId?: string): __Observable<__StrictHttpResponse<Currency>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (currencyId != null) __params = __params.set('currencyId', currencyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Currency/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Currency>;
      })
    );
  }
  /**
   * @param currencyId undefined
   * @return Success
   */
  Disable(currencyId?: string): __Observable<Currency> {
    return this.DisableResponse(currencyId).pipe(
      __map(_r => _r.body as Currency)
    );
  }

  /**
   * @param currencyId undefined
   * @return Success
   */
  EnableResponse(currencyId?: string): __Observable<__StrictHttpResponse<Currency>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (currencyId != null) __params = __params.set('currencyId', currencyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Currency/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Currency>;
      })
    );
  }
  /**
   * @param currencyId undefined
   * @return Success
   */
  Enable(currencyId?: string): __Observable<Currency> {
    return this.EnableResponse(currencyId).pipe(
      __map(_r => _r.body as Currency)
    );
  }

  /**
   * @return Success
   */
  GetCurrenciesResponse(): __Observable<__StrictHttpResponse<PagedCollectionResponseCurrency>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Currency/GetCurrencies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseCurrency>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCurrencies(): __Observable<PagedCollectionResponseCurrency> {
    return this.GetCurrenciesResponse().pipe(
      __map(_r => _r.body as PagedCollectionResponseCurrency)
    );
  }

  /**
   * @return Success
   */
  GetCurrenciesExchangesResponse(): __Observable<__StrictHttpResponse<PagedCollectionResponseCurrency>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Currency/GetCurrenciesExchanges`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseCurrency>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCurrenciesExchanges(): __Observable<PagedCollectionResponseCurrency> {
    return this.GetCurrenciesExchangesResponse().pipe(
      __map(_r => _r.body as PagedCollectionResponseCurrency)
    );
  }

  /**
   * @return Success
   */
  GetActiveCurrenciesResponse(): __Observable<__StrictHttpResponse<PagedCollectionResponseCurrency>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Currency/GetActiveCurrencies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseCurrency>;
      })
    );
  }
  /**
   * @return Success
   */
  GetActiveCurrencies(): __Observable<PagedCollectionResponseCurrency> {
    return this.GetActiveCurrenciesResponse().pipe(
      __map(_r => _r.body as PagedCollectionResponseCurrency)
    );
  }
}

module CurrencyService {
}

export { CurrencyService }
