
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-modal-dialog-managing-authority',
  templateUrl: './modal-dialog-managing-authority.component.html',
  styleUrls: ['./modal-dialog-managing-authority.component.scss']
})
export class ModalDialogManagingAuthorityComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalDialogManagingAuthorityComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  close(): void {
    this.dialogRef.close();
  }

  getOpenedMenu( isOpen :boolean){
    console.log('getOpenedMenu: '+isOpen)
    this.close()
  }

  ngOnInit() {
  }

}
