import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from './report.component';
import { GeneralExecutionComponent } from './general-execution/general-execution.component';
import { HistoryComponent } from './history/history.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { ExecutionReportComponent } from './execution-report/execution-report.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { ScheduledReportComponent } from './scheduled-report/scheduled-report.component';

const routes: Routes = [
    {
      path: '',
      canActivate: [AuthenticationGuard],
      component: ReportComponent,
      children: [
        {
          path:'execution',
          canActivate: [AuthenticationGuard],
          component: GeneralExecutionComponent
        },
        {
          path:'history',
          canActivate: [AuthenticationGuard],
          component: HistoryComponent
        },
        {
          path:'execution/executionReport',
          canActivate: [AuthenticationGuard],
          component: ExecutionReportComponent
        },
        {
          path:'hierarchy',
          canActivate: [AuthenticationGuard],
          component: HierarchyComponent //
        },
        {
          path:'schedule',
          canActivate: [AuthenticationGuard],
          component: ScheduledReportComponent
        }
      ]
    },
    ];
    @NgModule({
        imports: [RouterModule.forChild(routes)],
        exports: [RouterModule]
      })
export class ReportRoutingModule { }

