/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CollectionResultLibraryType } from '../models/collection-result-library-type';
import { EntityResultLibraryType } from '../models/entity-result-library-type';
import { LibraryType } from '../models/library-type';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class LibraryTypeService extends __BaseService {
  static readonly FindPath = '/api/LibraryType/Find';
  static readonly InsertPath = '/api/LibraryType';
  static readonly UpdatePath = '/api/LibraryType';
  static readonly DeletePath = '/api/LibraryType';
  static readonly EnablePath = '/api/LibraryType/Enable';
  static readonly DisablePath = '/api/LibraryType/Disable';
  static readonly GetAllPath = '/api/LibraryType/GetAll';
  static readonly GetByIdPath = '/api/LibraryType/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `LibraryTypeService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindResponse(params: LibraryTypeService.FindParams): __Observable<__StrictHttpResponse<CollectionResultLibraryType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LibraryType/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultLibraryType>;
      })
    );
  }
  /**
   * @param params The `LibraryTypeService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  Find(params: LibraryTypeService.FindParams): __Observable<CollectionResultLibraryType> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultLibraryType)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: LibraryType): __Observable<__StrictHttpResponse<EntityResultLibraryType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/LibraryType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultLibraryType>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: LibraryType): __Observable<EntityResultLibraryType> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultLibraryType)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: LibraryType): __Observable<__StrictHttpResponse<EntityResultLibraryType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/LibraryType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultLibraryType>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: LibraryType): __Observable<EntityResultLibraryType> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultLibraryType)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/LibraryType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/LibraryType/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/LibraryType/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultLibraryType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LibraryType/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultLibraryType>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultLibraryType> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultLibraryType)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultLibraryType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LibraryType/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultLibraryType>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultLibraryType> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultLibraryType)
    );
  }
}

module LibraryTypeService {

  /**
   * Parameters for Find
   */
  export interface FindParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    Name?: string;
    IsAvailable?: boolean;
    ContinuationToken?: string;
  }
}

export { LibraryTypeService }
