import { ProcessInfillVariable } from "./process-infill-variable";
import { Injectable } from "@angular/core";
import { DalLibraryItem, DalTemplateLibraryItem, ItemVariable, ItemVariableValueView } from "@app/api/models";
import { LibrarySubSectionService } from "@app/api/services";
import { environment } from "@env/environment";
import { DalEndDateInformationalVariable } from "./dal-end-date-informational-variable";
import { DalAuthorityHolderInformationalVariable } from "./dal-authority-holder-informational-variable";
import { DalDelegatorInformationalVariable } from "./dal-delegator-informational-variable";
import { DalStateInformationalVariable } from "./dal-state-informational-variable";
import { DalVersionInformationalVariable } from "./dal-version-informational-variable";
import { DalReviewDateInformationalVariable } from "./dal-review-date-informational-variable";
import { DalStartDateInformationalVariable } from "./dal-start-date-informational-variable";
import { ProcessBasicInfillVariable } from "./process-basic-infill-variable";
import { ProcessGeneralInfillVariable } from "./process-general-infill-variable";
import { ProcessDataSetInfillVariable } from "./process-dataset-infill-variable";
import { ProcessOrganizationInfillVariable } from "./process-organization-infill-variable";
import { DalComposerInformationalVariable } from "./dal-composer-informational-variable";
import { DalCodeInformationalVariable } from "./dal-code-informational-variable";
import { DalDivisionsInformationalVariable } from "./dal-divisions-informational-variable";
import { DalFunctionsInformationalVariable } from "./dal-functions-informational-variable";
import { DalAcknowledgementDateInformationalVariable } from "./dal-acknowledgement-informational-variable";
import { AHBroadbandLevelInformationalVariable } from "./ah-broadband-level-informational-variable";
import { AHCompanyInformationalVariable } from "./ah-company-informational-variable";
import { AHCostCentreIdInformationalVariable } from "./ah-cost-centre-id-informational-variable";
import { AHCostCentreNameInformationalVariable } from "./ah-cost-centre-name-informational-variable";
import { AHBCountryInformationalVariable } from "./ah-country-informational-variable";
import { AHDepartamentInformationalVariable } from "./ah-departament-informational-variable";
import { AHDivisionInformationalVariable } from "./ah-division-informational-variable";
import { AHEmailInformationalVariable } from "./ah-email-informational-variable";
import { AHEndDateInformationalVariable } from "./ah-end-date-informational-variable";
import { AHJobTitleInformationalVariable } from "./ah-job-title-informational-variable";
import { AHLocationInformationalVariable } from "./ah-location-informational-variable";
import { AHManagerInformationalVariable } from "./ah-manager-informational-variable";
import { AHOfficeAddressInformationalVariable } from "./ah-office-address-informational-variable";
import { AHPhoneNumberInformationalVariable } from "./ah-phone-number-informational-variable";
import { AHPreferredNameInformationalVariable } from "./ah-preferred-name-informational-variable";
import { AHPreviousFirstNameInformationalVariable } from "./ah-previous-firstname-informational-variable";
import { AHPreviousLastNameInformationalVariable } from "./ah-previous-lastname-informational-variable";
import { AHStartDateInformationalVariable } from "./ah-start-date-informational-variable";
import { AHTeamInformationalVariable } from "./ah-team-informational-variable";
import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../library-item-processing/dal-content-configuration";
import { AHLeaveStartDateInformationalVariable } from "./ah-leave-start-date-informational-variable";
import { AHLeaveEndDateInformationalVariable } from "./ah-leave-end-date-informational-variable";
import { AHEmbMemberInformationalVariable } from "./ah-emb-member-informational-variable";

@Injectable({
    providedIn: 'root',
})

export class ProcessLIInfillVariable extends ProcessInfillVariable {
    constructor(
        private _subSectionService: LibrarySubSectionService,

        dah: DalAuthorityHolderInformationalVariable,
        dde: DalDelegatorInformationalVariable,
        dsi: DalStateInformationalVariable,
        dvi: DalVersionInformationalVariable,
        drd: DalReviewDateInformationalVariable,
        dsd: DalStartDateInformationalVariable,
        ded: DalEndDateInformationalVariable,
        dbv: ProcessBasicInfillVariable,
        dgv: ProcessGeneralInfillVariable,
        ddv: ProcessDataSetInfillVariable,
        dov: ProcessOrganizationInfillVariable,

        //DAL
        dcp: DalComposerInformationalVariable,                 // Composer
        dco: DalCodeInformationalVariable,                     // DAL Code
        ddi: DalDivisionsInformationalVariable,                // Division(s)
        dfu: DalFunctionsInformationalVariable,                // Function(s)
        dac: DalAcknowledgementDateInformationalVariable,      // Acknowledgement Date

        //AH
        abd: AHBroadbandLevelInformationalVariable,            // Broadband Level [ABD]    
        aco: AHCompanyInformationalVariable,                   // Company [ACO]           
        aci: AHCostCentreIdInformationalVariable,              // Cost Centre ID [ACI]     
        acc: AHCostCentreNameInformationalVariable,            // Cost Centre Name [ACC]  
        acn: AHBCountryInformationalVariable,                  // Country [ACN]            
        ade: AHDepartamentInformationalVariable,               // Department [ADE]        
        adi: AHDivisionInformationalVariable,                  // Division [ADI]           
        aea: AHEmailInformationalVariable,                     // Email Address [AEA]       
        aem: AHEmbMemberInformationalVariable,                  // EMB Member [AEM]           
        aed: AHEndDateInformationalVariable,                   // End Date [AED]            
        ajt: AHJobTitleInformationalVariable,                  // Job Title [AJT]          
        alo: AHLocationInformationalVariable,                  // Location [ALO]           
        ama: AHManagerInformationalVariable,                   // Manager [AMA]             
        aoa: AHOfficeAddressInformationalVariable,             // Office Address [AOA]      
        apn: AHPhoneNumberInformationalVariable,               // Phone Number [APN]        
        apr: AHPreferredNameInformationalVariable,             // Preferred Name [APR]      
        apf: AHPreviousFirstNameInformationalVariable,         // Previous First Name [APF] 
        apl: AHPreviousLastNameInformationalVariable,          // Previous Last Name [APL]  
        asd: AHStartDateInformationalVariable,                 // Start Date [ASD]          
        ate: AHTeamInformationalVariable,                      // Team [ATE]  
        als: AHLeaveStartDateInformationalVariable,            // Employment Leave Start Date [ALS]
        ale: AHLeaveEndDateInformationalVariable               // Employment Leave End Date [ALE]
    ) {
        super();
        this._subSectionService.rootUrl = environment.ApiUrl;
        this.informationInfillVariables = [
            dah, // Authority Holder
            dde, // Delegator,
            dsi, // Dal State
            dvi, // Dal Version
            drd, // Review Date
            dsd, // Start Date
            ded, // End Date
            // DAL
            dcp,
            dco,
            ddi,
            dfu,
            dac,
            // AH
            abd,
            aco,
            aci,
            acc,
            acn,
            ade,
            adi,
            aea,
            aem,
            aed,
            ajt,
            alo,
            ama,
            aoa,
            apn,
            apr,
            apf,
            apl,
            asd,
            ate,
            als,
            ale
        ];
        this.processInfillVariables = [
            dbv, // Basic (Amount, Number, Percentage, Text)
            dgv,
            ddv,
            dov
        ];
    }
    referenceLI: Array<DalLibraryItem | DalTemplateLibraryItem>;
    processInfillVariables: Array<ProcessInfillVariable> = [];
    control: boolean = false;
    // @Input() currentLibraryItem: DalTemplateLibraryItem | DalLibraryItem | LibraryItem;
    informationInfillVariables: Array<ProcessInformationalVariable> = [];


    applies(itemVariable: ItemVariable): boolean {
        if (itemVariable) {
            return itemVariable.variableType == "Library Item";
        }
        return false;
    }
    /**
    * Gets a text information.
    * @param variable Item Variable
    * @param config 
    */
    public getTextInformation(variable: ItemVariable, config: DalContentConfiguration): string {

        if (variable) {
            if (variable.currentValue != null) {
                if (config.isReaderMode) {
                    return this.processTextPreview(config, variable.currentValue);
                } else {
                    return variable.currentValue.description;
                }

            }
            else {
                if( !config.isReaderMode)
                { 
                    return 'Select Library Item';
                }      
                else
                {
                    return "";
                }
               
            }
        }
    }

    processTextPreview(dalConfig: DalContentConfiguration, itemVariableValue: ItemVariableValueView): string {
        let prevText: string = itemVariableValue.valueString;
        if (!prevText) {
            prevText = '';
        }

        const libraryItem = dalConfig.libraryItems.find(x => x.id == itemVariableValue.valueId || x.libraryItemBase.id ==itemVariableValue.valueId);

        if (libraryItem && libraryItem.itemVariables != null && libraryItem.itemVariables.length > 0) {
            //Recorrer las variables
            libraryItem.itemVariables.forEach(itemVariable => {

                for (let index = 0; index < this.processInfillVariables.length; index++) {
                    const infillVariable = this.processInfillVariables[index];
                    if (infillVariable.applies(itemVariable)) {
                        let value: string = infillVariable.getPreviewVariable(itemVariable, dalConfig);
                        prevText = prevText.replace(itemVariable.infillCode, value);
                        break;
                    }
                }
            });
        }
        // Replaces all Informational Variables
        this.informationInfillVariables.forEach(currentInformationVariable => {
            let value: string = currentInformationVariable.getPreviewVariable(null, dalConfig);
            prevText = this.replaceAll(prevText, currentInformationVariable.infillCode, value);
        });

        return prevText;
    }

    replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }
}