import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';

@Component({
  selector: 'app-modal-bulk-upload',
  templateUrl: './modal-bulk-upload.html',
  styleUrls: ['./modal-bulk-upload.scss']
})
export class ModalBulkUploadComponent implements OnInit {
  bulkUpload: number;
  bulkUploadType: string;


  constructor(
    public dialogRef: MatDialogRef<ModalBulkUploadComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log('modalBulk')
  }
  
  accept() {
    if(this.bulkUpload){
      //send logic to accept button in dal
      this.dialogRef.close({number: this.bulkUpload, contentType: this.bulkUploadType});
    } 
  }

    // snack bar to show message
    showSnackBar(msg: string): void {
      const message = msg;
      const action = '';
      this._snackBar.open(message, action, {
        duration: 3000
      });
    }
 
}