import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DDI] => Dal Division(s)
 */
@Injectable({
  providedIn: 'root',
})
export class DalDivisionsInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[DDI]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {
      
      if (config.divisions){
        return this.getDivisionDescriptions(config.divisions)
      }

      return this.infillCode;
    }


    getDivisionDescriptions(organizationDatas: any) {
  
      if(!organizationDatas){
        return this.infillCode;
      } 

      var _divisionNames = organizationDatas.filter(data => data.orgGroupType === "DIVISIONS")
        .map(x => x.orgGroupName);
  
      if(_divisionNames != null && _divisionNames.length > 0) {
        return _divisionNames.join(", ");
      }
  
      return "N/A";
    }

  }