/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Permission } from '../models/permission';
import { UserView } from '../models/user-view';
import { PermissionRole } from '../models/permission-role';
import { User } from '../models/user';
import { UserRoleView } from '../models/user-role-view';
import { HcmEmployeeDetails } from '../models/hcm-employee-details';
import { UserInfo } from '../models/user-info';
import { UserExternalIDView } from '../models/user-external-idview';
import { OrganizationDataView } from '../models/organization-data-view';
import { ResultGetDbUsers } from '../models/result-get-db-users';
import { DalActivities } from '../models/dal-activities';
import { FilterData } from '../models/filter-data';
import { UpdateManagedUserParams } from '../models/update-managed-user-params';
import { AssignSuperUserResponse } from '../models/assign-super-user-response';
import { AssignExternalAuthorityResponse } from '../models/assign-external-authority-response';
import { CollectionResultUserDataView } from '../models/collection-result-user-data-view';
import { VoidResult } from '../models/void-result';
import { EntityResultUser } from '../models/entity-result-user';
import { UserDataView } from '../models/user-data-view';
import { UsersWithExternalSearchCriteria } from '../models/users-with-external-search-criteria';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly GetPermissionsByUserPath = '/api/Users/GetPermissionsByUser';
  static readonly GetPermissionsWithRolesByUserPath = '/api/Users/GetPermissionsWithRolesByUser';
  static readonly UpdateOtherRolesPath = '/api/Users/UpdateOtherRoles/{id}';
  static readonly UpdateHcmEmployeeDetailsPath = '/api/Users/UpdateHcmEmployeeDetails';
  static readonly UpdateHcmEmployeeDetailsWorkdayPath = '/api/Users/UpdateHcmEmployeeDetailsWorkday';
  static readonly AddHcmEmployeeDetailsPath = '/api/Users/AddHcmEmployeeDetails';
  static readonly GetHcmEmployeeDetailsPath = '/api/Users/GetHcmEmployeeDetails/{id}';
  static readonly GetHcmEmployeeDetailsByEmailPath = '/api/Users/GetHcmEmployeeDetailsByEmail/{email}';
  static readonly GetHcmEmployeeDetailsByExternalIdPath = '/api/Users/GetHcmEmployeeDetailsByExternalId/{externalId}';
  static readonly GetHcmEmployeeDetailsByEmailAndExternalIdPath = '/api/Users/GetHcmEmployeeDetailsByEmailAndExternalId';
  static readonly GetDivisionsByCurrentUserPath = '/api/Users/GetDivisionsByCurrentUser';
  static readonly GetFunctionsByCurrentUserPath = '/api/Users/GetFunctionssByCurrentUser';
  static readonly GetPath = '/api/Users';
  static readonly PostPath = '/api/Users';
  static readonly GetPaginatedPath = '/api/Users/getPaginated';
  static readonly GetDbUsersbyNamePath = '/api/Users/usersListbyName';
  static readonly GetByIdPath = '/api/Users/{id}';
  static readonly PutPath = '/api/Users/{id}';
  static readonly DeletePath = '/api/Users/{id}';
  static readonly GetByEmailPath = '/api/Users/GetByEmail/{email}';
  static readonly GetUserNameByEmailPath = '/api/Users/GetUserNameByEmail/{email}';
  static readonly GetUserRolesByEmailandDalIdPath = '/api/Users/GetUserRolesByEmailandDalId/{email}/{dalId}';
  static readonly GetAllowedActivitiesPath = '/api/Users/GetAllowedActivities/{dalId}/{email}';
  static readonly GetDelegatorDataPath = '/api/Users/getDelegatorData';
  static readonly GetEnabledGroupTypePath = '/api/Users/getEnableGroupType';
  static readonly GetDbUsersPath = '/api/Users/usersList';
  static readonly PutAuthorityAdministratorPath = '/api/Users/PutAuthorityAdministrator';
  static readonly UpdateManagedUserPath = '/api/Users/UpdateManagedUser';
  static readonly DeleteAuthorityAdministratorPath = '/api/Users/DeleteAuthorityAdministrator';
  static readonly AssignSuperUserPath = '/api/Users/AssignSuperUser';
  static readonly AssignExternalAuthorityPath = '/api/Users/AssignExternalAuthority';
  static readonly GetUsersWithExternalPath = '/api/Users/GetUsersWithExternal';
  static readonly EnableAsyncPath = '/api/Users/EnableAsync';
  static readonly DisableAsyncPath = '/api/Users/DisableAsync';
  static readonly UserEnableAsyncPath = '/api/Users/UserExistAndEnableAsync';
  static readonly AcceptTermsPath = '/api/Users/AcceptTerms';
  static readonly UserAcceptTermsPath = '/api/Users/UserAcceptTerms';
  static readonly GetAllUsersDataPath = '/api/Users/GetAllUsersData';
  static readonly EnableEmailNotificationsPath = '/api/Users/EnableEmailNotifications';
  static readonly DisableEmailNotificationsPath = '/api/Users/DisableEmailNotifications';
  static readonly UserSynchronizedPath = '/api/Users/UserSynchronized';
  static readonly UpdateActiveDirectoryObjectIdentifierPath = '/api/Users/UpdateActiveDirectoryObjectIdentifier';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param user undefined
   * @return Success
   */
  GetPermissionsByUserResponse(user?: UserView): __Observable<__StrictHttpResponse<Array<Permission>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/GetPermissionsByUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Permission>>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  GetPermissionsByUser(user?: UserView): __Observable<Array<Permission>> {
    return this.GetPermissionsByUserResponse(user).pipe(
      __map(_r => _r.body as Array<Permission>)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  GetPermissionsWithRolesByUserResponse(user?: UserView): __Observable<__StrictHttpResponse<Array<PermissionRole>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/GetPermissionsWithRolesByUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PermissionRole>>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  GetPermissionsWithRolesByUser(user?: UserView): __Observable<Array<PermissionRole>> {
    return this.GetPermissionsWithRolesByUserResponse(user).pipe(
      __map(_r => _r.body as Array<PermissionRole>)
    );
  }

  /**
   * @param params The `UsersService.UpdateOtherRolesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `roles`:
   *
   * @return Success
   */
  UpdateOtherRolesResponse(params: UsersService.UpdateOtherRolesParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.roles;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/UpdateOtherRoles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `UsersService.UpdateOtherRolesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `roles`:
   *
   * @return Success
   */
  UpdateOtherRoles(params: UsersService.UpdateOtherRolesParams): __Observable<User> {
    return this.UpdateOtherRolesResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param hcmEmployee undefined
   * @return Success
   */
  UpdateHcmEmployeeDetailsResponse(hcmEmployee?: HcmEmployeeDetails): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = hcmEmployee;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/UpdateHcmEmployeeDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param hcmEmployee undefined
   * @return Success
   */
  UpdateHcmEmployeeDetails(hcmEmployee?: HcmEmployeeDetails): __Observable<HcmEmployeeDetails> {
    return this.UpdateHcmEmployeeDetailsResponse(hcmEmployee).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

  /**
   * @param workdayInput undefined
   * @return Success
   */
  UpdateHcmEmployeeDetailsWorkdayResponse(workdayInput?: UserInfo): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = workdayInput;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/UpdateHcmEmployeeDetailsWorkday`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param workdayInput undefined
   * @return Success
   */
  UpdateHcmEmployeeDetailsWorkday(workdayInput?: UserInfo): __Observable<HcmEmployeeDetails> {
    return this.UpdateHcmEmployeeDetailsWorkdayResponse(workdayInput).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

  /**
   * @param hcmEmployee undefined
   * @return Success
   */
  AddHcmEmployeeDetailsResponse(hcmEmployee?: HcmEmployeeDetails): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = hcmEmployee;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/AddHcmEmployeeDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param hcmEmployee undefined
   * @return Success
   */
  AddHcmEmployeeDetails(hcmEmployee?: HcmEmployeeDetails): __Observable<HcmEmployeeDetails> {
    return this.AddHcmEmployeeDetailsResponse(hcmEmployee).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetHcmEmployeeDetailsResponse(id: string): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetHcmEmployeeDetails/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetHcmEmployeeDetails(id: string): __Observable<HcmEmployeeDetails> {
    return this.GetHcmEmployeeDetailsResponse(id).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

  /**
   * @param email undefined
   * @return Success
   */
  GetHcmEmployeeDetailsByEmailResponse(email: string): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetHcmEmployeeDetailsByEmail/${email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param email undefined
   * @return Success
   */
  GetHcmEmployeeDetailsByEmail(email: string): __Observable<HcmEmployeeDetails> {
    return this.GetHcmEmployeeDetailsByEmailResponse(email).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

  /**
   * @param externalId undefined
   * @return Success
   */
  GetHcmEmployeeDetailsByExternalIdResponse(externalId: string): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetHcmEmployeeDetailsByExternalId/${externalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param externalId undefined
   * @return Success
   */
  GetHcmEmployeeDetailsByExternalId(externalId: string): __Observable<HcmEmployeeDetails> {
    return this.GetHcmEmployeeDetailsByExternalIdResponse(externalId).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

  /**
   * @param userExternalId undefined
   * @return Success
   */
  GetHcmEmployeeDetailsByEmailAndExternalIdResponse(userExternalId?: UserExternalIDView): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userExternalId;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/GetHcmEmployeeDetailsByEmailAndExternalId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param userExternalId undefined
   * @return Success
   */
  GetHcmEmployeeDetailsByEmailAndExternalId(userExternalId?: UserExternalIDView): __Observable<HcmEmployeeDetails> {
    return this.GetHcmEmployeeDetailsByEmailAndExternalIdResponse(userExternalId).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

  /**
   * @return Success
   */
  GetDivisionsByCurrentUserResponse(): __Observable<__StrictHttpResponse<Array<OrganizationDataView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetDivisionsByCurrentUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationDataView>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetDivisionsByCurrentUser(): __Observable<Array<OrganizationDataView>> {
    return this.GetDivisionsByCurrentUserResponse().pipe(
      __map(_r => _r.body as Array<OrganizationDataView>)
    );
  }

  /**
   * @return Success
   */
  GetFunctionsByCurrentUserResponse(): __Observable<__StrictHttpResponse<Array<OrganizationDataView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetFunctionssByCurrentUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationDataView>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetFunctionsByCurrentUser(): __Observable<Array<OrganizationDataView>> {
    return this.GetFunctionsByCurrentUserResponse().pipe(
      __map(_r => _r.body as Array<OrganizationDataView>)
    );
  }

  /**
   * @param params The `UsersService.GetParams` containing the following parameters:
   *
   * - `SearchUser`:
   *
   * - `SearchText`:
   *
   * - `SearchPeople`:
   *
   * - `SearchEmail`:
   *
   * - `RetrieveDelegatorTreeForEmail`:
   *
   * - `DelegatorSearch`:
   *
   * - `DelegateParentEmail`:
   *
   * - `AdministratorEmail`:
   *
   * @return Success
   */
  GetResponse(params: UsersService.GetParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SearchUser != null) __params = __params.set('SearchUser', params.SearchUser.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.SearchPeople != null) __params = __params.set('SearchPeople', params.SearchPeople.toString());
    if (params.SearchEmail != null) __params = __params.set('SearchEmail', params.SearchEmail.toString());
    if (params.RetrieveDelegatorTreeForEmail != null) __params = __params.set('RetrieveDelegatorTreeForEmail', params.RetrieveDelegatorTreeForEmail.toString());
    if (params.DelegatorSearch != null) __params = __params.set('DelegatorSearch', params.DelegatorSearch.toString());
    if (params.DelegateParentEmail != null) __params = __params.set('DelegateParentEmail', params.DelegateParentEmail.toString());
    if (params.AdministratorEmail != null) __params = __params.set('AdministratorEmail', params.AdministratorEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetParams` containing the following parameters:
   *
   * - `SearchUser`:
   *
   * - `SearchText`:
   *
   * - `SearchPeople`:
   *
   * - `SearchEmail`:
   *
   * - `RetrieveDelegatorTreeForEmail`:
   *
   * - `DelegatorSearch`:
   *
   * - `DelegateParentEmail`:
   *
   * - `AdministratorEmail`:
   *
   * @return Success
   */
  Get(params: UsersService.GetParams): __Observable<Array<User>> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  PostResponse(user?: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  Post(user?: User): __Observable<User> {
    return this.PostResponse(user).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `UsersService.GetPaginatedParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `SearchUser`:
   *
   * - `SearchText`:
   *
   * - `SearchPeople`:
   *
   * - `SearchEmail`:
   *
   * - `RetrieveDelegatorTreeForEmail`:
   *
   * - `DelegatorSearch`:
   *
   * - `DelegateParentEmail`:
   *
   * - `AdministratorEmail`:
   *
   * @return Success
   */
  GetPaginatedResponse(params: UsersService.GetPaginatedParams): __Observable<__StrictHttpResponse<ResultGetDbUsers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.SearchUser != null) __params = __params.set('SearchUser', params.SearchUser.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.SearchPeople != null) __params = __params.set('SearchPeople', params.SearchPeople.toString());
    if (params.SearchEmail != null) __params = __params.set('SearchEmail', params.SearchEmail.toString());
    if (params.RetrieveDelegatorTreeForEmail != null) __params = __params.set('RetrieveDelegatorTreeForEmail', params.RetrieveDelegatorTreeForEmail.toString());
    if (params.DelegatorSearch != null) __params = __params.set('DelegatorSearch', params.DelegatorSearch.toString());
    if (params.DelegateParentEmail != null) __params = __params.set('DelegateParentEmail', params.DelegateParentEmail.toString());
    if (params.AdministratorEmail != null) __params = __params.set('AdministratorEmail', params.AdministratorEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/getPaginated`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResultGetDbUsers>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetPaginatedParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `SearchUser`:
   *
   * - `SearchText`:
   *
   * - `SearchPeople`:
   *
   * - `SearchEmail`:
   *
   * - `RetrieveDelegatorTreeForEmail`:
   *
   * - `DelegatorSearch`:
   *
   * - `DelegateParentEmail`:
   *
   * - `AdministratorEmail`:
   *
   * @return Success
   */
  GetPaginated(params: UsersService.GetPaginatedParams): __Observable<ResultGetDbUsers> {
    return this.GetPaginatedResponse(params).pipe(
      __map(_r => _r.body as ResultGetDbUsers)
    );
  }

  /**
   * @param params The `UsersService.GetDbUsersbyNameParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `Status`:
   *
   * - `Role`:
   *
   * - `ReviewerGroup`:
   *
   * - `LastName`:
   *
   * - `FirstName`:
   *
   * - `EmployeeID`:
   *
   * - `Email`:
   *
   * @return Success
   */
  GetDbUsersbyNameResponse(params: UsersService.GetDbUsersbyNameParams): __Observable<__StrictHttpResponse<ResultGetDbUsers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Role != null) __params = __params.set('Role', params.Role.toString());
    if (params.ReviewerGroup != null) __params = __params.set('ReviewerGroup', params.ReviewerGroup.toString());
    if (params.LastName != null) __params = __params.set('LastName', params.LastName.toString());
    if (params.FirstName != null) __params = __params.set('FirstName', params.FirstName.toString());
    if (params.EmployeeID != null) __params = __params.set('EmployeeID', params.EmployeeID.toString());
    if (params.Email != null) __params = __params.set('Email', params.Email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/usersListbyName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResultGetDbUsers>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetDbUsersbyNameParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `Status`:
   *
   * - `Role`:
   *
   * - `ReviewerGroup`:
   *
   * - `LastName`:
   *
   * - `FirstName`:
   *
   * - `EmployeeID`:
   *
   * - `Email`:
   *
   * @return Success
   */
  GetDbUsersbyName(params: UsersService.GetDbUsersbyNameParams): __Observable<ResultGetDbUsers> {
    return this.GetDbUsersbyNameResponse(params).pipe(
      __map(_r => _r.body as ResultGetDbUsers)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<User> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `UsersService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `users`:
   *
   * @return Success
   */
  PutResponse(params: UsersService.PutParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.users;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `UsersService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `users`:
   *
   * @return Success
   */
  Put(params: UsersService.PutParams): __Observable<Array<User>> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Users/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<User> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param email undefined
   * @return Success
   */
  GetByEmailResponse(email: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetByEmail/${email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param email undefined
   * @return Success
   */
  GetByEmail(email: string): __Observable<User> {
    return this.GetByEmailResponse(email).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param email undefined
   * @return Success
   */
  GetUserNameByEmailResponse(email: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetUserNameByEmail/${email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param email undefined
   * @return Success
   */
  GetUserNameByEmail(email: string): __Observable<string> {
    return this.GetUserNameByEmailResponse(email).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `UsersService.GetUserRolesByEmailandDalIdParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  GetUserRolesByEmailandDalIdResponse(params: UsersService.GetUserRolesByEmailandDalIdParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetUserRolesByEmailandDalId/${params.email}/${params.dalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetUserRolesByEmailandDalIdParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  GetUserRolesByEmailandDalId(params: UsersService.GetUserRolesByEmailandDalIdParams): __Observable<Array<string>> {
    return this.GetUserRolesByEmailandDalIdResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `UsersService.GetAllowedActivitiesParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `managedEmail`:
   *
   * @return Success
   */
  GetAllowedActivitiesResponse(params: UsersService.GetAllowedActivitiesParams): __Observable<__StrictHttpResponse<DalActivities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.managedEmail != null) __params = __params.set('managedEmail', params.managedEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetAllowedActivities/${params.dalId}/${params.email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalActivities>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetAllowedActivitiesParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `managedEmail`:
   *
   * @return Success
   */
  GetAllowedActivities(params: UsersService.GetAllowedActivitiesParams): __Observable<DalActivities> {
    return this.GetAllowedActivitiesResponse(params).pipe(
      __map(_r => _r.body as DalActivities)
    );
  }

  /**
   * @param params The `UsersService.GetDelegatorDataParams` containing the following parameters:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * @return Success
   */
  GetDelegatorDataResponse(params: UsersService.GetDelegatorDataParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/getDelegatorData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetDelegatorDataParams` containing the following parameters:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * @return Success
   */
  GetDelegatorData(params: UsersService.GetDelegatorDataParams): __Observable<User> {
    return this.GetDelegatorDataResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `UsersService.GetEnabledGroupTypeParams` containing the following parameters:
   *
   * - `orgGrouptype`:
   *
   * - `email`:
   *
   * @return Success
   */
  GetEnabledGroupTypeResponse(params: UsersService.GetEnabledGroupTypeParams): __Observable<__StrictHttpResponse<Array<FilterData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orgGrouptype != null) __params = __params.set('orgGrouptype', params.orgGrouptype.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/getEnableGroupType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FilterData>>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetEnabledGroupTypeParams` containing the following parameters:
   *
   * - `orgGrouptype`:
   *
   * - `email`:
   *
   * @return Success
   */
  GetEnabledGroupType(params: UsersService.GetEnabledGroupTypeParams): __Observable<Array<FilterData>> {
    return this.GetEnabledGroupTypeResponse(params).pipe(
      __map(_r => _r.body as Array<FilterData>)
    );
  }

  /**
   * @param params The `UsersService.GetDbUsersParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `Status`:
   *
   * - `Role`:
   *
   * - `ReviewerGroup`:
   *
   * - `LastName`:
   *
   * - `FirstName`:
   *
   * - `EmployeeID`:
   *
   * - `Email`:
   *
   * @return Success
   */
  GetDbUsersResponse(params: UsersService.GetDbUsersParams): __Observable<__StrictHttpResponse<ResultGetDbUsers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Role != null) __params = __params.set('Role', params.Role.toString());
    if (params.ReviewerGroup != null) __params = __params.set('ReviewerGroup', params.ReviewerGroup.toString());
    if (params.LastName != null) __params = __params.set('LastName', params.LastName.toString());
    if (params.FirstName != null) __params = __params.set('FirstName', params.FirstName.toString());
    if (params.EmployeeID != null) __params = __params.set('EmployeeID', params.EmployeeID.toString());
    if (params.Email != null) __params = __params.set('Email', params.Email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/usersList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResultGetDbUsers>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetDbUsersParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `Status`:
   *
   * - `Role`:
   *
   * - `ReviewerGroup`:
   *
   * - `LastName`:
   *
   * - `FirstName`:
   *
   * - `EmployeeID`:
   *
   * - `Email`:
   *
   * @return Success
   */
  GetDbUsers(params: UsersService.GetDbUsersParams): __Observable<ResultGetDbUsers> {
    return this.GetDbUsersResponse(params).pipe(
      __map(_r => _r.body as ResultGetDbUsers)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  PutAuthorityAdministratorResponse(user?: User): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/PutAuthorityAdministrator`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  PutAuthorityAdministrator(user?: User): __Observable<string> {
    return this.PutAuthorityAdministratorResponse(user).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param updateManagedUserParams undefined
   * @return Success
   */
  UpdateManagedUserResponse(updateManagedUserParams?: UpdateManagedUserParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = updateManagedUserParams;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/UpdateManagedUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param updateManagedUserParams undefined
   * @return Success
   */
  UpdateManagedUser(updateManagedUserParams?: UpdateManagedUserParams): __Observable<string> {
    return this.UpdateManagedUserResponse(updateManagedUserParams).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  DeleteAuthorityAdministratorResponse(user?: User): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Users/DeleteAuthorityAdministrator`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  DeleteAuthorityAdministrator(user?: User): __Observable<string> {
    return this.DeleteAuthorityAdministratorResponse(user).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param assignSuperUserResponse undefined
   * @return Success
   */
  AssignSuperUserResponse(assignSuperUserResponse?: AssignSuperUserResponse): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = assignSuperUserResponse;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/AssignSuperUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param assignSuperUserResponse undefined
   * @return Success
   */
  AssignSuperUser(assignSuperUserResponse?: AssignSuperUserResponse): __Observable<User> {
    return this.AssignSuperUserResponse(assignSuperUserResponse).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param assignExternalAuthorityResponse undefined
   * @return Success
   */
  AssignExternalAuthorityResponse(assignExternalAuthorityResponse?: AssignExternalAuthorityResponse): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = assignExternalAuthorityResponse;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/AssignExternalAuthority`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param assignExternalAuthorityResponse undefined
   * @return Success
   */
  AssignExternalAuthority(assignExternalAuthorityResponse?: AssignExternalAuthorityResponse): __Observable<User> {
    return this.AssignExternalAuthorityResponse(assignExternalAuthorityResponse).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param searchCriteria undefined
   * @return Success
   */
  GetUsersWithExternalResponse(searchCriteria?: UsersWithExternalSearchCriteria): __Observable<__StrictHttpResponse<CollectionResultUserDataView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchCriteria;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/GetUsersWithExternal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultUserDataView>;
      })
    );
  }
  /**
   * @param searchCriteria undefined
   * @return Success
   */
  GetUsersWithExternal(searchCriteria?: UsersWithExternalSearchCriteria): __Observable<CollectionResultUserDataView> {
    return this.GetUsersWithExternalResponse(searchCriteria).pipe(
      __map(_r => _r.body as CollectionResultUserDataView)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableAsyncResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/EnableAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  EnableAsync(id?: string): __Observable<VoidResult> {
    return this.EnableAsyncResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableAsyncResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/DisableAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DisableAsync(id?: string): __Observable<VoidResult> {
    return this.DisableAsyncResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param email undefined
   * @return Success
   */
  UserEnableAsyncResponse(email?: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/UserExistAndEnableAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body}) as __StrictHttpResponse<any>
      })
    );
  }
  /**
   * @param email undefined
   * @return Success
   */
  UserExistAndEnableAsync(email?: string): __Observable<__StrictHttpResponse<any>> {
    return this.UserEnableAsyncResponse(email).pipe(
      __map(_r => _r.body as __StrictHttpResponse<any>)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  AcceptTermsResponse(user?: UserView): __Observable<__StrictHttpResponse<EntityResultUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/AcceptTerms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultUser>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  AcceptTerms(user?: UserView): __Observable<EntityResultUser> {
    return this.AcceptTermsResponse(user).pipe(
      __map(_r => _r.body as EntityResultUser)
    );
  }

  /**
   * @param email undefined
   * @return Success
   */
  UserAcceptTermsResponse(email?: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/UserAcceptTerms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param email undefined
   * @return Success
   */
  UserAcceptTerms(email?: string): __Observable<boolean> {
    return this.UserAcceptTermsResponse(email).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetAllUsersDataResponse(): __Observable<__StrictHttpResponse<Array<UserDataView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetAllUsersData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDataView>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllUsersData(): __Observable<Array<UserDataView>> {
    return this.GetAllUsersDataResponse().pipe(
      __map(_r => _r.body as Array<UserDataView>)
    );
  }

  /**
   * @param userId undefined
   * @return Success
   */
  EnableEmailNotificationsResponse(userId?: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/EnableEmailNotifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param userId undefined
   * @return Success
   */
  EnableEmailNotifications(userId?: string): __Observable<User> {
    return this.EnableEmailNotificationsResponse(userId).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param userId undefined
   * @return Success
   */
  DisableEmailNotificationsResponse(userId?: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/DisableEmailNotifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param userId undefined
   * @return Success
   */
  DisableEmailNotifications(userId?: string): __Observable<User> {
    return this.DisableEmailNotificationsResponse(userId).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param email undefined
   * @return Success
   */
  UserSynchronizedResponse(email?: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/UserSynchronized`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param email undefined
   * @return Success
   */
  UserSynchronized(email?: string): __Observable<boolean> {
    return this.UserSynchronizedResponse(email).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  UpdateActiveDirectoryObjectIdentifierResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/UpdateActiveDirectoryObjectIdentifier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  UpdateActiveDirectoryObjectIdentifier(): __Observable<string> {
    return this.UpdateActiveDirectoryObjectIdentifierResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module UsersService {

  /**
   * Parameters for UpdateOtherRoles
   */
  export interface UpdateOtherRolesParams {
    id: string;
    roles?: Array<UserRoleView>;
  }

  /**
   * Parameters for Get
   */
  export interface GetParams {
    SearchUser?: string;
    SearchText?: string;
    SearchPeople?: string;
    SearchEmail?: string;
    RetrieveDelegatorTreeForEmail?: string;
    DelegatorSearch?: string;
    DelegateParentEmail?: string;
    AdministratorEmail?: string;
  }

  /**
   * Parameters for GetPaginated
   */
  export interface GetPaginatedParams {
    limit?: number;
    SearchUser?: string;
    SearchText?: string;
    SearchPeople?: string;
    SearchEmail?: string;
    RetrieveDelegatorTreeForEmail?: string;
    DelegatorSearch?: string;
    DelegateParentEmail?: string;
    AdministratorEmail?: string;
  }

  /**
   * Parameters for GetDbUsersbyName
   */
  export interface GetDbUsersbyNameParams {
    limit?: number;
    Status?: string;
    Role?: string;
    ReviewerGroup?: string;
    LastName?: string;
    FirstName?: string;
    EmployeeID?: string;
    Email?: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    users?: Array<User>;
  }

  /**
   * Parameters for GetUserRolesByEmailandDalId
   */
  export interface GetUserRolesByEmailandDalIdParams {
    email: string;
    dalId: string;
  }

  /**
   * Parameters for GetAllowedActivities
   */
  export interface GetAllowedActivitiesParams {
    email: string;
    dalId: string;
    managedEmail?: string;
  }

  /**
   * Parameters for GetDelegatorData
   */
  export interface GetDelegatorDataParams {
    lastName?: string;
    firstName?: string;
  }

  /**
   * Parameters for GetEnabledGroupType
   */
  export interface GetEnabledGroupTypeParams {
    orgGrouptype?: string;
    email?: string;
  }

  /**
   * Parameters for GetDbUsers
   */
  export interface GetDbUsersParams {
    limit?: number;
    Status?: string;
    Role?: string;
    ReviewerGroup?: string;
    LastName?: string;
    FirstName?: string;
    EmployeeID?: string;
    Email?: string;
  }

  /**
   * Parameters for GetUsersWithExternal
   */
  export interface GetUsersWithExternalParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    SearchUser?: string;
    SearchInExternal?: boolean;
    Role?: string;
    ReviewerGroup?: string;
    PageSize?: number;
    PageNum?: number;
    LastName?: string;
    FirstName?: string;
    EmployeeID?: string;
    Email?: string;
    ContinuationToken?: string;
  }
}

export { UsersService }
