/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BaseDataDetailView } from '../models/base-data-detail-view';
@Injectable({
  providedIn: 'root',
})
class BaseDataDetailViewsService extends __BaseService {
  static readonly GetPath = '/api/BaseDataDetailViews';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BaseDataDetailViewsService.GetParams` containing the following parameters:
   *
   * - `SubType`:
   *
   * - `Sections`:
   *
   * - `ListType`:
   *
   * - `Functions`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * @return Success
   */
  GetResponse(params: BaseDataDetailViewsService.GetParams): __Observable<__StrictHttpResponse<BaseDataDetailView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SubType != null) __params = __params.set('SubType', params.SubType.toString());
    if (params.Sections != null) __params = __params.set('Sections', params.Sections.toString());
    if (params.ListType != null) __params = __params.set('ListType', params.ListType.toString());
    if (params.Functions != null) __params = __params.set('Functions', params.Functions.toString());
    if (params.Divisions != null) __params = __params.set('Divisions', params.Divisions.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BaseDataDetailViews`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseDataDetailView>;
      })
    );
  }
  /**
   * @param params The `BaseDataDetailViewsService.GetParams` containing the following parameters:
   *
   * - `SubType`:
   *
   * - `Sections`:
   *
   * - `ListType`:
   *
   * - `Functions`:
   *
   * - `Divisions`:
   *
   * - `Description`:
   *
   * @return Success
   */
  Get(params: BaseDataDetailViewsService.GetParams): __Observable<BaseDataDetailView> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as BaseDataDetailView)
    );
  }
}

module BaseDataDetailViewsService {

  /**
   * Parameters for Get
   */
  export interface GetParams {
    SubType?: string;
    Sections?: boolean;
    ListType?: string;
    Functions?: string;
    Divisions?: string;
    Description?: string;
  }
}

export { BaseDataDetailViewsService }
