import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { LibraryItem, DalLibraryItem, DalTemplateLibraryItem, ItemVariable, ItemVariableValueView, Currency, ReferenceTableMeta, ReferenceTable } from '@app/api/models';
import { ProcessBasicInfillVariable } from '@app/shared/infill-processing/process-basic-infill-variable';
import { BaseDatasService, OrganizationDatasService, CurrencyService, TenantService, ExchangeRateService, ReferenceTableMetaService, ReferenceTableService } from '@app/api/services';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { e } from '@angular/core/src/render3';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-modal-dataset-variable',
  templateUrl: './modal-dataset-variable.component.html',
  styleUrls: ['./modal-dataset-variable.component.scss']
})
export class ModalDatasetComponent implements OnInit {
  referenceControl = new FormControl({ value: '' });
  textControl = new FormControl({ value: '' });
  title: string;
  message: string;
  okText: string;
  cancelText: string;
  confirmRevoke: boolean = false;
  dalContentConfiguration: DalContentConfiguration;
  currentLibraryItem: LibraryItem | DalLibraryItem | DalTemplateLibraryItem;
  variable: ItemVariable;
  chkDelegatable: boolean = false;
  textValue: string;
  numberValue: number;
  amountValue: number;
  currencies: Currency[];
  currencySelected: Currency;
  _baseCurrencyCode: string;

  referenceTableMeta: ReferenceTableMeta[];
  referenceTable: ReferenceTable[];

  selectedReference: ReferenceTable;

  selectedSubtype: any;

  constructor(
    private _referenceTableService: ReferenceTableService,
    private _referenceTableMetaService: ReferenceTableMetaService,
    private _baseDatasService: CurrencyService,
    private _tenantService: TenantService,
    private _spinner :Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<ModalDatasetComponent>,
    public dbv: ProcessBasicInfillVariable,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.dalContentConfiguration = data.dalContentConfiguration;
    this.currentLibraryItem = data.currentLibraryItem;
    this.variable = data.variable;
    this._baseDatasService.rootUrl = environment.ApiUrl;
    this._tenantService.rootUrl = environment.ApiUrl;
    this._referenceTableService.rootUrl = environment.ApiUrl;
    this._referenceTableMetaService.rootUrl = environment.ApiUrl;

  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getBaseData(this.variable.isDelegatable);
  }

  onDelegatableChange(event){
    this.getBaseData(this.chkDelegatable);
  }

  getBaseData(isDelegatable:boolean){
    this._spinner.show();

    let divisions = this.currentLibraryItem.organizationDataView.find(x => x.orgGroupType.toUpperCase() == "DIVISIONS");
    let functions = this.currentLibraryItem.organizationDataView.find(x => x.orgGroupType.toUpperCase() == "FUNCTION");
     let originalValue = this.variable.originalValue ? this.variable.originalValue.valueString : "";
      this._referenceTableService.GetReferenceTableByRTMCodeStatusAsync
        ({ rtmCode: this.variable.variableCode,
           division: divisions.orgGroupName,
            function: functions.orgGroupName, 
            parent:originalValue, 
            isDelegatable:null }).subscribe(response => {
            this.referenceTable = response;
            this.referenceTable.sort((a, b) => a.name.localeCompare(b.name));
          if (this.variable.variableType) {
            if (this.variable.currentValue) {
              this.selectedReference = this.referenceTable.find(x => x.code == this.variable.currentValue.valueString);    
            } 
            else if (this.variable.defaultValue) {
              this.selectedReference = this.referenceTable.find(x => x.code == this.variable.defaultValue.valueString);
            }
          }

          this._spinner.hide();
        },
          (err: HttpErrorResponse) => {
            this._spinner.hide();
            console.log(err.message);
          }
        );
    if (isDelegatable) {
      this.chkDelegatable =isDelegatable;
    }


  }

  onYesClick() {
    let newValue: ItemVariableValueView = {};
    // On press Ok, the LI is mark as update
    this.currentLibraryItem.status ="Update";
    if (this.selectedReference) {

      newValue.valueString = this.selectedReference.code;
      newValue.description = this.selectedReference.name ? this.selectedReference.name : "";
      this.variable.currentValue = newValue;
      this.variable.isDelegatable = this.chkDelegatable;
      this.dialogRef.close({confirm:true});
    }
    else if (this.variable.defaultValue) {
      Object.assign(newValue, this.variable.defaultValue);
      this.variable.currentValue = newValue;
      this.variable.isDelegatable = this.chkDelegatable;
      this.dialogRef.close();
    }
    else {
      this.variable.currentValue = null;
      this.variable.isDelegatable = this.chkDelegatable;
      this.dialogRef.close();
    }

   

  }

}