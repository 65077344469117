/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BaseData } from '../models/base-data';
@Injectable({
  providedIn: 'root',
})
class ReasonService extends __BaseService {
  static readonly EnableReasonPath = '/api/Reason/EnableReason';
  static readonly DisableReasonPath = '/api/Reason/DisableReason';
  static readonly InsertVersioningReasonPath = '/api/Reason/InsertVersioningReason';
  static readonly GetVersioningReasonsPath = '/api/Reason/GetVersioningReasons';
   static readonly EnableVersioningReasonPath = '/api/Reason/EnableVersioningReason';
  static readonly DisableVersioningReasonPath = '/api/Reason/DisableVersioningReason';
  static readonly InsertSuspendReasonPath = '/api/Reason/InsertSuspendReason';
  static readonly GetSuspendReasonsPath = '/api/Reason/GetSuspendReasons';
  static readonly InsertReinstateReasonPath = '/api/Reason/InsertReinstateReason';
  static readonly GetReinstateReasonsPath = '/api/Reason/GetReinstateReasons';
  static readonly InsertRevokeReasonPath = '/api/Reason/InsertRevokeReason';
  static readonly GetRevokeReasonsPath = '/api/Reason/GetRevokeReasons';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param idReason undefined
   * @return Success
   */
  EnableReasonResponse(idReason?: string): __Observable<__StrictHttpResponse<BaseData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idReason != null) __params = __params.set('idReason', idReason.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Reason/EnableReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseData>;
      })
    );
  }
  /**
   * @param idReason undefined
   * @return Success
   */
  EnableReason(idReason?: string): __Observable<BaseData> {
    return this.EnableReasonResponse(idReason).pipe(
      __map(_r => _r.body as BaseData)
    );
  }

  /**
   * @param idReason undefined
   * @return Success
   */
  DisableReasonResponse(idReason?: string): __Observable<__StrictHttpResponse<BaseData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (idReason != null) __params = __params.set('idReason', idReason.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Reason/DisableReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseData>;
      })
    );
  }
  /**
   * @param idReason undefined
   * @return Success
   */
  DisableReason(idReason?: string): __Observable<BaseData> {
    return this.DisableReasonResponse(idReason).pipe(
      __map(_r => _r.body as BaseData)
    );
  }

  /**
   * @param versioningReason undefined
   * @return Success
   */
  InsertVersioningReasonResponse(versioningReason?: BaseData): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = versioningReason;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Reason/InsertVersioningReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param versioningReason undefined
   * @return Success
   */
  InsertVersioningReason(versioningReason?: BaseData): __Observable<boolean> {
    return this.InsertVersioningReasonResponse(versioningReason).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetVersioningReasonsResponse(): __Observable<__StrictHttpResponse<Array<BaseData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Reason/GetVersioningReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BaseData>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetVersioningReasons(): __Observable<Array<BaseData>> {
    return this.GetVersioningReasonsResponse().pipe(
      __map(_r => _r.body as Array<BaseData>)
    );
  }

    /**
   * @param idReason undefined
   * @return Success
   */
     EnableVersioningReasonResponse(idReason?: string): __Observable<__StrictHttpResponse<BaseData>> {
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
      if (idReason != null) __params = __params.set('idReason', idReason.toString());
      let req = new HttpRequest<any>(
        'POST',
        this.rootUrl + `/api/Reason/EnableVersioningReason`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        __filter(_r => _r instanceof HttpResponse),
        __map((_r) => {
          return _r as __StrictHttpResponse<BaseData>;
        })
      );
    }
    /**
     * @param idReason undefined
     * @return Success
     */
    EnableVersioningReason(idReason?: string): __Observable<BaseData> {
      return this.EnableVersioningReasonResponse(idReason).pipe(
        __map(_r => _r.body as BaseData)
      );
    }
  
    /**
     * @param idReason undefined
     * @return Success
     */
    DisableVersioningReasonResponse(idReason?: string): __Observable<__StrictHttpResponse<BaseData>> {
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
      if (idReason != null) __params = __params.set('idReason', idReason.toString());
      let req = new HttpRequest<any>(
        'POST',
        this.rootUrl + `/api/Reason/DisableVersioningReason`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        __filter(_r => _r instanceof HttpResponse),
        __map((_r) => {
          return _r as __StrictHttpResponse<BaseData>;
        })
      );
    }
    /**
     * @param idReason undefined
     * @return Success
     */
    DisableVersioningReason(idReason?: string): __Observable<BaseData> {
      return this.DisableVersioningReasonResponse(idReason).pipe(
        __map(_r => _r.body as BaseData)
      );
    }
  
    /**
   * @param suspendReason undefined
   * @return Success
   */
  InsertSuspendReasonResponse(suspendReason?: BaseData): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = suspendReason;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Reason/InsertSuspendReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param suspendReason undefined
   * @return Success
   */
  InsertSuspendReason(suspendReason?: BaseData): __Observable<boolean> {
    return this.InsertSuspendReasonResponse(suspendReason).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetSuspendReasonsResponse(): __Observable<__StrictHttpResponse<Array<BaseData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Reason/GetSuspendReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BaseData>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetSuspendReasons(): __Observable<Array<BaseData>> {
    return this.GetSuspendReasonsResponse().pipe(
      __map(_r => _r.body as Array<BaseData>)
    );
  }

  /**
   * @param reinstateReason undefined
   * @return Success
   */
  InsertReinstateReasonResponse(reinstateReason?: BaseData): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reinstateReason;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Reason/InsertReinstateReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param reinstateReason undefined
   * @return Success
   */
  InsertReinstateReason(reinstateReason?: BaseData): __Observable<boolean> {
    return this.InsertReinstateReasonResponse(reinstateReason).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetReinstateReasonsResponse(): __Observable<__StrictHttpResponse<Array<BaseData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Reason/GetReinstateReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BaseData>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetReinstateReasons(): __Observable<Array<BaseData>> {
    return this.GetReinstateReasonsResponse().pipe(
      __map(_r => _r.body as Array<BaseData>)
    );
  }

  /**
   * @param reason undefined
   * @return Success
   */
  InsertRevokeReasonResponse(reason?: BaseData): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reason;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Reason/InsertRevokeReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param reason undefined
   * @return Success
   */
  InsertRevokeReason(reason?: BaseData): __Observable<boolean> {
    return this.InsertRevokeReasonResponse(reason).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetRevokeReasonsResponse(): __Observable<__StrictHttpResponse<Array<BaseData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Reason/GetRevokeReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BaseData>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRevokeReasons(): __Observable<Array<BaseData>> {
    return this.GetRevokeReasonsResponse().pipe(
      __map(_r => _r.body as Array<BaseData>)
    );
  }
}

module ReasonService {
}

export { ReasonService }
