import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Dal, Region } from '@app/api/models';
import { UserService } from '@app/shared/services/user/user.service';
import { isUndefined } from 'util';
import { DalContentConfiguration } from '@app/shared/shared.module';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-dal-content',
  templateUrl: './dal-content.component.html',
  styleUrls: ['./dal-content.component.scss']
})
export class DalContentComponent implements OnInit {

  @Input() completeDalView: any;
  @Input() editMode:boolean = false;
  @Input() divisionDAL:any;
  @Input() functionDAL:any;
  @Input() masterId:boolean = false;
  @Input() masterType:string;
  @Input() expandAll: boolean;
  @Input() regions: Region[];

  selectedRegion: Region;

  startDateUTC: string = "";
  endDateUTC: string = "";
  reviewDateUTC: string = "";

  constructor(private datePipe: DatePipe) {
    
   }


  ngOnInit() {
    if(this.completeDalView.dal.region != null)
    {
      this.selectedRegion = this.regions.find(x=> x.code == this.completeDalView.dal.region.code);
    }
    else
    {
      this.selectedRegion = this.regions.find(x=> x.code == 'None');
    }

      this.endDateUTC = this.getUtcDates(this.completeDalView.dalHeader.endDate);
      this.startDateUTC = this.getUtcDates(this.completeDalView.dalHeader.startDate);
      this.reviewDateUTC = this.getUtcDates(this.completeDalView.dalHeader.reviewDate);
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.expandAll){
      this.expandAll = changes.expandAll.currentValue;
    }
  }

  getUtcDates(data: string): string {
    if (data && typeof data === 'string') {
      data = data.substring(0,data.length - 1)
      var timestamp = Date.parse(data);

    if (isNaN(timestamp) == false) {
     var d = new Date(timestamp);
     return this.datePipe.transform(d , 'dd MMM yyyy HH:mm') + " (UTC)";
    } else {
     return "-";
    }
      
    } else{
      //it is converted
      return this.datePipe.transform(data,'dd MMM yyyy HH:mm') + " (UTC)";
    }
    return "-";
  }

  modelChanged(newObj, type) {

    //var copiedDate : any = "";
    //Object.assign(copiedDate,newObj);
    

    var plainDate = newObj.toDate();

    if(type=="startDate" && plainDate){
      this.startDateUTC = this.datePipe.transform(plainDate , 'dd MMM yyyy HH:mm') + " (UTC)";
    }
    if(type=="endDate" && plainDate){
      this.endDateUTC = this.datePipe.transform(plainDate , 'dd MMM yyyy HH:mm') + " (UTC)";
    }
    if(type=="reviewDate" && plainDate){
      this.reviewDateUTC = this.datePipe.transform(plainDate, 'dd MMM yyyy HH:mm') + " (UTC)";
    }
}
  
  //Migrations
  //CanEdit -> only for SUs
  //Checked ON -> visible for any user
  //Checked Off -> only visisble for SUs 
  showDalMigrationControl(){
    if(this.checkSuperUser() || this.completeDalView.dalHeader.isForMigration)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  disableDalMigration(){
    //Only Super Users can set the DAL for migration
    if(!this.checkSuperUser())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  checkSuperUser(){
    var userRoles = JSON.parse(localStorage.getItem("userRole"));
    if(userRoles == null || isUndefined(userRoles))
    {
      return false;
    }
    else
    {
       return userRoles.includes('SUPERUSER');
    }
  }

  regionSelected() {
    console.log(this.selectedRegion)
    if (this.selectedRegion) {
      if(this.selectedRegion.code != 'None')
      {
      this.completeDalView.dal.region = this.selectedRegion;
      this.completeDalView.dalContentConfiguration.region = this.selectedRegion;
      }
      else
      {
        this.completeDalView.dal.region = null;
        this.completeDalView.dalContentConfiguration.region = null;
      }
    }

  }

}

