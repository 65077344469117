import { Component, OnInit,Input } from '@angular/core';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'app-impact-analysis',
  templateUrl: './impact-analysis.component.html',
  styleUrls: ['./impact-analysis.component.scss']
})
export class ImpactAnalysisComponent implements OnInit {
  
  @Input() sectionId: any;
  @Input() dataSource: Array<any>;
  showImpactedDals: boolean;
  impactAnalysisColumns = ['DAL Effected', 'DAL Status'];
  constructor() { }

  ngOnInit() {
    if (this.dataSource !== undefined && this.dataSource !== null) {
      if (this.dataSource.length > 0) {
        this.showImpactedDals = true;
      } else {
        this.showImpactedDals = false;
      }
    }
  }
}
