/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseDTO } from '../models/response-dto';
import { Role } from '../models/role';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class RolesService extends __BaseService {
  static readonly InsertRolePath = '/api/Roles/InsertRole';
  static readonly UpdateRolePath = '/api/Roles/UpdateRole';
  static readonly GetRolesPath = '/api/Roles/GetRoles';
  static readonly FindRolesPath = '/api/Roles/FindRoles';
  static readonly GetRoleByIdPath = '/api/Roles/GetRoleById';
  static readonly EnablePath = '/api/Roles/Enable';
  static readonly DisablePath = '/api/Roles/Disable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param role undefined
   * @return Success
   */
  InsertRoleResponse(role?: Role): __Observable<__StrictHttpResponse<ResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = role;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Roles/InsertRole`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseDTO>;
      })
    );
  }
  /**
   * @param role undefined
   * @return Success
   */
  InsertRole(role?: Role): __Observable<ResponseDTO> {
    return this.InsertRoleResponse(role).pipe(
      __map(_r => _r.body as ResponseDTO)
    );
  }

  /**
   * @param role undefined
   * @return Success
   */
  UpdateRoleResponse(role?: Role): __Observable<__StrictHttpResponse<ResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = role;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Roles/UpdateRole`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseDTO>;
      })
    );
  }
  /**
   * @param role undefined
   * @return Success
   */
  UpdateRole(role?: Role): __Observable<ResponseDTO> {
    return this.UpdateRoleResponse(role).pipe(
      __map(_r => _r.body as ResponseDTO)
    );
  }

  /**
   * @return Success
   */
  GetRolesResponse(): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Roles/GetRoles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRoles(): __Observable<Array<Role>> {
    return this.GetRolesResponse().pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * @param params The `RolesService.FindRolesParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Name`:
   *
   * - `Description`:
   *
   * @return Success
   */
  FindRolesResponse(params: RolesService.FindRolesParams): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Roles/FindRoles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * @param params The `RolesService.FindRolesParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Name`:
   *
   * - `Description`:
   *
   * @return Success
   */
  FindRoles(params: RolesService.FindRolesParams): __Observable<Array<Role>> {
    return this.FindRolesResponse(params).pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetRoleByIdResponse(id?: string): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Roles/GetRoleById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetRoleById(id?: string): __Observable<Role> {
    return this.GetRoleByIdResponse(id).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Roles/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Roles/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }
}

module RolesService {

  /**
   * Parameters for FindRoles
   */
  export interface FindRolesParams {
    Status?: string;
    Name?: string;
    Description?: string;
  }
}

export { RolesService }
