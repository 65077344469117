/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExecutionErrorAdapter } from '../models/execution-error-adapter';
@Injectable({
  providedIn: 'root',
})
class ExecutionErrorAdaptersService extends __BaseService {
  static readonly GetPath = '/api/ExecutionErrorAdapters';
  static readonly PostPath = '/api/ExecutionErrorAdapters';
  static readonly GetByIdPath = '/api/ExecutionErrorAdapters/{id}';
  static readonly PutPath = '/api/ExecutionErrorAdapters/{id}';
  static readonly DeletePath = '/api/ExecutionErrorAdapters/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetResponse(): __Observable<__StrictHttpResponse<Array<ExecutionErrorAdapter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ExecutionErrorAdapters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ExecutionErrorAdapter>>;
      })
    );
  }
  /**
   * @return Success
   */
  Get(): __Observable<Array<ExecutionErrorAdapter>> {
    return this.GetResponse().pipe(
      __map(_r => _r.body as Array<ExecutionErrorAdapter>)
    );
  }

  /**
   * @param executionErrorAdapter undefined
   * @return Success
   */
  PostResponse(executionErrorAdapter?: ExecutionErrorAdapter): __Observable<__StrictHttpResponse<ExecutionErrorAdapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = executionErrorAdapter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ExecutionErrorAdapters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExecutionErrorAdapter>;
      })
    );
  }
  /**
   * @param executionErrorAdapter undefined
   * @return Success
   */
  Post(executionErrorAdapter?: ExecutionErrorAdapter): __Observable<ExecutionErrorAdapter> {
    return this.PostResponse(executionErrorAdapter).pipe(
      __map(_r => _r.body as ExecutionErrorAdapter)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<ExecutionErrorAdapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ExecutionErrorAdapters/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExecutionErrorAdapter>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<ExecutionErrorAdapter> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as ExecutionErrorAdapter)
    );
  }

  /**
   * @param params The `ExecutionErrorAdaptersService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `executionErrorAdapter`:
   *
   * @return Success
   */
  PutResponse(params: ExecutionErrorAdaptersService.PutParams): __Observable<__StrictHttpResponse<ExecutionErrorAdapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.executionErrorAdapter;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ExecutionErrorAdapters/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExecutionErrorAdapter>;
      })
    );
  }
  /**
   * @param params The `ExecutionErrorAdaptersService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `executionErrorAdapter`:
   *
   * @return Success
   */
  Put(params: ExecutionErrorAdaptersService.PutParams): __Observable<ExecutionErrorAdapter> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as ExecutionErrorAdapter)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<ExecutionErrorAdapter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ExecutionErrorAdapters/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExecutionErrorAdapter>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<ExecutionErrorAdapter> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as ExecutionErrorAdapter)
    );
  }
}

module ExecutionErrorAdaptersService {

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    executionErrorAdapter?: ExecutionErrorAdapter;
  }
}

export { ExecutionErrorAdaptersService }
