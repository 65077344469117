import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DRD] => DAL Review Date
 */
@Injectable({
  providedIn: 'root',
})
export class DalReviewDateInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[DRD]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if (config.reviewDate == null)
        return "N/A";
      else if (config.reviewDate )
      {
        if(config.reviewDate.includes("<span"))
        return config.reviewDate;
        else
        return this.dateToString(config.reviewDate);
      }
        

      return this.infillCode;
    }
  }