import { Component, OnDestroy, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements OnInit, OnDestroy {

  constructor(private _spinnerService: Ng4LoadingSpinnerService,) {

   }

  ngOnInit() {
    //this._spinnerService.show();
  }

  ngOnDestroy() {
    //this._spinnerService.hide();
  }

}
