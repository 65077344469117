/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DalHierarchyView } from '../models/dal-hierarchy-view';
import { Dal } from '../models/dal';
import { EntityResultDal } from '../models/entity-result-dal';
import { CopyDalParam } from '../models/copy-dal-param';
import { User } from '../models/user';
import { ViewDalService } from '@app/shared/services/dal/view-dal.service';
import { WorkflowDALProgress } from '../models/workflow-dalprogress';
import { HcmEmployeeDetails } from '../models/hcm-employee-details';
import { OrganizationDataView } from '../models';
import { DalSearchHierarchy } from '../models/dal-search-hierarchy';
import { CollectionResultDal } from '../models/collection-result-dal';
import { DalSearchCriteriaPaginate } from '../models/dal-search-criteria-paginate';
@Injectable({
  providedIn: 'root',
})
class DalsService extends __BaseService {
  static readonly GetPath = '/api/Dals';
  static readonly PostPath = '/api/Dals';
  static readonly Get_1Path = '/api/Dals/{id}';
  static readonly PutPath = '/api/Dals/{id}';
  static readonly DeletePath = '/api/Dals/{id}';
  static readonly CopyDefaultAsyncPath = '/api/Dals/CopyDefaultAsync';
  static readonly CopyPath = '/api/Dals/Copy';
  static readonly CopyFromDalTemplatePath = '/api/Dals/CopyFromDalTemplate';
  //static readonly DalHierarchyAsyncPath = '/api/Dals/getHierarchyDals';
  static readonly DalHierarchyAsyncPath = '/api/Dals/getHierarchyDalsRoot';
  static readonly DalHierarchyUsersAsyncPath = '/api/Dals/getHierarchyDalsUsers';
  static readonly GetRootDelegatesDalsPath = '/api/Dals/getRootDelegatesDals';
  static readonly GetHasAcceptedDalsAsyncPath = '/api/Dals/GetHasAcceptedDalsAsync';
  static readonly ParentDalIsMasterDalPath = '/api/Dals/parentDalIsMasterDal';
  static readonly GetWorkflowStatusProgressPath = '/api/Dals/GetWorkflowStatusProgress';
  static readonly GetAuthorityHolderEmployeeDetailsByDalPath = '/api/Dals/GetAuthorityHolderEmployeeDetailsByDal/{dalId}';
  static readonly CopyMasterDalPath = '/api/Dals/CopyMasterDal';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DalsService.GetParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetResponse(params: DalsService.GetParams): __Observable<__StrictHttpResponse<DalHierarchyView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ViewerRole != null) __params = __params.set('ViewerRole', params.ViewerRole.toString());
    if (params.ViewerEmail != null) __params = __params.set('ViewerEmail', params.ViewerEmail.toString());
    if (params.SourceDalId != null) __params = __params.set('SourceDalId', params.SourceDalId.toString());
    if (params.SearchView != null) __params = __params.set('SearchView', params.SearchView.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RootDelegatorEmail != null) __params = __params.set('RootDelegatorEmail', params.RootDelegatorEmail.toString());
    if (params.RootDelegates != null) __params = __params.set('RootDelegates', params.RootDelegates.toString());
    if (params.ParentDelegatorEmail != null) __params = __params.set('ParentDelegatorEmail', params.ParentDelegatorEmail.toString());
    if (params.OnlyMyDals != null) __params = __params.set('OnlyMyDals', params.OnlyMyDals.toString());
    if (params.IncludeAllowedActivities != null) __params = __params.set('IncludeAllowedActivities', params.IncludeAllowedActivities.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.DelegatorEmail != null) __params = __params.set('DelegatorEmail', params.DelegatorEmail.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.DalStatus != null) __params = __params.set('DalStatus', params.DalStatus.toString());
    if (params.CopyType != null) __params = __params.set('CopyType', params.CopyType.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    if (params.CheckDelegate != null) __params = __params.set('CheckDelegate', params.CheckDelegate.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalHierarchyView>;
      })
    );
  }
  /**
   * @param params The `DalsService.GetParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  Get(params: DalsService.GetParams): __Observable<DalHierarchyView> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as DalHierarchyView)
    );
  }

    /**
   * @param dalSearchCriteria undefined
   * @return Success
   */
     GetPaginatedResponse(dalSearchCriteria?: DalSearchCriteriaPaginate): __Observable<__StrictHttpResponse<CollectionResultDal>> {
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
      __body = dalSearchCriteria;
      let req = new HttpRequest<any>(
        'POST',
        this.rootUrl + `/api/Dals/GetPaginated`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        __filter(_r => _r instanceof HttpResponse),
        __map((_r) => {
          return _r as __StrictHttpResponse<CollectionResultDal>;
        })
      );
    }
    /**
     * @param dalSearchCriteria undefined
     * @return Success
     */
    GetPaginated(dalSearchCriteria?: DalSearchCriteriaPaginate): __Observable<CollectionResultDal> {
      return this.GetPaginatedResponse(dalSearchCriteria).pipe(
        __map(_r => _r.body as CollectionResultDal)
      );
    }

  /**
   * @param params The `DalsService.PostParams` containing the following parameters:
   *
   * - `dal`:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  PostResponse(params: DalsService.PostParams): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.dal;
    if (params.ViewerRole != null) __params = __params.set('ViewerRole', params.ViewerRole.toString());
    if (params.ViewerEmail != null) __params = __params.set('ViewerEmail', params.ViewerEmail.toString());
    if (params.SourceDalId != null) __params = __params.set('SourceDalId', params.SourceDalId.toString());
    if (params.SearchView != null) __params = __params.set('SearchView', params.SearchView.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RootDelegatorEmail != null) __params = __params.set('RootDelegatorEmail', params.RootDelegatorEmail.toString());
    if (params.RootDelegates != null) __params = __params.set('RootDelegates', params.RootDelegates.toString());
    if (params.ParentDelegatorEmail != null) __params = __params.set('ParentDelegatorEmail', params.ParentDelegatorEmail.toString());
    if (params.OnlyMyDals != null) __params = __params.set('OnlyMyDals', params.OnlyMyDals.toString());
    if (params.IncludeAllowedActivities != null) __params = __params.set('IncludeAllowedActivities', params.IncludeAllowedActivities.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.DelegatorEmail != null) __params = __params.set('DelegatorEmail', params.DelegatorEmail.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.DalStatus != null) __params = __params.set('DalStatus', params.DalStatus.toString());
    if (params.CopyType != null) __params = __params.set('CopyType', params.CopyType.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    if (params.CheckDelegate != null) __params = __params.set('CheckDelegate', params.CheckDelegate.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param params The `DalsService.PostParams` containing the following parameters:
   *
   * - `dal`:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  Post(params: DalsService.PostParams): __Observable<Dal> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as Dal)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  Get_1Response(id: string): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get_1(id: string): __Observable<Dal> {
    return this.Get_1Response(id).pipe(
      __map(_r => _r.body as Dal)
    );
  }

  /**
   * @param params The `DalsService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dal`:
   *
   * @return Success
   */
  PutResponse(params: DalsService.PutParams): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.dal;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Dals/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param params The `DalsService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dal`:
   *
   * @return Success
   */
  Put(params: DalsService.PutParams): __Observable<Dal> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as Dal)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<Dal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Dals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Dal>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<Dal> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as Dal)
    );
  }

  /**
   * @param parameters undefined
   * @return Success
   */
  CopyDefaultAsyncResponse(parameters?: CopyDalParam): __Observable<__StrictHttpResponse<EntityResultDal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = parameters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dals/CopyDefaultAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDal>;
      })
    );
  }
  /**
   * @param parameters undefined
   * @return Success
   */
  CopyDefaultAsync(parameters?: CopyDalParam): __Observable<EntityResultDal> {
    return this.CopyDefaultAsyncResponse(parameters).pipe(
      __map(_r => _r.body as EntityResultDal)
    );
  }

  /**
   * @param parameters undefined
   * @return Success
   */
  CopyResponse(parameters?: CopyDalParam): __Observable<__StrictHttpResponse<EntityResultDal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = parameters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dals/Copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDal>;
      })
    );
  }
  /**
   * @param parameters undefined
   * @return Success
   */
  Copy(parameters?: CopyDalParam): __Observable<EntityResultDal> {
    return this.CopyResponse(parameters).pipe(
      __map(_r => _r.body as EntityResultDal)
    );
  }

  /**
   * @param parameters undefined
   * @return Success
   */
  CopyFromDalTemplateResponse(parameters?: CopyDalParam): __Observable<__StrictHttpResponse<EntityResultDal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = parameters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dals/CopyFromDalTemplate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDal>;
      })
    );
  }


  PostComment(parameters?: any): __Observable<__StrictHttpResponse<EntityResultDal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = parameters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dals/PostComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDal>;
      })
    );
  }
  /**
   * @param parameters undefined
   * @return Success
   */
  CopyFromDalTemplate(parameters?: CopyDalParam): __Observable<EntityResultDal> {
    return this.CopyFromDalTemplateResponse(parameters).pipe(
      __map(_r => _r.body as EntityResultDal)
    );
  }

    /**
   * @param parameters undefined
   * @return Success
   */
     ValidateSameDelegatorResponse(parameters?: CopyDalParam): __Observable<__StrictHttpResponse<boolean>> {
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
      __body = parameters;
      let req = new HttpRequest<any>(
        'POST',
        this.rootUrl + `/api/Dals/ValidateSameDelegator`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: 'text'
        });
  
      return this.http.request<any>(req).pipe(
        __filter(_r => _r instanceof HttpResponse),
        __map((_r) => {
          return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
        })
      );
    }
    /**
     * @param parameters undefined
     * @return Success
     */
    ValidateSameDelegator(parameters?: CopyDalParam): __Observable<boolean> {
      return this.ValidateSameDelegatorResponse(parameters).pipe(
        __map(_r => _r.body as boolean)
      );
    }

  /**
   * @param params The `DalsService.DalHierarchyAsyncParams` containing the following parameters:
   *
   * - `rootEmail`:
   *
   * - `dalSearchHierarchy`:
   *
   * @return Success
   */
  DalHierarchyAsyncResponse(params: DalsService.DalHierarchyAsyncParams): __Observable<__StrictHttpResponse<DalHierarchyView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.rootEmail != null) __params = __params.set('rootEmail', params.rootEmail.toString());
    __body = params.dalSearchHierarchy;
    let req = new HttpRequest<any>(
      'POST',
      //this.rootUrl + `/api/Dals/getHierarchyDals`,
      this.rootUrl + `/api/Dals/getHierarchyDalsRoot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalHierarchyView>;
      })
    );
  }

    /**
   * @param params The `DalsService.DalHierarchyAsyncParams` containing the following parameters:
   *
   * - `rootEmail`:
   *
   * - `dalSearchHierarchy`:
   *
   * @return Success
   */
  DalHierarchyAsyncResponseChildNode(params: DalsService.DalHierarchyAsyncParams): __Observable<__StrictHttpResponse<DalHierarchyView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.rootEmail != null) __params = __params.set('rootEmail', params.rootEmail.toString());
    __body = params.dalSearchHierarchy;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dals/DalHierarchyAsyncRootChildNode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalHierarchyView>;
      })
    );
  }
  /**
   * @param params The `DalsService.DalHierarchyAsyncParams` containing the following parameters:
   *
   * - `rootEmail`:
   *
   * - `dalSearchHierarchy`:
   *
   * @return Success
   */
  DalHierarchyAsync(params: DalsService.DalHierarchyAsyncParams): __Observable<DalHierarchyView> {
    return this.DalHierarchyAsyncResponse(params).pipe(
      __map(_r => _r.body as DalHierarchyView)
    );
  }

    /**
   * @param params The `DalsService.DalHierarchyAsyncParams` containing the following parameters:
   *
   * - `rootEmail`:
   *
   * - `dalSearchHierarchy`:
   *
   * @return Success
   */
  DalHierarchyAsyncChildNode(params: DalsService.DalHierarchyAsyncParams): __Observable<DalHierarchyView> {
    return this.DalHierarchyAsyncResponse(params).pipe(
      __map(_r => _r.body as DalHierarchyView)
    );
  }

  /**
   * @param params The `DalsService.DalHierarchyUsersAsyncParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  DalHierarchyUsersAsyncResponse(params: DalsService.DalHierarchyUsersAsyncParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ViewerRole != null) __params = __params.set('ViewerRole', params.ViewerRole.toString());
    if (params.ViewerEmail != null) __params = __params.set('ViewerEmail', params.ViewerEmail.toString());
    if (params.SourceDalId != null) __params = __params.set('SourceDalId', params.SourceDalId.toString());
    if (params.SearchView != null) __params = __params.set('SearchView', params.SearchView.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RootDelegatorEmail != null) __params = __params.set('RootDelegatorEmail', params.RootDelegatorEmail.toString());
    if (params.RootDelegates != null) __params = __params.set('RootDelegates', params.RootDelegates.toString());
    if (params.ParentDelegatorEmail != null) __params = __params.set('ParentDelegatorEmail', params.ParentDelegatorEmail.toString());
    if (params.OnlyMyDals != null) __params = __params.set('OnlyMyDals', params.OnlyMyDals.toString());
    if (params.IncludeAllowedActivities != null) __params = __params.set('IncludeAllowedActivities', params.IncludeAllowedActivities.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.DelegatorEmail != null) __params = __params.set('DelegatorEmail', params.DelegatorEmail.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.DalStatus != null) __params = __params.set('DalStatus', params.DalStatus.toString());
    if (params.CopyType != null) __params = __params.set('CopyType', params.CopyType.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    if (params.CheckDelegate != null) __params = __params.set('CheckDelegate', params.CheckDelegate.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/getHierarchyDalsUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `DalsService.DalHierarchyUsersAsyncParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  DalHierarchyUsersAsync(params: DalsService.DalHierarchyUsersAsyncParams): __Observable<Array<User>> {
    return this.DalHierarchyUsersAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param params The `DalsService.GetRootDelegatesDalsParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetRootDelegatesDalsResponse(params: DalsService.GetRootDelegatesDalsParams): __Observable<__StrictHttpResponse<Array<Dal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ViewerRole != null) __params = __params.set('ViewerRole', params.ViewerRole.toString());
    if (params.ViewerEmail != null) __params = __params.set('ViewerEmail', params.ViewerEmail.toString());
    if (params.SourceDalId != null) __params = __params.set('SourceDalId', params.SourceDalId.toString());
    if (params.SearchView != null) __params = __params.set('SearchView', params.SearchView.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RootDelegatorEmail != null) __params = __params.set('RootDelegatorEmail', params.RootDelegatorEmail.toString());
    if (params.RootDelegates != null) __params = __params.set('RootDelegates', params.RootDelegates.toString());
    if (params.ParentDelegatorEmail != null) __params = __params.set('ParentDelegatorEmail', params.ParentDelegatorEmail.toString());
    if (params.OnlyMyDals != null) __params = __params.set('OnlyMyDals', params.OnlyMyDals.toString());
    if (params.IncludeAllowedActivities != null) __params = __params.set('IncludeAllowedActivities', params.IncludeAllowedActivities.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.DelegatorEmail != null) __params = __params.set('DelegatorEmail', params.DelegatorEmail.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.DalStatus != null) __params = __params.set('DalStatus', params.DalStatus.toString());
    if (params.CopyType != null) __params = __params.set('CopyType', params.CopyType.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    if (params.CheckDelegate != null) __params = __params.set('CheckDelegate', params.CheckDelegate.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/getRootDelegatesDals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Dal>>;
      })
    );
  }
  /**
   * @param params The `DalsService.GetRootDelegatesDalsParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `SourceDalId`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Function`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetRootDelegatesDals(params: DalsService.GetRootDelegatesDalsParams): __Observable<Array<Dal>> {
    return this.GetRootDelegatesDalsResponse(params).pipe(
      __map(_r => _r.body as Array<Dal>)
    );
  }

  /**
   * @param params The `DalsService.GetHasAcceptedDalsAsyncParams` containing the following parameters:
   *
   * - `userMail`:
   *
   * - `functionDAL`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetHasAcceptedDalsAsyncResponse(params: DalsService.GetHasAcceptedDalsAsyncParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userMail != null) __params = __params.set('userMail', params.userMail.toString());
    if (params.functionDAL != null) __params = __params.set('functionDAL', params.functionDAL.toString());
    if (params.division != null) __params = __params.set('division', params.division.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/GetHasAcceptedDalsAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `DalsService.GetHasAcceptedDalsAsyncParams` containing the following parameters:
   *
   * - `userMail`:
   *
   * - `functionDAL`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetHasAcceptedDalsAsync(params: DalsService.GetHasAcceptedDalsAsyncParams): __Observable<boolean> {
    return this.GetHasAcceptedDalsAsyncResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }


  /**
   * @param dalId undefined
   * @return Success
   */
  ParentDalIsMasterDalResponse(dalId?: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (dalId != null) __params = __params.set('dalId', dalId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/parentDalIsMasterDal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  ParentDalIsMasterDal(dalId?: string): __Observable<boolean> {
    return this.ParentDalIsMasterDalResponse(dalId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param dalId undefined
   * @return Success
   */
  GetWorkflowStatusProgressResponse(dalId?: string): __Observable<__StrictHttpResponse<WorkflowDALProgress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (dalId != null) __params = __params.set('dalId', dalId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/GetWorkflowStatusProgress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowDALProgress>;
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  GetWorkflowStatusProgress(dalId?: string): __Observable<WorkflowDALProgress> {
    return this.GetWorkflowStatusProgressResponse(dalId).pipe(
      __map(_r => _r.body as WorkflowDALProgress)
    );
  }

  /**
   * @param dalId undefined
   * @return Success
   */
  GetAuthorityHolderEmployeeDetailsByDalResponse(dalId: string): __Observable<__StrictHttpResponse<HcmEmployeeDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/GetAuthorityHolderEmployeeDetailsByDal/${dalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HcmEmployeeDetails>;
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  GetAuthorityHolderEmployeeDetailsByDal(dalId: string): __Observable<HcmEmployeeDetails> {
    return this.GetAuthorityHolderEmployeeDetailsByDalResponse(dalId).pipe(
      __map(_r => _r.body as HcmEmployeeDetails)
    );
  }

    /**
   * @param params The `DalsService.GetRootDelegatesDalsTreeParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `States`:
   *
   * - `SourceDalId`:
   *
   * - `ShowDalForAllDelegators`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Functions`:
   *
   * - `Function`:
   *
   * - `Divisions`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetRootDelegatesDalsTreeResponse(params: DalsService.GetForTreeParams): __Observable<__StrictHttpResponse<Array<Dal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ViewerRole != null) __params = __params.set('ViewerRole', params.ViewerRole.toString());
    if (params.ViewerEmail != null) __params = __params.set('ViewerEmail', params.ViewerEmail.toString());
    (params.States || []).forEach(val => {if (val != null) __params = __params.append('States', val.toString())});
    if (params.SourceDalId != null) __params = __params.set('SourceDalId', params.SourceDalId.toString());
    if (params.ShowDalForAllDelegators != null) __params = __params.set('ShowDalForAllDelegators', params.ShowDalForAllDelegators.toString());
    if (params.SearchView != null) __params = __params.set('SearchView', params.SearchView.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RootDelegatorEmail != null) __params = __params.set('RootDelegatorEmail', params.RootDelegatorEmail.toString());
    if (params.RootDelegates != null) __params = __params.set('RootDelegates', params.RootDelegates.toString());
    if (params.ParentDelegatorEmail != null) __params = __params.set('ParentDelegatorEmail', params.ParentDelegatorEmail.toString());
    if (params.OnlyMyDals != null) __params = __params.set('OnlyMyDals', params.OnlyMyDals.toString());
    if (params.IncludeAllowedActivities != null) __params = __params.set('IncludeAllowedActivities', params.IncludeAllowedActivities.toString());
    (params.Functions || []).forEach(val => {if (val != null) __params = __params.append('Functions', val.toString())});
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    (params.Divisions || []).forEach(val => {if (val != null) __params = __params.append('Divisions', val.toString())});
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.DelegatorEmail != null) __params = __params.set('DelegatorEmail', params.DelegatorEmail.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.DalStatus != null) __params = __params.set('DalStatus', params.DalStatus.toString());
    if (params.CopyType != null) __params = __params.set('CopyType', params.CopyType.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    if (params.CheckDelegate != null) __params = __params.set('CheckDelegate', params.CheckDelegate.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    (params.Ancestors || []).forEach(val => {if (val != null) __params = __params.append('Ancestors', val.toString())});

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/GetRootDelegatesDalsTree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Dal>>;
      })
    );
  }
  /**
   * @param params The `DalsService.GetRootDelegatesDalsTreeParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `States`:
   *
   * - `SourceDalId`:
   *
   * - `ShowDalForAllDelegators`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Functions`:
   *
   * - `Function`:
   *
   * - `Divisions`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetRootDelegatesDalsTree(params: DalsService.GetForTreeParams): __Observable<Array<Dal>> {
    return this.GetRootDelegatesDalsTreeResponse(params).pipe(
      __map(_r => _r.body as Array<Dal>)
    );
  }

    /**
   * @param params The `DalsService.GetForTreeParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `States`:
   *
   * - `SourceDalId`:
   *
   * - `ShowDalForAllDelegators`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Functions`:
   *
   * - `Function`:
   *
   * - `Divisions`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetForTreeResponse(params: DalsService.GetForTreeParams): __Observable<__StrictHttpResponse<DalHierarchyView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ViewerRole != null) __params = __params.set('ViewerRole', params.ViewerRole.toString());
    if (params.ViewerEmail != null) __params = __params.set('ViewerEmail', params.ViewerEmail.toString());
    (params.States || []).forEach(val => {if (val != null) __params = __params.append('States', val.toString())});
    if (params.SourceDalId != null) __params = __params.set('SourceDalId', params.SourceDalId.toString());
    if (params.ShowDalForAllDelegators != null) __params = __params.set('ShowDalForAllDelegators', params.ShowDalForAllDelegators.toString());
    if (params.SearchView != null) __params = __params.set('SearchView', params.SearchView.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RootDelegatorEmail != null) __params = __params.set('RootDelegatorEmail', params.RootDelegatorEmail.toString());
    if (params.RootDelegates != null) __params = __params.set('RootDelegates', params.RootDelegates.toString());
    if (params.ParentDelegatorEmail != null) __params = __params.set('ParentDelegatorEmail', params.ParentDelegatorEmail.toString());
    if (params.OnlyMyDals != null) __params = __params.set('OnlyMyDals', params.OnlyMyDals.toString());
    if (params.IncludeAllowedActivities != null) __params = __params.set('IncludeAllowedActivities', params.IncludeAllowedActivities.toString());
    (params.Functions || []).forEach(val => {if (val != null) __params = __params.append('Functions', val.toString())});
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    (params.Divisions || []).forEach(val => {if (val != null) __params = __params.append('Divisions', val.toString())});
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.DelegatorEmail != null) __params = __params.set('DelegatorEmail', params.DelegatorEmail.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.DalStatus != null) __params = __params.set('DalStatus', params.DalStatus.toString());
    if (params.CopyType != null) __params = __params.set('CopyType', params.CopyType.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    if (params.CheckDelegate != null) __params = __params.set('CheckDelegate', params.CheckDelegate.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dals/GetForTree`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalHierarchyView>;
      })
    );
  }
  /**
   * @param params The `DalsService.GetForTreeParams` containing the following parameters:
   *
   * - `ViewerRole`:
   *
   * - `ViewerEmail`:
   *
   * - `States`:
   *
   * - `SourceDalId`:
   *
   * - `ShowDalForAllDelegators`:
   *
   * - `SearchView`:
   *
   * - `SearchText`:
   *
   * - `RootDelegatorEmail`:
   *
   * - `RootDelegates`:
   *
   * - `ParentDelegatorEmail`:
   *
   * - `OnlyMyDals`:
   *
   * - `IncludeAllowedActivities`:
   *
   * - `Functions`:
   *
   * - `Function`:
   *
   * - `Divisions`:
   *
   * - `Division`:
   *
   * - `DelegatorEmail`:
   *
   * - `Delegator`:
   *
   * - `DalStatus`:
   *
   * - `CopyType`:
   *
   * - `Code`:
   *
   * - `CheckDelegate`:
   *
   * - `AuthorityHolderEmail`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  GetForTree(params: DalsService.GetForTreeParams): __Observable<DalHierarchyView> {
    return this.GetForTreeResponse(params).pipe(
      __map(_r => _r.body as DalHierarchyView)
    );
  }

  /**
   * @param parameters undefined
   * @return Success
   */
   CopyMasterDalResponse(parameters?: CopyDalParam): __Observable<__StrictHttpResponse<EntityResultDal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = parameters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dals/CopyMasterDal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDal>;
      })
    );
  }
  /**
   * @param parameters undefined
   * @return Success
   */
  CopyMasterDal(parameters?: CopyDalParam): __Observable<EntityResultDal> {
    return this.CopyMasterDalResponse(parameters).pipe(
      __map(_r => _r.body as EntityResultDal)
    );
  }
}

module DalsService {

  /**
   * Parameters for GetForTree
   */
  export interface GetForTreeParams {
    ViewerRole?: ViewDalService.ViewDalRole;
    ViewerEmail?: string;
    States?: Array<string>;
    SourceDalId?: string;
    ShowDalForAllDelegators?: boolean;
    SearchView?: boolean;
    SearchText?: string;
    RootDelegatorEmail?: string;
    RootDelegates?: boolean;
    ParentDelegatorEmail?: string;
    OnlyMyDals?: boolean;
    IncludeAllowedActivities?: boolean;
    Functions?: Array<string>;
    Function?: string;
    Divisions?: Array<string>;
    Division?: string;
    DelegatorEmail?: string;
    Delegator?: string;
    DalStatus?: string;
    CopyType?: string;
    Code?: string;
    CheckDelegate?: boolean;
    AuthorityHolderEmail?: string;
    AuthorityHolder?: string;
    Ancestors?: Array<string>;
  }

  /**
   * Parameters for Get
   */
  export interface GetParams {
    ViewerRole?: ViewDalService.ViewDalRole;
    ViewerEmail?: string;
    SourceDalId?: string;
    SearchView?: boolean;
    SearchText?: string;
    RootDelegatorEmail?: string;
    RootDelegates?: boolean;
    ParentDelegatorEmail?: string;
    OnlyMyDals?: boolean;
    IncludeAllowedActivities?: boolean;
    Function?: string;
    Division?: string;
    DelegatorEmail?: string;
    Delegator?: string;
    DalStatus?: string;
    CopyType?: string;
    Code?: string;
    CheckDelegate?: boolean;
    AuthorityHolderEmail?: string;
    AuthorityHolder?: string;
  }

   /**
   * Parameters for GetPaginated
   */
    export interface GetPaginatedParams {
      ViewerRole?: ViewDalService.ViewDalRole;
      ViewerEmail?: string;
      States?: Array<string>;
      SourceDalId?: string;
      ShowDalForAllDelegators?: boolean;
      SearchView?: boolean;
      SearchText?: string;
      RootDelegatorEmail?: string;
      RootDelegates?: boolean;
      ParentDelegatorEmail?: string;
      Page?: string;
      OnlyMyDals?: boolean;
      IncludeAllowedActivities?: boolean;
      Functions?: Array<string>;
      Function?: string;
      Divisions?: Array<string>;
      Division?: string;
      DelegatorId?: string;
      DelegatorEmail?: string;
      Delegator?: string;
      DalStatus?: string;
      CopyType?: string;
      ContinuationToken?: string;
      Code?: string;
      CheckDelegate?: boolean;
      AuthorityHolderId?: string;
      AuthorityHolderEmail?: string;
      AuthorityHolder?: string;
      Ancestors?: Array<string>;
    }

  /**
   * Parameters for Post
   */
  export interface PostParams {
    dal?: Dal;
    ViewerRole?: ViewDalService.ViewDalRole;
    ViewerEmail?: string;
    SourceDalId?: string;
    SearchView?: boolean;
    SearchText?: string;
    RootDelegatorEmail?: string;
    RootDelegates?: boolean;
    ParentDelegatorEmail?: string;
    OnlyMyDals?: boolean;
    IncludeAllowedActivities?: boolean;
    Function?: string;
    Division?: string;
    DelegatorEmail?: string;
    Delegator?: string;
    DalStatus?: string;
    CopyType?: string;
    Code?: string;
    CheckDelegate?: boolean;
    AuthorityHolderEmail?: string;
    AuthorityHolder?: string;
  }

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    dal?: Dal;
  }

 /**
   * Parameters for DalHierarchyAsync
   */
  export interface DalHierarchyAsyncParams {
    rootEmail?: string;
    dalSearchHierarchy?: DalSearchHierarchy;
  }



  /**
   * Parameters for DalHierarchyUsersAsync
   */
  export interface DalHierarchyUsersAsyncParams {
    ViewerRole?:ViewDalService.ViewDalRole;
    ViewerEmail?: string;
    SourceDalId?: string;
    SearchView?: boolean;
    SearchText?: string;
    RootDelegatorEmail?: string;
    RootDelegates?: boolean;
    ParentDelegatorEmail?: string;
    OnlyMyDals?: boolean;
    IncludeAllowedActivities?: boolean;
    Function?: string;
    Division?: string;
    DelegatorEmail?: string;
    Delegator?: string;
    DalStatus?: string;
    CopyType?: string;
    Code?: string;
    CheckDelegate?: boolean;
    AuthorityHolderEmail?: string;
    AuthorityHolder?: string;
  }

  /**
   * Parameters for GetRootDelegatesDals
   */
  export interface GetRootDelegatesDalsParams {
    ViewerRole?: ViewDalService.ViewDalRole;
    ViewerEmail?: string;
    SourceDalId?: string;
    SearchView?: boolean;
    SearchText?: string;
    RootDelegatorEmail?: string;
    RootDelegates?: boolean;
    ParentDelegatorEmail?: string;
    OnlyMyDals?: boolean;
    IncludeAllowedActivities?: boolean;
    Function?: string;
    Division?: string;
    DelegatorEmail?: string;
    Delegator?: string;
    DalStatus?: string;
    CopyType?: string;
    Code?: string;
    CheckDelegate?: boolean;
    AuthorityHolderEmail?: string;
    AuthorityHolder?: string;
  }

  /**
   * Parameters for GetHasAcceptedDalsAsync
   */
  export interface GetHasAcceptedDalsAsyncParams {
    userMail?: string;
    functionDAL?: string;
    division?: string;
  }
}

export { DalsService }
