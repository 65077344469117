import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  showSubmenuGeneralReports:boolean= false;
  selectedSubmenuIndex: number = 0;

  constructor() { }

  ngOnInit() {
    this.selectedSubmenuIndex = 0;
  }

  selectSubMenu(index) {
    this.selectedSubmenuIndex = index;
  }

  // onClkToggleSelected(menuClicked: string) {
  //   switch(menuClicked) { 
  //    case 'showSubmenuGeneralReports': { 
  //       this.showSubmenuGeneralReports = true;
  //       break; 
  //    } 
  //    default: { 
  //       //statements; 
  //       break; 
  //    } 
  //   } 
  // }
}
