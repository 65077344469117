/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Region } from '../models/region';
import { PagedCollectionResponseRegion } from '../models/paged-collection-response-region';
@Injectable({
  providedIn: 'root',
})
class RegionService extends __BaseService {
  static readonly InsertRegionPath = '/api/Region/InsertRegion';
  static readonly UpdateRegionPath = '/api/Region/UpdateRegion';
  static readonly GetRegionsPath = '/api/Region/GetRegions';
  static readonly GetActiveRegionsPath = '/api/Region/GetActiveRegions';
  static readonly DisablePath = '/api/Region/Disable';
  static readonly EnablePath = '/api/Region/Enable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param Region undefined
   * @return Success
   */
  InsertRegionResponse(Region?: Region): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = Region;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Region/InsertRegion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param Region undefined
   * @return Success
   */
  InsertRegion(Region?: Region): __Observable<boolean> {
    return this.InsertRegionResponse(Region).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param Region undefined
   * @return Success
   */
  UpdateRegionResponse(Region?: Region): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = Region;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Region/UpdateRegion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param Region undefined
   * @return Success
   */
  UpdateRegion(Region?: Region): __Observable<boolean> {
    return this.UpdateRegionResponse(Region).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetRegionsResponse(): __Observable<__StrictHttpResponse<PagedCollectionResponseRegion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Region/GetRegions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseRegion>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRegions(): __Observable<PagedCollectionResponseRegion> {
    return this.GetRegionsResponse().pipe(
      __map(_r => _r.body as PagedCollectionResponseRegion)
    );
  }

  /**
   * @return Success
   */
  GetActiveRegionsResponse(): __Observable<__StrictHttpResponse<PagedCollectionResponseRegion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Region/GetActiveRegions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseRegion>;
      })
    );
  }
  /**
   * @return Success
   */
  GetActiveRegions(): __Observable<PagedCollectionResponseRegion> {
    return this.GetActiveRegionsResponse().pipe(
      __map(_r => _r.body as PagedCollectionResponseRegion)
    );
  }

  /**
   * @param RegionId undefined
   * @return Success
   */
  DisableResponse(RegionId?: string): __Observable<__StrictHttpResponse<Region>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (RegionId != null) __params = __params.set('RegionId', RegionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Region/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Region>;
      })
    );
  }
  /**
   * @param RegionId undefined
   * @return Success
   */
  Disable(RegionId?: string): __Observable<Region> {
    return this.DisableResponse(RegionId).pipe(
      __map(_r => _r.body as Region)
    );
  }

  /**
   * @param RegionId undefined
   * @return Success
   */
  EnableResponse(RegionId?: string): __Observable<__StrictHttpResponse<Region>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (RegionId != null) __params = __params.set('RegionId', RegionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Region/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Region>;
      })
    );
  }
  /**
   * @param RegionId undefined
   * @return Success
   */
  Enable(RegionId?: string): __Observable<Region> {
    return this.EnableResponse(RegionId).pipe(
      __map(_r => _r.body as Region)
    );
  }
}

module RegionService {
}

export { RegionService }
