import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MsAdalAngular6Module, MsAdalAngular6Service, AuthenticationGuard } from 'microsoft-adal-angular6';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './shared/interceptors/custom-http-interceptor';
import { environment } from '@env/environment';
import { AccessDeniedComponent } from './errors/access-denied/access-denied.component';
import { InvalidUserComponent } from './errors/invalid-user/invalid-user.component';

import { ɵ_sanitizeStyle } from '@angular/core';
import {NgDompurifyDomSanitizer, NgDompurifyModule, SANITIZE_STYLE} from '@tinkoff/ng-dompurify';
import { ApplicationRef } from '@angular/core/src/application_ref';
import { MsalComponent } from './msal.component';
import { UnsuportedComponent } from './demo/unsuported/unsuported.component';
import { OutOfSyncComponent } from './errors/out-of-sync/out-of-sync.component';
import { DatePipe } from '@angular/common';

let adalConfig: any; // will be initialized by APP_INITIALIZER
export function msAdalAngular6ConfigFactory() {
  return adalConfig; // will be invoked later when creating MsAdalAngular6Service
}


// refer to:
// https://devblogs.microsoft.com/premier-developer/angular-how-to-editable-config-files/
// for a description of the AppConfig service
export function initializeApp() {
  // const promise = appConfig.load().then(() => {
    if (window.location.hash.indexOf('#id_token') > -1) {
      sessionStorage.setItem('adal.token.keys', '');
    }
    let cacheLocation: string;
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      cacheLocation = "localStorage";
    }
    else {
      cacheLocation = environment.cacheLocation;
    }

    adalConfig = {
      tenant: environment.tenant,
      clientId: environment.clientId,
      redirectUri: window.location.origin,
      endpoints: environment.endPoints,
      navigateToLoginRequestUrl: false,
      cacheLocation: cacheLocation,
      loadFrameTimeout: 15000
   };
  return () => adalConfig;
  // return adalConfig;
}


@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    InvalidUserComponent,
    MsalComponent,
    UnsuportedComponent,
    OutOfSyncComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    Ng4LoadingSpinnerModule,
    MsAdalAngular6Module,
    NgDompurifyModule
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHttpInterceptor,
    multi: true
  },{
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    // deps: [environment],
    deps: [],
    multi: true
  },
  {
    provide: SANITIZE_STYLE,
    useValue: ɵ_sanitizeStyle,
  },
  {
    provide : DatePipe
  },
  MsAdalAngular6Service,
  {
    provide: 'adalConfig',
    useFactory: msAdalAngular6ConfigFactory,
    deps: []
  },
  AuthenticationGuard]//,
  //bootstrap: [AppComponent]
  ,
  entryComponents: [AppComponent, MsalComponent, UnsuportedComponent]
})
export class AppModule {

  ngDoBootstrap(ref: ApplicationRef) {
    if (this.isIE()) {
      ref.bootstrap(UnsuportedComponent);
    }
    else if (window !== window.parent && !window.opener)
    {
      ref.bootstrap(MsalComponent);
    }
    else
    {
      ref.bootstrap(AppComponent);
    }
  }

  isIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    {
        return true;
    }
    else  // If another browser, return 0
    {
        return false;
    }
  }
}
