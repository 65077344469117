import { Component, OnInit } from '@angular/core';
import {  MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '@app/shared/services/user/user.service';

@Component({
  selector: 'app-invalid-user',
  templateUrl: './invalid-user.component.html',
  styleUrls: ['./invalid-user.component.scss']
})
export class InvalidUserComponent implements OnInit {

  constructor( 
       private _msAdal: MsAdalAngular6Service,
       private _spinnerService : Ng4LoadingSpinnerService,
       private _userService: UserService
     ) { }

  ngOnInit() {
    this._spinnerService.hide();
  }

  Logout(){
      localStorage.clear();
      sessionStorage.clear();
      this._userService.logOut();
    }

}
