import { DalContentConfiguration } from "../shared.module";
import { config } from "rxjs";
import { ItemVariable } from "@app/api/models";
import { formatDate } from "@angular/common";

/**
 * Processes an infill variable
 */
export class ProcessInfillVariable {
    applies(itemVariable: ItemVariable) : boolean {
      return false;
    }
  
    /**
     * InfillCode
     */
    public infillCode;
    /**
     * Gets a text information.
     * @param variable Item Variable
     * @param config 
     */
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {
      if (variable) {
        return `${variable.infillCode} [Not yet implemented]`;  
      }
      return `[Not yet implemented]`;
    }
  
    /**
     * Gets a preview of the variable wrapped
     * @param variable Item Variable
     * @param config Dal config
     */
    public getPreviewVariable(variable : ItemVariable, config : DalContentConfiguration) {
      const variableValue = this.getTextInformation(variable, config);
  
      // In Exporting Mode we don't need to wrap the variable.
      if(config.isForExport) {
        return variableValue;
      }
      return this.getWrappedVariable(variable, variableValue);
    }
  
      /**
     * Gets a wrapped instance of a Variable with the current value.
     * @param variable 
     * @param variableValue 
     */
    protected getWrappedVariable(variable : ItemVariable, variableValue : string) : string {
      let infillCode = variable ? variable.infillCode : this.infillCode;
      return `<span class="infill-change-value" value='${infillCode}'>${variableValue}</span>`;
    }
  
    /**
     * Converts a date into a String using local settings
     * @param dateISO Date expressed in ISO8601 Format
     */
    protected dateToString(dateISO : string) : string {
      if (dateISO == '' || dateISO == null) {
        return null;
      }
      return formatDate(dateISO, "dd MMM yyyy", 'en-GB');
    }
  
      /**
     * Converts a date into a String using local settings
     * @param num Date expressed in ISO8601 Format
     */
    protected numberToString(num : number) : string {
      return num.toPrecision(4);
    }
  }