import { Component, OnInit, Inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Subscription } from 'rxjs';
import { OrganizationDatasService } from '@app/api/services/organization-datas.service';
import { GlobalService } from '@app/shared/services/global/global.service';
import { environment } from '@env/environment';
import { DalDetailViewsService } from '@app/api/services/dal-detail-views.service';
import { BaseData, SectionView, DalContent } from '@app/api/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-request-clause',
  templateUrl: './request-clause.component.html',
  styleUrls: ['./request-clause.component.scss']
})

export class RequestClauseComponent implements OnInit, OnDestroy {
  divisionData: any;
  functionData: any;
  tooltTipPostion = ['after'];
  sectionData = [];
  levelData = ['One', 'Two', 'Three', 'Four', 'Five'];
  masterDataView: { id: string, listType: string, title: string }[];
  contentData = [];
  newClauseObj: Object;
  newClauseArray = [];
  selected: any;
  secLevel = '';
  subSectionSelected: any;
  subSectionSelectedOrder: any;
  clauseDesc = '';
  clauseHeading = '';
  quillEditorRef;
  private tableDD_DS;
  dispColumns: any[] = [];
  cob_DS = [];
  moa_DS = [];
  entity_DS = [];
  selectedCOB = '';
  selectedMOA = '';
  selectedENTITY = '';
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],                  // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                         // remove formatting button
      ],
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter');
            return true;
          }
        }
      }
    }
  }
  private _getDalSectionsSubscribe$: Subscription;
  private _raiseNewClsRqst$: any;
  @Output() submitNewClause: EventEmitter<any> = new EventEmitter();
  @Output() NewClauseLevel: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<RequestClauseComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _globalService: GlobalService,
    private _spinnerService: Ng4LoadingSpinnerService,
    private _organizationDatasService: OrganizationDatasService,
    private _dalDetailViewsService: DalDetailViewsService) {
    this._organizationDatasService.rootUrl = this._dalDetailViewsService.rootUrl = environment.ApiUrl;
  }

  ngOnInit() {
    this.dispColumns = ['class of business', 'method of acceptance', 'entity'];
    this.masterDataView = [];
    this.getOrganizationData();
    this.getDalSectionDate();
    this.getDataSourse();
  }

  ngOnDestroy() {
    if (this._getDalSectionsSubscribe$) {
      this._getDalSectionsSubscribe$.unsubscribe();
    }
    if (this._getDalSectionsSubscribe$) {
      this._getDalSectionsSubscribe$.unsubscribe();
    }
  }

  matSelectedOption(event) {
    if (event.listType.trim() === 'Class Of Business') {
      //  this.selectedCOB = event.title;
      this.masterDataView.push({ id: event.id, listType: event.listType, title: event.title });
    } else if (event.listType.trim() === 'Method Of Acceptance') {
      //  this.selectedMOA = event.title;
      this.masterDataView.push({ id: event.id, listType: event.listType, title: event.title });
    } else {
      //  this.selectedENTITY = event.title;
      this.masterDataView.push({ id: event.id, listType: event.listType, title: event.title });
    }
  }

  getDataSourse(): void {
    this.tableDD_DS = JSON.parse(sessionStorage.getItem('dataset'));
    if (this.tableDD_DS !== null) {
      this.cob_DS = this.tableDD_DS.items.filter(data => data.listType === 'Class Of Business');
      this.moa_DS = this.tableDD_DS.items.filter(data => data.listType === 'Method Of Acceptance');
      this.entity_DS = this.tableDD_DS.items.filter(data => data.listType === 'Entity');
    }
  }


  // fn to retrieve organization data from API
  getOrganizationData(): void {

    if (this._globalService.editDalObj) {
      this._globalService.organizationData_functions_arr = [];
      this._globalService.organizationData_divisions = '';
      this._globalService.editDalObj['organizationDataView'].forEach(element => {
        if (element['orgGroupType'] === 'DIVISIONS') {
          this._globalService.organizationData_divisions = element['orgGroupName'];
          this.divisionData = this._globalService.organizationData_divisions;
        }
        if (element['orgGroupType'] === 'FUNCTION') {
          this._globalService.organizationData_functions_arr.push(element['orgGroupName']);
          this.functionData = this._globalService.organizationData_functions_arr[0];
        }
      });
    }
  }

  // fn to load seactionView data in select option
  getDalSectionDate(): void {
    const dalStatus = this._globalService.dalStatus;
    const dalId = this._globalService.editDalId;
    this._getDalSectionsSubscribe$ = this._dalDetailViewsService.Get({
      WorkflowActions: false,
      TableConfigurations: true,
      Sections: true,
      DelegatorTables: true,
      DalId: dalId,
      Contents: true,
      Comments: false,
      SuspendReasons: true,
      RevokeReasons: true
    }).subscribe(resp => {
      this.sectionData = resp.sections;
      this.contentData = resp.contents;
    });
  }

  onOptionsSelected() {
    this.sectionData.find(t => t.title === this.selected);
  }

  onLevelValueSelected(value: any) {
    this.secLevel = value;
  }

  // fn to submit newly requested clause, if not found from existing api call
  requestNewClause(): void {
    // const newClasuseRqstArray: Array<BaseData> = [];
    const secViewObj: SectionView = {
      id: this.selected.id,
      title: this.selected.title,
      description: '',
      order: this.selected.order
    };
    const newClauseObj: DalContent = {
      sectionView: secViewObj,
      order: this.subSectionSelectedOrder,
      description: this.clauseDesc,
      title: this.clauseHeading,
      organizationDataView: this._globalService.editDalObj.organizationDataView,
      partitionKey: '',
      subType: 'Clause',
      status: 'Draft',
      clientId: '',
      type: 'DalContent',
      masterDataView: this.masterDataView
    };
    newClauseObj['isNewClause'] = true;

    const level = this.secLevel;
    this.submitNewClause.emit(newClauseObj);
    this.NewClauseLevel.emit(level);
    this.dialogRef.close(true);
  }

  // prevent to close model when clicking on outside
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  validateForSubmitBtn() {
    if (this.selectedCOB && this.selectedMOA && this.selectedENTITY && this.selected && this.clauseDesc) {
      return true;
    } else {
      return false;
    }

  }
}
