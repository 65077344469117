import { Component, OnInit, OnDestroy } from '@angular/core';
import { TenantService, UsersService } from '@app/api/services';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '@app/shared/services/user/user.service';
import { GlobalService } from '@app/shared/services/global/global.service';
import { User } from '@app/api/models';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  checkBox: boolean = false;
  _privacyPolicyText: string;
  selectedUserData: any;

  constructor(private _tenantService: TenantService,
    private _userService: UsersService,
    private _router: Router,
    private _globalService: GlobalService,
    private _spinnerService: Ng4LoadingSpinnerService,
    private _snackBar: MatSnackBar) {
    this._tenantService.rootUrl = environment.ApiUrl;
    this._userService.rootUrl = environment.ApiUrl;
  }

  ngOnInit() {
    console.log('privacy test');
    this.getPrivacyPolicyText();
  }

  getPrivacyPolicyText(){
    this._spinnerService.show();
    this._tenantService.GetTenantPrivacyPolicy()
    .subscribe(response => {
      this._spinnerService.hide();
      if(response){
        this._privacyPolicyText = response;
      }

    },
    (err: HttpErrorResponse) => {
      console.log(err.message);
      this._spinnerService.hide();
      return null
    }
  );
  }

  ngOnDestroy() {
    // Add unsuscribes
  }

  updateAcceptedTermsUser(){
    this._spinnerService.show();
    var user = this._globalService.userManager.currentLoggedUser;

    if(user){
      this._userService.AcceptTerms(user).subscribe(data=>{
        if(data){
          if (data.errors && data.errors.length > 0) {
            this._spinnerService.hide();
            this.showSnackBar(data.errors[0].message);
          }
          else
          {
             // this.getLoggedInUserDetails();
             this._globalService.userManager.currentLoggedUser = data.result;
             // Assign userRole to userService property
             let loggedInUserRole = [];
             for (let i = 0; i < data.result.userRoleView.length; i++) {
             loggedInUserRole.push((data.result.userRoleView[i].title).toUpperCase().trim());
             }
             console.log('user role (home):', loggedInUserRole);

             // store user role in local storage
             const uRole =loggedInUserRole;
             localStorage.setItem ('userRole', JSON.stringify(uRole));
             this._spinnerService.hide();
             // navigate based on user role
             this._router.navigate(["/"+sessionStorage.getItem('client') + '/admin']);
          }
        }
        else
        {
          this._spinnerService.hide();
          this.showSnackBar("User cannot be created.")
        }
      },(err: HttpErrorResponse) => {
        console.log(err.message);
        this._spinnerService.hide();
      })
    }
  }

     // snack bar to show message
  showSnackBar(msg: string): void {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }
}
