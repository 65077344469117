import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatTable, MatTableDataSource } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationTagsRelations } from '@app/api/models';
import { NotificationTags } from '@app/api/models/notification-tags';
import { NotificationTagsRelationsService, NotificationTagsService } from '@app/api/services';
import { environment } from '@env/environment';
import { HeapProfiler } from 'inspector';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';

@Component({
  selector: 'app-modal-tags-add',
  templateUrl: './modal-tags-add.component.html',
  styleUrls: ['./modal-tags-add.component.scss']
})
export class ModalTagsAddComponent implements OnInit {
  
  displayedColumns = ['select','code','description'];
  notificationTags_DS: MatTableDataSource<NotificationTags>; 

  //notificationTags_toTable: SelectableNotificationTags[];
  type :string;
  title:string;
  code:string;
  edit?:boolean;
  okText: string;
  cancelText: string;
  id :string;
  initialSelection = [];
  oldValues:string[] =[];
  allowMultiSelect = true;
  relationExist :string  = '-1';
  selection = new SelectionModel<NotificationTags>(this.allowMultiSelect, this.initialSelection);

  @ViewChild('notificationTags') table: MatTable<NotificationTags>;
 
 
  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _spinnerService: Ng4LoadingSpinnerService,
    private _notificationTagService : NotificationTagsService,
    private _notificationTagRelationService : NotificationTagsRelationsService,
    private _snackBar: MatSnackBar
    )
    {
      this._notificationTagService.rootUrl = environment.ApiUrl;
      this._notificationTagRelationService.rootUrl = environment.ApiUrl;
    }


  ngOnInit() {
    this.type = this.data.type;
    this.title = this.data.title;
    this.id= this.data.id;
    this.code = this.data.code;
    this.edit = this.data.edit;
    this.LoadNotificationTags();
  }

  close(): void {
    this.dialogRef.close();
  }

       /**
    * Get Tags data
    */
    LoadNotificationTags() {
    this._spinnerService.show();
    this._notificationTagService.Find({IsAvailable: true}).subscribe(async data => {
      if(data && data.results && data.results.length != 0)
      {
        this.notificationTags_DS = new MatTableDataSource(data.results.sort((a,b)=> a.code > b.code ?1 :a.code < b.code ? -1:0));
        let existingTags = await this.GetExistingNotificationTags();
    }
      else
      {
        this.notificationTags_DS = new MatTableDataSource();
          console.log("No Results"); 
          this._spinnerService.hide();
       }
    
      },
      (err:HttpErrorResponse) =>{
        console.log(err.message);
        this._spinnerService.hide();
      })
    };

    GetExistingNotificationTags() 
    {
 
      this._notificationTagRelationService.FindAllowed( 
        {
          Subtype : this.type,
          PartentId:this.id,
        }
        ).subscribe(data => {
          if (data && data.results && data.results.length > 0)
          {
            this.relationExist = data.results[0].id;
           this.oldValues = data.results[0].notificationTags;   
          }
                  
          this.notificationTags_DS.data.forEach(element => {
         
             data.results.forEach(rel => {
              let aux = rel.notificationTags.filter(x=> x == element.id);
              if (aux && aux.length>0)
              {
                this.initialSelection.push(element);
              }
             });
          })
          this.selection = new SelectionModel<NotificationTags>(this.allowMultiSelect, this.initialSelection);
          this._spinnerService.hide();
        },(err:HttpErrorResponse) => {
          console.log(err.message);
        });
    };


    onYesClick()
    {

      let ntlist:string[]=[];
      this.selection.selected.forEach(element => {
        ntlist.push(element.id);
      });

       const newNTRel : NotificationTagsRelations = {
        subtype: this.type,
        parentId: this.id,
        notificationTags: ntlist,
        clientId:'',
      }

      this._spinnerService.show();
      if( JSON.stringify(this.oldValues) != JSON.stringify(ntlist))
      {
        if( this.relationExist != '-1')
            {
              newNTRel.id = this.relationExist;
              this._notificationTagRelationService.UpdateNTRelations(newNTRel).subscribe(
                data=>{
                   if(data.errors && data.errors.length >0)
                  {
                    this.showValidationMessage("The Notification Tag"+(newNTRel.notificationTags.length > 1?"s were " : " was ") +" not updated due to some errors." );
                    console.log(data.errors);
                  }
                  else
                  {
                    this.showValidationMessage("The Notification Tag"+(newNTRel.notificationTags.length > 1?"s were " : " was ") +" updated successfully." );
                  }
                },(err:HttpErrorResponse) => {
                  this.showValidationMessage("The Notification Tag"+(newNTRel.notificationTags.length > 1?"s were " : " was ") +" not updated due to some errors." );
                  console.log(err.message);
                });
                this._spinnerService.hide();
              }
                else
                {
                  if(newNTRel.notificationTags.length>0 )
                  {
                    this._notificationTagRelationService.InsertNTRelations(newNTRel).subscribe(
                      data=>{
                        if(data.errors && data.errors.length > 0)
                        {
                          // Show error message;
                          this.showValidationMessage("The Notification Tag"+(newNTRel.notificationTags.length > 1?"s were " : " was ") +" not updated due to some errors." );
                          console.log(data.errors);
                        }
                        else
                        {
        
                        this.showValidationMessage("The Notification Tag"+(newNTRel.notificationTags.length > 1?"s were " : " was ") +"updated successfully." );
                        }
                      },(err:HttpErrorResponse) => {
                        this.showValidationMessage("The Notification Tag"+(newNTRel.notificationTags.length > 1?"s were " : " was ") +" not updated due to some errors." );
                        console.log(err.message);
                      });
                  }
                
                    this._spinnerService.hide();
                }

      }
      else
      {
        this._spinnerService.hide();
      }
   
    this.dialogRef.close();
    }
    
    showValidationMessage(msg: string) {
      const message = msg;
      const action = '';
      this._snackBar.open(message, action, {
        duration: 3000
      });
    }
}
