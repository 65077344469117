import { Component, OnInit, Inject } from '@angular/core';
import { LibrarySection } from '@app/api/models/library-section';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LibrarySectionService } from '@app/api/services/library-section.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationDataView, Division } from '@app/api/models';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { OrganizationDatasService, DivisionService, FunctionService } from '@app/api/services';
import { environment } from '@env/environment';
import { DalContentConfiguration } from '@app/shared/shared.module';

@Component({
  selector: 'app-add-library-section-modal-dialog',
  templateUrl: './add-library-section-modal-dialog.component.html',
  styleUrls: ['./add-library-section-modal-dialog.component.scss']
})
export class AddLibrarySectionModalDialogComponent implements OnInit {

  filter:LibrarySectionFilter = new LibrarySectionFilter();
  types:any = [];
  dataSource:LibrarySection[]=[];
  displayedColumns: string[] = ['select','name', 'division', 'function'];
  selectedLibrarySections:LibrarySection[] = [];
  divisionsForSearch: Array<OrganizationDataView>;
  functionsForSearch: Array<OrganizationDataView>;
  sections:any[];
  dalContentConfiguration : DalContentConfiguration;
  showButtons:boolean = false;
  searchClicked:boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<AddLibrarySectionModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _spinnerService: Ng4LoadingSpinnerService,
    private _organizationDataService : OrganizationDatasService,
    private librarySectionService:LibrarySectionService,
    private divisionService: DivisionService,
    private functionService: FunctionService) {
      this._organizationDataService.rootUrl = environment.ApiUrl;
      this.librarySectionService.rootUrl = environment.ApiUrl;
      this.divisionService.rootUrl = environment.ApiUrl;
      this.functionService.rootUrl = environment.ApiUrl;
      if (data)
      {
        if(data.sections)
          this.sections = data.sections;

        if(data.dalContentConfiguration) {
          this.dalContentConfiguration = data.dalContentConfiguration;
        }
      }
    }

  close(): void {
    this.dialogRef.close();
  }

  getOpenedMenu( isOpen :boolean){
    console.log('getOpenedMenu: '+isOpen)
    this.close()
  }

  ngOnInit() {
    this.loadDivisionAndFunctions();
  }

  
  loadDivisionAndFunctions() {

    // First searchs in the dalContentConfiguration

    if (this.dalContentConfiguration != null &&
      this.dalContentConfiguration.functions != null &&
      this.dalContentConfiguration.functions.length > 0
    ) {
      this.functionsForSearch = this.dalContentConfiguration.functions;
      if(this.functionsForSearch.length == 1)
        this.filter.functionSection = this.functionsForSearch[0];
    } else {
      this._spinnerService.show();
      this.functionService.GetActiveFunctions()
      .subscribe(response => {
        this.functionsForSearch = response;
        this._spinnerService.hide();
      },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          console.log(err.message);
        }
      );
    }

    if (this.dalContentConfiguration != null &&
      this.dalContentConfiguration.divisions != null &&
      this.dalContentConfiguration.divisions.length > 0
    ) {
      this.divisionsForSearch = this.dalContentConfiguration.divisions;
      if(this.divisionsForSearch.length == 1)
        this.filter.division = this.divisionsForSearch[0];
    } else {
      this._spinnerService.show();
      this.divisionService.GetActiveDivisions()
      .subscribe(response => {
        this.divisionsForSearch = response;
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
    }
  }


  search() {
    this._spinnerService.show();

    let onlyInThisDivisions : string[] = null;
    let onlyInThisFunctions : string[] = null;

    // Only needs to search in this division and functions
    if (this.dalContentConfiguration != null) {
      if (this.dalContentConfiguration.divisions != null) {
        onlyInThisDivisions = this.dalContentConfiguration.divisions.map(x => x.orgGroupName);
      }

      if (this.dalContentConfiguration.functions != null) {
        onlyInThisFunctions = this.dalContentConfiguration.functions.map(x => x.orgGroupName);
      }
    }

    this.librarySectionService.Find({
        DivisionName: this.filter.division != null ? this.filter.division.orgGroupName : "",
        FunctionName: this.filter.functionSection  != null ? this.filter.functionSection.orgGroupName : "",
        Title: this.filter.name.replace(/  +/g, ' '), // replace to remove double space,
        OnlyInTheseDivisions: onlyInThisDivisions,
        OnlyInTheseFunctions: onlyInThisFunctions,
        IsAvailable: true
    })
    .subscribe(response =>
      {
        this._spinnerService.hide();
        this.searchClicked = true;
        if(response.errors.length == 0){
          if(this.sections){
            var results = response.results.filter(x => 
              !this.sections.find(y => y.sectionBase.id == x.id));
            this.dataSource = results;
          }else
            this.dataSource = response.results;
          
          if(this.dataSource.length == 0)
            this.showButtons = false;
          else
            this.showButtons = true;
        }
        else
          response.errors.forEach(x => console.log(x.message));
      },
      (err: HttpErrorResponse) => {
        this.searchClicked = false;
        this._spinnerService.hide();
        console.log(err.message);
      }
    );
  }

  checkSelected(value:any, libraryItem:any) {
    if(value.checked){
      this.selectedLibrarySections.push(libraryItem);
    }else{
      this.selectedLibrarySections = this.selectedLibrarySections.filter( x => x.id != libraryItem.id);
    }
  }

  getDivisionDescription(organizationDatas: Array<OrganizationDataView>) {
    if(!organizationDatas) {
      return null;
    } 
    if(organizationDatas.length == 0){
      return null;
    }
    var division = organizationDatas.find(data => data.orgGroupType === "DIVISIONS");

    if(!division){
      return null;
    }

    return division.orgGroupName;
  }

  getFunctionDescription(organizationDatas: Array<OrganizationDataView>){
    if(!organizationDatas){
      return null;
    } 
    if(organizationDatas.length == 0){
      return null;
    }
    var _function = organizationDatas.find(data => data.orgGroupType === "FUNCTION");

    if(!_function){
      return null;
    }

    return _function.orgGroupName;
  }

  add(): void {
    this.dialogRef.close(this.selectedLibrarySections);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

export class LibrarySectionFilter{
  name:string="";
  division:any;
  functionSection:any;
}