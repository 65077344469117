import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Inject, Pipe, PipeTransform, Directive, ElementRef, NgZone, ChangeDetectorRef, OnDestroy, Input, ViewChild, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ok } from 'assert';
import { OverlayModule } from '@angular/cdk/overlay';
import { DalComponent } from '@app/view-dal/dal/dal.component';
import angular from 'angular';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

import * as moment from 'moment';

@Component({
  selector: 'app-modal-validation',
  templateUrl: './modal-validation.component.html',
  styleUrls: ['./modal-validation.component.scss']
})


export class ModalValidationComponent implements OnInit {
  // @Input() title: string;
  @Input() title: string = "DAL Validations";
  @Input() message: any;
  @Input() additionalText: string = "validation problem(s) were found. It is necessary to correct them before proceeding.";
  @Input() transaction: string;
  fileName: string;
  okText: string;
  cancelText: string;
  @Input() dataDAL: string;
  dateText: string;
  confirmRevoke: boolean = false;
  valuedrag: boolean = true;
  showTemplates: boolean = true;
  @Output() PasameElPueblo = new EventEmitter();
  position: any;
  center: any;

  currentHours:any;

  constructor(
    // public dialogRef: MatDialogRef<ModalValidationComponent>,
    // @Inject(MAT_DIALOG_DATA) private data: any,
    // @Inject(MAT_DIALOG_DATA) public options: { positionRelativeToElement: ElementRef }
    private _datepipe: DatePipe
  ) {


  }


  // close(): void {
  //   this.showTemplates = false;
  // }

  close(event) {
    this.showTemplates = false;
    // Usamos el método emit
    this.PasameElPueblo.emit({ nombre: this.showTemplates });
  }
  getCurrentLocaleDate()
  {
    let dateTimeAux = new Date();
    return new Date(dateTimeAux.getFullYear(), dateTimeAux.getMonth(), dateTimeAux.getDate());
  }

  ngOnInit() {
    var dateWithouthSecond = new Date();
    this.currentHours = dateWithouthSecond.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit',hour12: false});

    this.dateText = moment.default(this.getCurrentLocaleDate()).toLocaleString();
    var doc = document.documentElement;
    var docu = document ;
    var rest = doc.scrollTop > 0 ? doc.scrollTop + 450 : 450;
    this.position = -(doc.scrollHeight - rest);
    if (docu.location.href.includes('delegation-tree')) {
      this.center = document.body.clientHeight;
    } else {

      this.center = 396;
    }
  }
  onYesClick() {
    this.confirmRevoke = true;
  }

  onclicDrag = function ($event) {
    this.valuedrag = false;
    var $li = $event.currentTarget.parentNode;
    angular.element($li).attr("draggable", "false");
    angular.element($li).attr("ng-reflect-ng-draggable", "false");

    var element = document.getElementById("draggable");
    element.classList.remove("ng-dragging");
    element.classList.remove("ng-draggable");
  }

  onDrag = function ($event) {
    this.valuedrag = true;
    // var $li = $event.currentTarget.parentNode;
    // angular.element($li).attr("draggable","true");
    // angular.element($li).attr("ng-reflect-ng-draggable","true");

    // var element = document.getElementById("draggable");
    // element.classList.add("ng-dragging");
    // element.classList.add("ng-draggable");
  }
  // $('.selector').draggable({ cancel: '.text' }); 

  /**
   * find li element and Enalbe the draggable feature, on blur of the editable input
   */
  enableDrag = function ($event) {
    this.valuedrag = false;

  }

  downloadTxt() {
    var items = this.message;

    var dalSection = this.message.reduce((r, a) => {

      r[a.dalSection] = [...r[a.dalSection] || [], a];
      return r;
    }, {});



    for (var i in dalSection) {

      console.log(i);
      var dalSubSections = dalSection[i].reduce((r, a) => {

        if (a.dalSubSections != null) {
          r[a.dalSubSections] = [...r[a.dalSubSections] || [], a];
          return r;
        }

      }, {});



      if (dalSubSections == null) {
        var library = dalSection[i].reduce((r, a) => {

          if (a.library != null) {
            r[a.library] = [...r[a.library] || [], a];
            return r;
          }

        }, {});
        for (var i in library) {
          console.log(i);

          library[i].forEach(element => {
            console.log(element.message);
          });

        }

      } else {

        for (var i in dalSubSections) {

          console.log(i);

        }

        var library = dalSubSections[i].reduce((r, a) => {

          if (a.library != null) {
            r[a.library] = [...r[a.library] || [], a];
            return r;
          }

        }, {});
        for (var i in library) {
          console.log(i);

          library[i].forEach(element => {
            console.log(element.message);
          });

        }

      }


    }

    var csv = '';
    var row = 0;
    csv += items.length + ' ' + this.additionalText + '\r\n'
    csv += 'Transaction: ' + this.transaction + '\r\n'
    csv += 'Date: ' + this._datepipe.transform(this.dateText,'dd MMM yyyy') + ' ' + this.currentHours + ' Hs.'  + '\r\n'
    csv += 'DAL: ' + this.dataDAL + '\r\n'
    for (var i in dalSection) {

      csv += i + '\r\n';
      var dalSubSections = dalSection[i].reduce((sr, a) => {

        if (a.dalSubSections != null) {
          sr[a.dalSubSections] = [...sr[a.dalSubSections] || [], a];
          return sr;
        }

      }, {});



      if (dalSubSections == null) {
        var library = dalSection[i].reduce((r, a) => {

          if (a.library != null) {
            r[a.library] = [...r[a.library] || [], a];
            return r;
          }

        }, {});
        for (var i in library) {
          csv += i + '\r\n';

          library[i].forEach(element => {

            csv += '  ' + "#" + element.message + '\r\n';

          });

        }

      } else {

        for (var i in dalSubSections) {

          csv += i + '\r\n';

        }

        var library = dalSubSections[i].reduce((r, a) => {

          if (a.library != null) {
            r[a.library] = [...r[a.library] || [], a];
            return r;
          }

        }, {});
        for (var i in library) {
          csv += i + '\r\n';

          library[i].forEach(element => {
            csv += '  ' + "#" + element.message + '\r\n';
          });

        }

      }

    }

    // print document

    var a = document.createElement('a');
    var blob = new Blob([csv], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;

    var d = new Date();

    var datestring =  d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + ":" +
      d.getHours() + ":" + d.getMinutes();
    a.download = "DAL " + this.dataDAL + '_' + datestring + '_' + "validation.txt";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}

