/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Country } from '../models/country';
import { PagedCollectionResponseCountry } from '../models/paged-collection-response-country';
@Injectable({
  providedIn: 'root',
})
class CountryService extends __BaseService {
  static readonly InsertCountryPath = '/api/Country/InsertCountry';
  static readonly UpdateCountryPath = '/api/Country/UpdateCountry';
  static readonly GetCountriesPath = '/api/Country/GetCountries';
  static readonly DisablePath = '/api/Country/Disable';
  static readonly EnablePath = '/api/Country/Enable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param country undefined
   * @return Success
   */
  InsertCountryResponse(country?: Country): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = country;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Country/InsertCountry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param country undefined
   * @return Success
   */
  InsertCountry(country?: Country): __Observable<boolean> {
    return this.InsertCountryResponse(country).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param country undefined
   * @return Success
   */
  UpdateCountryResponse(country?: Country): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = country;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Country/UpdateCountry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param country undefined
   * @return Success
   */
  UpdateCountry(country?: Country): __Observable<boolean> {
    return this.UpdateCountryResponse(country).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetCountriesResponse(): __Observable<__StrictHttpResponse<PagedCollectionResponseCountry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Country/GetCountries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedCollectionResponseCountry>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCountries(): __Observable<PagedCollectionResponseCountry> {
    return this.GetCountriesResponse().pipe(
      __map(_r => _r.body as PagedCollectionResponseCountry)
    );
  }

  /**
   * @param countryId undefined
   * @return Success
   */
  DisableResponse(countryId?: string): __Observable<__StrictHttpResponse<Country>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (countryId != null) __params = __params.set('countryId', countryId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Country/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Country>;
      })
    );
  }
  /**
   * @param countryId undefined
   * @return Success
   */
  Disable(countryId?: string): __Observable<Country> {
    return this.DisableResponse(countryId).pipe(
      __map(_r => _r.body as Country)
    );
  }

  /**
   * @param countryId undefined
   * @return Success
   */
  EnableResponse(countryId?: string): __Observable<__StrictHttpResponse<Country>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (countryId != null) __params = __params.set('countryId', countryId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Country/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Country>;
      })
    );
  }
  /**
   * @param countryId undefined
   * @return Success
   */
  Enable(countryId?: string): __Observable<Country> {
    return this.EnableResponse(countryId).pipe(
      __map(_r => _r.body as Country)
    );
  }
}

module CountryService {
}

export { CountryService }
