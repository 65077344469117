import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";
import { DatePipe } from "@angular/common";
import * as moment from 'moment';

/**
 * [DAC] => Dal Acknowledgement Date
 */
@Injectable({
  providedIn: 'root',
})
export class DalAcknowledgementDateInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor(private datePipe: DatePipe = new DatePipe('en-US'),) {
      super();
      this.infillCode = "[DAC]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

    if (config.acknowledgementDate == null)
      return "N/A";
    else if (config.acknowledgementDate)
    {
      if(config.acknowledgementDate.includes("<span")){
        return config.acknowledgementDate;
      }

      else{
        return  this.datePipe.transform(moment.default(config.acknowledgementDate).toLocaleString(),'dd MMM yyyy ');
      }
      
       
    }
     

    return this.infillCode;
    }
  }