/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EntityResultDal } from '../models/entity-result-dal';
import { CopyDalParam } from '../models/copy-dal-param';
import { DalTemplate } from '../models/dal-template';
import { DalTemplateSearchCriteria } from '../models/dal-template-search-criteria';
import { OrganizationDataView } from '../models/organization-data-view';
import { CollectionResultDalTemplate } from '../models/collection-result-dal-template';
import { EntityResultDalTemplate } from '../models/entity-result-dal-template';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class DalTemplateService extends __BaseService {
  static readonly CopyDefaultAsyncPath = '/api/DalTemplate/CopyDefaultAsync';
  static readonly GetTemplatesForDelegationPath = '/api/DalTemplate/GetTemplatesForDelegation';
  static readonly GetAllowedOrganizationDataPath = '/api/DalTemplate/GetAllowedOrganizationData';
  static readonly FindPath = '/api/DalTemplate/Find';
  static readonly FindAllowedPath = '/api/DalTemplate/FindAllowed';
  static readonly InsertPath = '/api/DalTemplate';
  static readonly UpdatePath = '/api/DalTemplate';
  static readonly DeletePath = '/api/DalTemplate';
  static readonly EnablePath = '/api/DalTemplate/Enable';
  static readonly DisablePath = '/api/DalTemplate/Disable';
  static readonly GetAllPath = '/api/DalTemplate/GetAll';
  static readonly GetByIdPath = '/api/DalTemplate/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param parameters undefined
   * @return Success
   */
  CopyDefaultAsyncResponse(parameters?: CopyDalParam): __Observable<__StrictHttpResponse<EntityResultDal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = parameters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplate/CopyDefaultAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDal>;
      })
    );
  }
  /**
   * @param parameters undefined
   * @return Success
   */
  CopyDefaultAsync(parameters?: CopyDalParam): __Observable<EntityResultDal> {
    return this.CopyDefaultAsyncResponse(parameters).pipe(
      __map(_r => _r.body as EntityResultDal)
    );
  }

  /**
   * @param searchCriteria undefined
   * @return Success
   */
  GetTemplatesForDelegationResponse(searchCriteria?: DalTemplateSearchCriteria): __Observable<__StrictHttpResponse<Array<DalTemplate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchCriteria;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplate/GetTemplatesForDelegation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DalTemplate>>;
      })
    );
  }
  /**
   * @param searchCriteria undefined
   * @return Success
   */
  GetTemplatesForDelegation(searchCriteria?: DalTemplateSearchCriteria): __Observable<Array<DalTemplate>> {
    return this.GetTemplatesForDelegationResponse(searchCriteria).pipe(
      __map(_r => _r.body as Array<DalTemplate>)
    );
  }

  /**
   * @return Success
   */
  GetAllowedOrganizationDataResponse(): __Observable<__StrictHttpResponse<Array<OrganizationDataView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplate/GetAllowedOrganizationData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationDataView>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllowedOrganizationData(): __Observable<Array<OrganizationDataView>> {
    return this.GetAllowedOrganizationDataResponse().pipe(
      __map(_r => _r.body as Array<OrganizationDataView>)
    );
  }

  /**
   * @param params The `DalTemplateService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OrganizationDataView`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindResponse(params: DalTemplateService.FindParams): __Observable<__StrictHttpResponse<CollectionResultDalTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    (params.OrganizationDataView || []).forEach(val => {if (val != null) __params = __params.append('OrganizationDataView', val.toString())});
    (params.OnlyInTheseFunctions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseFunctions', val.toString())});
    (params.OnlyInTheseDivisions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseDivisions', val.toString())});
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.HasFunctions != null) __params = __params.set('HasFunctions', params.HasFunctions.toString());
    if (params.HasDivisions != null) __params = __params.set('HasDivisions', params.HasDivisions.toString());
    if (params.FunctionName != null) __params = __params.set('FunctionName', params.FunctionName.toString());
    if (params.DivisionName != null) __params = __params.set('DivisionName', params.DivisionName.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplate/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDalTemplate>;
      })
    );
  }
  /**
   * @param params The `DalTemplateService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OrganizationDataView`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  Find(params: DalTemplateService.FindParams): __Observable<CollectionResultDalTemplate> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultDalTemplate)
    );
  }

  /**
   * @param searchCriteria undefined
   * @return Success
   */
  FindAllowedResponse(searchCriteria?: DalTemplateSearchCriteria): __Observable<__StrictHttpResponse<CollectionResultDalTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchCriteria;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplate/FindAllowed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDalTemplate>;
      })
    );
  }
  /**
   * @param searchCriteria undefined
   * @return Success
   */
  FindAllowed(searchCriteria?: DalTemplateSearchCriteria): __Observable<CollectionResultDalTemplate> {
    return this.FindAllowedResponse(searchCriteria).pipe(
      __map(_r => _r.body as CollectionResultDalTemplate)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: DalTemplate): __Observable<__StrictHttpResponse<EntityResultDalTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DalTemplate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDalTemplate>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: DalTemplate): __Observable<EntityResultDalTemplate> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultDalTemplate)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: DalTemplate): __Observable<__StrictHttpResponse<EntityResultDalTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDalTemplate>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: DalTemplate): __Observable<EntityResultDalTemplate> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultDalTemplate)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/DalTemplate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplate/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplate/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultDalTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplate/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDalTemplate>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultDalTemplate> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultDalTemplate)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultDalTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplate/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDalTemplate>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultDalTemplate> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultDalTemplate)
    );
  }
}

module DalTemplateService {

  /**
   * Parameters for Find
   */
  export interface FindParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    OrganizationDataView?: Array<any>;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
    Name?: string;
    IsAvailable?: boolean;
    HasFunctions?: boolean;
    HasDivisions?: boolean;
    FunctionName?: string;
    DivisionName?: string;
    Description?: string;
    ContinuationToken?: string;
  }

  /**
   * Parameters for FindAllowed
   */
  export interface FindAllowedParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    OrganizationDataView?: Array<any>;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
    Name?: string;
    IsAvailable?: boolean;
    HasFunctions?: boolean;
    HasDivisions?: boolean;
    FunctionName?: string;
    DivisionName?: string;
    Description?: string;
    ContinuationToken?: string;
  }
}

export { DalTemplateService }
