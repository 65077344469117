import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { LibraryItem, DalLibraryItem, DalTemplateLibraryItem, ItemVariable, ItemVariableValueView, Currency, ExchangeRate } from '@app/api/models';
import { ProcessBasicInfillVariable } from '@app/shared/infill-processing/process-basic-infill-variable';
import { BaseDatasService, OrganizationDatasService, CurrencyService, TenantService, ExchangeRateService } from '@app/api/services';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, Validators, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { formatNumber } from '@angular/common';
import { BigNumber } from "bignumber.js";

@Component({
  selector: 'app-modal-basic-variable',
  templateUrl: './modal-basic-variable.component.html',
  styleUrls: ['./modal-basic-variable.component.scss']
})
export class ModalBasicVariableComponent implements OnInit {
  currencyControl = new FormControl({ value: '' });
  amountControl = new FormControl({ value: '' });
  textControl = new FormControl({ value: '' });
  numberControl = new FormControl({ value: '' });
  title: string;
  message: string;
  okText: string;
  cancelText: string;
  confirmRevoke: boolean = false;
  dalContentConfiguration: DalContentConfiguration;
  currentLibraryItem: LibraryItem | DalLibraryItem | DalTemplateLibraryItem;
  variable: ItemVariable;
  textValue: string;
  numberValue: number;
  amountValue: string;
  currencies: Currency[];
  currencySelected: Currency;
  _baseCurrencyCode:string;
  chkSkipValidation = false;
  previewLibraryItem: boolean;
  showAmountError: boolean = false;
  isNaN:boolean=false;
  isIntegerError:boolean=false;
  isDecimalError:boolean=false;
  numPercDecimalError:boolean=false;
  exchangeRate: string;

  constructor(
    private _exchangeRateService: ExchangeRateService,
    private _baseDatasService: CurrencyService,
    private _tenantService: TenantService,
    public dialogRef: MatDialogRef<ModalBasicVariableComponent>,
    public dbv: ProcessBasicInfillVariable,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private spinnerService: Ng4LoadingSpinnerService) {
    this.dalContentConfiguration = data.dalContentConfiguration;
    this.currentLibraryItem = data.currentLibraryItem;
    this.variable = data.variable;
    this.previewLibraryItem = data.previewLibraryItemMode;
    this._baseDatasService.rootUrl = environment.ApiUrl;
    this._tenantService.rootUrl = environment.ApiUrl;
    this._exchangeRateService.rootUrl = environment.ApiUrl;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log('openAnount');
    if (this.variable) {
      if (this.variable.variableCode == 'T') {
        if (this.variable.currentValue) {
          this.textValue = this.variable.currentValue.valueString;
        } else if (this.variable.defaultValue) {
          this.textValue = this.variable.defaultValue.valueString;
        }
      }

      if (this.variable.variableCode == '#') {
                if (this.variable.currentValue) {
          let amountString = '';
          if (this.variable.currentValue && this.variable.currentValue.valueNumber &&
              this.variable.currentValue.valueNumber != 0 )
            amountString = formatNumber(this.variable.currentValue.valueNumber,"en-US");
          else {
            var regExp = new RegExp(this.getGroupSeparator(), "g");
            let number = new BigNumber(this.variable.currentValue.valueString.replace(regExp,''));
            if (number.decimalPlaces() > 0) {
              amountString = number.toFormat(2);
            }
            else {
              amountString = number.toFormat();
            }
          }

          this.amountValue = amountString;

        } else if (this.variable.defaultValue) {
          let defaultString = '';
          if (this.variable.defaultValue && !isNaN(this.variable.defaultValue.valueNumber)
          && this.variable.defaultValue.valueNumber != null )
            defaultString = formatNumber(this.variable.defaultValue.valueNumber,"en-US");
          else{
            var regExp = new RegExp(this.getGroupSeparator(), "g");
            let number = new BigNumber(this.variable.defaultValue.valueString.replace(regExp,''));
            if (number.decimalPlaces() > 0) {
              defaultString = number.toFormat(2);
            }
            else {
              defaultString = number.toFormat();
            }
          }

          this.amountValue = defaultString;
        }
      }

      if (this.variable.variableCode == '%') {
        if (this.variable.currentValue) {
          this.numberValue = this.variable.currentValue.valueNumber;
        } else if (this.variable.defaultValue) {
          this.numberValue = this.variable.defaultValue.valueNumber;
        }
      }

      if (this.variable.variableCode == 'A') {
        if(this.dalContentConfiguration.baseCurrencyCode) {
          this._baseCurrencyCode = this.dalContentConfiguration.baseCurrencyCode;
        }

        if (this.variable.currentValue) {
          let amountString = '';
          if (this.variable.currentValue && !isNaN(this.variable.currentValue.valueNumber)
           && this.variable.currentValue.valueNumber!= null )
            amountString = formatNumber(this.variable.currentValue.valueNumber,"en-US");
          else {
            var regExp = new RegExp(this.getGroupSeparator(), "g");
            let number = new BigNumber(this.variable.currentValue.valueString.replace(regExp,''));
            if (number.decimalPlaces() > 0) {
              amountString = number.toFormat(2);
            }
            else {
              amountString = number.toFormat();
            }
          }

          this.amountValue = amountString;
          this.chkSkipValidation = this.variable.currentValue.skipValidation;
          this.currencies = [];
          if (this.variable.currentValue.currencyCode)
          {
            this.currencySelected = {
              code : this.variable.currentValue.currencyCode,
              symbol : this.variable.variableDescription,
              clientId: null,
            };
          }

        } else if (this.variable.defaultValue) {
          let defaultString = '';
          if (this.variable.defaultValue && !isNaN(this.variable.defaultValue.valueNumber)
           && this.variable.defaultValue.valueNumber!= null )
            defaultString = formatNumber(this.variable.defaultValue.valueNumber,"en-US");
          else{
            var regExp = new RegExp(this.getGroupSeparator(), "g");
            let number = new BigNumber(this.variable.defaultValue.valueString.replace(regExp,''));
            if (number.decimalPlaces() > 0) {
              defaultString = number.toFormat(2);
            }
            else {
              defaultString = number.toFormat();
            }
          }

          this.amountValue = defaultString;
          this.chkSkipValidation = this.variable.defaultValue.skipValidation;
          this.currencies = [];
          if (this.variable.defaultValue.currencyCode)
          {
            this.currencySelected = {
              code : this.variable.defaultValue.currencyCode,
              symbol : this.variable.variableDescription,
              clientId: null,
            };
          }
        }

        this.spinnerService.show();

        // Initializing from DB
        this._baseDatasService.GetActiveCurrencies()
        .subscribe(response => {

          this._tenantService.GetTenantCurrency()
          .subscribe(response => {

            this._baseCurrencyCode = response;
          },
          (err: HttpErrorResponse) => {
            console.log(err.message);
          }
        );

          let currencies: any = response;
          this.currencies = currencies;

          if (this.variable) {
              if (this.variable.variableCode == 'A') {
                let code = null;
                if (this.variable.currentValue) {
                  code = this.variable.currentValue.currencyCode;
                } else if (this.variable.defaultValue) {
                  code = this.variable.defaultValue.currencyCode;
                }

                if (code) {
                  this.currencySelected =  this.currencies.find(x => x.code == code);

                  this.onCurrencySelected();
                }
              }
          }

          this.spinnerService.hide();

        },
          (err: HttpErrorResponse) => {
            this.spinnerService.hide();
            console.log(err.message);
          }
        );
      }
    }
  }

  onCurrencySelected(){
    if(this.currencySelected){
      if(this.currencySelected.code == this._baseCurrencyCode){
        this.exchangeRate = "1";
      } else{
        this._exchangeRateService.GetCurrentExchangeRate(this.currencySelected.id).subscribe(response => {
          if(response){
            if(response.value){
              this.exchangeRate = response.value.toString();
            } else{
              this.exchangeRate = "";
            }
          }else{
            this.exchangeRate = "";
          }
        }),
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      }
    }
  }

  onYesClick() {
    let newValue: ItemVariableValueView = {};
    this.showAmountError = false;
    this.numPercDecimalError = false;
  // when press ok, mark the LI to be update.
  this.currentLibraryItem.status = "Update";

    if (this.variable && this.variable.variableCode == 'T') {
      if (this.textValue) {
        newValue.valueString = this.textValue;
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      } else if (this.variable.defaultValue) {
        Object.assign(newValue, this.variable.defaultValue);
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      } else {
        this.variable.currentValue = null;
        this.dialogRef.close();
      }

    }

    if (this.variable && this.variable.variableCode == '%') {
      if (this.variable.variableCode == '%') {
        if (this.numberValue) {
          var sNumber = new BigNumber(this.numberValue);
          if(sNumber.decimalPlaces() > 2){
            this.numPercDecimalError = true;
            this.numberControl.setErrors({'incorrect':true});
            return;
          }
        }
      }

      if (this.numberValue) {
        newValue.valueNumber = this.numberValue;
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      } else if (this.variable.defaultValue) {
        Object.assign(newValue, this.variable.defaultValue);
        this.variable.currentValue = newValue;
        this.dialogRef.close();
      }
       else {
        this.variable.currentValue = null;
        this.dialogRef.close();
      }
    }

    if(this.variable && this.variable.variableCode == '#' ){
      if (!this.amountControl.valid) {
        this.amountControl.markAsTouched();
      }
      else {

        if (this.amountValue) {
          if (this.currencySelected){
            newValue.currencyCode = this.currencySelected.code;
            newValue.currencyDescription = this.currencySelected.symbol;
          }
          newValue.valueString = this.amountValue;
          //newValue.skipValidation = this.chkSkipValidation;
          this.variable.currentValue = newValue;
          this.dialogRef.close();
          } else if (this.variable.defaultValue) {
            Object.assign(newValue, this.variable.defaultValue);
            //newValue.skipValidation = this.chkSkipValidation;
            this.variable.currentValue = newValue;
            this.dialogRef.close();
          } else {
            this.variable.currentValue = null;
            this.dialogRef.close();
          }
        }
      }


    if (this.variable && this.variable.variableCode == 'A')  {
      if (!this.amountControl.valid) {
        this.amountControl.markAsTouched();
      }
      else {
        if (this.amountValue && (!this.currencySelected || !this.currencySelected.code || this.currencySelected.code == "")) {
          this.currencyControl.markAsTouched();
          this.showAmountError = true;
        }
        else {
        if (this.amountValue) {
          if (this.currencySelected){
            newValue.currencyCode = this.currencySelected.code;
            newValue.currencyDescription = this.currencySelected.symbol;
          }
          newValue.valueString = this.amountValue;
          newValue.skipValidation = this.chkSkipValidation;
          this.variable.currentValue = newValue;
          this.dialogRef.close();
          } else if (this.variable.defaultValue) {
            Object.assign(newValue, this.variable.defaultValue);
            newValue.skipValidation = this.chkSkipValidation;
            this.variable.currentValue = newValue;
            this.dialogRef.close();
          } else {
            this.variable.currentValue = null;
            this.dialogRef.close();
          }
        }
      }
    }
  
  }

  onFocus(control) {
    if(this.isNaN || this.isDecimalError || this.isIntegerError){
      return;
    }

    var regExp = new RegExp(this.getGroupSeparator(), "g");
    let number = new BigNumber(control.target.value.replace(regExp, ''));
    if (!isNaN(number.toNumber())){
      if (number.decimalPlaces() > 0) {
        control.target.value = number.toFixed(2);
      }
      else {
        control.target.value = number.toFixed();
      }
      this.amountValue = control.target.value;
    }
  }

  onBlur(control){
    this.isNaN = false;
    this.isIntegerError = false;
    this.isDecimalError = false;
    if (control.target.value == "" || !control.target.value)
    {
      control.target.value = null;
      this.amountControl.setErrors(null);
    }
    else
    {
      let number = new BigNumber(control.target.value);

      //add validation only if is not nAn
      if(isNaN(number.toNumber())){
        this.isNaN = true;
        this.amountControl.setErrors({'incorrect':true});
        return;
      }

      if (!isNaN(number.toNumber())){
        if ((number.precision(true) - number.decimalPlaces()) > 20) {
          this.isIntegerError = true;
          this.amountControl.setErrors({'incorrect':true});
          return;
        }
        if ( number.decimalPlaces() > 2) {
          this.isDecimalError = true;
          this.amountControl.setErrors({'incorrect':true});
          return;
        }
      }

      if (number.decimalPlaces() > 0) {
        control.target.value = number.toFormat(2);
      }
      else {
        control.target.value = number.toFormat();
      }
      this.amountControl.setErrors(null);
      this.amountValue = control.target.value;
    }
  }

  getGroupSeparator(){
    let currentLocale = this.getCurrentLocale('en-US');
    return this.getSeparator(currentLocale, 'group', ',');
  }

  getSeparator(locale, separatorType, defaultSep) {
    const numberWithGroupAndDecimalSeparator = 1000.1;
    let numIntl = new Intl.NumberFormat(locale);
    if (numIntl['formatToParts'] && numIntl['formatToParts'][separatorType]) {
      let info = numIntl['formatToParts'](numberWithGroupAndDecimalSeparator);
      return info.find(part => part.type === separatorType).value;
      }
    else
      return defaultSep;
  }

  getCurrentLocale(defaultLocale) {
    var isIE = (navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document['documentMode'] == true );
    if (isIE)
      return navigator['browserLanguage'];
    if (navigator.language)
      return navigator.language;

    return defaultLocale;
  }
}
