import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuditRecordService } from '@app/api/services/audit-record.service';
import { AuditData } from '@app/api/models/audit-data';
import * as moment from 'moment';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
 })
 export class AuditLogComponent implements OnInit {
  @Input() id: string;
  @Input() description: string;
  @Input() type: string;
  @Input() subType: string;
  @Input() childIdObject: string;
  @Input() typeDescription: string;
  @Output() closeModal = new EventEmitter<boolean>();
  filterCriteria:string;
  StartDate: Date;
  EndDate: Date;
  displayedColumns: string[] = ['dateAndTime','user', 'action', 'summary'];
  dataSource:AuditData[];
  dataSourceFiltered:AuditData[];

  constructor(
    private _spinnerService: Ng4LoadingSpinnerService,
    private auditRecordService: AuditRecordService) {
      this.auditRecordService.rootUrl = environment.ApiUrl;
    }

  ngOnInit() {
    console.log('audit')
    this.loadAudit();
  }

  loadAudit() {
    this._spinnerService.show();

    this.auditRecordService.GetAuditDataByIdType({
      idObject: this.id,
      subType: this.type
    })
    .subscribe(response =>
      {
        this.dataSourceFiltered = [];

        
        
        if(response != undefined && response != null && response.results != null) {
          this.dataSource = response.results;
          if (this.childIdObject && this.subType) {
            this.dataSource = response.results.filter(
                x => (x.childSubType == this.subType && x.childIdObject == this.childIdObject) ||
                x.inProgress  // Main transaction in progress
              );


          }
        }
        this.dataSourceFiltered = this.dataSource;

        if(this.dataSourceFiltered) {
          this.dataSourceFiltered.forEach(log => {
          log.createdDate = log.createdDate.substring(0, log.createdDate.length - 1)})
        }

        this._spinnerService.hide();
      },
      (err: HttpErrorResponse) => {
        this._spinnerService.hide();
        console.log(err.message);
      }
    );
  }

  close(){
    this.closeModal.emit(true);
  }

  userToShow(data: AuditData){
    if(data && data.summaryOfChanges){
      var findProcess = data.natureOfChanges.find(x => x == "DAL expired")
      if(findProcess){
        return "System Triggered"
      } else {
        return data.userMail;
      }
      }
  }

  getType():string{
    if(this.typeDescription)
      return this.typeDescription.toLocaleUpperCase();
    else if(this.subType)
      return this.getTypeToShow(this.subType);
    else
      return this.getTypeToShow(this.type);
  }

  getId():string{
    if(this.childIdObject)
      return this.childIdObject;
    else
      return this.id;
  }

  getTypeToShow(type:string){
    switch (type.toLocaleUpperCase()) {
      case 'DALLIBRARYITEM':
          return 'Library Item';
      case 'DALTEMPLATELIBRARYITEM':
        return 'Library Item';
      case 'LIBRARYITEM':
        return 'Library Item';
      case 'DALTEMPLATE':
        return 'DAL Template';
      default:
        return type.toUpperCase();
    }
  }

  clear(){
    this.StartDate = null;
    this.EndDate = null; 
    this.filterCriteria = null;
  }

  filterData() {
    this.dataSourceFiltered = this.dataSource;
    var startDate = this.StartDate !=null ? new Date(this.StartDate) : null;
    var endDate = this.EndDate != null ? new Date(this.EndDate) : null;

    this.filterCriteria = !this.filterCriteria ? "" : this.filterCriteria;

    if(this.filterCriteria){
      this.dataSourceFiltered = this.dataSourceFiltered.filter(x => 
        (x.userMail != null && x.userMail.toUpperCase().includes(this.filterCriteria.toUpperCase()))
        || ((x.summaryOfChanges && x.summaryOfChanges.filter(y => y.toUpperCase().includes(this.filterCriteria.toUpperCase())).length > 0)
        || (x.summaryOfChanges.length == 0 && 'Log Generation in Progress'.toUpperCase().includes(this.filterCriteria.toUpperCase())))
        || (x.natureOfChanges && x.natureOfChanges.filter(y => y.toUpperCase().includes(this.filterCriteria.toUpperCase()))).length > 0
        || (x.natureOfChanges.length == 0 && 'Log Generation in Progress'.toUpperCase().includes(this.filterCriteria.toUpperCase())));
    }

    if(startDate || endDate){
      //add 1 day to endDate 
      if(endDate){
        endDate = this.addDays(endDate,1);
      }
      var datesFiltered = [];

      if(this.dataSourceFiltered) {
        this.dataSourceFiltered.forEach(element => {
          let currentDate = new Date(element.createdDate)
          if(startDate && endDate){
            if(currentDate >= startDate && currentDate <= endDate) {
              datesFiltered.push(element);
            }

          } else if (startDate){
            if(currentDate >= startDate ) {
              datesFiltered.push(element);
            }

          } else{
            if(currentDate <= endDate ) {
              datesFiltered.push(element);
            }
          }
        });
      }

      this.dataSourceFiltered = datesFiltered;
    }
 
  }

  addDays(date: Date, days: number): Date {​​
    date.setDate(date.getDate() + days);
    return date;
  }​​
}
