/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrganizationData } from '../models/organization-data';
@Injectable({
  providedIn: 'root',
})
class OrganizationDatasService extends __BaseService {
  static readonly GetPath = '/api/OrganizationDatas';
  static readonly PostPath = '/api/OrganizationDatas';
  static readonly GetByIdPath = '/api/OrganizationDatas/{id}';
  static readonly PutPath = '/api/OrganizationDatas/{id}';
  static readonly DeletePath = '/api/OrganizationDatas/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param OrgGroupType undefined
   * @return Success
   */
  GetResponse(OrgGroupType?: string): __Observable<__StrictHttpResponse<Array<OrganizationData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (OrgGroupType != null) __params = __params.set('OrgGroupType', OrgGroupType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrganizationDatas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationData>>;
      })
    );
  }
  /**
   * @param OrgGroupType undefined
   * @return Success
   */
  Get(OrgGroupType?: string): __Observable<Array<OrganizationData>> {
    return this.GetResponse(OrgGroupType).pipe(
      __map(_r => _r.body as Array<OrganizationData>)
    );
  }

  /**
   * @param organizationData undefined
   * @return Success
   */
  PostResponse(organizationData?: OrganizationData): __Observable<__StrictHttpResponse<OrganizationData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = organizationData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/OrganizationDatas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationData>;
      })
    );
  }
  /**
   * @param organizationData undefined
   * @return Success
   */
  Post(organizationData?: OrganizationData): __Observable<OrganizationData> {
    return this.PostResponse(organizationData).pipe(
      __map(_r => _r.body as OrganizationData)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<OrganizationData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrganizationDatas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationData>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<OrganizationData> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as OrganizationData)
    );
  }

  /**
   * @param params The `OrganizationDatasService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `organizationData`:
   *
   * @return Success
   */
  PutResponse(params: OrganizationDatasService.PutParams): __Observable<__StrictHttpResponse<OrganizationData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.organizationData;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/OrganizationDatas/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationData>;
      })
    );
  }
  /**
   * @param params The `OrganizationDatasService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `organizationData`:
   *
   * @return Success
   */
  Put(params: OrganizationDatasService.PutParams): __Observable<OrganizationData> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as OrganizationData)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<OrganizationData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/OrganizationDatas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationData>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<OrganizationData> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as OrganizationData)
    );
  }
}

module OrganizationDatasService {

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    organizationData?: OrganizationData;
  }
}

export { OrganizationDatasService }
