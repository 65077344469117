import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ReportSchedule } from '@app/api/models';
import { ReportScheduleService} from '@app/api/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: ['DD MMM YYYY','DD/MM/YYYY'], 
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-schedule-report-modal-dialog',
  templateUrl: './schedule-report-modal-dialog.component.html',
  styleUrls: ['./schedule-report-modal-dialog.component.scss'],
  providers: [   
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
  })

export class ScheduleReportModalDialogComponent implements OnInit {

  descriptionFormControl = new FormControl('',[Validators.required]);
  periodFormControl = new FormControl('', [Validators.required]);
  periodicityFormControl = new FormControl('', Validators.required);
  endDateFormControl = new FormControl('', Validators.required);

  public reportSchedule: ReportSchedule;
  public recurrence: string;
  public period: string;
  private reportScheduleId: string;
  public startHour: string;
  public startMinute: string;
  public HourList: string[];
  public MinuteList: string[];
  public endHour: string;
  public endMinute: string;

  constructor(
    public dialogRef: MatDialogRef<ScheduleReportModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _snackBar: MatSnackBar,
    private spinnerService: Ng4LoadingSpinnerService,
    private reportScheduleService: ReportScheduleService ,
    private _adapter: DateAdapter<any>
    ) { 
      this.reportScheduleId = data.id;
    }

  ngOnInit() {
    this.reportSchedule = {
      clientId : ""
    };
    this.recurrence = "NoRepetition";
    this.period = "";
    this.startHour = "00";
    this.startMinute = "00";
    this.initTimeLists();
    this.endHour = "23"; //default
    this.endMinute = "59"; //default

    let defaultStartDate = new Date();
    defaultStartDate = new Date(defaultStartDate.getFullYear(), defaultStartDate.getMonth(), defaultStartDate.getDate());
    this.reportSchedule.scheduleStart = defaultStartDate.toISOString();

    this.loadReportScheduleData();
  }

  onClickApply(){
    if(this.validate())
      this.dialogRef.close(this.generateSchedule());
  }

  onClickCancel(){
    this.dialogRef.close();
  }

  generateSchedule(){
    let schedule : ReportSchedule = {
      id : this.reportScheduleId,
      clientId : "",
      description : this.reportSchedule.description,
      scheduleStart : this.encodeScheduleStart(),
      nextSchedule : this.encodeScheduleStart(),
    }
    if (this.recurrence == "NoRepetition")
      schedule.recurrence = "NoRepetition"
    else{
      schedule.recurrence = this.period;
      schedule.periodicity = this.reportSchedule.periodicity;
      schedule.scheduleEnd = this.encodeScheduleEnd();
    }
    
    return schedule;
  }

  encodeScheduleStart(){
    var d1 = new Date(this.reportSchedule.scheduleStart);
    var d2 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), Number(this.startHour), Number(this.startMinute));
    return d2.toISOString();
  }

  encodeScheduleEnd(){
    var d1 = new Date(this.reportSchedule.scheduleEnd);
    var d2 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), Number(this.endHour), Number(this.endMinute));
    return d2.toISOString();
  }

  padTo2Digits(value: number){
    if (value < 10)
      return "0" + value;
    else
      return "" + value;
  }

  decodeDateTime(value: string){
    var d1 = new Date(value);
    var result = {
      date: new Date(d1.getFullYear(), d1.getMonth(), d1.getDate()).toISOString(),
      hour: this.padTo2Digits(d1.getHours()),
      minute: this.padTo2Digits(d1.getMinutes())
    };

    return result;
  }

  validate():boolean{
    let message:string = '';

    let errors = 0;
    if( this.descriptionFormControl.status.toUpperCase() != "VALID")
    {
      errors++;
      this.descriptionFormControl.markAsTouched();
    }

    if (this.recurrence == "Repetition")
    {
      if( this.periodFormControl.status.toUpperCase() != "VALID")
      {
        errors++;
        this.periodFormControl.markAsTouched();
      }

      if( this.periodicityFormControl.status.toUpperCase() != "VALID")
      {
        errors++;
        this.periodicityFormControl.markAsTouched();
      }

      if(this.endDateFormControl.status.toUpperCase() != "VALID"){
        errors++;
        this.endDateFormControl.markAsUntouched();
      }

    }
        
    if (errors > 0)
      message += 'Please complete all the mandatory fields \n';

    if(this.reportSchedule.scheduleStart && this.reportSchedule.scheduleEnd){
      let startDate = new Date(this.reportSchedule.scheduleStart);
      let endDate = new Date(this.reportSchedule.scheduleEnd);
      if(startDate > endDate){
        message += 'The "Start Date" must be earlier than "End Date" \n';
      }
    }

    if(message != ''){
      this.showSnackBar(message);
      return false;
    }

    return true;
  }

  showSnackBar(msg: string): void {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  loadReportScheduleData(){
    if (this.reportScheduleId != undefined && this.reportScheduleId != null && this.reportScheduleId != ""){
      this.spinnerService.show();
      this.reportScheduleService.GetById(this.reportScheduleId)
      .subscribe(response => {
        if (response && response.result)
        {
          this.reportSchedule.description = response.result.description;
          var decodedStart = this.decodeDateTime(response.result.scheduleStart);
          this.reportSchedule.scheduleStart = decodedStart.date;
          this.startHour = decodedStart.hour;
          this.startMinute = decodedStart.minute;
          if (response.result.recurrence == "NoRepetition")
            this.recurrence = "NoRepetition";
          else{
            this.recurrence = "Repetition";
            this.period = response.result.recurrence;
            this.reportSchedule.periodicity = response.result.periodicity;
            var decodedEnd = this.decodeDateTime(response.result.scheduleEnd);
            this.reportSchedule.scheduleEnd = decodedEnd.date;
            this.endHour = decodedEnd.hour;
            this.endMinute = decodedEnd.minute;
          }
        }
        else if (response && response.errors && response.errors.length > 0)
          response.errors.forEach(x => console.log(x.message));
          this.spinnerService.hide();
      },(err: HttpErrorResponse) =>{
        this.spinnerService.hide();
        console.log(err.message);
      });
    }

  }

  initTimeLists(){
    this.HourList = [];
    for (var i = 0; i<24;i++)
    {
      if (i < 10)
        this.HourList.push("0" + i);
      else
        this.HourList.push("" + i);
    }

    this.MinuteList = [];
    for (var i = 0; i<6;i++)
    {
      if (i == 0)
        this.MinuteList.push("00");
      else
        this.MinuteList.push("" + 10 * i);
    }

  }

}
