import { NgModule } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { CoreRoutingModule } from '@app/core/core-routing.module';
import { HeaderComponent } from '@app/core/header/header.component';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from '@app/core/profile/profile.component';
import { MaterialModule } from '@app/material/material.module';
import { PageNotFoundComponent } from '@app/core/page-not-found/page-not-found.component';
import { MsAdalAngular6Module, AuthenticationGuard } from 'microsoft-adal-angular6';
import { environment } from '@env/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from '@app/shared/interceptors/custom-http-interceptor';
import { CorrelationIdInterceptor } from '@app/shared/interceptors/correlation-id-interceptor';
import { LoginPageValidateComponent } from './LoginPageValidate/login-page-validate/login-page-validate.component';
import { SharedModule } from '@app/shared/shared.module';
import { DemoModule } from '@app/demo/demo.module';
import { ReportModule } from '@app/report/report.module';
import { RefreshComponent } from './refresh/refresh.component'

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    MaterialModule,
    SharedModule,
    DemoModule,
    ReportModule,
    MsAdalAngular6Module.forRoot({
      tenant: environment.tenant,
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      navigateToLoginRequestUrl: environment.navigateToLoginRequestUrl,
      cacheLocation: environment.cacheLocation,
      loadFrameTimeout: 15000
    //  endPoints: environment.endPoints
    })
  ],
  exports: [
    RouterModule,
    HeaderComponent,
    // ProfileComponent,
    PageNotFoundComponent,
    RefreshComponent,
    HttpClientModule
  ],
  declarations: [
    HeaderComponent,
    // ProfileComponent,
    PageNotFoundComponent,
    LoginPageValidateComponent,
    RefreshComponent
  ],
  providers: [AuthenticationGuard,
    {provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CorrelationIdInterceptor, multi: true}]
})
export class CoreModule { }
