import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DalContentConfiguration} from '@app/shared/shared.module';
import { DalTemplateLibraryItem, DalLibraryItem, LibraryItem } from '@app/api/models';

@Component({
  selector: 'app-infill-basic-editor',
  templateUrl: './infill-basic-editor.component.html',
  styleUrls: ['./infill-basic-editor.component.scss']
})
export class InfillBasicEditorComponent implements OnInit {
  currentLibraryItem: DalTemplateLibraryItem | DalLibraryItem | LibraryItem;
  dalContentConfiguration : DalContentConfiguration;
  
  constructor(

    private dialogRef : MatDialogRef<InfillBasicEditorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) { 

      // Sets the current LibraryItem
      // if (data.currentLibraryItem) {
      //   this.currentLibraryItem = data.currentLibraryItem;
      // }

      // if(data.dalContentConfiguration) {
      //   this.dalContentConfiguration = data.dalContentConfiguration;
      // }
    }

  ngOnInit() {
  }


  /**
   * Closes the preview Dialog.
   */
  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

}
