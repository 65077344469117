/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CollectionResultNotificationTags } from '../models/collection-result-notification-tags';
import { EntityResultNotificationTags } from '../models/entity-result-notification-tags';
import { NotificationTags } from '../models/notification-tags';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class NotificationTagsService extends __BaseService {
  static readonly FindPath = '/api/NotificationTags/Find';
  static readonly FindAllowedPath = '/api/NotificationTags/FindAllowed';
  static readonly InsertPath = '/api/NotificationTags';
  static readonly UpdatePath = '/api/NotificationTags';
  static readonly DeletePath = '/api/NotificationTags';
  static readonly EnablePath = '/api/NotificationTags/Enable';
  static readonly DisablePath = '/api/NotificationTags/Disable';
  static readonly GetAllPath = '/api/NotificationTags/GetAll';
  static readonly GetByIdPath = '/api/NotificationTags/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `NotificationTagsService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `IsAvailable`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  FindResponse(params: NotificationTagsService.FindParams): __Observable<__StrictHttpResponse<CollectionResultNotificationTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTags/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultNotificationTags>;
      })
    );
  }
  /**
   * @param params The `NotificationTagsService.FindParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `IsAvailable`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  Find(params: NotificationTagsService.FindParams): __Observable<CollectionResultNotificationTags> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultNotificationTags)
    );
  }

  /**
   * @param params The `NotificationTagsService.FindAllowedParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `IsAvailable`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  FindAllowedResponse(params: NotificationTagsService.FindAllowedParams): __Observable<__StrictHttpResponse<CollectionResultNotificationTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.Description != null) __params = __params.set('Description', params.Description.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTags/FindAllowed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultNotificationTags>;
      })
    );
  }
  /**
   * @param params The `NotificationTagsService.FindAllowedParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `IsAvailable`:
   *
   * - `Description`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  FindAllowed(params: NotificationTagsService.FindAllowedParams): __Observable<CollectionResultNotificationTags> {
    return this.FindAllowedResponse(params).pipe(
      __map(_r => _r.body as CollectionResultNotificationTags)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: NotificationTags): __Observable<__StrictHttpResponse<EntityResultNotificationTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/NotificationTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTags>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: NotificationTags): __Observable<EntityResultNotificationTags> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultNotificationTags)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: NotificationTags): __Observable<__StrictHttpResponse<EntityResultNotificationTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NotificationTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTags>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: NotificationTags): __Observable<EntityResultNotificationTags> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultNotificationTags)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/NotificationTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NotificationTags/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NotificationTags/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultNotificationTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTags/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultNotificationTags>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultNotificationTags> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultNotificationTags)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultNotificationTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NotificationTags/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultNotificationTags>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultNotificationTags> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultNotificationTags)
    );
  }
}

module NotificationTagsService {

  /**
   * Parameters for Find
   */
  export interface FindParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    IsAvailable?: boolean;
    Description?: string;
    ContinuationToken?: string;
    Code?: string;
  }

  /**
   * Parameters for FindAllowed
   */
  export interface FindAllowedParams {
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    IsAvailable?: boolean;
    Description?: string;
    ContinuationToken?: string;
    Code?: string;
  }
}

export { NotificationTagsService }
