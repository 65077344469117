import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { BaseData, User, OrganizationDataView, UserView, DelegationDal, Permission, ChangeDelegator} from '@app/api/models';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { PermissionRole } from '@app/api/models/permission-role';

@Injectable({
  providedIn: 'root'
})
export class GlobalService implements OnInit {

  msAdal: MsAdalAngular6Service;
  userManager:UserManager;
  public changeManagedUser: BehaviorSubject<UserManager> = new BehaviorSubject<UserManager>(this.userManager);
  delegatorsList: any;
  delegatorName: string;
  delegatorObj: any;
  authorityHolderObj: any;
  selectedAuthorityHolderObj: any;
  dalDelegatorObj: Object;
  dalContentObj: any;
  editDalId: any;
  dalStatus: any;
  organizationDataObj: any;
  editDalViewObj: any;
  editDalObj: any;
  organizationData_divisions: string;
  organizationData_functions_arr: Array<string>;
  selectedDivision: any;
  selectedFunction: any;
  selectedDelegate: string;
  selectedmanaged: boolean;
  sMUILU: string;
  userDisabled:boolean = true;
  userAcceptedTerms:boolean = false;
  isIexplore:boolean = false;
  canChange:canChangeAA;

  //delegation dal
  delegationDalParams: DelegationDal;
  //changeDelegatorParams
  changeDelegatorParams :ChangeDelegator;
  //managed Authority globals values

  isAdminonBehalf:boolean = false;
  // isMasterTableEdited: boolean;
  selectedDALForAuditObj: any;
  selectedDALForAuditId: any;
  selectedBaseDataIdForAudit: any;

  ediDalContentID: any;
  selectedBaseDataUserObj: User;
  selecteBaseDataUserPermissions: Array<Permission>;
  selectedBaseDataUserRolesAndPermissions : Array<PermissionRole>;



  // base data edited vlaues
  baseDataClausesSource: Array<BaseData> = [];

  // base data - Table - edited values
  baseDataTableSource: Array<BaseData> = [];

  // master data - edited values
  baseDataMasterSource: Array<BaseData> = [];

  // to store bulk upload data
  baseDataBulkUploadSource: Array<BaseData> = [];

  // to store ExchangRate data
  baseDataExchangeRateSource: Array<BaseData> = [];

  // to use global event emitter
  // private emitTableListSource = new Subject<any>();
  // tableListEmitted$ = this.emitTableListSource.asObservable();
  // emitTableList(data: any) {
  //     this.emitTableListSource.next(data);
  // }

  baseDataSaved$ = new EventEmitter();

  divisionDS: Array<OrganizationDataView> = [];
  functionDS: Array<OrganizationDataView> = [];

  ExportDalContentdata: any;

  constructor(private _adalSvc: MsAdalAngular6Service) {
    this.msAdal = this._adalSvc;
    this.userManager = new UserManager();
    this.userManager.currentLoggedUser = {
      email : this.msAdal.LoggedInUserEmail.toLocaleLowerCase(),
      firstName : this.msAdal.userInfo.profile.given_name,
      lastName : this.msAdal.userInfo.profile.family_name
    };

    this.sMUILU = sessionStorage.getItem('selectedManagedUseIsLoggedUser');
    if (this.sMUILU!==null && this.sMUILU!==undefined && this.sMUILU!=='null'){

      this.userManager.selectedManagedUseIsLoggedUser = false;

      this.userManager.selectedManagedUser = {
        email : this.sMUILU.split("|")[0],
        firstName : this.sMUILU.split("|")[1],
        lastName : this.sMUILU.split("|")[2],
        id: (this.sMUILU.split("|")[3] ? this.sMUILU.split("|")[3] : "")
      };

    }
  }

  ngOnInit(): void {

  }


  getSelectedManagedUser(): UserView{
    if(this.userManager.selectedManagedUseIsLoggedUser)
      return this.getCurrentLoggedUser();
    else
    return this.userManager.selectedManagedUser;
  }

  getCurrentLoggedUser(): UserView{
    if(this.userManager.currentLoggedUser){ 
      if(!this.userManager.currentLoggedUser.email || !this.userManager.currentLoggedUser.firstName || !this.userManager.currentLoggedUser.lastName){
        this.userManager.currentLoggedUser = {
          email : this.msAdal.LoggedInUserEmail.toLocaleLowerCase(),
          firstName : this.msAdal.userInfo.profile.given_name,
          lastName : this.msAdal.userInfo.profile.family_name
        };
      }
    }
    return this.userManager.currentLoggedUser;
  }

}

export class UserManager{
  selectedManagedUser : UserView;
  currentLoggedUser : UserView;
  selectedManagedUseIsLoggedUser : boolean = true;
}
export class canChangeAA{
  canChangeAA:boolean = true;
  currentProcess:string ="";
}
