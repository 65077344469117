import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent implements OnInit {
  title:string;
  message:string;
  version:string;
  //hasErrors:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalInfoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.message = this.data.message;
    this.version = this.data.version;
  }

  onYesClick()
  {
    this.dialogRef.close({hasErrors: this.data.hasErrors});
  }

}
