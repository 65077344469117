/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EntityResultDal } from '../models/entity-result-dal';
@Injectable({
  providedIn: 'root',
})
class VersionService extends __BaseService {
  static readonly CreateNewVersionPath = '/api/Version/CreateNewVersion';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VersionService.CreateNewVersionParams` containing the following parameters:
   *
   * - `versioningReason`:
   *
   * - `versioningComment`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  CreateNewVersionResponse(params: VersionService.CreateNewVersionParams): __Observable<__StrictHttpResponse<EntityResultDal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.versioningReason != null) __params = __params.set('versioningReason', params.versioningReason.toString());
    if (params.versioningComment != null) __params = __params.set('versioningComment', params.versioningComment.toString());
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Version/CreateNewVersion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDal>;
      })
    );
  }
  /**
   * @param params The `VersionService.CreateNewVersionParams` containing the following parameters:
   *
   * - `versioningReason`:
   *
   * - `versioningComment`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  CreateNewVersion(params: VersionService.CreateNewVersionParams): __Observable<EntityResultDal> {
    return this.CreateNewVersionResponse(params).pipe(
      __map(_r => _r.body as EntityResultDal)
    );
  }
}

module VersionService {

  /**
   * Parameters for CreateNewVersion
   */
  export interface CreateNewVersionParams {
    versioningReason?: string;
    versioningComment?: string;
    dalId?: string;
  }
}

export { VersionService }
