import { Component, OnInit, OnDestroy } from '@angular/core';
declare var ActiveXObject: (type: string) => void;

@Component({
  selector: 'app-root',
  templateUrl: './unsuported.component.html',
  styleUrls: ['./unsuported.component.scss']
})
export class UnsuportedComponent implements OnInit, OnDestroy {
  currentUrl=''

  constructor() {
  }
  ngOnInit() {
    this.currentUrl = window.location.href;
  }
  navigateToEdge(){
    window.location.href = 'microsoft-edge:'+this.currentUrl;
  }
  ngOnDestroy() {
    // Add unsuscribes
  }
}
