import { Injectable } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Subscription } from 'rxjs';
import { UsersService } from '@app/api/services';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { CurrentTenantService } from '../tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  // For naming convension msAdal used for now
  msAdal: MsAdalAngular6Service;

  /* We have single client now, hence client id hard coded and placed here as discussed.
     Once multiple clients are available, it need to get from backend.
     Later, this variable should move to client obj if any
  */
  clientId = 'qbe';
  loggedInUserRole = [];
  loggedInUserName = '';
  loggedInUserEmail = '';
  private _usersServiceSubscribe$: any;

  constructor(private _adalSvc: MsAdalAngular6Service,
    private _usersService: UsersService,private _router: Router,
    private _currentTenantService: CurrentTenantService) {
    this.msAdal = this._adalSvc;
    //console.log(this.msAdal);


    if (sessionStorage.getItem('AdminPath') !== undefined && sessionStorage.getItem('AdminPath') !== null) {
      if (sessionStorage.getItem('AdminPath') === 'true') {

        this.navigateToTenantRoute('/base-data');
      }
    }
    // this._router.navigate(['/notfound'])
  }


  /**
   * Navigates to a route or reloads the page
   * @param route
   */
  navigateToRoute(route : string) {
    if (this._router.url == route) {
      this._router.navigateByUrl('/refresh?refresh=1', { skipLocationChange : true }).then(() => {
        this._router.navigate([route]);
      });
   }
   else {
     // Direct navigation
     this._router.navigate([route]);
   }
  }

    /**
   * Navigates to a tenant route or reloads the page
   * @param route
   */
  navigateToTenantRoute(route : string) {
    const tenantName = this._currentTenantService.getTenantName();
    route = "/" + tenantName + route;
    if (this._router.url == route) {
      this._router.navigateByUrl('/refresh?refresh=1', { skipLocationChange : true }).then(() => {
        this._router.navigate([route]);
      });
   }
   else {
     // Direct navigation
     this._router.navigate([route]);
   }
  }

  isLoggedIn(): boolean {
    // console.log("UserService LoggedIn");

    if (this.msAdal && this.msAdal.LoggedInUserEmail) {
      return true;
    }
    return false;
  }

  getUserRole(): Array<string> {
    // if (this.loggedInUserRole && this.loggedInUserRole.length === 0) {
      this.loggedInUserRole = [];
      const userRoleItem = localStorage.getItem('userRole');
      if(userRoleItem !== undefined && userRoleItem !== null && userRoleItem !== "undefined") {
        const userRole = JSON.parse(userRoleItem);
        if (userRole) {
          this.loggedInUserRole = userRole;
        }
      }
    // }
    return this.loggedInUserRole;
  }

  logOut(){
    this.msAdal.logout();
  }

  loadRoles({ email, dalID }: { email: string; dalID: string; }):Array<string>
  {
    this._usersService.GetUserRolesByEmailandDalId({email:email,dalId:dalID}).subscribe( response =>
      {
        this.loggedInUserRole = [];
        const uRole  = response;
        if (uRole && uRole.length > 0) {
          this.loggedInUserRole = uRole;
        }
      });
       return this.loggedInUserRole;
  }

}
