/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CollectionResultDalTemplateSection } from '../models/collection-result-dal-template-section';
import { EntityResultDalTemplateSection } from '../models/entity-result-dal-template-section';
import { DalTemplateSection } from '../models/dal-template-section';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class DalTemplateSectionService extends __BaseService {
  static readonly FindPath = '/api/DalTemplateSection/Find';
  static readonly InsertPath = '/api/DalTemplateSection';
  static readonly UpdatePath = '/api/DalTemplateSection';
  static readonly DeletePath = '/api/DalTemplateSection';
  static readonly EnablePath = '/api/DalTemplateSection/Enable';
  static readonly DisablePath = '/api/DalTemplateSection/Disable';
  static readonly GetAllPath = '/api/DalTemplateSection/GetAll';
  static readonly GetByIdPath = '/api/DalTemplateSection/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DalTemplateSectionService.FindParams` containing the following parameters:
   *
   * - `Title`:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindResponse(params: DalTemplateSectionService.FindParams): __Observable<__StrictHttpResponse<CollectionResultDalTemplateSection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Title != null) __params = __params.set('Title', params.Title.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    (params.OnlyInTheseFunctions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseFunctions', val.toString())});
    (params.OnlyInTheseDivisions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseDivisions', val.toString())});
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.HasFunctions != null) __params = __params.set('HasFunctions', params.HasFunctions.toString());
    if (params.HasDivisions != null) __params = __params.set('HasDivisions', params.HasDivisions.toString());
    if (params.FunctionName != null) __params = __params.set('FunctionName', params.FunctionName.toString());
    if (params.DivisionName != null) __params = __params.set('DivisionName', params.DivisionName.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplateSection/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDalTemplateSection>;
      })
    );
  }
  /**
   * @param params The `DalTemplateSectionService.FindParams` containing the following parameters:
   *
   * - `Title`:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  Find(params: DalTemplateSectionService.FindParams): __Observable<CollectionResultDalTemplateSection> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultDalTemplateSection)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: DalTemplateSection): __Observable<__StrictHttpResponse<EntityResultDalTemplateSection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DalTemplateSection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDalTemplateSection>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: DalTemplateSection): __Observable<EntityResultDalTemplateSection> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultDalTemplateSection)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: DalTemplateSection): __Observable<__StrictHttpResponse<EntityResultDalTemplateSection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplateSection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDalTemplateSection>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: DalTemplateSection): __Observable<EntityResultDalTemplateSection> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultDalTemplateSection)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/DalTemplateSection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplateSection/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalTemplateSection/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultDalTemplateSection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplateSection/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDalTemplateSection>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultDalTemplateSection> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultDalTemplateSection)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultDalTemplateSection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalTemplateSection/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDalTemplateSection>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultDalTemplateSection> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultDalTemplateSection)
    );
  }
}

module DalTemplateSectionService {

  /**
   * Parameters for Find
   */
  export interface FindParams {
    Title?: string;
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    PageSize?: number;
    PageNum?: number;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
    IsAvailable?: boolean;
    HasFunctions?: boolean;
    HasDivisions?: boolean;
    FunctionName?: string;
    DivisionName?: string;
    ContinuationToken?: string;
  }
}

export { DalTemplateSectionService }
