import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SectionView, LibrarySubSection, LibraryItem, DalSection, DalTemplateSection, Region } from '@app/api/models';
import { AddLibraryItemModalDialogComponent } from '../add-library-item-modal-dialog/add-library-item-modal-dialog.component';
import { MatDialog } from '@angular/material';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { AuditLogModalComponent } from '../audit-log-modal/audit-log-modal.component';

@Component({
  selector: 'app-subsection',
  templateUrl: './subsection.component.html',
  styleUrls: ['./subsection.component.scss']
})
export class SubsectionComponent implements OnInit {
  @Input() editMode:boolean;
  @Input() dalContentConfiguration: DalContentConfiguration;
  @Input() subSection:any;
  @Input() FirstItem:boolean;
  @Input() LastItem:boolean;
  @Input() sectionDeleted:boolean;
  @Input() parentSection : DalSection | DalTemplateSection;
  @Input() masterId:boolean = false;
  @Input() masterType:string;
  subSectionExpanded :boolean = false;
  @Input() expandAll:boolean;
  warningFlag :boolean = false;
  editFlag:boolean =false;

  constructor(public dialog: MatDialog) {
  }
  
  ngOnInit() {  
    this.flagsEnables();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.expandAll){
      this.expandAll = changes.expandAll.currentValue;
      this.subSectionExpanded = changes.expandAll.currentValue;
    }
  }

  subSectionExpand()
  {
    this.subSectionExpanded = !this.subSectionExpanded;
  }

  getLibraryItems()
  { 
    var libraryItems = [];

    if(this.dalContentConfiguration && this.dalContentConfiguration.libraryItems && this.dalContentConfiguration.isEmployeeDetailLoaded)
    {
       libraryItems = this.dalContentConfiguration.libraryItems.filter(x => 
        (x.subSection != undefined || x.subSection != null)
        && (
           x.subSection.id == this.subSection.id ||
           x.subSection.id == this.subSection.subSectionBase.id))
        .sort((x,y) => 
        {
          if(x.order > y.order) {
            return 1;
          } else if(x.order < y.order) {
            return -1;
          } else {
            return 0;
          }
        });
        //return libraryItems;
    }
    return libraryItems;
  }

  showAddLibraryItem(){
    const dialogRef = this.dialog.open(AddLibraryItemModalDialogComponent, {
      width: '80%',
      data: {
        dalContentConfiguration:this.dalContentConfiguration,
        subSection: this.subSection.subSectionBase,
        libraryItems: this.dalContentConfiguration.libraryItems
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        var results = result as LibraryItem[];
        results.forEach(x =>{
            let order = this.getLibraryItems().length;
            this.dalContentConfiguration.addLibraryItem(x, this.subSection.parentSection, this.subSection.subSectionBase,  null,order);
          }
        );
      }
    });
  }

  moveUp () {
    if (this.dalContentConfiguration) {
      this.dalContentConfiguration.moveUp(this.subSection);
    }
  }

  moveDown () {
    if (this.dalContentConfiguration) {
      this.dalContentConfiguration.moveDown(this.subSection);
    }
  }

  removeSubSection () {
    if(this.getLibraryItems().length > 0)
    {
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '450px',
        data: {title: "Warning", message: "You are about to remove a Sub-Section from the DAL. This will also remove its Library Items. Do you wish to continue?"
        , okText: "Yes, delete.", cancelText: "No, cancel." }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      let confirm = result;
      if ( confirm == true)
      {
        if (this.dalContentConfiguration) {
          this.dalContentConfiguration.removeSubSection(this.subSection);
        }
      }});
    }else{
      if (this.dalContentConfiguration) {
        this.dalContentConfiguration.removeSubSection(this.subSection);
      }
    }
  }

  undoRemove () {
    if (this.dalContentConfiguration) {
      this.dalContentConfiguration.undoRemove(this.subSection);
    }
  }
  
  gotoAudit(){
    if(this.subSection && this.subSection.id){
      const dialogRef = this.dialog.open(AuditLogModalComponent, {
        width: '80%',
        data: {
          id: this.masterId,
          type: this.masterType,
          subType: this.subSection.type,
          childIdObject: this.subSection.id,
          typeDescription:'DAL SUB SECTION'
        }
      });
    }
  }
  flagsEnables()
  {//"<span class=\"material-icons\">create</span>";
  //private readonly string warnigFlag = "<span class=\"material-icons\">warning</span>";
     var hasDiferences = this.subSection.title.indexOf("<span hidden class=\"material-icons\">create</span>");
     var hasWarning = this.subSection.title.indexOf("<span hidden class=\"material-icons\">warning</span>");
     if (hasDiferences >0)
     {
       this.subSection.title= this.subSection.title.replace("<span class=\"material-icons\">create</span>","");
        this.editFlag = true;
     }

     if(hasWarning >0)
    {
      this.subSection.title = this.subSection.title.replace("<span class=\"material-icons\">warning</span>","");
      this.warningFlag = true;
    }
    
  }
}

interface DalSubSection
{
id:string;
title: string;
status: string;
order: number;
// organizationData : OrganizationDataView[];
parentSection?:SectionView
}