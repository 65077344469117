import { Component, OnInit } from '@angular/core';
import { AuditLogModalComponent } from '@app/shared/components/audit-log-modal/audit-log-modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-demo-audit-modal',
  templateUrl: './demo-audit-modal.component.html',
  styleUrls: ['./demo-audit-modal.component.scss']
})
export class DemoAuditModalComponent implements OnInit {
  id:string;
  type:string;
  description:string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  gotoAudit(id:string, type:string){
    const dialogRef = this.dialog.open(AuditLogModalComponent, {
      width: '80%',
      data: {
        id:this.id,
        type:this.type,
        description: this.description,
      }
    });
  }
}
