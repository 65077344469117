import { DomSanitizer } from '@angular/platform-browser'
import { Pipe, PipeTransform } from '@angular/core';
import {NgDompurifySanitizer} from '@tinkoff/ng-dompurify';
import { SecurityContext } from '@angular/core';
 
@Pipe({
  name: 'safeHtmlStyle'
})
export class SafeHtmlStylePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer, private readonly dompurifySanitizer: NgDompurifySanitizer) {}
 
  transform(value) {
    //TODO: Add DOMPurify to avoid XSS problems
    let newValue = this.dompurifySanitizer.sanitize(SecurityContext.HTML, value, 
      { ALLOWED_ATTR: ['style','class','value','rowspan','colspan', 'href','target'], 
        ALLOWED_TAGS: ['h1','h2','h3','h4','h5','p','br','em','span','ul','li',
        'ol','strong','br','table','tr','td','th','div','i','thead','a', 'b'],
       ALLOWED_URI_REGEXP: /^((?:http(s)?:\/\/)|(www.))([\w.-])+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&%"'\(\)\*\+,;=.]+$/i});
    return this.sanitized.bypassSecurityTrustHtml(newValue);
  }
}