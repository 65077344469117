import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DVE] => Dal Version
 */
@Injectable({
  providedIn: 'root',
})
export class DalVersionInformationalVariable extends ProcessInformationalVariable {
  /**
   *
   */
  constructor() {
    super();
    this.infillCode = "[DVE]";
  }

  public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string{
    
    if(config.version && config.version.trim() != "") 
      return config.version;
    else if (config.version == null || config.version.trim() == "")
      return "N/A";

    return this.infillCode;
  }
}