/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DalCompleteViewModel } from '../models/dal-complete-view-model';
import { ValueResultBoolean } from '../models/value-result-boolean';
import { ExportDalsInstance } from '../models/export-dals-instance';
import { DataSetLevelsCalculation } from '../models/data-set-levels-calculation';
import { DataSetLevelsCalculationRequest } from '../models/data-set-levels-calculation-request';
import { DalLibraryItem } from '../models/dal-library-item';
@Injectable({
  providedIn: 'root',
})
class DalCompleteViewModelService extends __BaseService {
  static readonly GetCompleteDalPath = '/api/DalCompleteViewModel/{id}';
  static readonly MockDataPath = '/api/DalCompleteViewModel';
  static readonly PutPath = '/api/DalCompleteViewModel/Put';
  static readonly GetDalExportByDivisionFunctionAsyncPath = '/api/DalCompleteViewModel/GetDalExportByDivisionFunctionAsync';
  static readonly DownloadExportDalsByInstanceIdAsyncPath = '/api/DalCompleteViewModel/DownloadExportDalsByInstanceIdAsync';
  static readonly DownloadExportDalsLogByInstanceIdAsyncPath = '/api/DalCompleteViewModel/DownloadExportDalsLogByInstanceIdAsync';
  static readonly CancelDalExportInstanceAsyncPath = '/api/DalCompleteViewModel/CancelDalExportInstanceAsync';
  static readonly GetDalExportInstanceAsyncPath = '/api/DalCompleteViewModel/GetDalExportInstanceAsync';
  static readonly LastDalExportExecutionAsyncPath = '/api/DalCompleteViewModel/LastDalExportExecutionAsync';
  static readonly RunDalExportByDivisionFunctionAsyncPath = '/api/DalCompleteViewModel/RunDalExportByDivisionFunctionAsync';
  static readonly CalculateDataSetLevelsPath = '/api/DalCompleteViewModel/CalculateDataSetLevels';
  static readonly BuildLIParentPath = '/api/DalCompleteViewModel/BuildLIParent';
  static readonly GetMutualDalComparePath = '/api/DalCompleteViewModel/GetMutualDalCompare';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetCompleteDalResponse(id: string): __Observable<__StrictHttpResponse<DalCompleteViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalCompleteViewModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetCompleteDal(id: string): __Observable<DalCompleteViewModel> {
    return this.GetCompleteDalResponse(id).pipe(
      __map(_r => _r.body as DalCompleteViewModel)
    );
  }

  /**
   * @return Success
   */
  MockDataResponse(): __Observable<__StrictHttpResponse<DalCompleteViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalCompleteViewModel>;
      })
    );
  }
  /**
   * @return Success
   */
  MockData(): __Observable<DalCompleteViewModel> {
    return this.MockDataResponse().pipe(
      __map(_r => _r.body as DalCompleteViewModel)
    );
  }

  /**
   * @param DalCompleteViewModel undefined
   * @return Success
   */
  PutResponse(DalCompleteViewModel?: DalCompleteViewModel): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = DalCompleteViewModel;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DalCompleteViewModel/Put`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param DalCompleteViewModel undefined
   * @return Success
   */
  Put(DalCompleteViewModel?: DalCompleteViewModel): __Observable<ValueResultBoolean> {
    return this.PutResponse(DalCompleteViewModel).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalCompleteViewModelService.GetDalExportByDivisionFunctionAsyncParams` containing the following parameters:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetDalExportByDivisionFunctionAsyncResponse(params: DalCompleteViewModelService.GetDalExportByDivisionFunctionAsyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.function != null) __params = __params.set('function', params.function.toString());
    if (params.division != null) __params = __params.set('division', params.division.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel/GetDalExportByDivisionFunctionAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `DalCompleteViewModelService.GetDalExportByDivisionFunctionAsyncParams` containing the following parameters:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetDalExportByDivisionFunctionAsync(params: DalCompleteViewModelService.GetDalExportByDivisionFunctionAsyncParams): __Observable<string> {
    return this.GetDalExportByDivisionFunctionAsyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DownloadExportDalsByInstanceIdAsyncResponse(id?: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel/DownloadExportDalsByInstanceIdAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DownloadExportDalsByInstanceIdAsync(id?: string): __Observable<Blob> {
    return this.DownloadExportDalsByInstanceIdAsyncResponse(id).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DownloadExportDalsLogByInstanceIdAsyncResponse(id?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel/DownloadExportDalsLogByInstanceIdAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DownloadExportDalsLogByInstanceIdAsync(id?: string): __Observable<string> {
    return this.DownloadExportDalsLogByInstanceIdAsyncResponse(id).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  CancelDalExportInstanceAsyncResponse(id?: string): __Observable<__StrictHttpResponse<ExportDalsInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalCompleteViewModel/CancelDalExportInstanceAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExportDalsInstance>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  CancelDalExportInstanceAsync(id?: string): __Observable<ExportDalsInstance> {
    return this.CancelDalExportInstanceAsyncResponse(id).pipe(
      __map(_r => _r.body as ExportDalsInstance)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetDalExportInstanceAsyncResponse(id?: string): __Observable<__StrictHttpResponse<ExportDalsInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel/GetDalExportInstanceAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExportDalsInstance>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetDalExportInstanceAsync(id?: string): __Observable<ExportDalsInstance> {
    return this.GetDalExportInstanceAsyncResponse(id).pipe(
      __map(_r => _r.body as ExportDalsInstance)
    );
  }

  /**
   * @return Success
   */
  LastDalExportExecutionAsyncResponse(): __Observable<__StrictHttpResponse<ExportDalsInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel/LastDalExportExecutionAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExportDalsInstance>;
      })
    );
  }
  /**
   * @return Success
   */
  LastDalExportExecutionAsync(): __Observable<ExportDalsInstance> {
    return this.LastDalExportExecutionAsyncResponse().pipe(
      __map(_r => _r.body as ExportDalsInstance)
    );
  }

  /**
   * @param params The `DalCompleteViewModelService.RunDalExportByDivisionFunctionAsyncParams` containing the following parameters:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  RunDalExportByDivisionFunctionAsyncResponse(params: DalCompleteViewModelService.RunDalExportByDivisionFunctionAsyncParams): __Observable<__StrictHttpResponse<ExportDalsInstance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.function != null) __params = __params.set('function', params.function.toString());
    if (params.division != null) __params = __params.set('division', params.division.toString());
    if (params.reducedACKCheckOutput != null) __params = __params.set('reducedACKCheckOutput', params.reducedACKCheckOutput.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DalCompleteViewModel/RunDalExportByDivisionFunctionAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExportDalsInstance>;
      })
    );
  }
  /**
   * @param params The `DalCompleteViewModelService.RunDalExportByDivisionFunctionAsyncParams` containing the following parameters:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  RunDalExportByDivisionFunctionAsync(params: DalCompleteViewModelService.RunDalExportByDivisionFunctionAsyncParams): __Observable<ExportDalsInstance> {
    return this.RunDalExportByDivisionFunctionAsyncResponse(params).pipe(
      __map(_r => _r.body as ExportDalsInstance)
    );
  }

  /**
   * @param dataSetLevelsCalculationRequest undefined
   * @return Success
   */
  CalculateDataSetLevelsResponse(dataSetLevelsCalculationRequest?: DataSetLevelsCalculationRequest): __Observable<__StrictHttpResponse<DataSetLevelsCalculation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dataSetLevelsCalculationRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalCompleteViewModel/CalculateDataSetLevels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSetLevelsCalculation>;
      })
    );
  }
  /**
   * @param dataSetLevelsCalculationRequest undefined
   * @return Success
   */
  CalculateDataSetLevels(dataSetLevelsCalculationRequest?: DataSetLevelsCalculationRequest): __Observable<DataSetLevelsCalculation> {
    return this.CalculateDataSetLevelsResponse(dataSetLevelsCalculationRequest).pipe(
      __map(_r => _r.body as DataSetLevelsCalculation)
    );
  }

  /**
   * @param params The `DalCompleteViewModelService.BuildLIParentParams` containing the following parameters:
   *
   * - `liBaseId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  BuildLIParentResponse(params: DalCompleteViewModelService.BuildLIParentParams): __Observable<__StrictHttpResponse<DalLibraryItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.liBaseId != null) __params = __params.set('liBaseId', params.liBaseId.toString());
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalCompleteViewModel/BuildLIParent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalLibraryItem>;
      })
    );
  }
  /**
   * @param params The `DalCompleteViewModelService.BuildLIParentParams` containing the following parameters:
   *
   * - `liBaseId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  BuildLIParent(params: DalCompleteViewModelService.BuildLIParentParams): __Observable<DalLibraryItem> {
    return this.BuildLIParentResponse(params).pipe(
      __map(_r => _r.body as DalLibraryItem)
    );
  }

  /**
   * @param params The `DalCompleteViewModelService.GetMutualDalCompareParams` containing the following parameters:
   *
   * - `readerMode`:
   *
   * - `dal2`:
   *
   * - `dal1`:
   *
   * @return Success
   */
  GetMutualDalCompareResponse(params: DalCompleteViewModelService.GetMutualDalCompareParams): __Observable<__StrictHttpResponse<Array<DalCompleteViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.readerMode != null) __params = __params.set('readerMode', params.readerMode.toString());
    if (params.dal2 != null) __params = __params.set('dal2', params.dal2.toString());
    if (params.dal1 != null) __params = __params.set('dal1', params.dal1.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalCompleteViewModel/GetMutualDalCompare`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DalCompleteViewModel>>;
      })
    );
  }
  /**
   * @param params The `DalCompleteViewModelService.GetMutualDalCompareParams` containing the following parameters:
   *
   * - `readerMode`:
   *
   * - `dal2`:
   *
   * - `dal1`:
   *
   * @return Success
   */
  GetMutualDalCompare(params: DalCompleteViewModelService.GetMutualDalCompareParams): __Observable<Array<DalCompleteViewModel>> {
    return this.GetMutualDalCompareResponse(params).pipe(
      __map(_r => _r.body as Array<DalCompleteViewModel>)
    );
  }
}

module DalCompleteViewModelService {

  /**
   * Parameters for GetDalExportByDivisionFunctionAsync
   */
  export interface GetDalExportByDivisionFunctionAsyncParams {
    function?: string;
    division?: string;
  }

  /**
   * Parameters for RunDalExportByDivisionFunctionAsync
   */
  export interface RunDalExportByDivisionFunctionAsyncParams {
    function?: string;
    division?: string;
    reducedACKCheckOutput?: boolean;
  }

  /**
   * Parameters for BuildLIParent
   */
  export interface BuildLIParentParams {
    liBaseId?: string;
    dalId?: string;
  }

  /**
   * Parameters for GetMutualDalCompare
   */
  export interface GetMutualDalCompareParams {
    readerMode?: boolean;
    dal2?: string;
    dal1?: string;
  }
}

export { DalCompleteViewModelService }
