import { DalLibraryItem, DalTemplateSection, DalSubSection, DalTemplateSubSection, DalTemplateLibraryItem, LibraryItem, LibrarySection, LibrarySubSection, DalSection, UserView, SectionView, OrganizationData, LibraryTypeView, LibraryType, OrganizationDataView, Region } from "@app/api/models";
import { Output, EventEmitter, Query } from "@angular/core";
import { DalQueryDetail } from "@app/api/models/dal-query-detail";
import { QueryComment } from "@app/api/models/query-comment";
import { HcmEmployeeDetails } from '@app/api/models/hcm-employee-details';


/**
 * Object needed to pass information to a LibraryControl
 */
export class DalContentConfiguration {

    /**
     * In case of exporting information, we need to delete all
     * placeholders and just leave Raw information.
     */
    public isForExport : boolean = false;

    public isFromDal: boolean = false;

    public isReaderMode: boolean = false;

    public region: Region = null;
  
    /**
     * This element can be used in several places:
     * 1) DAL => Used to setup the current values
     * 2) LibraryItem => When we are creating the LibraryItems
     * 3) Template => When we are creating templates
     */
    public contentMode : string; // DAL | LibraryItem | Template

    /**
     * Base currency code used in the pages
     */
    public baseCurrencyCode : string;
  
    libraryItems : Array<DalLibraryItem | DalTemplateLibraryItem>;
    sections : Array<DalSection | DalTemplateSection>;
    subSections : Array<DalSubSection | DalTemplateSubSection>;

    queryMode: String; //NONE | QUERY | REPLY | REJECTED
    queryComments:Array<QueryComment>;


    // Arrays of division and functions that can be selected
    divisions : Array<OrganizationData | OrganizationDataView>;
    functions : Array<OrganizationData | OrganizationDataView>;
    libraryTypes : Array<LibraryType>;

    /**
     * State of the DAL or DAL Template
     */
    state : string;

    version : string;

    reviewDate : string;

    startDate : string;

    endDate : string;

    code : string;

    // acceptanceDate : string;

    // effectiveDate : string;

    acknowledgementDate : string;

    // Events needed to add an element
    sectionSelected : EventEmitter<SectionSelectedEventArgs> = new EventEmitter<SectionSelectedEventArgs>();
    subSectionSelected : EventEmitter<SubSectionSelectedEventArgs> = new EventEmitter<SubSectionSelectedEventArgs>();
    libraryItemSelected : EventEmitter<LibraryItemSelectedEventArgs> = new EventEmitter<LibraryItemSelectedEventArgs>();

    // Events needed to remove an element
    removeSelectedSection : EventEmitter<RemoveSectionEventArgs> = new EventEmitter<RemoveSectionEventArgs>();
    removeSelectedSubSection : EventEmitter<RemoveSubSectionEventArgs> = new EventEmitter<RemoveSubSectionEventArgs>();
    removeSelectedLibraryItem : EventEmitter<RemoveLibraryItemEventArgs> = new EventEmitter<RemoveLibraryItemEventArgs>();

    // Events needed to move an Item
    moveElementUp : EventEmitter<MoveItemEventArgs> = new EventEmitter<MoveItemEventArgs>();
    moveElementDown : EventEmitter<MoveItemEventArgs> = new EventEmitter<MoveItemEventArgs>();
  
    undoRemoveElement : EventEmitter<UndoRemoveItemEventArgs> = new EventEmitter<UndoRemoveItemEventArgs>();
    /**
     * Current Delegator
     */
    delegator : UserView;
  
    /**
     * Current Authority Holder
     */
    authorityHolder : UserView;

    /**
     * Current Composer
     */
    composer : UserView;

    /**
     * Current Authority Holder Employee Info
     */
    authorityHolderEmployeeInfo : HcmEmployeeDetails;
    isEmployeeDetailLoaded : boolean = true;

    rowsToHide:Array<string>;
  
    constructor() {
      this.libraryItems = [];
      this.sections = [];
      this.subSections = [];
      this.queryComments = [];
      this.queryMode="NONE";
      this.baseCurrencyCode = null;
    }

    hasQueryComments():boolean{
      // if(!this.currentQuery)
      //   return false;
      if(!this.queryComments || this.queryComments.length === 0)
        return false;
      return true;  
    }

    resetQueryList()
    {
      if(this.queryComments)
        this.queryComments = [];
    }

    InitializeQueryComments()
    {
      this.resetQueryList();

      //General Query Comment
      const dalQueryComment:QueryComment = {
          itemId: null,
          comment: "",
          itemType: null
      };
      this.queryComments.push(dalQueryComment);

      //Section Query Comment
      this.sections.forEach(section => {

        let sectionQueryComment:QueryComment = {
          itemId: section.id,
          comment: "",
          itemType: "SECTION"
        }

        this.queryComments.push(sectionQueryComment);
      });
    }

    
    InitializeQueryCommentsMock()
    {
      this.resetQueryList();

      //General Query Comment
      const dalQueryComment:QueryComment = {
          itemId: null,
          comment: "Header Query Mock",
          itemType: null
      };
      this.queryComments.push(dalQueryComment);

      var count = 0;
      //Section Query Comment
      this.sections.forEach(section => {

        let sectionQueryComment:QueryComment = {
          itemId: section.id,
          comment: "Section Query Mock",
          itemType: "SECTION"
        }

        this.queryComments.push(sectionQueryComment);
      });
    }
  
    addSection(element: DalSection | DalTemplateSection, positionType:any, order:any) {
      this.sectionSelected.emit({
        librarySection : element,
        order : order,
        positionType : positionType
      });
    }
    
    addSubSection(element:LibrarySubSection, section:SectionView, positionType, order ) {

      this.subSectionSelected.emit({
        librarySubSection : element,
        librarySection : section,
        order : order,
        positionType : positionType
      });
    }
  
    addLibraryItem(element:LibraryItem, section: SectionView, subSection: SectionView, positionType, order) {
      this.libraryItemSelected.emit({
        libraryItem : element,
        librarySubSection : subSection,
        librarySection : section,
        order : order,
        positionType : positionType
      });
    }
  
    removeSection(element : DalSection | DalTemplateSection) {
      this.removeSelectedSection.emit({
          librarySection : element,
        }
      );
    }
  
    removeSubSection(element : DalSubSection | DalTemplateSubSection) {
      this.removeSelectedSubSection.emit({
        librarySubSection : element
      });
    }
  
    removeLibraryItem(element : DalLibraryItem | DalTemplateLibraryItem) {
      this.removeSelectedLibraryItem.emit({
        libraryItem : element
      });
    }
  
    moveUp(element:
      DalLibraryItem | DalTemplateLibraryItem | 
      DalSubSection | DalTemplateSubSection |
      DalSection | DalTemplateSection) {
      this.moveElementUp.emit({
        libraryItem : element
      });
    }
  
    moveDown(element:
      DalLibraryItem | DalTemplateLibraryItem | 
      DalSubSection | DalTemplateSubSection |
      DalSection | DalTemplateSection){
      this.moveElementDown.emit({
        libraryItem : element
      });
    }

    undoRemove(element:
      DalLibraryItem | DalTemplateLibraryItem | 
      DalSubSection | DalTemplateSubSection |
      DalSection | DalTemplateSection){
      this.undoRemoveElement.emit({
        libraryItem : element
      });
    }

    assingOrders(){
      var libraryItems = this.libraryItems.filter(x => 
        (x.section == undefined || x.section == null)
        && (x.subSection == undefined || x.subSection == null))
        .sort(this.sortByOrder);

      for (let index = 0; index < libraryItems.length; index++) {
        this.libraryItems.find(x => x.id == libraryItems[index].id).order = index + 1;
      }

      var sections = this.sections.sort(this.sortByOrder);
      for (let index = 0; index < sections.length; index++) {
        this.sections.find(x => x.id == sections[index].id).order = index + 1;

        var libraryItemsBySections = this.libraryItems.filter(x => 
          (x.section != undefined && x.section != null)
          && (x.subSection == undefined || x.subSection == null)
          && (x.section.id == sections[index].id))
          .sort(this.sortByOrder);

        var subSectionsBySection = this.subSections.filter(x => 
          (x.parentSection != undefined && x.parentSection != null) && x.parentSection.id == sections[index].id)
          .sort(this.sortByOrder);

        for (let index = 0; index < subSectionsBySection.length; index++) {
          this.subSections.find(x => x.id == subSectionsBySection[index].id).order = index + 1;
          
          var libraryItemsBySubSections = this.libraryItems.filter(x => 
            (x.subSection != undefined || x.subSection != null)
            && (x.subSection.id == subSectionsBySection[index].id))
            .sort(this.sortByOrder);
    
          for (let indexLi = 0; indexLi < libraryItemsBySubSections.length; indexLi++) {
            this.libraryItems.find(x => x.id == libraryItemsBySubSections[indexLi].id).order = indexLi + 1;
          }
        }
  
        for (let indexLi = 0; indexLi < libraryItemsBySections.length; indexLi++) {
          this.libraryItems.find(x => x.id == libraryItemsBySections[indexLi].id).order = indexLi + 1;
        }
      }
    }

    sortByOrder(x : DalTemplateSection | DalTemplateSubSection | DalTemplateLibraryItem
      | DalSection | DalSubSection | DalLibraryItem
      , y : DalTemplateSection | DalTemplateSubSection | DalTemplateLibraryItem
      | DalSection | DalSubSection | DalLibraryItem
      ) {
      if(x.order > y.order) {
        return 1;
      } else if(x.order < y.order) {
        return -1;
      } 
        return 0;
    }
  }

  export interface SectionSelectedEventArgs {
    /**
     * Section selected to be added
     */
    librarySection : LibrarySection,
    positionType : any,
    order : any
  }

  export interface SubSectionSelectedEventArgs {
    /**
     * Subsection selected to be added
     */
    librarySubSection : LibrarySubSection,
    /**
     * Parent section. Can not be null.
     */
    librarySection : SectionView,
    positionType : any,
    order : any
  }

  export interface LibraryItemSelectedEventArgs {
    /**
     * Lbirary item selected
     */
    libraryItem : LibraryItem;
    /**
     * Parent subsection
     */
    librarySection : SectionView,
    /**
     * Parent Section
     */
    librarySubSection : SectionView,
    positionType : any,
    order : any
  }

  export interface RemoveSectionEventArgs {
    /**
     * Section selected to be deleted
     */
    librarySection : DalSection | DalTemplateSection;
  }

  export interface RemoveSubSectionEventArgs {
    /**
     * SubSection selected to be deleted
     */
    librarySubSection : DalSubSection | DalTemplateSubSection;
  }

  export interface RemoveLibraryItemEventArgs {
    /**
     * Library Item to be deleted
     */
    libraryItem : DalLibraryItem | DalTemplateLibraryItem;
  }

  /**
   * Item to be moved
   */
  export interface MoveItemEventArgs {
    /**
     * Item to be moved
     */
    libraryItem :
      DalLibraryItem | DalTemplateLibraryItem | 
      DalSubSection | DalTemplateSubSection |
      DalSection | DalTemplateSection;
  }

  export interface UndoRemoveItemEventArgs {
    /**
     * Item to be moved
     */
    libraryItem :
      DalLibraryItem | DalTemplateLibraryItem | 
      DalSubSection | DalTemplateSubSection |
      DalSection | DalTemplateSection;
  }