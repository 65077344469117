import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatRadioChange, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-change-delegator-modal',
  templateUrl: './change-delegator-modal.component.html',
  styleUrls: ['./change-delegator-modal.component.scss']
})
export class ChangeDelegatorModalComponent implements OnInit {
  selectedOption = '';
  operation:string = '';
  message = '';
  isMasterDal = false;
  swapChecked = false;
  
  constructor(
    public dialogRef: MatDialogRef<ChangeDelegatorModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
      if(data){
       if(data.operation){
          this.operation = data.operation;
          if(this.operation == 'Revocation')
            this.message = 'This DAL has other(s) active DAL(s) under it. In order to revoke it you need to perform one of the following options:'
          else
            this.message = 'This DAL has other(s) active DAL(s) under it. In order to suspend it you need to perform one of the following options:'
        }
        if(data.isMasterDal){
          this.isMasterDal = data.isMasterDal;
          this.swapChecked = true;
          this.selectedOption = 'swap';
        }
      }
    }

  ngOnInit() {
  }

  onOptionsChange(event: MatRadioChange): void {
    this.selectedOption = event.value;
  }

  close(): void {
    this.dialogRef.close();
  }

  continue()
  {
    this.dialogRef.close({option: this.selectedOption});
  }
}
