import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DemoComponent } from './demo.component';
import { MaterialModule } from '@app/material/material.module';
import { MatTableModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { DemoDalLibraryItemsComponent } from './demo-dal-library-items/demo-dal-library-items.component';
import { DemoLibraryItemComponent } from './demo-library-item/demo-library-item.component';
import { DemoAuditModalComponent } from './demo-audit-modal/demo-audit-modal.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UnsuportedComponent } from './unsuported/unsuported.component';

const moment = _rollupMoment || _moment;
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports:[
    DemoComponent
  ],
  declarations:[
    DemoComponent,
    DemoDalLibraryItemsComponent,
    DemoLibraryItemComponent,
    DemoAuditModalComponent,
    PrivacyPolicyComponent
  ],
  providers: [

    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DemoModule {
 }
