import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit,AfterContentChecked } from '@angular/core';
import { elementStart } from '@angular/core/src/render3/instructions';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DalActionsService, ReportsService } from '@app/api/services';
import { environment } from '@env/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
//import { setTimeout } from 'timers';

@Component({
  selector: 'app-change-delegator-logs-modal',
  templateUrl: './change-delegator-logs-modal.component.html',
  styleUrls: ['./change-delegator-logs-modal.component.scss']
})
export class ChangeDelegatorLogsModalComponent implements OnInit ,AfterContentChecked{
  title:string;
  dal:string;
  newParent:string;
  okText:string;
  cancelText:string;
  summary:string;
  subtitle:string;
  endMessage:string ="";
  itemsMessages:any;
  refresDisable: boolean = true;
  refreshVisible: boolean = true;
  workflowId:string = "";

  constructor ( public dialogRef: MatDialogRef<ChangeDelegatorLogsModalComponent>,
  @Inject(MAT_DIALOG_DATA) private data: any,
  private _dalActionsService: DalActionsService,
  private _spinnerService: Ng4LoadingSpinnerService,
  private changeDetector: ChangeDetectorRef,) {
    this._dalActionsService.rootUrl = environment.ApiUrl;
   }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.title = this.data.title;
    this.dal = this.data.dal;
    this.newParent = this.data.newParent;
    this.okText = this.data.okText;
    this.cancelText = this.data.cancelText;
    if(this.title.includes("Swap"))
    {
      this.subtitle="The Delegator swap process has started. You can close this window and the process will run in the background";
    }
    else
    {
      this.subtitle="The Delegator skip process has started. You can close this window and the process will run in the background";
    }

    this.loadWfInfo();
  }

  loadWfInfo()
  {
    this._spinnerService.show();
    if( this.workflowId.length < 5)
    {
      this._dalActionsService.ChangeDelegatorbySwappingWFInfo({dalId: this.dal,newParentDalId: this.newParent}).
      subscribe(response=>{
       if(response.result)
       {// Get the Workflow Id
    
        if(response.result && response.result.id && response.result.id.length>5)
        {
          this.workflowId = response.result.id;
        }
        
        this.workflowAnalisis(response);  
       }
       else
       {
        this._spinnerService.hide();
         this.summary ="";
         // Wait 15 seconds and enable the refresh button
         this.refreshDisable();
       }
      }),
      (err: HttpErrorResponse) => {
       this._spinnerService.hide();
       console.log(err.message);
     }
    }
    else
    {
      // Do the Call by a Workflow ID
      this._dalActionsService.ChangeDelegatorbySwappingWFId({dalId: this.dal,id:this.workflowId}).
      subscribe(response=>{
        if(response.result)
        {
          this.workflowAnalisis(response);
        }
      }),
      (err: HttpErrorResponse) => {
        this._spinnerService.hide();
        console.log(err.message);
      }
    }

  }

  refreshDisable()
  {
    this.refresDisable= true;
    setTimeout(()=>{
      this.refresDisable= false;
      //this.loadWfInfo();
      console.log("Refresh button Active")},15000);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  workflowAnalisis(response : any  )
  {
    this._spinnerService.hide();
    if(response.result)
       {        
         // Get the items count.
         let itemsCount = response.result.countItems;
         // Get the items processed.
         let itemsProcessed = response.result.itemsSuccess;
         // get items failed
         let itemsFailed = response.result.itemsFailed;
   
         this.summary =  "DALs to Process: "+ itemsCount + " DALs Processed: " + itemsProcessed + " DALs failed: "+ itemsFailed;
   
         // clean and publish the items message
         this.itemsMessages = response.result.items.filter(x=> x.message);
   
         if( itemsCount == itemsFailed+ itemsProcessed)
         {
           //Finished proces there is no need to refresh
           //this.refresDisable = true;
           this.refreshVisible = false;
           this.subtitle="";
           //this.refreshDisable();
           if(this.title.includes("Swap"))
           {
             this.endMessage ="The swap delegator process has been finished."
           }
           else
           {
            this.endMessage ="The skip delegator process has been finished."
           }
         }
         else
         {
           // Wait 15seconds and enable the Refresh button
           this.refreshDisable();
         }
      }
      else
      {
         // Wait 15seconds and enable the Refresh button
         this.refreshDisable();
      }
    }
  }
