import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { CurrentTenantService } from '../tenant/tenant.service';

@Injectable({
    providedIn: 'root'
})

class ViewDalService {
    
    private VIEW_DAL_PARAMS_KEY: string = "viewDalParams";

    constructor(private _router: Router,
        private _currentTenantService: CurrentTenantService) {

     }

    navigateToViewDalPage(viewDalParams: ViewDalService.ViewDalParams, pageViewDal: PageViewDal): void {
        if (viewDalParams) {
            sessionStorage.setItem(this.VIEW_DAL_PARAMS_KEY, JSON.stringify(viewDalParams));
            if (pageViewDal == PageViewDal.Copy || pageViewDal == PageViewDal.DalDelegatee)
            {
                 this.navigateToTenantRoute('/admin/people/view-dal', { fragment: 'People' } );
            }
            else if (pageViewDal == PageViewDal.DalTaskInbox) {
                this.navigateToTenantRoute('/admin/view-dal');
            }
            else {
                this.navigateToTenantRoute('/view-dal');
            }
        }
    }

    getCurrentParams() {
        let paramsData = sessionStorage.getItem(this.VIEW_DAL_PARAMS_KEY);
        if (paramsData) {
            return JSON.parse(paramsData);
        }
        return null;
    }

    
  /**
   * Navigates to a route or reloads the page
   * @param route 
   */
  navigateToRoute(route : string, extras?: NavigationExtras) {
    if (this._router.url == route) {
      this._router.navigateByUrl('/refresh?refresh=1', { skipLocationChange : true }).then(() => {
        this._router.navigate([route], extras);
      });
   }
   else {
     // Direct navigation
     this._router.navigate([route], extras);
   }
  }

    /**
   * Navigates to a tenant route or reloads the page
   * @param route 
   */
  navigateToTenantRoute(route : string, extras?: NavigationExtras) {
    const tenantName = this._currentTenantService.getTenantName();
    route = "/" + tenantName + route;
    if (this._router.url == route) {
      this._router.navigateByUrl('/refresh?refresh=1', { skipLocationChange : true }).then(() => {
        this._router.navigate([route], extras);
      });
   }
   else {
     // Direct navigation
     this._router.navigate([route], extras);
   }
  }
}

module ViewDalService {
    export enum DalStatus
    {
        Draft = "Draft",
        InReview = "In Review",
        Approved = "Approved",
        Queried = "Queried",
        Accepted = "Accepted",
        Superceded = "Superceded",
        Suspended = "Suspended",
        Revoked = "Revoked",
        Deleted = "Deleted",
        WordingRequested = "Wording Requested",
        ReInstated = "ReInstated",
        SentToDelegator = "Sent To Delegator",
    }

    export enum ViewDalRole {
        AuthorityHolder,
        Delegator ,
        Delegatee ,
        SuperUser ,
        People
    }

    export interface ViewDalParams {
        searchView?: boolean;
        viewerEmail?: string;
        viewerRole?: ViewDalService.ViewDalRole;
        dalId? : string;     
        ParentDelegatorEmail?: string;
        AuthorityHolderEmail?: string;
        Division?:string;
        Function?:string;
        taskSelected?:string;
        breadTitle?:string;
    }
 
}

export enum PageViewDal {
    ViewDal,
    Copy,
    DalDelegatee,
    DalTaskInbox,
    NewVersionDal 
}

export { ViewDalService }