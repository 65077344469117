import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UsersService, AdUsersService,DalsService } from '@app/api/services';
import { MatTableDataSource, MatSnackBar, TransitionCheckState } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { User, UserView } from '@app/api/models';
import { GlobalService } from '../services/global/global.service';
import { isUndefined } from 'util';




@Component({
  selector: 'app-people-search',
  templateUrl: './people-search.component.html',
  styleUrls: ['./people-search.component.scss']
})
export class PeopleSearchComponent implements OnInit {

  displayedColumns = ['more', 'firstName', 'lastName', 'email'];
  page: number = 1;
  filter: FilterUser = new FilterUser();
  showMoreResults: boolean;
  searchResult_DS: MatTableDataSource<any>;
  showSearchResults: boolean;
  private _searhtype: string = '';
  searchKeyword: string = '';
  isSuperUserorAuditor = false;
  FilterMyHerierachy = true;
  showNoResults = false;

  @Input("notAllowed") notAllowed: any[];
  @Input("selectedUserData") selectedUserData: any;
  @Input("isFromReviewer") isFromReviewer: boolean;
  @Input("isFromTask") isFromTask: boolean;
  @Input("isFromReport") isFromReport:boolean;
  @Output() autorithyAdmistratorSelected = new EventEmitter<string>();
  @Output() userSelected = new EventEmitter<string>();
  @Output() onlyMyHierachy = new EventEmitter<boolean>();

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private _usersService: UsersService,
    private _globalService: GlobalService,
    private _snackBar: MatSnackBar,
    private _apiADUsersService: AdUsersService,
    private _dalService: DalsService) {
    this._apiADUsersService.rootUrl = environment.ApiUrl;
    this._dalService.rootUrl = environment.ApiUrl;
  }

  ngOnInit() {
    console.log('peoplesearch');
   this.isSuperUserorAuditor =this.checkSuperUserorAuditor();
  }



  // API call to retrieve search results
  doSearch() {
    if(this.filter && this.filter.firstName){
      if(this.filter.firstName.length < 2){
        this.showValidationMessage('Name field must have at least 2 characters.')
        return;
      }
    }

    this.spinnerService.show();
    this._usersService.GetDbUsersbyName(
      {
        limit: this.page,
        Email: this.filter.email,
        EmployeeID: this.filter.employeeID,
        FirstName: this.filter.firstName,
        ReviewerGroup: this.filter.reviewersGroup
      })
      .subscribe(data => {
        this.spinnerService.hide();
        // when result found, show table. Else hide it.
        if (data)
          this.showMoreResults = data.hasMoreResults;
        if (data && data.users && data.users.length != 0) {
          this.searchResult_DS = new MatTableDataSource(data.users);
          this.showSearchResults = true;
          this.showNoResults = false;
        } else {
          this.showNoResults = true;
          //here can search in AD if the database result is empty 
          if (this.filter.email.length > 0 || this.filter.firstName.length > 0) {
            if (this.filter.email.length > this.filter.firstName.length) { this.searchKeyword = this.filter.email; }
            else { this.searchKeyword = this.filter.firstName }

            this.doSearchAD();
          }
          else {
            this.searchResult_DS = null;
            this.showSearchResults = false;
          }
        }
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
  }

  doSearchAD(): void {
    var searchResultAD = this._apiADUsersService.SearchUsersPaginated({ limit: this.page, search: this.searchKeyword }).subscribe(
      data => {
        this.searchResult_DS = new MatTableDataSource(data);
        if (this.searchResult_DS.data != null) {
          this.showSearchResults = true;
        }
        else {
          this.searchResult_DS = null;
          this.showSearchResults = false;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  performUserSearch() {
    if(this.filter && this.filter.firstName){
      if(this.filter.firstName.length < 2){
        this.showValidationMessage('Name field must have at least 2 characters.')
        return;
      }
    }

    this.spinnerService.show();
      // Initial search
    let delegateParentMail = this._globalService.getSelectedManagedUser().email;
    if( (this.isSuperUserorAuditor && this.FilterMyHerierachy == false))
    {
      var seachResult = this._usersService.Get({
            DelegateParentEmail: null,
            SearchPeople: 'true',
            SearchUser: this.filter.firstName,
            SearchEmail: this.filter.email,
            
          }).subscribe(data => {

            if (data && data.length > 0) {
              let info = data.sort((a,b)=> ((a.firstName!=null?a.firstName:"")+(a.lastName!=null?a.lastName:""))
              .localeCompare(((b.firstName!=null?b.firstName:"")+(b.lastName!=null?b.lastName:""))));
              this.searchResult_DS = new MatTableDataSource(info);
              this.showSearchResults = true;
              this.showNoResults = false;
            }
            else
            {
              this.showSearchResults = false;
              this.showNoResults = true;
            }
          this.spinnerService.hide();
          },
            (err: HttpErrorResponse) => {
              this.spinnerService.hide();
              console.log(err.message);
            });
    }
    else{
       var searchInTree = this._dalService.DalHierarchyUsersAsync({ DelegatorEmail:this.filter.email,
         SearchText:this.filter.firstName})
       .subscribe(data=>{
         if(data && data.length > 0){
           this.searchResult_DS = new MatTableDataSource(data);
           this.showSearchResults = true;
           this.showNoResults = false;
         }
         else
         {
          this.showSearchResults = false;
          this.showNoResults = true;
         }
         this.spinnerService.hide();
       },
       (err: HttpErrorResponse)=> {
         this.spinnerService.hide();
         console.log(err.message);
       });
         
       }
    }

  checkSuperUserorAuditor(){
    var userRoles = JSON.parse(localStorage.getItem("userRole"));
    let auditor :any = null;
    let permissions =this._globalService.selecteBaseDataUserPermissions;
    if(permissions != null || permissions != undefined)
    {
      if (permissions.some(x=> x.id == '6000'))
      { 
        auditor= permissions.filter(x=> x.id == '6000');
      }
    }

    if(userRoles == null || isUndefined(userRoles))
    {
      return false;
    }
    else
    { 
        return (userRoles.includes('SUPERUSER') || auditor!= null);
    }
  }

  onClickSearch() {
    if(this.isFromReport)
    {
      this.performUserSearch();
    }
    else{
      this.page = 1;
      this.doSearch();
    }    
  }

  searchMoreResults() {
    this.page += 1;
    this.doSearch();
  }

  rowSelected(element): void {
    this.spinnerService.show();

    if (this.isFromReport || this.isFromTask || this.isFromReviewer) {
      this.userSelected.emit(element);
      this.onlyMyHierachy.emit(this.FilterMyHerierachy);
      this.spinnerService.hide();
    }
    else {
      //Send to Addorupdate
      let addUser: User = { firstName: element.firstName, lastName: element.lastName, email: element.email, id: element.id, clientId: element.clientId, adminUserView: [], modifiedBy: this._globalService.getCurrentLoggedUser().email };
      addUser.adminUserView.push({
        id: this.selectedUserData.id,
        email: this.selectedUserData.email,
        firstName: this.selectedUserData.firstName,
        lastName: this.selectedUserData.lastName,
        canReview: false,
        canAccept: false,
        canAprove: false
      });
      this._usersService.PutAuthorityAdministrator(addUser).subscribe(data => {
        if (data != null) {
          this.spinnerService.hide();
          this.showValidationMessage(data);
          this.autorithyAdmistratorSelected.emit(data);

        }
      },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
    }


  }

  /**Define if the row is not listed in Not Allowed emails. */
  isSelectable(element): boolean {
    let result: boolean = true;
    if (this.notAllowed != undefined || this.notAllowed != null) {
      if (this.notAllowed.indexOf(element.email) != -1) {
        result = false;
      }
    }

    return result;
  }

  /** To show a Toast message. */
  showValidationMessage(msg: string) {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3500
    });
  }

}

export class FilterUser {

  firstName: string = "";
  lastName: string = "";
  email: string = "";
  role: string = "";
  employeeID: string = "";
  reviewersGroup: string = "";


}
