/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReportSchedule } from '../models/report-schedule';
import { CollectionResultReportSchedule } from '../models/collection-result-report-schedule';
import { VoidResult } from '../models/void-result';
import { EntityResultReportSchedule } from '../models/entity-result-report-schedule';
@Injectable({
  providedIn: 'root',
})
class ReportScheduleService extends __BaseService {
  static readonly ScheduleReportPath = '/api/ReportSchedule/ScheduleReport';
  static readonly GetAllReportScheduleByUserPath = '/api/ReportSchedule/GetAllReportScheduleByUser';
  static readonly DeleteReportSchedulePath = '/api/ReportSchedule/{id}';
  static readonly InsertPath = '/api/ReportSchedule';
  static readonly UpdatePath = '/api/ReportSchedule';
  static readonly DeletePath = '/api/ReportSchedule';
  static readonly EnablePath = '/api/ReportSchedule/Enable';
  static readonly DisablePath = '/api/ReportSchedule/Disable';
  static readonly GetAllPath = '/api/ReportSchedule/GetAll';
  static readonly GetByIdPath = '/api/ReportSchedule/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param reportSchedule undefined
   * @return Success
   */
  ScheduleReportResponse(reportSchedule?: ReportSchedule): __Observable<__StrictHttpResponse<ReportSchedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reportSchedule;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ReportSchedule/ScheduleReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportSchedule>;
      })
    );
  }
  /**
   * @param reportSchedule undefined
   * @return Success
   */
  ScheduleReport(reportSchedule?: ReportSchedule): __Observable<ReportSchedule> {
    return this.ScheduleReportResponse(reportSchedule).pipe(
      __map(_r => _r.body as ReportSchedule)
    );
  }

  /**
   * @return Success
   */
  GetAllReportScheduleByUserResponse(): __Observable<__StrictHttpResponse<CollectionResultReportSchedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportSchedule/GetAllReportScheduleByUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReportSchedule>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllReportScheduleByUser(): __Observable<CollectionResultReportSchedule> {
    return this.GetAllReportScheduleByUserResponse().pipe(
      __map(_r => _r.body as CollectionResultReportSchedule)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteReportScheduleResponse(id: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ReportSchedule/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DeleteReportSchedule(id: string): __Observable<VoidResult> {
    return this.DeleteReportScheduleResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: ReportSchedule): __Observable<__StrictHttpResponse<EntityResultReportSchedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ReportSchedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReportSchedule>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: ReportSchedule): __Observable<EntityResultReportSchedule> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultReportSchedule)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: ReportSchedule): __Observable<__StrictHttpResponse<EntityResultReportSchedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReportSchedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReportSchedule>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: ReportSchedule): __Observable<EntityResultReportSchedule> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultReportSchedule)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ReportSchedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReportSchedule/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReportSchedule/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultReportSchedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportSchedule/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReportSchedule>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultReportSchedule> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultReportSchedule)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultReportSchedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReportSchedule/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReportSchedule>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultReportSchedule> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultReportSchedule)
    );
  }
}

module ReportScheduleService {
}

export { ReportScheduleService }
