/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { VoidResult } from '../models/void-result';
import { CollectionResultDataSetRelationsData } from '../models/collection-result-data-set-relations-data';
import { EntityResultDataSetRelationsData } from '../models/entity-result-data-set-relations-data';
import { DataSetRelationsData } from '../models/data-set-relations-data';
@Injectable({
  providedIn: 'root',
})
class DataSetRelationsDataService extends __BaseService {
  static readonly AddMultipleRelationsDataPath = '/api/DataSetRelationsData/AddMultipleRelationsData';
  static readonly FindPath = '/api/DataSetRelationsData/Find';
  static readonly InsertPath = '/api/DataSetRelationsData';
  static readonly UpdatePath = '/api/DataSetRelationsData';
  static readonly DeletePath = '/api/DataSetRelationsData';
  static readonly EnablePath = '/api/DataSetRelationsData/Enable';
  static readonly DisablePath = '/api/DataSetRelationsData/Disable';
  static readonly GetAllPath = '/api/DataSetRelationsData/GetAll';
  static readonly GetByIdPath = '/api/DataSetRelationsData/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param dataSetRelationsData undefined
   * @return Success
   */
  AddMultipleRelationsDataResponse(dataSetRelationsData?: Array<any>): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (dataSetRelationsData || []).forEach(val => {if (val != null) __params = __params.append('dataSetRelationsData', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DataSetRelationsData/AddMultipleRelationsData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param dataSetRelationsData undefined
   * @return Success
   */
  AddMultipleRelationsData(dataSetRelationsData?: Array<any>): __Observable<VoidResult> {
    return this.AddMultipleRelationsDataResponse(dataSetRelationsData).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param params The `DataSetRelationsDataService.FindParams` containing the following parameters:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `DataSetRelationsCode`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  FindResponse(params: DataSetRelationsDataService.FindParams): __Observable<__StrictHttpResponse<CollectionResultDataSetRelationsData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    if (params.DataSetRelationsCode != null) __params = __params.set('DataSetRelationsCode', params.DataSetRelationsCode.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DataSetRelationsData/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDataSetRelationsData>;
      })
    );
  }
  /**
   * @param params The `DataSetRelationsDataService.FindParams` containing the following parameters:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `DataSetRelationsCode`:
   *
   * - `ContinuationToken`:
   *
   * @return Success
   */
  Find(params: DataSetRelationsDataService.FindParams): __Observable<CollectionResultDataSetRelationsData> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultDataSetRelationsData)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: DataSetRelationsData): __Observable<__StrictHttpResponse<EntityResultDataSetRelationsData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DataSetRelationsData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDataSetRelationsData>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: DataSetRelationsData): __Observable<EntityResultDataSetRelationsData> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultDataSetRelationsData)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: DataSetRelationsData): __Observable<__StrictHttpResponse<EntityResultDataSetRelationsData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DataSetRelationsData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDataSetRelationsData>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: DataSetRelationsData): __Observable<EntityResultDataSetRelationsData> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultDataSetRelationsData)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/DataSetRelationsData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DataSetRelationsData/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DataSetRelationsData/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultDataSetRelationsData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DataSetRelationsData/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDataSetRelationsData>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultDataSetRelationsData> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultDataSetRelationsData)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultDataSetRelationsData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DataSetRelationsData/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultDataSetRelationsData>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultDataSetRelationsData> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultDataSetRelationsData)
    );
  }
}

module DataSetRelationsDataService {

  /**
   * Parameters for Find
   */
  export interface FindParams {
    SortBy?: string;
    SortAscending?: boolean;
    PageSize?: number;
    PageNum?: number;
    DataSetRelationsCode?: string;
    ContinuationToken?: string;
  }
}

export { DataSetRelationsDataService }
