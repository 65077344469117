import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { LibrarySection, LibraryItem, LibrarySubSection, DalLibraryItem, DalTemplateLibraryItem, DalSection, DalTemplateSection, DalTemplateSubSection, DalSubSection, UserView, Region } from '@app/api/models';
import { LiteralArray } from '@angular/compiler';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';

@Component({
  selector: 'app-library-item',
  templateUrl: './library-item.component.html',
  styleUrls: ['./library-item.component.scss']
})
export class LibraryItemComponent implements OnInit {

  @Input() dalContentConfiguration: DalContentConfiguration;
  @Input() editMode:boolean = false;
  @Input() masterId:boolean = false;
  @Input() masterType:string;
  @Input() expandAll: boolean;
  @Input() Region: Region;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.expandAll){
      this.expandAll = changes.expandAll.currentValue;
    }
  }

  getLibraryItemsWithOutParent():Array<DalLibraryItem | DalTemplateLibraryItem>{
    if(this.dalContentConfiguration && this.dalContentConfiguration.libraryItems && this.dalContentConfiguration.libraryItems.length > 0
      && this.dalContentConfiguration.isEmployeeDetailLoaded)
    {
      var libraryItems = this.dalContentConfiguration.libraryItems.filter(x => 
        (x.section == undefined || x.section == null)
        && (x.subSection == undefined || x.subSection == null))
        .sort((x,y) => 
        {
          if(x.order > y.order) {
            return 1;
          } else if(x.order < y.order) {
            return -1;
          } else {
            return 0;
          }
        });
        return libraryItems;
    }
    return;
  }

  getSections():Array<DalSection | DalTemplateSection>{
    if(this.dalContentConfiguration && this.dalContentConfiguration.sections && this.dalContentConfiguration.sections.length > 0)
    {
      var sections = this.dalContentConfiguration.sections
      .sort((x,y) => 
      {
        if(x.order > y.order) {
          return 1;
        } else if(x.order < y.order) {
          return -1;
        } else {
          return 0;
        }
      });
        return sections;
    }
    return;
  }
}
