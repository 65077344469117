import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [ALE] => Employment Leave End Date
 */
@Injectable({
  providedIn: 'root',
})
export class AHLeaveEndDateInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[ALE]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(!config.authorityHolderEmployeeInfo) {
        return "N/A"
      }

      let AHInfo = config.authorityHolderEmployeeInfo; 
      if (AHInfo.leaveEndDate == null) 
        return "N/A";
      else if (AHInfo.leaveEndDate)
        { 
          if(AHInfo.leaveEndDate.includes("<span"))
          return AHInfo.leaveEndDate;
          else 
          return this.dateToString(AHInfo.leaveEndDate);
        }
       

      return this.infillCode;
    }
  }