import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { LibraryItem, DalLibraryItem, DalTemplateLibraryItem, ItemVariable, ItemVariableValueView, Currency, ReviewerGroup, OrganizationDataView } from '@app/api/models';
import { ProcessBasicInfillVariable } from '@app/shared/infill-processing/process-basic-infill-variable';
import { BaseDatasService, OrganizationDatasService, CurrencyService, TenantService, ExchangeRateService } from '@app/api/services';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { ModalDialogComponent } from '@app/shared/components/modal-dialog/modal-dialog.component';

@Component({
  selector: 'app-modal-reviewers-group',
  templateUrl: './modal-reviewers-group.component.html',
  styleUrls: ['./modal-reviewers-group.component.scss']
})
export class ModalReviewersGroupComponent implements OnInit {
  reviewersGroup_DS: MatTableDataSource<any>;
  displayedColumns = ['select','priority','name', 'description', 'division', 'function','approvalcriteria'];

  reviewersGroup: ReviewerGroup[];
  defaultDivisions: string[];
  defaultFunctions: string[];
  messageButton:string = '';
  isFromSendToDelegator:boolean = false;
  reviewerGroupsInDAL: ReviewerGroup[];

  constructor(
    private _baseDatasService: CurrencyService,
    private _tenantService: TenantService,
    public dialogRef: MatDialogRef<ModalReviewersGroupComponent>,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.reviewersGroup = data.reviewersGroup;
    this.defaultDivisions = data.defaultDivisions;
    this.defaultFunctions = data.defaultFunctions;
    this._baseDatasService.rootUrl = environment.ApiUrl;
    this._tenantService.rootUrl = environment.ApiUrl;

    if (data && data.isFromSendToDelegator){
      this.messageButton = "Send To Delegator";
      this.isFromSendToDelegator = true;
    }else{
      this.messageButton = "Send for review";
    }

    if (data && data.reviewerGroupsInDAL){
      this.reviewerGroupsInDAL = data.reviewerGroupsInDAL;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    let reviewersGroupList = this.buildSelectableList(this.reviewersGroup);

    this.reviewersGroup_DS = new MatTableDataSource(reviewersGroupList);
  }

  onYesClick() {

    if(this.isFromSendToDelegator){
      const result =  this.mapFromSelectableGroup(this.reviewersGroup_DS.data);
      if(this.validReviewersGroupSelected(result)){
        this.dialogRef.close({selectedReviewedGroups: result});
      }
    }
    else if(this.reviewersGroup_DS.data.some(x => x.selected))
    {
      const dialogRef = this.dialog.open(ModalDialogComponent, {
        width: '450px',
        data: {title: "Warning", message: "You are about to Send the DAL for Review. Do you want to continue?", okText: "Yes, continue.", cancelText: "No, cancel." }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        let confirm = result;
  
        if ( confirm == true)
        {
            const result =  this.mapFromSelectableGroup(this.reviewersGroup_DS.data);
            if(this.validReviewersGroupSelected(result)){
              this.dialogRef.close({selectedReviewedGroups: result});
            }
        }
      });
    } 
    else
    {
      this._snackBar.open("Please, select at least one Reviewer Group.", "", {duration: 3000});
    }


  }

  validReviewersGroupSelected(reviewerGroups: ReviewerGroup[]): boolean {
    var valid = true;
    if(reviewerGroups){
      var invalidPriority = reviewerGroups.filter(x => x.groupPriority == 0 || x.groupPriority == null);
      if(invalidPriority && invalidPriority.length > 0){
        this.showValidationMessage('The priority number must be greater than 0.');
        valid = false;
      }

      reviewerGroups.forEach(reviewerGroup => {
        var samePriority = reviewerGroups.filter(x => x.groupPriority == reviewerGroup.groupPriority);
        if(samePriority.length != 1){
          this.showValidationMessage('There are duplicate priorities. Please define an order for the Reviewers Teams to continue.');
          valid = false;
        }
      });
    }

    return valid;
  }

  chkSelect(element: ReviewerGroup) {
    //console.log(element);
  }

  returnDivisions(element: ReviewerGroup) {
    let divisions : string[] = [];

    if (element && element.organizationDataView && element.organizationDataView.length > 0) {
      divisions = element.organizationDataView.filter(data => data.orgGroupType === "DIVISIONS")
        .map(x => x.orgGroupName);

      if(divisions != null && divisions.length > 0) {
        return divisions.join(", ");
      }
    }

    return "";
  }

  returnFunctions(element: any) {
    let functions : string[] = [];

    if (element && element.organizationDataView && element.organizationDataView.length > 0) {
      functions = element.organizationDataView.filter(data => data.orgGroupType === "FUNCTION")
        .map(x => x.orgGroupName);

      if(functions != null && functions.length > 0) {
        return functions.join(", ");
      }
    }

    return "";
  }



  getFunctionsArray(item: SelectableReviewerGroup)
  {
    let functions : string[] = [];
    let element = item.reviewerGroup;

    if (element && element.organizationDataView && element.organizationDataView.length > 0) {
      functions = element.organizationDataView
                        .filter(data => data.orgGroupType === "FUNCTION")
                        .map(x => x.orgGroupName);
    }
    return functions;
  }

  getDivisionsArray(item: SelectableReviewerGroup)
  {
    let divisions : string[] = [];
    let element = item.reviewerGroup;

    if (element && element.organizationDataView && element.organizationDataView.length > 0) {
      divisions = element.organizationDataView
                        .filter(data => data.orgGroupType === "DIVISIONS")
                        .map(x => x.orgGroupName);
    }
    return divisions;
  }


  buildSelectableList(RGArray:ReviewerGroup[])
  {
    let rgSelectableList = this.mapToSelectableGroup(RGArray)

    rgSelectableList.forEach(
      x => {
              const itemDivisions = this.getDivisionsArray(x);
              const itemFunctions = this.getFunctionsArray(x);
              x.default = 'yes';
              // x.selected = true;
              if(!this.arrayEquals(itemDivisions, this.defaultDivisions)
                  || !this.arrayEquals(itemFunctions, this.defaultFunctions)){
                x.default = 'no';
                x.selected = false;
              }
            }
    );

    if (this.reviewerGroupsInDAL){
      rgSelectableList.forEach(
        x => {
                let reviewerGroup = this.reviewerGroupsInDAL.find(y => y.id == x.reviewerGroup.id);
                if (reviewerGroup){
                  x.selected = true;
                  x.reviewerGroup.groupPriority = reviewerGroup.groupPriority;
                }else{
                  x.selected = false;
                }
            }
      );
    }
    
    rgSelectableList.sort(
      function(x, y) {
         if (x.reviewerGroup.groupPriority === y.reviewerGroup.groupPriority) {
          return (x.default === y.default)? 0 : x.default == 'yes'? -1 : 1;
         }
         return x.reviewerGroup.groupPriority > y.reviewerGroup.groupPriority ? 1 : -1;
      });
   
    return rgSelectableList;
  }

  arrayEquals(a1: string[], a2: string[])
  {
    if(JSON.stringify(a1.sort())==JSON.stringify(a2.sort()))
      return true;
    return false;
  }

  mapToSelectableGroup(RGArray:ReviewerGroup[])
  {
    if(RGArray == null)
      return [];

    return RGArray.map(rg => {return <SelectableReviewerGroup>{     
      reviewerGroup: rg,
      selected : false,
      default : 'no'
     }});
  }

  mapFromSelectableGroup(selectableRGArray:SelectableReviewerGroup[])
  {
    if(selectableRGArray == null)
      return [];

    return selectableRGArray
                .filter(x => x.selected)
                .map(x => x.reviewerGroup);    
  }

  trimInput(element: any, event: any) {
    if ((element.reviewerGroup.groupPriority == null) || (!/^\d+$/.test(event.target.value))){
      event.target.value = "0";
      element.reviewerGroup.groupPriority = 0;
    }
  }

  showValidationMessage(msg: string) {
    const message = msg;
    const action = '';
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }
}

export interface SelectableReviewerGroup {
  reviewerGroup: ReviewerGroup,
  selected: boolean,
  default: string,
}

