/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseDTO } from '../models/response-dto';
import { ReferenceTable } from '../models/reference-table';
import { OrganizationDataView } from '../models/organization-data-view';
import { DataSetLevel } from '../models/data-set-level';
import { CollectionResultDataSetLevel } from '../models/collection-result-data-set-level';
import { CollectionResultReferenceTable } from '../models/collection-result-reference-table';
import { EntityResultReferenceTable } from '../models/entity-result-reference-table';
import { VoidResult } from '../models/void-result';
@Injectable({
  providedIn: 'root',
})
class ReferenceTableService extends __BaseService {
  static readonly InsertReferenceTablePath = '/api/ReferenceTable/InsertReferenceTable';
  static readonly GetReferenceTablesPath = '/api/ReferenceTable/GetReferenceTables';
  static readonly GetActiveReferenceTablesPath = '/api/ReferenceTable/GetActiveReferenceTables';
  static readonly GetReferenceTablesStatusActivePath = '/api/ReferenceTable/GetReferenceTablesStatusActive';
  static readonly GetReferenceTablesFilteredStatusActivePath = '/api/ReferenceTable/GetReferenceTablesFilteredStatusActive';
  static readonly GetReferenceTableByIdPath = '/api/ReferenceTable/GetReferenceTableById';
  static readonly GetAllowedOrganizationDataPath = '/api/ReferenceTable/GetAllowedOrganizationData';
  static readonly GetLevelsByDatasetPath = '/api/ReferenceTable/GetLevelsByDataset/{id}';
  static readonly SaveLevelsPath = '/api/ReferenceTable/SaveLevels/{id}';
  static readonly UpdateLevelsPath = '/api/ReferenceTable/UpdateLevels/{id}';
  static readonly GetReferenceTableByRTMCodeStatusAsyncPath = '/api/ReferenceTable/GetReferenceTableByRTMCodeStatusAsync';
  static readonly GetLevelsByDatasetAndCurrencyPath = '/api/ReferenceTable/GetLevelsByDatasetAndCurrency';
  static readonly FindPath = '/api/ReferenceTable/Find';
  static readonly FindAllowedPath = '/api/ReferenceTable/FindAllowed';
  static readonly InsertPath = '/api/ReferenceTable';
  static readonly UpdatePath = '/api/ReferenceTable';
  static readonly DeletePath = '/api/ReferenceTable';
  static readonly EnablePath = '/api/ReferenceTable/Enable';
  static readonly DisablePath = '/api/ReferenceTable/Disable';
  static readonly GetAllPath = '/api/ReferenceTable/GetAll';
  static readonly GetByIdPath = '/api/ReferenceTable/GetById';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param referenceTable undefined
   * @return Success
   */
  InsertReferenceTableResponse(referenceTable?: ReferenceTable): __Observable<__StrictHttpResponse<ResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = referenceTable;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReferenceTable/InsertReferenceTable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseDTO>;
      })
    );
  }
  /**
   * @param referenceTable undefined
   * @return Success
   */
  InsertReferenceTable(referenceTable?: ReferenceTable): __Observable<ResponseDTO> {
    return this.InsertReferenceTableResponse(referenceTable).pipe(
      __map(_r => _r.body as ResponseDTO)
    );
  }

  /**
   * @param subType undefined
   * @return Success
   */
  GetReferenceTablesResponse(subType?: string): __Observable<__StrictHttpResponse<Array<ReferenceTable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (subType != null) __params = __params.set('subType', subType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetReferenceTables`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferenceTable>>;
      })
    );
  }
  /**
   * @param subType undefined
   * @return Success
   */
  GetReferenceTables(subType?: string): __Observable<Array<ReferenceTable>> {
    return this.GetReferenceTablesResponse(subType).pipe(
      __map(_r => _r.body as Array<ReferenceTable>)
    );
  }

  /**
   * @param subType undefined
   * @return Success
   */
  GetActiveReferenceTablesResponse(subType?: string): __Observable<__StrictHttpResponse<Array<ReferenceTable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (subType != null) __params = __params.set('subType', subType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetActiveReferenceTables`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferenceTable>>;
      })
    );
  }
  /**
   * @param subType undefined
   * @return Success
   */
  GetActiveReferenceTables(subType?: string): __Observable<Array<ReferenceTable>> {
    return this.GetActiveReferenceTablesResponse(subType).pipe(
      __map(_r => _r.body as Array<ReferenceTable>)
    );
  }

  /**
   * @param params The `ReferenceTableService.GetReferenceTablesStatusActiveParams` containing the following parameters:
   *
   * - `subType`:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetReferenceTablesStatusActiveResponse(params: ReferenceTableService.GetReferenceTablesStatusActiveParams): __Observable<__StrictHttpResponse<Array<ReferenceTable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.subType != null) __params = __params.set('subType', params.subType.toString());
    if (params.function != null) __params = __params.set('function', params.function.toString());
    if (params.division != null) __params = __params.set('division', params.division.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetReferenceTablesStatusActive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferenceTable>>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.GetReferenceTablesStatusActiveParams` containing the following parameters:
   *
   * - `subType`:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetReferenceTablesStatusActive(params: ReferenceTableService.GetReferenceTablesStatusActiveParams): __Observable<Array<ReferenceTable>> {
    return this.GetReferenceTablesStatusActiveResponse(params).pipe(
      __map(_r => _r.body as Array<ReferenceTable>)
    );
  }

  /**
   * @param params The `ReferenceTableService.GetReferenceTablesFilteredStatusActiveParams` containing the following parameters:
   *
   * - `subType`:
   *
   * - `parent`:
   *
   * - `isDelegatable`:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetReferenceTablesFilteredStatusActiveResponse(params: ReferenceTableService.GetReferenceTablesFilteredStatusActiveParams): __Observable<__StrictHttpResponse<Array<ReferenceTable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.subType != null) __params = __params.set('subType', params.subType.toString());
    if (params.parent != null) __params = __params.set('parent', params.parent.toString());
    if (params.isDelegatable != null) __params = __params.set('isDelegatable', params.isDelegatable.toString());
    if (params.function != null) __params = __params.set('function', params.function.toString());
    if (params.division != null) __params = __params.set('division', params.division.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetReferenceTablesFilteredStatusActive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferenceTable>>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.GetReferenceTablesFilteredStatusActiveParams` containing the following parameters:
   *
   * - `subType`:
   *
   * - `parent`:
   *
   * - `isDelegatable`:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetReferenceTablesFilteredStatusActive(params: ReferenceTableService.GetReferenceTablesFilteredStatusActiveParams): __Observable<Array<ReferenceTable>> {
    return this.GetReferenceTablesFilteredStatusActiveResponse(params).pipe(
      __map(_r => _r.body as Array<ReferenceTable>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetReferenceTableByIdResponse(id?: string): __Observable<__StrictHttpResponse<ReferenceTable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetReferenceTableById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferenceTable>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetReferenceTableById(id?: string): __Observable<ReferenceTable> {
    return this.GetReferenceTableByIdResponse(id).pipe(
      __map(_r => _r.body as ReferenceTable)
    );
  }

  /**
   * @return Success
   */
  GetAllowedOrganizationDataResponse(): __Observable<__StrictHttpResponse<Array<OrganizationDataView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetAllowedOrganizationData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationDataView>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllowedOrganizationData(): __Observable<Array<OrganizationDataView>> {
    return this.GetAllowedOrganizationDataResponse().pipe(
      __map(_r => _r.body as Array<OrganizationDataView>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetLevelsByDatasetResponse(id: string): __Observable<__StrictHttpResponse<Array<DataSetLevel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReferenceTable/GetLevelsByDataset/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DataSetLevel>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetLevelsByDataset(id: string): __Observable<Array<DataSetLevel>> {
    return this.GetLevelsByDatasetResponse(id).pipe(
      __map(_r => _r.body as Array<DataSetLevel>)
    );
  }

  /**
   * @param params The `ReferenceTableService.SaveLevelsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `levels`:
   *
   * @return Success
   */
  SaveLevelsResponse(params: ReferenceTableService.SaveLevelsParams): __Observable<__StrictHttpResponse<Array<DataSetLevel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.levels;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReferenceTable/SaveLevels/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DataSetLevel>>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.SaveLevelsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `levels`:
   *
   * @return Success
   */
  SaveLevels(params: ReferenceTableService.SaveLevelsParams): __Observable<Array<DataSetLevel>> {
    return this.SaveLevelsResponse(params).pipe(
      __map(_r => _r.body as Array<DataSetLevel>)
    );
  }

  /**
   * @param params The `ReferenceTableService.UpdateLevelsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `level`:
   *
   * - `isDelete`:
   *
   * @return Success
   */
  UpdateLevelsResponse(params: ReferenceTableService.UpdateLevelsParams): __Observable<__StrictHttpResponse<DataSetLevel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.level;
    if (params.isDelete != null) __params = __params.set('isDelete', params.isDelete.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ReferenceTable/UpdateLevels/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSetLevel>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.UpdateLevelsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `level`:
   *
   * - `isDelete`:
   *
   * @return Success
   */
  UpdateLevels(params: ReferenceTableService.UpdateLevelsParams): __Observable<DataSetLevel> {
    return this.UpdateLevelsResponse(params).pipe(
      __map(_r => _r.body as DataSetLevel)
    );
  }

  /**
   * @param params The `ReferenceTableService.GetReferenceTableByRTMCodeStatusAsyncParams` containing the following parameters:
   *
   * - `rtmCode`:
   *
   * - `parent`:
   *
   * - `isDelegatable`:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetReferenceTableByRTMCodeStatusAsyncResponse(params: ReferenceTableService.GetReferenceTableByRTMCodeStatusAsyncParams): __Observable<__StrictHttpResponse<Array<ReferenceTable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.rtmCode != null) __params = __params.set('rtmCode', params.rtmCode.toString());
    if (params.parent != null) __params = __params.set('parent', params.parent.toString());
    if (params.isDelegatable != null) __params = __params.set('isDelegatable', params.isDelegatable.toString());
    if (params.function != null) __params = __params.set('function', params.function.toString());
    if (params.division != null) __params = __params.set('division', params.division.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetReferenceTableByRTMCodeStatusAsync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReferenceTable>>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.GetReferenceTableByRTMCodeStatusAsyncParams` containing the following parameters:
   *
   * - `rtmCode`:
   *
   * - `parent`:
   *
   * - `isDelegatable`:
   *
   * - `function`:
   *
   * - `division`:
   *
   * @return Success
   */
  GetReferenceTableByRTMCodeStatusAsync(params: ReferenceTableService.GetReferenceTableByRTMCodeStatusAsyncParams): __Observable<Array<ReferenceTable>> {
    return this.GetReferenceTableByRTMCodeStatusAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<ReferenceTable>)
    );
  }

  /**
   * @param params The `ReferenceTableService.GetLevelsByDatasetAndCurrencyParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `currency`:
   *
   * @return Success
   */
  GetLevelsByDatasetAndCurrencyResponse(params: ReferenceTableService.GetLevelsByDatasetAndCurrencyParams): __Observable<__StrictHttpResponse<CollectionResultDataSetLevel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.currency != null) __params = __params.set('currency', params.currency.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetLevelsByDatasetAndCurrency`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultDataSetLevel>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.GetLevelsByDatasetAndCurrencyParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `currency`:
   *
   * @return Success
   */
  GetLevelsByDatasetAndCurrency(params: ReferenceTableService.GetLevelsByDatasetAndCurrencyParams): __Observable<CollectionResultDataSetLevel> {
    return this.GetLevelsByDatasetAndCurrencyResponse(params).pipe(
      __map(_r => _r.body as CollectionResultDataSetLevel)
    );
  }

  /**
   * @param params The `ReferenceTableService.FindParams` containing the following parameters:
   *
   * - `SubType`:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `ParentCode`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  FindResponse(params: ReferenceTableService.FindParams): __Observable<__StrictHttpResponse<CollectionResultReferenceTable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SubType != null) __params = __params.set('SubType', params.SubType.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.ParentCode != null) __params = __params.set('ParentCode', params.ParentCode.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    (params.OnlyInTheseFunctions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseFunctions', val.toString())});
    (params.OnlyInTheseDivisions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseDivisions', val.toString())});
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.HasFunctions != null) __params = __params.set('HasFunctions', params.HasFunctions.toString());
    if (params.HasDivisions != null) __params = __params.set('HasDivisions', params.HasDivisions.toString());
    if (params.FunctionName != null) __params = __params.set('FunctionName', params.FunctionName.toString());
    if (params.DivisionName != null) __params = __params.set('DivisionName', params.DivisionName.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/Find`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReferenceTable>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.FindParams` containing the following parameters:
   *
   * - `SubType`:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `ParentCode`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  Find(params: ReferenceTableService.FindParams): __Observable<CollectionResultReferenceTable> {
    return this.FindResponse(params).pipe(
      __map(_r => _r.body as CollectionResultReferenceTable)
    );
  }

  /**
   * @param params The `ReferenceTableService.FindAllowedParams` containing the following parameters:
   *
   * - `SubType`:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `ParentCode`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  FindAllowedResponse(params: ReferenceTableService.FindAllowedParams): __Observable<__StrictHttpResponse<CollectionResultReferenceTable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SubType != null) __params = __params.set('SubType', params.SubType.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.SortBy != null) __params = __params.set('SortBy', params.SortBy.toString());
    if (params.SortAscending != null) __params = __params.set('SortAscending', params.SortAscending.toString());
    if (params.ShowRemoved != null) __params = __params.set('ShowRemoved', params.ShowRemoved.toString());
    if (params.ParentCode != null) __params = __params.set('ParentCode', params.ParentCode.toString());
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.PageNum != null) __params = __params.set('PageNum', params.PageNum.toString());
    (params.OnlyInTheseFunctions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseFunctions', val.toString())});
    (params.OnlyInTheseDivisions || []).forEach(val => {if (val != null) __params = __params.append('OnlyInTheseDivisions', val.toString())});
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    if (params.IsAvailable != null) __params = __params.set('IsAvailable', params.IsAvailable.toString());
    if (params.HasFunctions != null) __params = __params.set('HasFunctions', params.HasFunctions.toString());
    if (params.HasDivisions != null) __params = __params.set('HasDivisions', params.HasDivisions.toString());
    if (params.FunctionName != null) __params = __params.set('FunctionName', params.FunctionName.toString());
    if (params.DivisionName != null) __params = __params.set('DivisionName', params.DivisionName.toString());
    if (params.ContinuationToken != null) __params = __params.set('ContinuationToken', params.ContinuationToken.toString());
    if (params.Code != null) __params = __params.set('Code', params.Code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/FindAllowed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReferenceTable>;
      })
    );
  }
  /**
   * @param params The `ReferenceTableService.FindAllowedParams` containing the following parameters:
   *
   * - `SubType`:
   *
   * - `Status`:
   *
   * - `SortBy`:
   *
   * - `SortAscending`:
   *
   * - `ShowRemoved`:
   *
   * - `ParentCode`:
   *
   * - `PageSize`:
   *
   * - `PageNum`:
   *
   * - `OnlyInTheseFunctions`:
   *
   * - `OnlyInTheseDivisions`:
   *
   * - `Name`:
   *
   * - `IsAvailable`:
   *
   * - `HasFunctions`:
   *
   * - `HasDivisions`:
   *
   * - `FunctionName`:
   *
   * - `DivisionName`:
   *
   * - `ContinuationToken`:
   *
   * - `Code`:
   *
   * @return Success
   */
  FindAllowed(params: ReferenceTableService.FindAllowedParams): __Observable<CollectionResultReferenceTable> {
    return this.FindAllowedResponse(params).pipe(
      __map(_r => _r.body as CollectionResultReferenceTable)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  InsertResponse(entity?: ReferenceTable): __Observable<__StrictHttpResponse<EntityResultReferenceTable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ReferenceTable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReferenceTable>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Insert(entity?: ReferenceTable): __Observable<EntityResultReferenceTable> {
    return this.InsertResponse(entity).pipe(
      __map(_r => _r.body as EntityResultReferenceTable)
    );
  }

  /**
   * @param entity undefined
   * @return Success
   */
  UpdateResponse(entity?: ReferenceTable): __Observable<__StrictHttpResponse<EntityResultReferenceTable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReferenceTable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReferenceTable>;
      })
    );
  }
  /**
   * @param entity undefined
   * @return Success
   */
  Update(entity?: ReferenceTable): __Observable<EntityResultReferenceTable> {
    return this.UpdateResponse(entity).pipe(
      __map(_r => _r.body as EntityResultReferenceTable)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ReferenceTable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: string): __Observable<VoidResult> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EnableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReferenceTable/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Enable(id?: string): __Observable<VoidResult> {
    return this.EnableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DisableResponse(id?: string): __Observable<__StrictHttpResponse<VoidResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ReferenceTable/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VoidResult>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Disable(id?: string): __Observable<VoidResult> {
    return this.DisableResponse(id).pipe(
      __map(_r => _r.body as VoidResult)
    );
  }

  /**
   * @return Success
   */
  GetAllResponse(): __Observable<__StrictHttpResponse<CollectionResultReferenceTable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionResultReferenceTable>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAll(): __Observable<CollectionResultReferenceTable> {
    return this.GetAllResponse().pipe(
      __map(_r => _r.body as CollectionResultReferenceTable)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id?: string): __Observable<__StrictHttpResponse<EntityResultReferenceTable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ReferenceTable/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityResultReferenceTable>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id?: string): __Observable<EntityResultReferenceTable> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as EntityResultReferenceTable)
    );
  }
}

module ReferenceTableService {

  /**
   * Parameters for GetReferenceTablesStatusActive
   */
  export interface GetReferenceTablesStatusActiveParams {
    subType?: string;
    function?: string;
    division?: string;
  }

  /**
   * Parameters for GetReferenceTablesFilteredStatusActive
   */
  export interface GetReferenceTablesFilteredStatusActiveParams {
    subType?: string;
    parent?: string;
    isDelegatable?: boolean;
    function?: string;
    division?: string;
  }

  /**
   * Parameters for SaveLevels
   */
  export interface SaveLevelsParams {
    id: string;
    levels?: Array<DataSetLevel>;
  }

  /**
   * Parameters for UpdateLevels
   */
  export interface UpdateLevelsParams {
    id: string;
    level?: DataSetLevel;
    isDelete?: boolean;
  }

  /**
   * Parameters for GetReferenceTableByRTMCodeStatusAsync
   */
  export interface GetReferenceTableByRTMCodeStatusAsyncParams {
    rtmCode?: string;
    parent?: string;
    isDelegatable?: boolean;
    function?: string;
    division?: string;
  }

  /**
   * Parameters for GetLevelsByDatasetAndCurrency
   */
  export interface GetLevelsByDatasetAndCurrencyParams {
    id?: string;
    currency?: string;
  }

  /**
   * Parameters for Find
   */
  export interface FindParams {
    SubType?: string;
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    ParentCode?: string;
    PageSize?: number;
    PageNum?: number;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
    Name?: string;
    IsAvailable?: boolean;
    HasFunctions?: boolean;
    HasDivisions?: boolean;
    FunctionName?: string;
    DivisionName?: string;
    ContinuationToken?: string;
    Code?: string;
  }

  /**
   * Parameters for FindAllowed
   */
  export interface FindAllowedParams {
    SubType?: string;
    Status?: string;
    SortBy?: string;
    SortAscending?: boolean;
    ShowRemoved?: boolean;
    ParentCode?: string;
    PageSize?: number;
    PageNum?: number;
    OnlyInTheseFunctions?: Array<string>;
    OnlyInTheseDivisions?: Array<string>;
    Name?: string;
    IsAvailable?: boolean;
    HasFunctions?: boolean;
    HasDivisions?: boolean;
    FunctionName?: string;
    DivisionName?: string;
    ContinuationToken?: string;
    Code?: string;
  }
}

export { ReferenceTableService }
