import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';
import { DalContentConfiguration } from '@app/shared/library-item-processing/dal-content-configuration';
import { LibraryItem, DalLibraryItem, DalTemplateLibraryItem, ItemVariable, ItemVariableValueView, Currency, ReferenceTableMeta, ReferenceTable, Section, DalSection, DalTemplateSection, SectionBaseView } from '@app/api/models';
import { ProcessBasicInfillVariable } from '@app/shared/infill-processing/process-basic-infill-variable';
import { BaseDatasService, OrganizationDatasService, CurrencyService, TenantService, ExchangeRateService, ReferenceTableMetaService, ReferenceTableService, LibrarySubSectionService, DalLibraryItemService } from '@app/api/services';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { e } from '@angular/core/src/render3';

@Component({
  selector: 'app-modal-li-variable',
  templateUrl: './modal-li-variable.component.html',
  styleUrls: ['./modal-li-variable.component.scss']
})
export class ModalLIComponent implements OnInit {
  referenceControl = new FormControl({ value: '' });
  referenceSectionControl = new FormControl({ value: '' });
  textControl = new FormControl({ value: '' });
  title: string;
  message: string;
  okText: string;
  cancelText: string;
  confirmRevoke: boolean = false;
  dalContentConfiguration: DalContentConfiguration;
  currentLibraryItem: LibraryItem | DalLibraryItem | DalTemplateLibraryItem;
  variable: ItemVariable;
  chkDelegatable: boolean = false;
  textValue: string;
  numberValue: number;
  amountValue: number;
  currencies: Currency[];
  currencySelected: Currency;
  _baseCurrencyCode: string;

  referenceTableMeta: ReferenceTableMeta[];
  referenceLI: Array<DalLibraryItem | DalTemplateLibraryItem>;

  selectedLI: DalLibraryItem | DalTemplateLibraryItem;
  selectedSection: DalSection | DalTemplateSection;

  selectedSubtype: any;

  constructor(
    private _subSectionService: LibrarySubSectionService,
    private _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<ModalLIComponent>,
    public dbv: ProcessBasicInfillVariable,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.dalContentConfiguration = data.dalContentConfiguration;
    this.currentLibraryItem = data.currentLibraryItem;
    this.variable = data.variable;
    this._subSectionService.rootUrl = environment.ApiUrl;

  }

  close(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    if (this.variable.currentValue && this.variable.currentValue.valueId) {

      // Set Selected LI
      this.selectedLI = this.dalContentConfiguration.libraryItems.find(x => x.id == this.variable.currentValue.valueId);
      if (!this.selectedLI) {
        this.selectedLI = this.dalContentConfiguration.libraryItems.find(x => x.libraryItemBase.id == this.variable.currentValue.valueId);
      }
    }
    
    if (this.currentLibraryItem.section) { 
      this.selectedSection = this.dalContentConfiguration.sections.find(x => x.id == this.currentLibraryItem.section.id);
      if (!this.selectedSection) 
        this.selectedSection = this.dalContentConfiguration.sections.find(x => x.sectionBase.id == this.currentLibraryItem.section.id);

      this.loadLiSubsectionsHide(this.selectedSection.id ? this.selectedSection.id : this.selectedSection.sectionBase.id);
    }
    else {
      this.loadLiSubsectionsHide("");
    }
  }

  loadLiSubsectionsHide(sectionId: string) {
    this._spinnerService.show();
    this.referenceLI = [];
    const newLI: DalLibraryItem = { clientId: '', id: '0', name: 'None' };
    let dalLi: Array<DalLibraryItem | DalTemplateLibraryItem>;
    if (sectionId != "") {
      dalLi = this.dalContentConfiguration.libraryItems.filter(x => x.contentType == 'TEXT' && x.section != null && x.section.id == sectionId && x.status != "Deleted");
    }
    else {
      dalLi = this.dalContentConfiguration.libraryItems.filter(x => x.contentType == 'TEXT' && x.status != "Deleted");
    }
    this._subSectionService.GetAll().subscribe(subsections => {
      subsections.results.forEach(subsection => {
        if (subsection.hide) {
          const dalSubsection = this.dalContentConfiguration.subSections.find(x => x.subSectionBase.id === subsection.id);
          if (dalSubsection != null) {
            const subsectionId = dalSubsection.id ? dalSubsection.id : dalSubsection.subSectionBase.id; 
            const dalLiList = dalLi.filter(x => x.subSection != null && x.subSection.id === subsectionId);
            if (dalLiList.length > 0) {
              dalLiList.forEach(li => {
                this.referenceLI.push(li);
              });

             
            }
            
          }
        }
      });
      this.referenceLI.sort((a, b) => a.name.localeCompare(b.name));
      //this.referenceLI.unshift(newLI);

      this._spinnerService.hide();
    },
      (err: HttpErrorResponse) => {
        this._spinnerService.hide();
        console.log(err.message);
      });
  }

  onYesClick() {
    const newValue: ItemVariableValueView = {};
    // Update LI mark
    this.currentLibraryItem.status ="Update";
    if (this.selectedLI && this.selectedLI.id != '0') {
      if (this.selectedLI.id) {
        newValue.valueId = this.selectedLI.id;
      } else {
        newValue.valueId = this.selectedLI.libraryItemBase.id;
      }
      newValue.valueString = this.selectedLI.description;
      newValue.description = this.selectedLI.name ? this.selectedLI.name : '';
      this.variable.currentValue = newValue;
      this.variable.isDelegatable = this.chkDelegatable;
      this.dialogRef.close();
    } else {
      this.variable.currentValue = null;
      this.variable.isDelegatable = this.chkDelegatable;
      this.dialogRef.close();
    }
  }
}