/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class ScheduledReportsService extends __BaseService {
  static readonly DownloadPath = '/api/ScheduledReports/Download';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ScheduledReportsService.DownloadParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `ReportType`:
   *
   * - `PurchaseFacRI`:
   *
   * - `NegotiateFacRI`:
   *
   * - `Location`:
   *
   * - `Function`:
   *
   * - `EffectiveDateTo`:
   *
   * - `EffectiveDateFrom`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `BinderTypePriorSubmit`:
   *
   * - `BinderTypeMatrix`:
   *
   * - `BinderTypeLineSlip`:
   *
   * - `BinderTypeLimited`:
   *
   * - `BinderTypeFull`:
   *
   * - `AuthorityId`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  DownloadResponse(params: ScheduledReportsService.DownloadParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.ReportType != null) __params = __params.set('ReportType', params.ReportType.toString());
    if (params.PurchaseFacRI != null) __params = __params.set('PurchaseFacRI', params.PurchaseFacRI.toString());
    if (params.NegotiateFacRI != null) __params = __params.set('NegotiateFacRI', params.NegotiateFacRI.toString());
    if (params.Location != null) __params = __params.set('Location', params.Location.toString());
    if (params.Function != null) __params = __params.set('Function', params.Function.toString());
    if (params.EffectiveDateTo != null) __params = __params.set('EffectiveDateTo', params.EffectiveDateTo.toString());
    if (params.EffectiveDateFrom != null) __params = __params.set('EffectiveDateFrom', params.EffectiveDateFrom.toString());
    if (params.Division != null) __params = __params.set('Division', params.Division.toString());
    if (params.Delegator != null) __params = __params.set('Delegator', params.Delegator.toString());
    if (params.BinderTypePriorSubmit != null) __params = __params.set('BinderTypePriorSubmit', params.BinderTypePriorSubmit.toString());
    if (params.BinderTypeMatrix != null) __params = __params.set('BinderTypeMatrix', params.BinderTypeMatrix.toString());
    if (params.BinderTypeLineSlip != null) __params = __params.set('BinderTypeLineSlip', params.BinderTypeLineSlip.toString());
    if (params.BinderTypeLimited != null) __params = __params.set('BinderTypeLimited', params.BinderTypeLimited.toString());
    if (params.BinderTypeFull != null) __params = __params.set('BinderTypeFull', params.BinderTypeFull.toString());
    if (params.AuthorityId != null) __params = __params.set('AuthorityId', params.AuthorityId.toString());
    if (params.AuthorityHolder != null) __params = __params.set('AuthorityHolder', params.AuthorityHolder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduledReports/Download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ScheduledReportsService.DownloadParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `ReportType`:
   *
   * - `PurchaseFacRI`:
   *
   * - `NegotiateFacRI`:
   *
   * - `Location`:
   *
   * - `Function`:
   *
   * - `EffectiveDateTo`:
   *
   * - `EffectiveDateFrom`:
   *
   * - `Division`:
   *
   * - `Delegator`:
   *
   * - `BinderTypePriorSubmit`:
   *
   * - `BinderTypeMatrix`:
   *
   * - `BinderTypeLineSlip`:
   *
   * - `BinderTypeLimited`:
   *
   * - `BinderTypeFull`:
   *
   * - `AuthorityId`:
   *
   * - `AuthorityHolder`:
   *
   * @return Success
   */
  Download(params: ScheduledReportsService.DownloadParams): __Observable<string> {
    return this.DownloadResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module ScheduledReportsService {

  /**
   * Parameters for Download
   */
  export interface DownloadParams {
    Status?: string;
    ReportType?: string;
    PurchaseFacRI?: boolean;
    NegotiateFacRI?: boolean;
    Location?: string;
    Function?: string;
    EffectiveDateTo?: string;
    EffectiveDateFrom?: string;
    Division?: string;
    Delegator?: string;
    BinderTypePriorSubmit?: boolean;
    BinderTypeMatrix?: boolean;
    BinderTypeLineSlip?: boolean;
    BinderTypeLimited?: boolean;
    BinderTypeFull?: boolean;
    AuthorityId?: string;
    AuthorityHolder?: string;
  }
}

export { ScheduledReportsService }
