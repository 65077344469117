import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { DalContentConfiguration, LibraryItemSelectedEventArgs, SectionSelectedEventArgs, SubSectionSelectedEventArgs, RemoveSubSectionEventArgs, RemoveSectionEventArgs, RemoveLibraryItemEventArgs, MoveItemEventArgs, UndoRemoveItemEventArgs } from '@app/shared/library-item-processing/dal-content-configuration';
import { MatDialog } from '@angular/material';
import { AddLibraryItemModalDialogComponent } from '../add-library-item-modal-dialog/add-library-item-modal-dialog.component';
import { AddLibrarySectionModalDialogComponent } from '../add-library-section-modal-dialog/add-library-section-modal-dialog.component';
import { LibraryItem, DalTemplateLibraryItem, DalTemplateSection, DalTemplateSubSection, SectionBaseView, DalTemplate, DalTemplateView } from '@app/api/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dal-template-content',
  templateUrl: './dal-template-content.component.html',
  styleUrls: ['./dal-template-content.component.scss']
})
export class DalTemplateContentComponent implements OnInit, OnDestroy {

  /**
   * Subscription to allow remove all the observables not used anymore.
   */
  private _destroySubscription : Subscription = new Subscription();

  /**
   * Configuration of the current DAL Content. Allow us to
   * edit a DAL template and a DAL
   */
  @Input() dalContentConfiguration : DalContentConfiguration;
  @Input() editMode : string;
  @Input() dalTemplate : DalTemplate;
  @Output() markRequired = new EventEmitter<string>();

  constructor(private _dialog : MatDialog) {

  }

  ngOnInit() {

    if(this.dalContentConfiguration == null) {
      return;
    }

    this._destroySubscription.add(
      this.dalContentConfiguration.libraryItemSelected.subscribe((args) => this.addDalTemplateLibraryItem(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.sectionSelected.subscribe((args) => this.addDalTemplateSection(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.subSectionSelected.subscribe((args) => this.addDalTemplateSubSection(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.removeSelectedLibraryItem.subscribe((args) => this.removeLibraryItem(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.removeSelectedSection.subscribe((args) => this.removeSection(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.removeSelectedSubSection.subscribe((args) => this.removeSubSection(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.moveElementUp.subscribe((args) => this.moveUp(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.moveElementDown.subscribe((args) => this.moveDown(args)));
    this._destroySubscription.add(
      this.dalContentConfiguration.undoRemoveElement.subscribe((args) => this.undoRemove(args)));
}

  ngOnDestroy() {
    // Un subscribe all the events
    if(this._destroySubscription) {
      this._destroySubscription.unsubscribe();
    }
  }

  /**
   * Shows a Dialog to insert a LibraryITem
   */
  showAddLibraryItem() {
    if((!this.dalContentConfiguration.divisions) || (!this.dalContentConfiguration.functions)
    || this.dalContentConfiguration.divisions.length == 0 || this.dalContentConfiguration.functions.length == 0){
      this.markRequired.emit();
    }else{
      const dialogRef = this._dialog.open(AddLibraryItemModalDialogComponent, {
        width: '80%',
        data: {
          dalContentConfiguration: this.dalContentConfiguration,
          libraryItems: this.dalContentConfiguration.libraryItems
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined && result != null){
          var results = result as LibraryItem[];
          results.forEach(x =>{
              this.dalContentConfiguration.addLibraryItem(x,
                 null,
                 null,
                 null, null);
            }
          );
        }
      });
    }
  }

  /**
   * Shows a Dialog to insert a LibrarySection
   */
  showAddLibrarySection() {
    if((!this.dalContentConfiguration.divisions) || (!this.dalContentConfiguration.functions)
    || this.dalContentConfiguration.divisions.length == 0 || this.dalContentConfiguration.functions.length == 0){
      this.markRequired.emit();
    }else{
      const dialogRef = this._dialog.open(AddLibrarySectionModalDialogComponent, {
        width: '80%',
        data: {
          dalContentConfiguration: this.dalContentConfiguration,
          sections: this.dalContentConfiguration.sections
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined && result != null){
          var results = result as LibraryItem[];
          results.forEach(x =>{
              this.dalContentConfiguration.addSection(x, null, null);
            }
          );
        }
      });
    }
  }

  addDalTemplateLibraryItem(args : LibraryItemSelectedEventArgs) {

    let libraryItem = args.libraryItem;
    let librarySectionView : SectionBaseView = null;
    let librarySubSectionView : SectionBaseView = null;

    if (args.librarySection != null) {
      // find section
      let section = this.dalContentConfiguration.sections.find(
        x => x.id == args.librarySection.id || (x.sectionBase != null && x.sectionBase.id == args.librarySection.id));
      if (section != null) {
        librarySectionView = {
          id : section.id ? section.id : section.sectionBase.id,
          title : section.title
        }
      }
    }

    if (args.librarySubSection != null) {
      // find subSection
      let subSection = this.dalContentConfiguration.subSections.find(
        x => x.id == args.librarySubSection.id || (x.subSectionBase != null && x.subSectionBase.id == args.librarySubSection.id));
      if (subSection != null) {
        librarySubSectionView = {
          id : subSection.id ? subSection.id : subSection.subSectionBase.id,
          title : subSection.title
        }
      }
    }


    let libraryItems = this.dalContentConfiguration.libraryItems;
    
    let newOrder = 1;
    // order by subSection
    if (librarySubSectionView != null) {

      if (libraryItems && libraryItems.length > 0) {
        let subSectionsForItem = libraryItems.filter(x => x.subSection && x.subSection.id == librarySubSectionView.id);
        newOrder = subSectionsForItem.length + 1;
      }
    } else if (librarySectionView != null) {

      // Order by section
      if (libraryItems && libraryItems.length > 0) {        

        let sectionsForItemN = libraryItems.filter(x => x.section && x.section.id == librarySectionView.id && x.subSection == null);
        newOrder = sectionsForItemN.length + 1;

      }
    } else {
      let itemsWithNoSection = libraryItems.filter(x => x.section == null && x.subSection == null);
      newOrder = itemsWithNoSection.length + 1;
    }

    // Finds the parent element
    let itemExists = libraryItems.filter(x =>x.libraryItemBase != null && x.libraryItemBase.id == libraryItem.id);
    if(itemExists.length == 0) {

      let dalTemplateLibraryItem : DalTemplateLibraryItem = {
        libraryItemBase : {
          id : libraryItem.id
        },
        description : libraryItem.description,
        // TODO: Complete with a copy of all the other properties
        name : libraryItem.name,
        libraryType : libraryItem.libraryType,
        contentType : libraryItem.contentType,
        isMandatory : libraryItem.isMandatory,
        limitsValidation : libraryItem.limitsValidation,
        startDate : libraryItem.startDate,
        endDate : libraryItem.endDate,
        order : newOrder,
        itemVariables : libraryItem.itemVariables ? this.deepCopy(libraryItem.itemVariables) : null,
        table : libraryItem.table ? this.deepCopy(libraryItem.table) : null,
        organizationDataView : libraryItem.organizationDataView ? Array.from(libraryItem.organizationDataView) : [],
        type : 'DalTemplateLibraryItem',
        status : 'Active',
        code : libraryItem.code,
        section: librarySectionView,
        subSection: librarySubSectionView,
        clientId : null,
        dalTemplate : this.createDalTemplateView(this.dalTemplate),
        isDelegatable: libraryItem.isDelegatable,
        currencyView : libraryItem.currencyView !=null ? this.deepCopy(libraryItem.currencyView) : null,
        dataSetChild : libraryItem.dataSetChild !=null ? this.deepCopy(libraryItem.dataSetChild) : null,
        dataSetParent : libraryItem.dataSetParent !=null ? this.deepCopy(libraryItem.dataSetParent) : null,
        dataSetParentDataView : libraryItem.dataSetParentDataView !=null ? this.deepCopy(libraryItem.dataSetParentDataView) : null,
        defaultLevel : libraryItem.defaultLevel !=null ? this.deepCopy(libraryItem.defaultLevel) : null,
        currentLevel : libraryItem.currentLevel !=null ? this.deepCopy(libraryItem.currentLevel) : libraryItem.defaultLevel
      };
      libraryItems.push(dalTemplateLibraryItem);
    }
  }

  /**
   * Creates a reference to a DAL Template
   */
  createDalTemplateView(dalTemplate : DalTemplate) : DalTemplateView {
    if (dalTemplate == null) {
      return null;
    }
    return {
      id : dalTemplate.id
    };
  }

  /**
   * Creates a reference to a DAL
   */
  createSectionView(sectionView : SectionBaseView) : SectionBaseView {
    if (sectionView == null) {
      return null;
    }
    return {
      id : sectionView.id,
      title : sectionView.title
    };
  }

    /**
   * Creates a reference to a DAL
   */
  createSubSectionView(sectionView : SectionBaseView) : SectionBaseView {
    if (sectionView == null) {
      return null;
    }
    return {
      id : sectionView.id,
      title : sectionView.title
    };
  }

  addDalTemplateSection(args : SectionSelectedEventArgs) {

    let librarySection = args.librarySection;

    let sections = this.dalContentConfiguration.sections;
    let newOrder = 1;
    if (sections && sections.length > 0) {
      newOrder = sections.length + 1;
    }

    // Finds the parent element
    let sectionExist = sections.filter(x =>  x.sectionBase != null && x.sectionBase.id == librarySection.id);
    if(sectionExist.length == 0) {

      let dalTemplateSection : DalTemplateSection = {
        sectionBase : {
          id : librarySection.id,
          title : librarySection.title
        },
        title : librarySection.title,
        type : 'DalTemplateSection',
        organizationDataView : librarySection.organizationDataView ? Array.from(librarySection.organizationDataView) : [],
        clientId : null,
        dalTemplate : this.createDalTemplateView(this.dalTemplate),
        status : 'Active',
        order : newOrder
      };
      sections.push(dalTemplateSection);
    }
  }

  addDalTemplateSubSection(args : SubSectionSelectedEventArgs) {
      
    let librarySubSection = args.librarySubSection;
    let parentSection = args.librarySection;
    librarySubSection.order = args.order;
    // TODO: Control that there is a Parent Section
    if(parentSection == null) {
      return;
    }
    let section = this.dalContentConfiguration.sections.find(
      x => x.id == args.librarySection.id || (x.sectionBase != null && x.sectionBase.id == args.librarySection.id));
    let subSections = this.dalContentConfiguration.subSections;
    let newOrder = 1;
    if (subSections && subSections.length > 0) {
      let subSectionsForSection = subSections.filter(x =>
        (section.id == undefined && x.parentSection.id == section.sectionBase.id)
        ||
        (
          ( x.parentSection.id == section.id
          ||x.parentSection.id == section.sectionBase.id)
        ));
      if (subSectionsForSection && subSectionsForSection.length > 0) {
        newOrder = subSections.length + 1;
      }
      newOrder = subSectionsForSection.length + 1;
    }

    // Finds the parent element
    let sectionExist = subSections
    .filter(x => x.subSectionBase != null && x.subSectionBase.id == librarySubSection.id);
    if(sectionExist.length == 0) {

      let dalTemplateSubSection : DalTemplateSubSection = {
        subSectionBase : {
          id : librarySubSection.id,
          title : librarySubSection.title
        },
        parentSection : {
          id : section.id ? section.id : section.sectionBase.id,
          title : section.id ? section.title : parentSection.title
        },
        type : 'DalTemplateSubSection',
        title : librarySubSection.title,
        organizationDataView : librarySubSection.organizationDataView ? Array.from(librarySubSection.organizationDataView) : [],
        clientId : null,
        dalTemplate : this.createDalTemplateView(this.dalTemplate),
        status : 'Active',
        order : newOrder
      };
      subSections.push(dalTemplateSubSection);
    }
  }

  
  
  deepCopy(obj : any) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

  removeLibraryItem (args : RemoveLibraryItemEventArgs) {
    let libraryItem = this.dalContentConfiguration.libraryItems.find(x => x.libraryItemBase.id == args.libraryItem.libraryItemBase.id);
    if(libraryItem)
      libraryItem.status = 'Deleted';
  }
  /**
  * Removes a Section from the DalTemplate
  * @param args 
  */
  removeSection (args : RemoveSectionEventArgs) {
    let section = this.dalContentConfiguration.sections.find(x => x == args.librarySection);
    if(section)
    {
      section.status = 'Deleted';
      var subSectionsBySection = this.dalContentConfiguration.subSections.filter(x =>
        x.subSectionBase &&
        ((section.id == undefined && x.parentSection.id == section.sectionBase.id)
        || (section.id != undefined && x.parentSection.id == section.id)));

      subSectionsBySection.forEach(x =>{
        x.status = 'Deleted';
      });

      var libraryItemsBySection = this.dalContentConfiguration.libraryItems.filter(x =>
        x.section && 
        ((section.id == undefined && x.section.id == section.sectionBase.id)
          || (section.id != undefined && x.section.id == section.id))
        );

      libraryItemsBySection.forEach(x =>{
        x.status = 'Deleted';
      });
    }
  }

/**
 * Removes a SubSection from the DalTemplate
 * @param args 
 */
  removeSubSection (args : RemoveSubSectionEventArgs) {
    // Find by SubSectionId
    let subSectionId = args.librarySubSection.id;

    // In case of new Items, there are no SubSectionId so we need to 
    // use the base SubSectionID
    if(!subSectionId && args.librarySubSection.subSectionBase) {
      subSectionId = args.librarySubSection.subSectionBase.id;
    }

    let subSection = this.dalContentConfiguration.subSections.find(x => x == args.librarySubSection);
    if(subSection)
    {
      subSection.status = 'Deleted';

      var libraryItemsBySection = this.dalContentConfiguration.libraryItems.filter(x =>
        x.subSection &&
        x.subSection.id == subSectionId);

      libraryItemsBySection.forEach(x =>{
        x.status = 'Deleted';
      });
    }
  }

  
  moveUp (args : MoveItemEventArgs) {
    if(args.libraryItem.type == "DalTemplateLibraryItem")
    {
      var libraryItem = args.libraryItem as DalTemplateLibraryItem;

      if(libraryItem.order > 0)
      {
        if(libraryItem.subSection)
        {
          var libraryItemsBySubSections = this.dalContentConfiguration.libraryItems.filter(x => 
            (x.section != undefined && x.section != null)
            && (x.subSection != undefined || x.subSection != null)
            && (x.subSection.id == libraryItem.subSection.id)).sort(this.sortByOrder);
          
          
          var indexLibraryItem = libraryItemsBySubSections.findIndex( x => x == libraryItem);
          const prevLibraryItem = libraryItemsBySubSections[indexLibraryItem-1];
          libraryItem.order = libraryItem.order - 1;
          prevLibraryItem.order = prevLibraryItem.order + 1;

        } else if (libraryItem.section) {

          var libraryItemsBySections = this.dalContentConfiguration.libraryItems.filter(x => 
            (x.section != undefined && x.section != null)
            && (x.section.id == libraryItem.section.id)).sort(this.sortByOrder);
          
          var indexLibraryItem = libraryItemsBySections.findIndex( x => x == libraryItem);
          const prevLibraryItem = libraryItemsBySections[indexLibraryItem-1];
          libraryItem.order = libraryItem.order - 1;
          prevLibraryItem.order = prevLibraryItem.order + 1;

        } else {

          // Gets All the libraryItems without Section
          var libraryItems = this.dalContentConfiguration.libraryItems.filter(x => 
            (x.section == undefined || x.section == null)).sort(this.sortByOrder);
          
          var indexLibraryItem = libraryItems.findIndex( x => x == libraryItem);
          const prevLibraryItem = libraryItems[indexLibraryItem-1];
          libraryItem.order = libraryItem.order - 1;
          prevLibraryItem.order = prevLibraryItem.order + 1;
        }
      }
    }
    if (args.libraryItem.type == "DalTemplateSection")
    {
      var section = args.libraryItem as DalTemplateSection;
      if(section.order > 0)
      {
        let sectionsSorted = this.dalContentConfiguration.sections.sort(this.sortByOrder);
        var indexLibraryItem = sectionsSorted.findIndex( x => x == section);
        
        var previewItem = sectionsSorted[indexLibraryItem-1];
        section.order = section.order - 1;
        previewItem.order = previewItem.order + 1;
      }
    }
    if (args.libraryItem.type == "DalTemplateSubSection")
    {
      var subSection = args.libraryItem as DalTemplateSubSection;

      if(subSection.order > 0)
      {
        var sectionSubSection;
        sectionSubSection = this.dalContentConfiguration.sections.find(x => 
          x.sectionBase.id == subSection.parentSection.id || x.id == subSection.parentSection.id);
        
        var subSectionsBySection = this.dalContentConfiguration.subSections.filter(x =>
          (sectionSubSection.id == undefined && x.parentSection.id == sectionSubSection.sectionBase.id)
          ||
          (
            x.parentSection.id == sectionSubSection.id
            ||x.parentSection.id == sectionSubSection.sectionBase.id
          ))
          .sort(this.sortByOrder);

        var indexSubSection = subSectionsBySection.findIndex(x => x == subSection);
        var previewSubSection = subSectionsBySection[indexSubSection - 1];
        subSection.order = subSection.order - 1;
        previewSubSection.order = previewSubSection.order + 1;
      }
    }
  }

  /**
   * Sort By order Function
   */
  sortByOrder(x : DalTemplateSection | DalTemplateSubSection | DalTemplateLibraryItem, y : DalTemplateSection | DalTemplateSubSection | DalTemplateLibraryItem) {
    if(x.order > y.order) {
      return 1;
    } else if(x.order < y.order) {
      return -1;
    } 
      return 0;
  }

  moveDown (args : MoveItemEventArgs) {
    if(args.libraryItem.type == "DalTemplateLibraryItem")
    {
      var dalLibraryItem = args.libraryItem as DalTemplateLibraryItem;
      var libraryItem = this.dalContentConfiguration.libraryItems.find(x => x.libraryItemBase.id == dalLibraryItem.libraryItemBase.id);

      if(libraryItem.subSection)
      {
        var libraryItemsBySubSections = this.dalContentConfiguration.libraryItems.filter(x => 
          (x.subSection != undefined && x.subSection != null)
          && (x.subSection.id == libraryItem.subSection.id)).sort(this.sortByOrder);
        
        
        var indexLibraryItem = libraryItemsBySubSections.findIndex( x => x == libraryItem);
        const prevItem = libraryItemsBySubSections[indexLibraryItem+1];
        libraryItem.order = libraryItem.order + 1;
        prevItem.order = prevItem.order - 1;

      }else if(libraryItem.section){
        var libraryItemsBySections = this.dalContentConfiguration.libraryItems.filter(x => 
          (x.section != undefined && x.section != null)
          && (x.section.id == libraryItem.section.id)).sort(this.sortByOrder);
        
        
        var indexLibraryItem = libraryItemsBySections.findIndex( x => x.libraryItemBase.id == libraryItem.libraryItemBase.id);
        const prevItem = libraryItemsBySections[indexLibraryItem+1];
        libraryItem.order = libraryItem.order + 1;
        prevItem.order = prevItem.order - 1;

      } else {
        var libraryItems = this.dalContentConfiguration.libraryItems.filter(x => 
          (x.section == undefined || x.section == null)).sort(this.sortByOrder);
        
        
        var indexLibraryItem = libraryItems.findIndex( x => x.libraryItemBase.id == libraryItem.libraryItemBase.id);
        const nextItem = libraryItems[indexLibraryItem+1]
        libraryItem.order = libraryItem.order + 1;
        nextItem.order = nextItem.order - 1;
      }
      this.dalContentConfiguration.libraryItems = this.dalContentConfiguration.libraryItems
      .sort(this.sortByOrder);
    }
    if (args.libraryItem.type == "DalTemplateSection")
    {
      var dalSection = args.libraryItem as DalTemplateSection;
      var section = this.dalContentConfiguration.sections.find(x => x.sectionBase.id == dalSection.sectionBase.id);

      const sortedSections = this.dalContentConfiguration.sections.sort(this.sortByOrder);
      var indexLibraryItem = sortedSections.findIndex( x => x == section);
      
      var nextItem = sortedSections[indexLibraryItem + 1];
      section.order = section.order + 1;
      nextItem.order = nextItem.order - 1;
    }
    if (args.libraryItem.type == "DalTemplateSubSection")
    {
      var dalSubSection = args.libraryItem as DalTemplateSubSection;
      var sectionSubSection;
      sectionSubSection = this.dalContentConfiguration.sections.find(
            x => x.sectionBase.id == dalSubSection.parentSection.id || x.id == dalSubSection.parentSection.id);

      var sortedSubSections = this.dalContentConfiguration.subSections.filter(x =>
        (sectionSubSection.id == undefined && x.parentSection.id == sectionSubSection.sectionBase.id)
        ||
        (
          x.parentSection.id == sectionSubSection.id
          ||x.parentSection.id == sectionSubSection.sectionBase.id
        ))
        .sort(this.sortByOrder);

      var indexSubSection = sortedSubSections.findIndex(x => x == dalSubSection);
      const nextItem = sortedSubSections[indexSubSection + 1];
      dalSubSection.order = dalSubSection.order + 1;
      nextItem.order = nextItem.order - 1;
    }
  }

  undoRemove (args : UndoRemoveItemEventArgs) {
    if(args.libraryItem.type == "DalTemplateLibraryItem"){
      var dalLibraryItem = args.libraryItem as DalTemplateLibraryItem;
      let libraryItem = this.dalContentConfiguration.libraryItems.find(x => x == dalLibraryItem);
      if(libraryItem)
        libraryItem.status = 'Active';
    }
    if(args.libraryItem.type == "DalTemplateSection"){
      var dalSection = args.libraryItem as DalTemplateSection;
      let section = this.dalContentConfiguration.sections.find(x => x == dalSection);
      if(section)
      {
        section.status = 'Active';
        var subSectionsBySection = this.dalContentConfiguration.subSections.filter(x =>
          x.parentSection.id == section.id || (section.id == undefined && x.parentSection.id == section.sectionBase.id));
  
        subSectionsBySection.forEach(x =>{
          x.status = 'Active';
        });
  
        var libraryItemsBySection = this.dalContentConfiguration.libraryItems.filter(x =>
          x.section
          && x.section.id == section.id || (section.id == undefined && x.section.id == section.sectionBase.id));
  
        libraryItemsBySection.forEach(x =>{
          x.status = 'Active';
        });
      }
    }
    if (args.libraryItem.type == "DalTemplateSubSection") {
      var dalSubSection = args.libraryItem as DalTemplateSubSection;
      let subSection = this.dalContentConfiguration.subSections.find(x => x == dalSubSection);
      if(subSection)
      {
        subSection.status = 'Active';
        var libraryItemsBySection = this.dalContentConfiguration.libraryItems.filter(x =>
          x.subSection &&
          x.subSection.id == subSection.id || (x.id == undefined && x.subSection.id == subSection.subSectionBase.id));
  
        libraryItemsBySection.forEach(x =>{
          x.status = 'Active';
        });
      }
    }
  }
}
