import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [ALO] => Authority Holder Location
 */
@Injectable({
  providedIn: 'root',
})
export class AHLocationInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[ALO]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(!config.authorityHolderEmployeeInfo) {
        return "N/A"
      }

      let AHInfo = config.authorityHolderEmployeeInfo; 
      if(AHInfo.location && AHInfo.location.trim() != "") 
        return AHInfo.location;
      else if (AHInfo.location == null || AHInfo.location.trim() == "")
        return "N/A";
      
      return this.infillCode;
    }
  }