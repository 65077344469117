import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ok } from 'assert';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {
  title:string;
  message:string;
  okText: string;
  cancelText: string;
  confirmRevoke: boolean =false;
  messages: any;
  multipleMessages: any;
  btnViolet: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.multipleMessages = this.data.multipleMessages;
    this.messages = this.data.messages;
    this.message = this.data.message;
    this.okText = this.data.okText;
    this.btnViolet = this.data.btnViolet;
    if (this.data.cancelText) {
      this.cancelText = this.data.cancelText;
    } else {
      this.cancelText = "Cancel";
    }
  }
  onYesClick()
  {
    this.confirmRevoke= true;
  }
 
}