/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DalDetailView } from '../models/dal-detail-view';
@Injectable({
  providedIn: 'root',
})
class DalDetailViewsService extends __BaseService {
  static readonly GetPath = '/api/DalDetailViews';
  static readonly PutPath = '/api/DalDetailViews';
  static readonly ExportPath = '/api/DalDetailViews/Export';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DalDetailViewsService.GetParams` containing the following parameters:
   *
   * - `WorkflowActions`:
   *
   * - `TableConfigurations`:
   *
   * - `SuspendReasons`:
   *
   * - `Sections`:
   *
   * - `RevokeReasons`:
   *
   * - `ExchangeRates`:
   *
   * - `DelegatorTables`:
   *
   * - `DalId`:
   *
   * - `Dal`:
   *
   * - `Contents`:
   *
   * - `CompareDalId`:
   *
   * - `Comments`:
   *
   * - `AuthorityHolderEmail`:
   *
   * @return Success
   */
  GetResponse(params: DalDetailViewsService.GetParams): __Observable<__StrictHttpResponse<DalDetailView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WorkflowActions != null) __params = __params.set('WorkflowActions', params.WorkflowActions.toString());
    if (params.TableConfigurations != null) __params = __params.set('TableConfigurations', params.TableConfigurations.toString());
    if (params.SuspendReasons != null) __params = __params.set('SuspendReasons', params.SuspendReasons.toString());
    if (params.Sections != null) __params = __params.set('Sections', params.Sections.toString());
    if (params.RevokeReasons != null) __params = __params.set('RevokeReasons', params.RevokeReasons.toString());
    if (params.ExchangeRates != null) __params = __params.set('ExchangeRates', params.ExchangeRates.toString());
    if (params.DelegatorTables != null) __params = __params.set('DelegatorTables', params.DelegatorTables.toString());
    if (params.DalId != null) __params = __params.set('DalId', params.DalId.toString());
    if (params.Dal != null) __params = __params.set('Dal', params.Dal.toString());
    if (params.Contents != null) __params = __params.set('Contents', params.Contents.toString());
    if (params.CompareDalId != null) __params = __params.set('CompareDalId', params.CompareDalId.toString());
    if (params.Comments != null) __params = __params.set('Comments', params.Comments.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalDetailViews`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalDetailView>;
      })
    );
  }
  /**
   * @param params The `DalDetailViewsService.GetParams` containing the following parameters:
   *
   * - `WorkflowActions`:
   *
   * - `TableConfigurations`:
   *
   * - `SuspendReasons`:
   *
   * - `Sections`:
   *
   * - `RevokeReasons`:
   *
   * - `ExchangeRates`:
   *
   * - `DelegatorTables`:
   *
   * - `DalId`:
   *
   * - `Dal`:
   *
   * - `Contents`:
   *
   * - `CompareDalId`:
   *
   * - `Comments`:
   *
   * - `AuthorityHolderEmail`:
   *
   * @return Success
   */
  Get(params: DalDetailViewsService.GetParams): __Observable<DalDetailView> {
    return this.GetResponse(params).pipe(
      __map(_r => _r.body as DalDetailView)
    );
  }

  /**
   * @param dalDetailView undefined
   * @return Success
   */
  PutResponse(dalDetailView?: DalDetailView): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dalDetailView;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DalDetailViews`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param dalDetailView undefined
   * @return Success
   */
  Put(dalDetailView?: DalDetailView): __Observable<boolean> {
    return this.PutResponse(dalDetailView).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `DalDetailViewsService.ExportParams` containing the following parameters:
   *
   * - `WorkflowActions`:
   *
   * - `TableConfigurations`:
   *
   * - `SuspendReasons`:
   *
   * - `Sections`:
   *
   * - `RevokeReasons`:
   *
   * - `ExchangeRates`:
   *
   * - `DelegatorTables`:
   *
   * - `DalId`:
   *
   * - `Dal`:
   *
   * - `Contents`:
   *
   * - `CompareDalId`:
   *
   * - `Comments`:
   *
   * - `AuthorityHolderEmail`:
   *
   * @return Success
   */
  ExportResponse(params: DalDetailViewsService.ExportParams): __Observable<__StrictHttpResponse<Array<DalDetailView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WorkflowActions != null) __params = __params.set('WorkflowActions', params.WorkflowActions.toString());
    if (params.TableConfigurations != null) __params = __params.set('TableConfigurations', params.TableConfigurations.toString());
    if (params.SuspendReasons != null) __params = __params.set('SuspendReasons', params.SuspendReasons.toString());
    if (params.Sections != null) __params = __params.set('Sections', params.Sections.toString());
    if (params.RevokeReasons != null) __params = __params.set('RevokeReasons', params.RevokeReasons.toString());
    if (params.ExchangeRates != null) __params = __params.set('ExchangeRates', params.ExchangeRates.toString());
    if (params.DelegatorTables != null) __params = __params.set('DelegatorTables', params.DelegatorTables.toString());
    if (params.DalId != null) __params = __params.set('DalId', params.DalId.toString());
    if (params.Dal != null) __params = __params.set('Dal', params.Dal.toString());
    if (params.Contents != null) __params = __params.set('Contents', params.Contents.toString());
    if (params.CompareDalId != null) __params = __params.set('CompareDalId', params.CompareDalId.toString());
    if (params.Comments != null) __params = __params.set('Comments', params.Comments.toString());
    if (params.AuthorityHolderEmail != null) __params = __params.set('AuthorityHolderEmail', params.AuthorityHolderEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalDetailViews/Export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DalDetailView>>;
      })
    );
  }
  /**
   * @param params The `DalDetailViewsService.ExportParams` containing the following parameters:
   *
   * - `WorkflowActions`:
   *
   * - `TableConfigurations`:
   *
   * - `SuspendReasons`:
   *
   * - `Sections`:
   *
   * - `RevokeReasons`:
   *
   * - `ExchangeRates`:
   *
   * - `DelegatorTables`:
   *
   * - `DalId`:
   *
   * - `Dal`:
   *
   * - `Contents`:
   *
   * - `CompareDalId`:
   *
   * - `Comments`:
   *
   * - `AuthorityHolderEmail`:
   *
   * @return Success
   */
  Export(params: DalDetailViewsService.ExportParams): __Observable<Array<DalDetailView>> {
    return this.ExportResponse(params).pipe(
      __map(_r => _r.body as Array<DalDetailView>)
    );
  }
}

module DalDetailViewsService {

  /**
   * Parameters for Get
   */
  export interface GetParams {
    WorkflowActions?: boolean;
    TableConfigurations?: boolean;
    SuspendReasons?: boolean;
    Sections?: boolean;
    RevokeReasons?: boolean;
    ExchangeRates?: boolean;
    DelegatorTables?: boolean;
    DalId?: string;
    Dal?: boolean;
    Contents?: boolean;
    CompareDalId?: string;
    Comments?: boolean;
    AuthorityHolderEmail?: string;
  }

  /**
   * Parameters for Export
   */
  export interface ExportParams {
    WorkflowActions?: boolean;
    TableConfigurations?: boolean;
    SuspendReasons?: boolean;
    Sections?: boolean;
    RevokeReasons?: boolean;
    ExchangeRates?: boolean;
    DelegatorTables?: boolean;
    DalId?: string;
    Dal?: boolean;
    Contents?: boolean;
    CompareDalId?: string;
    Comments?: boolean;
    AuthorityHolderEmail?: string;
  }
}

export { DalDetailViewsService }
