/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tenant } from '../models/tenant';
@Injectable({
  providedIn: 'root',
})
class TenantService extends __BaseService {
  static readonly GetByIdPath = '/api/Tenant/{id}';
  static readonly GetTenantNamePath = '/api/Tenant/GetTenantName';
  static readonly GetTenantCurrencyPath = '/api/Tenant/GetTenantCurrency';
  static readonly GetTenantHelpLinkPath = '/api/Tenant/GetTenantHelpLink';
  static readonly GetTenantPrivacyPolicyPath = '/api/Tenant/GetTenantPrivacyPolicy';
  static readonly GetTenantDalAcceptTermsLinkPath = '/api/Tenant/GetTenantDalAcceptTermsLink';
  static readonly GetTenantMaxDownloadSizePath = '/api/Tenant/GetTenantMaxDownloadSize';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<Tenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tenant>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<Tenant> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as Tenant)
    );
  }

  /**
   * @return Success
   */
  GetTenantNameResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/GetTenantName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTenantName(): __Observable<string> {
    return this.GetTenantNameResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetTenantCurrencyResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/GetTenantCurrency`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTenantCurrency(): __Observable<string> {
    return this.GetTenantCurrencyResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetTenantHelpLinkResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/GetTenantHelpLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTenantHelpLink(): __Observable<string> {
    return this.GetTenantHelpLinkResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetTenantPrivacyPolicyResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/GetTenantPrivacyPolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTenantPrivacyPolicy(): __Observable<string> {
    return this.GetTenantPrivacyPolicyResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetTenantDalAcceptTermsLinkResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/GetTenantDalAcceptTermsLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTenantDalAcceptTermsLink(): __Observable<string> {
    return this.GetTenantDalAcceptTermsLinkResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
   /**
   * @return Success
   */

  GetTenantLogoImgUrlResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tenant/GetTenantLogoImgUrl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTenantLogoImgUrl(): __Observable<string> {
    return this.GetTenantLogoImgUrlResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

      /**
   * @return Success
   */
       GetTenantMaxDownloadSizeResponse(): __Observable<__StrictHttpResponse<string>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let req = new HttpRequest<any>(
          'GET',
          this.rootUrl + `/api/Tenant/GetTenantMaxDownloadSize`,
          __body,
          {
            headers: __headers,
            params: __params,
            responseType: 'text'
          });
    
        return this.http.request<any>(req).pipe(
          __filter(_r => _r instanceof HttpResponse),
          __map((_r) => {
            return _r as __StrictHttpResponse<string>;
          })
        );
      }
      /**
       * @return Success
       */
      GetTenantMaxDownloadSize(): __Observable<string> {
        return this.GetTenantMaxDownloadSizeResponse().pipe(
          __map(_r => _r.body as string)
        );
      }
}

module TenantService {
}

export { TenantService }
