import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef,DateAdapter, MAT_DATE_FORMATS, MatSnackBar, MatSort, MatTableDataSource, Sort, MatDialog, _MatListItemMixinBase } from '@angular/material';
import { DalContentConfiguration } from '@app/shared/shared.module';
import { DalTemplateLibraryItem, DalLibraryItem, LibraryItem, ItemTableRow, ItemCellValue, ItemTable } from '@app/api/models';
import { InfillBasicEditorComponent } from '../infill-basic-editor/infill-basic-editor.component';

@Component({
  selector: 'app-preview-table-library-item',
  templateUrl: './preview-table-library-item.component.html',
  styleUrls: ['./preview-table-library-item.component.scss']
})
export class PreviewTableLibraryItemComponent implements OnInit {


  currentLibraryItem: DalTemplateLibraryItem | DalLibraryItem | LibraryItem;
  dalContentConfiguration : DalContentConfiguration;
  editMode: boolean;
  
  constructor(
    public dialog: MatDialog,
    private dialogRef : MatDialogRef<PreviewTableLibraryItemComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) { 

      this.editMode = data.editMode;

      // Sets the current LibraryItem
      if (data.currentLibraryItem) {
        this.currentLibraryItem = data.currentLibraryItem;
      }

      if(data.dalContentConfiguration) {
        this.dalContentConfiguration = data.dalContentConfiguration;
      }
    }

  ngOnInit() {
    //console.log(this.editMode);
    // console.log(this.currentLibraryItem)
    // console.log(this.dalContentConfiguration)
  }


  /**
   * Closes the preview Dialog.
   */
  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

}
