import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReportComponent } from './report.component';
import { MatTableModule, MatFormFieldModule, MatListModule, MatMenuModule, MatSelectModule, MatCheckboxModule, MatInputModule, MatIconModule, MatDatepickerModule, MatExpansionModule, MatAutocompleteModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportRoutingModule } from '@app/report/report-routing.module';
import { HistoryComponent } from './history/history.component';
import { ExecutionReportComponent } from './execution-report/execution-report.component';
import { GeneralExecutionComponent } from './general-execution/general-execution.component';
import { HierarchyComponent} from './hierarchy/hierarchy.component';
import { SharedModule } from '@app/shared/shared.module';
import { ScheduledReportComponent } from './scheduled-report/scheduled-report.component';
import { ScheduleReportModalDialogComponent } from './schedule-report-modal-dialog/schedule-report-modal-dialog.component';
import { MaterialModule } from '@app/material/material.module';
import { NofiltersModalDialogComponent } from './modal-dialog-no-filters/modal-dialog-no-filters.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MaterialModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatAutocompleteModule,
    SharedModule,
  
  ],
  exports:[
    ReportComponent,
    ReportRoutingModule,

  ],
  declarations:[
    ReportComponent,
    ExecutionReportComponent,
    HistoryComponent,
    GeneralExecutionComponent,
    ScheduledReportComponent,
    ScheduleReportModalDialogComponent,
    GeneralExecutionComponent,
    HierarchyComponent,
    NofiltersModalDialogComponent
  
  ],
  entryComponents:[
    ScheduleReportModalDialogComponent,
    NofiltersModalDialogComponent
  ],
})
export class ReportModule {
 }
