/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ValueResultBoolean } from '../models/value-result-boolean';
import { ReviewerGroup } from '../models/reviewer-group';
@Injectable({
  providedIn: 'root',
})
class WorkflowHostService extends __BaseService {
  static readonly PostPath = '/api/WorkflowHost';
  static readonly SendForApprovePath = '/api/WorkflowHost/SendForApprove/{dalId}';
  static readonly SendForReviewPath = '/api/WorkflowHost/SendForReview/{dalId}';
  static readonly ApproveDalPath = '/api/WorkflowHost/ApproveDal/{dalId}';
  static readonly SendToDelegatorPath = '/api/WorkflowHost/SendToDelegator/{dalId}';
  static readonly AcceptDalPath = '/api/WorkflowHost/AcceptDal';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WorkflowHostService.PostParams` containing the following parameters:
   *
   * - `eventData`:
   *
   * - `EventName`:
   *
   * - `EventKey`:
   *
   * - `CorrelationID`:
   *
   * @return Success
   */
  PostResponse(params: WorkflowHostService.PostParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.eventData;
    if (params.EventName != null) __params = __params.set('EventName', params.EventName.toString());
    if (params.EventKey != null) __params = __params.set('EventKey', params.EventKey.toString());
    if (params.CorrelationID != null) __params = __params.set('CorrelationID', params.CorrelationID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowHost`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `WorkflowHostService.PostParams` containing the following parameters:
   *
   * - `eventData`:
   *
   * - `EventName`:
   *
   * - `EventKey`:
   *
   * - `CorrelationID`:
   *
   * @return Success
   */
  Post(params: WorkflowHostService.PostParams): __Observable<boolean> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `WorkflowHostService.SendForApproveParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  SendForApproveResponse(params: WorkflowHostService.SendForApproveParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.continueWithWarning != null) __params = __params.set('continueWithWarning', params.continueWithWarning.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowHost/SendForApprove/${params.dalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `WorkflowHostService.SendForApproveParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  SendForApprove(params: WorkflowHostService.SendForApproveParams): __Observable<ValueResultBoolean> {
    return this.SendForApproveResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `WorkflowHostService.SendForReviewParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  SendForReviewResponse(params: WorkflowHostService.SendForReviewParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.continueWithWarning != null) __params = __params.set('continueWithWarning', params.continueWithWarning.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowHost/SendForReview/${params.dalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `WorkflowHostService.SendForReviewParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  SendForReview(params: WorkflowHostService.SendForReviewParams): __Observable<ValueResultBoolean> {
    return this.SendForReviewResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `WorkflowHostService.ApproveDalParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `actorEmail`:
   *
   * @return Success
   */
  ApproveDalResponse(params: WorkflowHostService.ApproveDalParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.actorEmail != null) __params = __params.set('actorEmail', params.actorEmail.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowHost/ApproveDal/${params.dalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `WorkflowHostService.ApproveDalParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `actorEmail`:
   *
   * @return Success
   */
  ApproveDal(params: WorkflowHostService.ApproveDalParams): __Observable<ValueResultBoolean> {
    return this.ApproveDalResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `WorkflowHostService.SendToDelegatorParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `reviewerGroups`:
   *
   * - `continueWithWarning`:
   *
   * - `comment`:
   *
   * @return Success
   */
  SendToDelegatorResponse(params: WorkflowHostService.SendToDelegatorParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.reviewerGroups;
    if (params.continueWithWarning != null) __params = __params.set('continueWithWarning', params.continueWithWarning.toString());
    if (params.comment != null) __params = __params.set('comment', params.comment.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowHost/SendToDelegator/${params.dalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `WorkflowHostService.SendToDelegatorParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `reviewerGroups`:
   *
   * - `continueWithWarning`:
   *
   * - `comment`:
   *
   * @return Success
   */
  SendToDelegator(params: WorkflowHostService.SendToDelegatorParams): __Observable<ValueResultBoolean> {
    return this.SendToDelegatorResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `WorkflowHostService.AcceptDalParams` containing the following parameters:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * - `dalId`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  AcceptDalResponse(params: WorkflowHostService.AcceptDalParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    if (params.dalId != null) __params = __params.set('dalId', params.dalId.toString());
    if (params.continueWithWarning != null) __params = __params.set('continueWithWarning', params.continueWithWarning.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowHost/AcceptDal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `WorkflowHostService.AcceptDalParams` containing the following parameters:
   *
   * - `lastName`:
   *
   * - `firstName`:
   *
   * - `dalId`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  AcceptDal(params: WorkflowHostService.AcceptDalParams): __Observable<ValueResultBoolean> {
    return this.AcceptDalResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }
}

module WorkflowHostService {

  /**
   * Parameters for Post
   */
  export interface PostParams {
    eventData?: {};
    EventName?: string;
    EventKey?: string;
    CorrelationID?: string;
  }

  /**
   * Parameters for SendForApprove
   */
  export interface SendForApproveParams {
    dalId: string;
    continueWithWarning?: boolean;
  }

  /**
   * Parameters for SendForReview
   */
  export interface SendForReviewParams {
    dalId: string;
    continueWithWarning?: boolean;
  }

  /**
   * Parameters for ApproveDal
   */
  export interface ApproveDalParams {
    dalId: string;
    actorEmail?: string;
  }

  /**
   * Parameters for SendToDelegator
   */
  export interface SendToDelegatorParams {
    dalId: string;
    reviewerGroups?: Array<ReviewerGroup>;
    continueWithWarning?: boolean;
    comment?: string;
  }

  /**
   * Parameters for AcceptDal
   */
  export interface AcceptDalParams {
    lastName?: string;
    firstName?: string;
    dalId?: string;
    continueWithWarning?: boolean;
  }
}

export { WorkflowHostService }
