/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DalContent } from '../models/dal-content';
@Injectable({
  providedIn: 'root',
})
class DalContentsService extends __BaseService {
  static readonly GetPath = '/api/DalContents';
  static readonly PostPath = '/api/DalContents';
  static readonly GetByIdPath = '/api/DalContents/{id}';
  static readonly PutPath = '/api/DalContents/{id}';
  static readonly DeletePath = '/api/DalContents/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetResponse(): __Observable<__StrictHttpResponse<Array<DalContent>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalContents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DalContent>>;
      })
    );
  }
  /**
   * @return Success
   */
  Get(): __Observable<Array<DalContent>> {
    return this.GetResponse().pipe(
      __map(_r => _r.body as Array<DalContent>)
    );
  }

  /**
   * @param dalContents undefined
   * @return Success
   */
  PostResponse(dalContents?: Array<DalContent>): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dalContents;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DalContents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param dalContents undefined
   * @return Success
   */
  Post(dalContents?: Array<DalContent>): __Observable<boolean> {
    return this.PostResponse(dalContents).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: string): __Observable<__StrictHttpResponse<DalContent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DalContents/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalContent>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: string): __Observable<DalContent> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as DalContent)
    );
  }

  /**
   * @param params The `DalContentsService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `DalContent`:
   *
   * @return Success
   */
  PutResponse(params: DalContentsService.PutParams): __Observable<__StrictHttpResponse<DalContent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.DalContent;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DalContents/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalContent>;
      })
    );
  }
  /**
   * @param params The `DalContentsService.PutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `DalContent`:
   *
   * @return Success
   */
  Put(params: DalContentsService.PutParams): __Observable<DalContent> {
    return this.PutResponse(params).pipe(
      __map(_r => _r.body as DalContent)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id: string): __Observable<__StrictHttpResponse<DalContent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/DalContents/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalContent>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id: string): __Observable<DalContent> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as DalContent)
    );
  }
}

module DalContentsService {

  /**
   * Parameters for Put
   */
  export interface PutParams {
    id: string;
    DalContent?: Array<DalContent>;
  }
}

export { DalContentsService }
