
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalService } from '@app/shared/services/global/global.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AuditDetailsService } from '@app/api/services';
import { environment } from '@env/environment';

@Component({
  selector: 'app-audit-table',
  templateUrl: './audit-table.component.html',
  styleUrls: ['./audit-table.component.scss']
})

export class AuditTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /* @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.activatePaginator();
  } */

  displayedColumns: string[] = ['natureofChanges', 'summaryofChanges', 'author', 'dateTime'];
  tiles: any;
  resultLength = -1;
  pageSize = 6;
  dataSource: any;
  // dataSource: MatTableDataSource<any>;
  delegatorName = '';
  type:string;
  previousPageRoute:string;

  // private paginator: MatPaginator;
  private _getBaseDataAuditSubscribe$: any;

  constructor(
    private _globalService: GlobalService,
    private _router: Router,
    private route:ActivatedRoute,
    private _location: Location,
    private spinnerService: Ng4LoadingSpinnerService,
    private _baseDataAuditService: AuditDetailsService) { }

  ngOnInit() {
    this._baseDataAuditService.rootUrl = environment.ApiUrl;
    this.type = this.route.snapshot.queryParamMap.get ("type");
    this.previousPageRoute = this.route.snapshot.queryParamMap.get ("previous");
    this.loadAuditTable();
  }

  // fn to load DAL history data in table
  loadAuditTable(): void {
    // this.currentDalForAudit = this._globalService.selectedDALForAuditObj;
    this.delegatorName = this._globalService.delegatorName;
    this.tiles = [
      { text: 'Tile 1', cols: 3, rows: 4.5, border: '2px solid #9e9e9e' },
    ];
    let subtype :string;
    if(this.type =='undefined'|| this.type == null || this.type.length <2)
    {
      this.type =  'BaseData';
    }
    const basedataID = this._globalService.selectedBaseDataIdForAudit;
    this.spinnerService.show();
    this._getBaseDataAuditSubscribe$ = this._baseDataAuditService.Get({
      Id: basedataID,
      SubType:this.type,
    }).subscribe(resp => {
      this.dataSource = resp;
      
      this.spinnerService.hide();
      if (this.dataSource != null && this.dataSource.length !== 0) {
        
       // this.activatePaginator();
      } else {
        this.dataSource = null;
        this.resultLength = 0;
      }
      /* if (resp) {
         this.dataSource = new MatTableDataSource(resp);
         this.resultLength = resp.length;
         this.activatePaginator();
       } else {
         this.dataSource = null;
         this.resultLength = 0;
       } */
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
      this.spinnerService.hide();
    });
  }

  // fn to show pagination for Mat-Table
  activatePaginator(): void {
    setTimeout(() => {
      if (this.dataSource) {
        this.resultLength = this.dataSource.length;
        if (this.resultLength > this.pageSize) {
          this.dataSource.paginator = this.paginator;
          this.paginator._intl.previousPageLabel = '';
          this.paginator._intl.nextPageLabel = '';
        }
      }
    }, 200);
  }

  /* activatePaginator() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  } */

  backToListView(): void {
    if(this.previousPageRoute) {
      this._router.navigate(["/"+sessionStorage.getItem('client')+'/base-data/' + this.previousPageRoute]);
      this._globalService.baseDataClausesSource = [];
    } else {
      this._router.navigate(["/"+sessionStorage.getItem('client')+'/base-data']);
      this._globalService.baseDataClausesSource = [];
    }
  }

  ngOnDestroy() {
    if (this._getBaseDataAuditSubscribe$) {
      this._getBaseDataAuditSubscribe$.unsubscribe();
    }
  }
}
