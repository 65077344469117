/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ValueResultBoolean } from '../models/value-result-boolean';
import { Dal } from '../models/dal';
import { SuspendRequest } from '../models/suspend-request';
import { QueryComment } from '../models/query-comment';
import { ReviewerGroup } from '../models/reviewer-group';
import { ValueResultDalChangeDelegatorProgress } from '../models/value-result-dal-change-delegator-progress';
import { DalImpactAnalysis } from '../models/dal-impact-analysis';
@Injectable({
  providedIn: 'root',
})
class DalActionsService extends __BaseService {
  static readonly PostPath = '/api/dals/{dalId}/actions';
  static readonly SuspendPath = '/api/dals/{dalId}/actions/SuspendDal/{email}';
  static readonly ReinstatePath = '/api/dals/{dalId}/actions/Reinstate';
  static readonly ReinstateDalPath = '/api/dals/{dalId}/actions/Reinstate/{email}/{reason}';
  static readonly AcceptMigrationPath = '/api/dals/{dalId}/actions/AcceptMigration/{email}';
  static readonly AcknowledgePath = '/api/dals/{dalId}/actions/Acknowledge/{email}/{hasMigrationErrors}';
  static readonly QueryPath = '/api/dals/{dalId}/actions/Query/{email}';
  static readonly AcceptQueryPath = '/api/dals/{dalId}/actions/AcceptQuery/{email}';
  static readonly RejectQueryPath = '/api/dals/{dalId}/actions/RejectQuery/{email}';
  static readonly SendForReviewPath = '/api/dals/{dalId}/actions/SendForReview/{email}';
  static readonly PreValidateChangeDelegatorPath = '/api/dals/{dalId}/actions/PreValidateChangeDelegator';
  static readonly ChangeDelegatorbySkippingPath = '/api/dals/{dalId}/actions/ChangeDelegatorbySkipping';
  static readonly ChangeDelegatorbySwappingPath = '/api/dals/{dalId}/actions/ChangeDelegatorbySwapping/{newParentDalId}';
  static readonly ChangeDelegatorbySwappingWFInfoPath = '/api/dals/{dalId}/actions/ChangeDelegatorbySwappingWFInfo/{newParentDalId}';
  static readonly ChangeDelegatorbySwappingWFIdPath = '/api/dals/{dalId}/actions/ChangeDelegatorbySwappingWFId';
  static readonly GetDalChildrenByIdPath = '/api/dals/{dalId}/actions/GetDalChildrenToProcess';
  static readonly ValidateDelegatorBySwappingPath = '/api/dals/{dalId}/actions/ValidateDelegatorBySwapping';
  static readonly PerformImpactAnalysisPath = '/api/dals/{dalId}/actions/PerformImpactAnalysis';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DalActionsService.PostParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `dal`:
   *
   * - `actionName`:
   *
   * @return Success
   */
  PostResponse(params: DalActionsService.PostParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.dal;
    if (params.actionName != null) __params = __params.set('actionName', params.actionName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.PostParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `dal`:
   *
   * - `actionName`:
   *
   * @return Success
   */
  Post(params: DalActionsService.PostParams): __Observable<ValueResultBoolean> {
    return this.PostResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalActionsService.SuspendParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `data`:
   *
   * @return Success
   */
  SuspendResponse(params: DalActionsService.SuspendParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions/SuspendDal/${params.email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `DalActionsService.SuspendParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `data`:
   *
   * @return Success
   */
  Suspend(params: DalActionsService.SuspendParams): __Observable<boolean> {
    return this.SuspendResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `DalActionsService.ReinstateParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `reason`:
   *
   * - `comment`:
   *
   * @return Success
   */
  ReinstateResponse(params: DalActionsService.ReinstateParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.reason != null) __params = __params.set('reason', params.reason.toString());
    if (params.comment != null) __params = __params.set('comment', params.comment.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions/Reinstate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `DalActionsService.ReinstateParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `reason`:
   *
   * - `comment`:
   *
   * @return Success
   */
  Reinstate(params: DalActionsService.ReinstateParams): __Observable<boolean> {
    return this.ReinstateResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `DalActionsService.ReinstateDalParams` containing the following parameters:
   *
   * - `reason`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  ReinstateDalResponse(params: DalActionsService.ReinstateDalParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions/Reinstate/${params.email}/${params.reason}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `DalActionsService.ReinstateDalParams` containing the following parameters:
   *
   * - `reason`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  ReinstateDal(params: DalActionsService.ReinstateDalParams): __Observable<boolean> {
    return this.ReinstateDalResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `DalActionsService.AcceptMigrationParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  AcceptMigrationResponse(params: DalActionsService.AcceptMigrationParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions/AcceptMigration/${params.email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.AcceptMigrationParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  AcceptMigration(params: DalActionsService.AcceptMigrationParams): __Observable<ValueResultBoolean> {
    return this.AcceptMigrationResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalActionsService.AcknowledgeParams` containing the following parameters:
   *
   * - `hasMigrationErrors`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  AcknowledgeResponse(params: DalActionsService.AcknowledgeParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions/Acknowledge/${params.email}/${params.hasMigrationErrors}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.AcknowledgeParams` containing the following parameters:
   *
   * - `hasMigrationErrors`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  Acknowledge(params: DalActionsService.AcknowledgeParams): __Observable<ValueResultBoolean> {
    return this.AcknowledgeResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalActionsService.QueryParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `queryComments`:
   *
   * @return Success
   */
  QueryResponse(params: DalActionsService.QueryParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.queryComments;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions/Query/${params.email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.QueryParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `queryComments`:
   *
   * @return Success
   */
  Query(params: DalActionsService.QueryParams): __Observable<ValueResultBoolean> {
    return this.QueryResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalActionsService.AcceptQueryParams` containing the following parameters:
   *
   * - `reason`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  AcceptQueryResponse(params: DalActionsService.AcceptQueryParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    // created bodyData as an json object to pass as reason 
    const bodyData = { 'acceptQuery': params.reason };
    let req = new HttpRequest<any>(
      'POST',
      // sending reason as body not as api parameter (removed param.reason from URI)
      // this.rootUrl + `/api/dals/${params.dalId}/actions/AcceptQuery/${params.email}/${params.reason}`, 
      this.rootUrl + `/api/dals/${params.dalId}/actions/AcceptQuery/${params.email}`,
      bodyData,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.AcceptQueryParams` containing the following parameters:
   *
   * - `reason`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  AcceptQuery(params: DalActionsService.AcceptQueryParams): __Observable<ValueResultBoolean> {
    return this.AcceptQueryResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalActionsService.RejectQueryParams` containing the following parameters:
   *
   * - `reason`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  RejectQueryResponse(params: DalActionsService.RejectQueryParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    // created bodyData as an json object to pass as reason 
    const bodyData = { 'rejectQuery': params.reason };
    let req = new HttpRequest<any>(
      'POST',
      // sending reason as body not as api parameter (removed param.reason from URI)
      // this.rootUrl + `/api/dals/${params.dalId}/actions/RejectQuery/${params.email}/${params.reason}`,
      this.rootUrl + `/api/dals/${params.dalId}/actions/RejectQuery/${params.email}`,
      bodyData,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.RejectQueryParams` containing the following parameters:
   *
   * - `reason`:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  RejectQuery(params: DalActionsService.RejectQueryParams): __Observable<ValueResultBoolean> {
    return this.RejectQueryResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalActionsService.SendForReviewParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `reviewerGroups`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  SendForReviewResponse(params: DalActionsService.SendForReviewParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.reviewerGroups;
    if (params.continueWithWarning != null) __params = __params.set('continueWithWarning', params.continueWithWarning.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${params.dalId}/actions/SendForReview/${params.email}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.SendForReviewParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `dalId`:
   *
   * - `reviewerGroups`:
   *
   * - `continueWithWarning`:
   *
   * @return Success
   */
  SendForReview(params: DalActionsService.SendForReviewParams): __Observable<ValueResultBoolean> {
    return this.SendForReviewResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param dalId undefined
   * @return Success
   */
  PreValidateChangeDelegatorResponse(dalId: string): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${dalId}/actions/PreValidateChangeDelegator`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  PreValidateChangeDelegator(dalId: string): __Observable<ValueResultBoolean> {
    return this.PreValidateChangeDelegatorResponse(dalId).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

  /**
   * @param params The `DalActionsService.ChangeDelegatorbySkippingParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `transaction`:
   *
   * - `childs`:
   *
   * @return Success
   */
   ChangeDelegatorbySkippingResponse(params: DalActionsService.ChangeDelegatorbySkippingParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.transaction != null) __params = __params.set('transaction', params.transaction.toString());
    __body = params.childs;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${encodeURIComponent(String(params.dalId))}/actions/ChangeDelegatorbySkipping`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultBoolean>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.ChangeDelegatorbySkippingParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `transaction`:
   *
   * - `childs`:
   *
   * @return Success
   */
  ChangeDelegatorbySkipping(params: DalActionsService.ChangeDelegatorbySkippingParams): __Observable<ValueResultBoolean> {
    return this.ChangeDelegatorbySkippingResponse(params).pipe(
      __map(_r => _r.body as ValueResultBoolean)
    );
  }

   /**
   * @param params The `DalActionsService.ChangeDelegatorbySwappingParams` containing the following parameters:
   *
   * - `newParentDalId`:
   *
   * - `dalId`:
   *
   * - `transaction`:
   *
   * - `childs`:
   *
   * @return Success
   */
    ChangeDelegatorbySwappingResponse(params: DalActionsService.ChangeDelegatorbySwappingParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
  
  
      if (params.transaction != null) __params = __params.set('transaction', params.transaction.toString());
      __body = params.childs;
      let req = new HttpRequest<any>(
        'POST',
        this.rootUrl + `/api/dals/${encodeURIComponent(String(params.dalId))}/actions/ChangeDelegatorbySwapping/${encodeURIComponent(String(params.newParentDalId))}`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        __filter(_r => _r instanceof HttpResponse),
        __map((_r) => {
          return _r as __StrictHttpResponse<ValueResultBoolean>;
        })
      );
    }
    /**
     * @param params The `DalActionsService.ChangeDelegatorbySwappingParams` containing the following parameters:
     *
     * - `newParentDalId`:
     *
     * - `dalId`:
     *
     * - `transaction`:
     *
     * - `childs`:
     *
     * @return Success
     */
    ChangeDelegatorbySwapping(params: DalActionsService.ChangeDelegatorbySwappingParams): __Observable<ValueResultBoolean> {
      return this.ChangeDelegatorbySwappingResponse(params).pipe(
        __map(_r => _r.body as ValueResultBoolean)
      );
    }

  /**
   * @param params The `DalActionsService.ChangeDelegatorbySwappingWFInfoParams` containing the following parameters:
   *
   * - `newParentDalId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  ChangeDelegatorbySwappingWFInfoResponse(params: DalActionsService.ChangeDelegatorbySwappingWFInfoParams): __Observable<__StrictHttpResponse<ValueResultDalChangeDelegatorProgress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/dals/${params.dalId}/actions/ChangeDelegatorbySwappingWFInfo/${params.newParentDalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultDalChangeDelegatorProgress>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.ChangeDelegatorbySwappingWFInfoParams` containing the following parameters:
   *
   * - `newParentDalId`:
   *
   * - `dalId`:
   *
   * @return Success
   */
  ChangeDelegatorbySwappingWFInfo(params: DalActionsService.ChangeDelegatorbySwappingWFInfoParams): __Observable<ValueResultDalChangeDelegatorProgress> {
    return this.ChangeDelegatorbySwappingWFInfoResponse(params).pipe(
      __map(_r => _r.body as ValueResultDalChangeDelegatorProgress)
    );
  }

  /**
   * @param params The `DalActionsService.ChangeDelegatorbySwappingWFIdParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `id`:
   *
   * @return Success
   */
  ChangeDelegatorbySwappingWFIdResponse(params: DalActionsService.ChangeDelegatorbySwappingWFIdParams): __Observable<__StrictHttpResponse<ValueResultDalChangeDelegatorProgress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/dals/${params.dalId}/actions/ChangeDelegatorbySwappingWFId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValueResultDalChangeDelegatorProgress>;
      })
    );
  }
  /**
   * @param params The `DalActionsService.ChangeDelegatorbySwappingWFIdParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `id`:
   *
   * @return Success
   */
  ChangeDelegatorbySwappingWFId(params: DalActionsService.ChangeDelegatorbySwappingWFIdParams): __Observable<ValueResultDalChangeDelegatorProgress> {
    return this.ChangeDelegatorbySwappingWFIdResponse(params).pipe(
      __map(_r => _r.body as ValueResultDalChangeDelegatorProgress)
    );
  }

  /**
   * @param dalId undefined
   * @return Success
   */
  GetDalChildrenByIdResponse(dalId: string): __Observable<__StrictHttpResponse<Array<Dal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${dalId}/actions/GetDalChildrenToProcess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Dal>>;
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  GetDalChildrenById(dalId: string): __Observable<Array<Dal>> {
    return this.GetDalChildrenByIdResponse(dalId).pipe(
      __map(_r => _r.body as Array<Dal>)
    );
  }

/**
   * @param params The `DalActionsService.ValidateDelegatorBySwappingParams` containing the following parameters:
   *
   * - `dalId`:
   *
   * - `transaction`:
   *
   * - `newParentDalId`:
   *
   * - `childs`:
   *
   * @return Success
   */
 ValidateDelegatorBySwappingResponse(params: DalActionsService.ValidateDelegatorBySwappingParams): __Observable<__StrictHttpResponse<ValueResultBoolean>> {
  let __params = this.newParams();
  let __headers = new HttpHeaders();
  let __body: any = null;

  if (params.transaction != null) __params = __params.set('transaction', params.transaction.toString());
  if (params.newParentDalId != null) __params = __params.set('newParentDalId', params.newParentDalId.toString());
  __body = params.childs;
  let req = new HttpRequest<any>(
    'POST',
    this.rootUrl + `/api/dals/${encodeURIComponent(String(params.dalId))}/actions/ValidateDelegatorBySwapping`,
    __body,
    {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

  return this.http.request<any>(req).pipe(
    __filter(_r => _r instanceof HttpResponse),
    __map((_r) => {
      return _r as __StrictHttpResponse<ValueResultBoolean>;
    })
  );
}
/**
 * @param params The `DalActionsService.ValidateDelegatorBySwappingParams` containing the following parameters:
 *
 * - `dalId`:
 *
 * - `transaction`:
 *
 * - `newParentDalId`:
 *
 * - `childs`:
 *
 * @return Success
 */
ValidateDelegatorBySwapping(params: DalActionsService.ValidateDelegatorBySwappingParams): __Observable<ValueResultBoolean> {
  return this.ValidateDelegatorBySwappingResponse(params).pipe(
    __map(_r => _r.body as ValueResultBoolean)
  );
}

  /**
   * @param dalId undefined
   * @return Success
   */
  PerformImpactAnalysisResponse(dalId: string): __Observable<__StrictHttpResponse<DalImpactAnalysis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/dals/${dalId}/actions/PerformImpactAnalysis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DalImpactAnalysis>;
      })
    );
  }
  /**
   * @param dalId undefined
   * @return Success
   */
  PerformImpactAnalysis(dalId: string): __Observable<DalImpactAnalysis> {
    return this.PerformImpactAnalysisResponse(dalId).pipe(
      __map(_r => _r.body as DalImpactAnalysis)
    );
  }
}

module DalActionsService {

  /**
   * Parameters for Post
   */
  export interface PostParams {
    dalId: string;
    dal?: Dal;
    actionName?: string;
  }

  /**
   * Parameters for Suspend
   */
  export interface SuspendParams {
    email: string;
    dalId: string;
    data?: SuspendRequest;
  }

  /**
   * Parameters for Reinstate
   */
  export interface ReinstateParams {
    dalId: string;
    reason?: string;
    comment?: string;
  }

  /**
   * Parameters for ReinstateDal
   */
  export interface ReinstateDalParams {
    reason: string;
    email: string;
    dalId: string;
  }

  /**
   * Parameters for AcceptMigration
   */
  export interface AcceptMigrationParams {
    email: string;
    dalId: string;
  }

  /**
   * Parameters for Acknowledge
   */
  export interface AcknowledgeParams {
    hasMigrationErrors: boolean;
    email: string;
    dalId: string;
  }

  /**
   * Parameters for Query
   */
  export interface QueryParams {
    email: string;
    dalId: string;
    queryComments?: Array<QueryComment>;
  }

  /**
   * Parameters for AcceptQuery
   */
  export interface AcceptQueryParams {
    reason: string;
    email: string;
    dalId: string;
  }

  /**
   * Parameters for RejectQuery
   */
  export interface RejectQueryParams {
    reason: string;
    email: string;
    dalId: string;
  }

  /**
   * Parameters for SendForReview
   */
  export interface SendForReviewParams {
    email: string;
    dalId: string;
    reviewerGroups?: Array<ReviewerGroup>;
    continueWithWarning?: boolean;
  }

  /**
   * Parameters for ChangeDelegatorbySkipping
   */
  export interface ChangeDelegatorbySkippingParams {
    dalId: string;
    transaction?: string;
    childs?: Array<string>;
  }

  /**
   * Parameters for ChangeDelegatorbySwapping
   */
  export interface ChangeDelegatorbySwappingParams {
    newParentDalId: string;
    dalId: string;
    transaction?: string;
    childs?: Array<string>;
  }

  /**
   * Parameters for ChangeDelegatorbySwappingWFInfo
   */
  export interface ChangeDelegatorbySwappingWFInfoParams {
    newParentDalId: string;
    dalId: string;
  }

  /**
   * Parameters for ChangeDelegatorbySwappingWFId
   */
  export interface ChangeDelegatorbySwappingWFIdParams {
    dalId: string;
    id?: string;
  }

  /**
   * Parameters for ValidateDelegatorBySwapping
   */
  export interface ValidateDelegatorBySwappingParams {
    dalId: string;
    transaction?: string;
    newParentDalId?: string;
    childs?: Array<string>;
  }
}

export { DalActionsService }
