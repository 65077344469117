/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Division } from '../models/division';
@Injectable({
  providedIn: 'root',
})
class DivisionService extends __BaseService {
  static readonly InsertDivisionPath = '/api/Division/InsertDivision';
  static readonly UpdateDivisionPath = '/api/Division/UpdateDivision';
  static readonly GetDivisionsPath = '/api/Division/GetDivisions';
  static readonly GetActiveDivisionsPath = '/api/Division/GetActiveDivisions';
  static readonly DisablePath = '/api/Division/Disable';
  static readonly EnablePath = '/api/Division/Enable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param division undefined
   * @return Success
   */
  InsertDivisionResponse(division?: Division): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = division;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Division/InsertDivision`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param division undefined
   * @return Success
   */
  InsertDivision(division?: Division): __Observable<boolean> {
    return this.InsertDivisionResponse(division).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param division undefined
   * @return Success
   */
  UpdateDivisionResponse(division?: Division): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = division;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Division/UpdateDivision`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param division undefined
   * @return Success
   */
  UpdateDivision(division?: Division): __Observable<boolean> {
    return this.UpdateDivisionResponse(division).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Success
   */
  GetDivisionsResponse(): __Observable<__StrictHttpResponse<Array<Division>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Division/GetDivisions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Division>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetDivisions(): __Observable<Array<Division>> {
    return this.GetDivisionsResponse().pipe(
      __map(_r => _r.body as Array<Division>)
    );
  }

  /**
   * @return Success
   */
  GetActiveDivisionsResponse(): __Observable<__StrictHttpResponse<Array<Division>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Division/GetActiveDivisions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Division>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetActiveDivisions(): __Observable<Array<Division>> {
    return this.GetActiveDivisionsResponse().pipe(
      __map(_r => _r.body as Array<Division>)
    );
  }

  /**
   * @param divisionId undefined
   * @return Success
   */
  DisableResponse(divisionId?: string): __Observable<__StrictHttpResponse<Division>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (divisionId != null) __params = __params.set('divisionId', divisionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Division/Disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Division>;
      })
    );
  }
  /**
   * @param divisionId undefined
   * @return Success
   */
  Disable(divisionId?: string): __Observable<Division> {
    return this.DisableResponse(divisionId).pipe(
      __map(_r => _r.body as Division)
    );
  }

  /**
   * @param divisionId undefined
   * @return Success
   */
  EnableResponse(divisionId?: string): __Observable<__StrictHttpResponse<Division>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (divisionId != null) __params = __params.set('divisionId', divisionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Division/Enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Division>;
      })
    );
  }
  /**
   * @param divisionId undefined
   * @return Success
   */
  Enable(divisionId?: string): __Observable<Division> {
    return this.EnableResponse(divisionId).pipe(
      __map(_r => _r.body as Division)
    );
  }
}

module DivisionService {
}

export { DivisionService }
