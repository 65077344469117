import { ProcessInformationalVariable } from "./process-informational-variable";
import { DalContentConfiguration } from "../shared.module";
import { Injectable } from "@angular/core";
import { ItemVariable } from "@app/api/models";

/**
 * [DCO] => Dal Code
 */
@Injectable({
  providedIn: 'root',
})
export class DalCodeInformationalVariable extends ProcessInformationalVariable {
    /**
     *
     */
    constructor() {
      super();
      this.infillCode = "[DCO]";
    }
  
    public getTextInformation(variable : ItemVariable, config : DalContentConfiguration) : string {

      if(config.code && config.code.trim() != "") 
        return config.code;
      else if (config.code == null || config.code.trim() == "")
        return "N/A";
    
      return this.infillCode;
    }
  }